import React from "react";
import OrgChart from '@balkangraph/orgchart.js';

import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

class OrgChartComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      displayBasic: false,
      displayModal: false,
      displayMaximizable: false,
      displayPosition: false,
      position: 'center',
      domainDesc:'',
      domainName:''
    }
    this.chart = null
    this.divRef = React.createRef();
    this.onHide = this.onHide.bind(this);
    this.pdf = this.pdf.bind(this);
  }

  componentDidMount() {
    if(this.props.pageID===21&&this.props.nodes.length!==0) {
      OrgChart.templates.domainTemplate = Object.assign({}, OrgChart.templates.polina);
      OrgChart.templates.domainTemplate.size = [250, 70];
      OrgChart.templates.domainTemplate.node = '<rect width="250" height="70" rx="50" ry="80" fill="#4d4d4d" stroke-width="1" stroke="#1C1C1C"></rect>';
      OrgChart.templates.domainTemplate.field_0 = '<text style="font-size: 30px;" fill="#ffffff" x="122" y="30" text-anchor="middle">{val}</text>';
      OrgChart.templates.domainTemplate.field_1 = '<text style="font-size: 18px;" fill="#ffffff" x="122" y="50" text-anchor="middle">{val}</text>';
      OrgChart.templates.domainTemplate.nodeMenuButton = '<g style="cursor:pointer;" transform="rotate(90) translate(27, -240)" control-node-menu-id="{id}"><rect x="-4" y="-10" fill="#000000" fill-opacity="0" width="22" height="22"></rect><circle cx="0" cy="0" r="2" stroke="#8e8e8e" fill="#8e8e8e" /><circle cx="8" cy="0" r="2" stroke="#8e8e8e" fill="#8e8e8e" /><circle cx="16" cy="0" r="2" stroke="#8e8e8e" fill="#8e8e8e" /></g>';

      var editForm = function () {
        this.nodeId = null;
      }

      editForm.prototype.init = (obj) => {
        this.obj = obj;
      }

      editForm.prototype.show = (nodeId) => {
        this.nodeId = nodeId;
        var node = this.chart.get(nodeId);
        this.setState({
          editDomainNode: true,
          domainName: node.Name,
          domainDesc: node.Description,
          displayBasic: true,
          thisNode: nodeId
        });
      }

      this.chart = new OrgChart(this.divRef.current, {
        template:'domainTemplate',
        nodes: this.props.nodes,
        enableDragDrop: true,
        enableSearch: false,
        nodeMouseClick: OrgChart.action.expandCollapse,
        // nodeContextMenu: {},
        nodeMenu:{
          edit: {text: "Details"},
          add:{text: "Add"},
          remove:{text: "Remove"}
        },
        editUI: new editForm(),
        nodeBinding: {
          field_0: "Name",
          field_1: "Description"
        }
      });
    } else if(this.props.pageID===11&&this.props.nodes.length!==0) {
      //template
      OrgChart.templates.masterSysTemplate = Object.assign({}, OrgChart.templates.ana);
      OrgChart.templates.masterSysTemplate.size = [200, 130];
      OrgChart.templates.masterSysTemplate.node = '<rect width="200" height="130" rx="20" ry="20" fill="#007AD9" stroke-width="1" stroke="#1C1C1C"></rect>';
      OrgChart.templates.masterSysTemplate.field_0 = '<text style="font-size: 30px;" fill="#ffffff" x="100" y="30" text-anchor="middle">{val}</text>';
      OrgChart.templates.masterSysTemplate.field_1 = '<text style="font-size: 18px;" fill="#ffffff" x="100" y="60" text-anchor="middle">Keys Cut: {val}</text>';
      OrgChart.templates.masterSysTemplate.field_2 = '<text style="font-size: 18px;" fill="#ffffff" x="100" y="85" text-anchor="middle">Cores Pinned: {val}</text>';
      OrgChart.templates.masterSysTemplate.field_3 = '<text style="font-size: 14px;" fill="#ffffff" x="100" y="105" text-overflow="ellipsis" width="190" text-anchor="middle">{val}</text>';

      //actual chart
      this.chart = new OrgChart(this.divRef.current, {
        menu: { 
          export_pdf: {
            text: "Export PDF",
            icon: OrgChart.icon.pdf(24, 24, "#7A7A7A"),
            onClick: () => this.pdf()
          }
        },
        template:'masterSysTemplate',
        layout: OrgChart.treeRightOffset,
        nodes: this.props.nodes,
        collapse: {
          level: 1,
          allChildren: true
        },
        nodeMouseClick: OrgChart.action.expandCollapse,
        nodeBinding: {
            field_0: "name",
            field_1: "keysCut",
            field_2: "coresPinned",
            field_3: "desc"
        }
      });
    }
  }  

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.treeLayout!==prevProps.treeLayout) {
      if(this.props.treeLayout==='OrgChart.normal') {
        this.chart.setLayout(OrgChart.treeRightOffset)
      } else {
        this.chart.setLayout(OrgChart.normal)
      }
    }
  }

  pdf(nodeId) {
    if(this.chart!==null) {
      OrgChart.pdfPrevUI.show(this.chart, {
        landscape: true,
        format: "A4",
        filename: `${this.props.systemName}.pdf`, 
        openInNewTab: false,
        expandChildren: false, 
        padding: 10,
        header: `${this.props.systemName}`,
        footer: 'Page {current-page} of {total-pages}',
        scale: 'fit'
        });
    }
    
  }

  onHide(name) {
    this.setState({
      [`${name}`]: false
    });
  }

  onSave(nodeIn, name) {
    var node = this.chart.get(nodeIn);
    node.Name = this.state.domainName;
    node.Description = this.state.domainDesc;
    this.chart.updateNode(node);
    this.onHide(name);
  }

  renderFooter(name) {
    return (
      <div>
        <Button label="Cancel" icon="pi pi-times" onClick={() => this.onHide(name)} />
        <Button label="Submit" icon="pi pi-check" onClick={() => this.onSave(this.state.thisNode, name)} autoFocus />
      </div>
    );
  }

  render() {
    return(
      <div className="dialog-demo">
        <div className="card">                    
          <Dialog 
            header="Edit" 
            visible={this.state.displayBasic} 
            style={{ width: '50vw' }} 
            footer={this.renderFooter('displayBasic')} 
            onHide={() => this.onHide('displayBasic')}
            id="editForm"
          > 
            <div className="p-field p-grid">
              <label htmlFor="domainName" className="p-col-fixed" style={{width:'100px'}}>Name</label>
              <div className="p-col">
                <InputText id="domainName" value={this.state.domainName} onChange={(e) => this.setState({domainName: e.target.value})} /> 
              </div>
            </div>
            <div className="p-field p-grid">
              <label htmlFor="domainDesc" className="p-col-fixed" style={{width:'100px'}}>Description</label>
                <div className="p-col">
                  <InputText id="domainDesc" value={this.state.domainDesc} onChange={(e) => this.setState({domainDesc: e.target.value})} /> 
                </div>
            </div>
          </Dialog>
        </div>
        <div id="tree" ref={this.divRef}></div>
      </div>
    );
  }
}

export default OrgChartComponent;