import React from 'react';
import GeneralDatatable from './GeneralDatatable';

class KeyHandInOutPeopleDatatable extends React.Component {
    constructor(props) {
        super(props);


        this.state={
            selectedColumns: [
                {field:'uiU_FN', header:'First Name'},
                {field:'uiU_LN', header:'Last Name'},
                {field:'uiU_EMAIL', header:'Email'},
                {field:'deptName', header:'Department'},
                {field:'uiU_TITLE', header:'Title'},
                {field:'uiU_PHONE', header:'Phone'},
                {field:'uiU_EMPLOYEEID', header:'Employee ID'},
                {field:'secRoleName', header:'Security Role '},
                {field:'uiU_NUMKEYS', header:'Keys Issued'}
            ],
            columnWidthTracker: "162.5,162.5,162.5,162.5,162.5,162.5,162.5,162.5,162.5",
            defaultOrder: ["uiU_FN", "uiU_LN", "uiU_EMAIL", "deptName", "uiU_TITLE", "uiU_PHONE", "uiU_EMPLOYEEID", "secRoleName", 'uiU_NUMKEYS']
        }
        
        this.handleWidth = this.handleWidth.bind(this);
        this.handleSelectedColumns = this.handleSelectedColumns.bind(this);

    }

    async componentDidMount() {
        let x = JSON.parse(sessionStorage.getItem("peopleTableData"));
        if(x !== null) {
            if(x.selectedColumns !== null) {
                this.setState({ selectedColumns: x.selectedColumns })
            }
        }
    }
       
    handleWidth(width) {
        this.setState({ columnWidthTracker: width })
    }

    handleSelectedColumns(e) {
        this.setState({ selectedColumns: e })
    }

    render() {
        if(this.props.peopleData.length<1) {
            return(<div>Key can not be handed out. Please add a user from the People page to hand a key out.</div>)
        } else {
            return (           
                <GeneralDatatable
                    columnArray = {[
                        {field:'uiU_FN', header:'First Name'},
                        {field:'uiU_LN', header:'Last Name'},
                        {field:'uiU_EMAIL', header:'Email'},
                        {field:'deptName', header:'Department'},
                        {field:'uiU_TITLE', header:'Title'},
                        {field:'uiU_PHONE', header:'Phone'},
                        {field:'uiU_EMPLOYEEID', header:'Employee ID'},
                        {field:'secRoleName', header:'Security Role '},
                        {field:'uiU_NUMKEYS', header:'Keys Issued'},
                    ]}                
                    
                    defaultOrder= {["uiU_FN", "uiU_LN", "uiU_EMAIL", "deptName", "uiU_TITLE", "uiU_PHONE", "uiU_EMPLOYEEID", "secRoleName", 'uiU_NUMKEYS']}

                    tableRecords = {this.props.peopleData}
                    pageNumber={this.props.pageNumber}
                    tableName={this.props.tableName}                
                    tableData={this.props.tableData}
                    filterData={this.props.filterData}
                    columnWidthTracker={this.state.columnWidthTracker}
                    isLoading={this.props.isLoading}
                    selectedColumns={this.state.selectedColumns}
                    handleRowClick={this.props.nextStep}
                    handleWidth={this.handleWidth}
                    handleSelectedColumns={this.handleSelectedColumns}    
                    hasButton={false}                          
                />            
            );
        }
    }
}

export default KeyHandInOutPeopleDatatable;