import React from 'react';

import Layout from '../components/Layout';

class Credentials extends React.Component {

    render(){
        return(
            <Layout>
                <h1>Credentials</h1>
            </Layout>
        );
    }
}

export default Credentials;