import React from 'react';

import Layout from '../components/Layout';
import Label from '../components/inputComponents/Label';
import DoorsComponent from '../components/DoorsComponent';
import TextBox from '../components/inputComponents/textbox';
import InputSelect from '../components/inputComponents/InputSelect';

import { InputText } from 'primereact/inputtext';
import {Messages} from 'primereact/messages';
import GeneralDatatable from '../components/GeneralDatatable';
import { CoreService } from '../services/FetchServices';
import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import { BrowserView, MobileView } from 'react-device-detect';

const statusOptions = [
    {value:'In Use', label:'In Use'},
    {value:'Do Not Use', label:'Do Not Use'},
    {value:'Other', label:'Other'},
    {value:'Not Used', label:'Not Used'}
]

let parsedUserData;

class Cores extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            coreData: [],
            isLoading: true,
            selectedColumns: [
                {field:'system', header:'System'},
                {field:'corE_MARK', header:'Core Marking'},
                {field:'sn', header:'S/N'},
                {field:'status', header:'Status'},
                {field:'keyway', header:'Keyway'},
                {field:'coreType', header:'Type'},
                {field:'loC_NAME', header:'Building'},
                {field:'dooR_NUMBER', header:'Door'}
            ],
            columnWidthTracker: "182.875,182.875,182.875,182.875,182.875,182.875,182.875,182.875",
            defaultOrder: ["system", "corE_MARK", "sn", "status", "keyway", "coreType", "loC_NAME", "dooR_NUMBER"],
            disabled: false
        };
        this.handleWidth = this.handleWidth.bind(this);
        this.handleSelectedColumns = this.handleSelectedColumns.bind(this);
        this.editRecord = this.editRecord.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.renderEditFooter = this.renderEditFooter.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.showResults = this.showResults.bind(this);
        this.fetchCoresProc = this.fetchCoresProc.bind(this);
    }


    async componentDidMount() {
        parsedUserData = JSON.parse(sessionStorage.getItem('userData'));

        if(parsedUserData.secString[10]==='2') {
            this.setState({disabled: true})
        }

        let parsedTableData = JSON.parse(sessionStorage.getItem('coresTableData'));

        if(parsedTableData!==null) {
            if(parsedTableData.selectedColumns !== null) {
                this.setState({ selectedColumns: parsedTableData.selectedColumns })
            }
        }

        await this.fetchCoresProc();

        this.setState({ isLoading: false, secRole: parsedUserData.secString[10] })
    }

    async fetchCoresProc() {
        let tempArray = [];
        let objArray = [];
        let coreType = '';
        await CoreService.fetchCores(0, parsedUserData.uiU_M1ORGID, parsedUserData.uiU_ID, parsedUserData.uiU_FN + ' ' + parsedUserData.uiU_LN, -1).then(data => tempArray = data.corelist)
        
        for(let i=0; i<tempArray.length; i++) {
            if(tempArray[i].excludE_CONTROL===false && tempArray[i].fixeD_CORE===false) {
                coreType='SFIC'
            } else if(tempArray[i].excludE_CONTROL===true && tempArray[i].fixeD_CORE===true) {
                coreType='FIXED'
            } else if(tempArray[i].excludE_CONTROL===true && tempArray[i].fixeD_CORE===false) {
                coreType='CAM'
            }

            objArray.push(
                {
                    "reQ_ID": tempArray[i].reQ_ID,
                    "reQ_NAME": tempArray[i].reQ_NAME,
                    "orG_ID": tempArray[i].orG_ID,
                    "dooR_ID": tempArray[i].dooR_ID,
                    "locatioN_ID": tempArray[i].locatioN_ID,
                    "loC_NAME": tempArray[i].loC_NAME,
                    "dooR_NUMBER": tempArray[i].dooR_NUMBER,
                    "system": tempArray[i].system,
                    "sn": tempArray[i].sn,
                    "corE_MARK": tempArray[i].corE_MARK,
                    "status": tempArray[i].status,
                    "product": tempArray[i].product,
                    "notes": tempArray[i].notes,
                    "deleted": tempArray[i].deleted,
                    "corE_MARK_ID": tempArray[i].corE_MARK_ID,
                    "uid": tempArray[i].uid,
                    "fulL_CODE": tempArray[i].fulL_CODE,
                    "keyway": tempArray[i].keyway,
                    "cross": tempArray[i].cross,
                    "excludE_CONTROL": tempArray[i].excludE_CONTROL,
                    "fixeD_CORE": tempArray[i].fixeD_CORE,
                    "corE_ID": tempArray[i].corE_ID,
                    "coreType": coreType
                }
            )
        }
        this.setState({ coreData: objArray })
    }

    handleWidth(width) {
        this.setState({ columnWidthTracker: width })
    }

    handleSelectedColumns(e) {
        this.setState({ selectedColumns: e })
    }

    async editRecord(e) {
        if(this.state.secRole!=='1' && this.state.secRole!=='0') {
            await CoreService.fetchPinnedCores(parsedUserData.uiU_M1ORGID, e.data.system, e.data.corE_MARK_ID, e.data.coreType)
            .then(data => 
                this.setState({
                    keyCutsString: data.keyCutsString,
                    pinString: data.pinString,
                    editCore: true,
                    coreMark: e.data.corE_MARK,
                    serialNum: e.data.sn,
                    type: e.data.coreType,
                    status: e.data.status,
                    product: e.data.product,
                    notes: e.data.notes,
                    system: e.data.system,
                    coreMarkID: e.data.corE_MARK_ID,
                    coreID: e.data.corE_ID
                })
            )
        }
    }

    handleClose() {
        this.setState({
            editCore: false,
            coreMark: '',
            serialNum: '',
            type: '',
            status: '',
            product: '',
            notes: '',
            system: '',
            coreMarkID: '',
            keyCutsString: '',
            pinString: '',
            coreID: ''
        })
    }

    async handleSave() {
        await CoreService.saveCore(parsedUserData.uiU_M1ORGID, this.state.coreID, this.state.status, this.state.product, this.state.notes).then(data => this.showResults(data));
        this.fetchCoresProc();
        this.handleClose();
    }

    showResults(data) {
        this.messages.clear();
        if(data.success) {
            this.messages.show({severity: 'success', summary: data.message, life:1000});
        } else {
            this.messages.show({severity: 'error', summary: data.message, sticky: true});
        }
    }

    handleFieldChange(e) {
        if(e.target.id==='notes') {
            this.setState({ notes: e.target.value })
        } else if(e.target.id==='product') {
            this.setState({ product: e.target.value })
        } else if(e.target.id==='status') {
            this.setState({ status: e.target.value })
        }
    }

    renderEditFooter() {
        return(
            <div>
                {this.state.disabled ? (null) : (<Button type='button' icon='pi pi-check' label='Save and Exit' onClick={this.handleSave} />)}
                <Button type='button' icon='pi pi-times' label='Exit' onClick={this.handleClose} />
            </div>
        )
    }

    render() {
        const editFooter = this.renderEditFooter();
        if(this.state.secRole==='0' && !this.state.isLoading) {
            return(<Layout><div>Security Role Prevents You From Seeing Data</div></Layout>)
        } else if(this.state.coreData.length===0 && !this.state.isLoading) {
            return(<Layout>Contact Midwest Security Products to add Cores</Layout>)
        } else {
            return(
                <div>
                    <Layout>
                        <Messages ref={(el) => this.messages = el} />
                        <GeneralDatatable 
                            columnArray = {[
                                {field:'system', header:'System'},
                                {field:'corE_MARK', header:'Core Marking'},
                                {field:'sn', header:'S/N'},
                                {field:'status', header:'Status'},
                                {field:'keyway', header:'Keyway'},
                                {field:'coreType', header:'Type'},
                                {field:'loC_NAME', header:'Building'},
                                {field:'dooR_NUMBER', header:'Door'}
                            ]}

                            csvHeaders = {[
                                {key:'system', label:'System'},
                                {key:'corE_MARK', label:'Core Marking'},
                                {key:'sn', label:'S/N'},
                                {key:'status', label:'Status'},
                                {key:'keyway', label:'Keyway'},
                                {key:'coreType', label:'Type'},
                                {key:'loC_NAME', label:'Building'},
                                {key:'dooR_NUMBER', label:'Door'}
                            ]}
                            
                            defaultOrder={["system", "corE_MARK", "sn", "status", "keyway", "coreType", "loC_NAME", "dooR_NUMBER"]}

                            tableRecords = {this.state.coreData}
                            pageNumber={5}
                            tableName={'Cores'}
                            exportFileName={'cores.csv'}
                            tableData={'coresTableData'}
                            filterData={'coresFilterData'}
                            columnWidthTracker={this.state.columnWidthTracker}
                            isLoading={this.state.isLoading}
                            selectedColumns={this.state.selectedColumns}
                            handleRowClick={this.editRecord}
                            handleWidth={this.handleWidth}
                            handleSelectedColumns={this.handleSelectedColumns}   
                        />
                    </Layout>
                    <Dialog header={'Core Information - ' + this.state.coreMark} visible={this.state.editCore} onHide={this.handleClose} baseZIndex={1300} maximized focusOnShow={false} footer={editFooter} closable={false}>
                        <BrowserView>
                            <div className='p-grid p-justify-even'>
                                <div className='p-col-4 p-grid'>
                                    <Label label={'Core Marking: '} value={this.state.coreMark} />
                                    <Label label={'Serial Number: '} value={this.state.serialNum} />
                                    <Label label={'Type: '} value={this.state.type} />
                                    <div className='p-col-12 p-grid'>
                                        <label className="p-col-6" style={{textAlign:'right'}}>
                                            <strong>{"Product: "}</strong>
                                        </label>
                                        <InputText disabled={this.state.disabled} className='p-col-6'value={this.state.product} onChange={this.handleFieldChange} type='text' id={'product'} />
                                    </div>
                                </div>
                                <div className='p-col-3 p-grid p-justify-start'>
                                    <InputSelect value={this.state.status} id={'status'} handleFieldChange={this.handleFieldChange} label={'Status: '} options={statusOptions} threeCol disabled={this.state.disabled} />
                                    <TextBox id={'notes'} value={this.state.notes} handleFieldChange={this.handleFieldChange} label={"Notes: "} disabled={this.state.disabled} threeCol area rows={5} />
                                </div>
                                <div className='p-col-5'>
                                    <div className='p-grid p-justify-center p-nogutter no-bmargin'>
                                        <p className='p-col-4 no-bmargin' style={{textAlign:'left' }}>
                                            Key Access
                                            <pre className='no-bmargin'>{this.state.keyCutsString}</pre> 
                                        </p>
                                        <p className='p-col-4 no-bmargin'>
                                            Pinning
                                            <pre className='no-bmargin'>{this.state.pinString}</pre>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='p-grid'>
                                <div className='p-col-12'>
                                    <DoorsComponent 
                                        doorID={0}
                                        buildingID={0}
                                        coreMarkID={this.state.coreMarkID}
                                        keymark={""}
                                        keyway={""}
                                        system={""}
                                        isComponent={true}
                                        hasButton={false}
                                        header={'Doors Installed With Cores Pinned To Core Record ' + this.state.coreMark}
                                        isCores
                                    />
                                </div>
                            </div>
                        </BrowserView>
                        <MobileView>
                            <div className='p-grid p-justify-even'>
                                <Label mobile label={'Core Marking: '} value={this.state.coreMark} />
                                <Label mobile label={'Serial Number: '} value={this.state.serialNum} />
                                <Label mobile label={'Type: '} value={this.state.type} />
                                <TextBox mobile value={this.state.product} id="product" label={"Product:"} handleFieldChange={(e) => this.handleFieldChange(e)} disabled={this.state.disabled} />
                                <InputSelect mobile value={this.state.status} id={'status'} handleFieldChange={this.handleFieldChange} label={'Status: '} options={statusOptions} disabled={this.state.disabled} />
                                <TextBox mobile area rows={5} value={this.state.notes} id="notes" label={"Notes:"} handleFieldChange={(e) => this.handleFieldChange(e)} disabled={this.state.disabled} />
                                <div className='p-col-12'>
                                    <div className='p-grid p-justify-center p-nogutter no-bmargin'>
                                        <p className='p-col-6 no-bmargin' style={{textAlign:'left' }}>
                                            Key Access
                                            <pre className='no-bmargin'>{this.state.keyCutsString}</pre> 
                                        </p>
                                        <p className='p-col-12 no-bmargin'>
                                            Pinning
                                            <pre className='no-bmargin'>{this.state.pinString}</pre>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='p-grid'>
                                <div className='p-col-12'>
                                    <DoorsComponent 
                                        doorID={0}
                                        buildingID={0}
                                        coreMarkID={this.state.coreMarkID}
                                        keymark={""}
                                        keyway={""}
                                        system={""}
                                        isComponent={true}
                                        hasButton={false}
                                        header={'Doors Installed With Cores Pinned To Core Record ' + this.state.coreMark}
                                        isCores
                                    />
                                </div>
                            </div>
                        </MobileView>
                    </Dialog>
                </div>
            );
        }
    }
}

export default Cores;