import React from 'react';

import Layout from '../components/Layout';
import GeneralDatatable from '../components/GeneralDatatable';
import {DomainService} from '../services/FetchServices';
import DomainChart from '../components/DomainChart';
import {TabView,TabPanel} from 'primereact/tabview';

class Domains extends React.Component {
    constructor(props) {        
        super(props);

        this.state={
            selectedColumns:[
                {field:'domain', header:'Domain'},
                {field:'description', header:'Description'},
                {field:'notes', header:'Notes'}
            ],
            columnWidthTracker:"487.6,487.6,487.6",
            data: [],
            isLoading: true
        }

        this.handleWidth = this.handleWidth.bind(this);
        this.handleSelectedColumns = this.handleSelectedColumns.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
    }

    async componentDidMount() {
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
        let parsedTableData = JSON.parse(sessionStorage.getItem('domainsTableData'));
        
        if(parsedTableData!==null) {
            if(parsedTableData.selectedColumns !== null) {
                this.setState({ selectedColumns: parsedTableData.selectedColumns })
            }
        }  

        await DomainService.fetchDomains( parsedUserData.uiU_M1ORGID, 0, parsedUserData.uiU_ID, parsedUserData.uiU_FN + parsedUserData.uiU_LN, "")
        .then(data => this.setState({ data: data.domainslist, isLoading: false }));    
    }

    handleWidth(width) {
        this.setState({ columnWidthTracker: width })
    }

    handleSelectedColumns(e) {
        this.setState({ selectedColumns: e })
    }

    handleRowClick() {

    }
    
    render(){
        return(
            <div>
            {(this.state.data.length===0 && !this.state.isLoading) ? (<Layout>Coming Soon</Layout>) : (
                <div>
                    <Layout>
                        <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({activeIndex: e.index})}>
                            <TabPanel header='Data Table'>
                                <div>
                                    <GeneralDatatable 
                                        columnArray = {[
                                            {field:'domain', header:'Domain'},
                                            {field:'description', header:'Description'},
                                            {field:'notes', header:'Notes'}
                                        ]}

                                        csvHeader = {[
                                            {key:'domain', label:'Domain'},
                                            {key:'description', label:'Description'},
                                            {key:'notes', label:'Notes'}
                                        ]}
                                        
                                        defaultOrder={["domain", "description", 'notes']}

                                        tableRecords = {this.state.data}
                                        pageNumber={21}
                                        tableName={'Domains'}
                                        exportFileName={'Domains.csv'}
                                        tableData={'domainsTableData'}
                                        filterData={'domainsFilterData'}
                                        columnWidthTracker={this.state.columnWidthTracker}
                                        isLoading={this.state.isLoading}
                                        selectedColumns={this.state.selectedColumns}
                                        handleRowClick={this.handleRowClick}
                                        handleWidth={this.handleWidth}
                                        handleSelectedColumns={this.handleSelectedColumns}   
                                        dataKey={'domaiN_ID'}
                                    />
                                </div>
                            </TabPanel>
                            <TabPanel header='Visual Chart'>
                                <div>
                                    <DomainChart domainData={this.state.data} />
                                </div>
                            </TabPanel>
                        </TabView>
                    </Layout>
                </div>
            )}
            </div>
        );
    }
}

export default Domains;