import React from 'react';

///9-4-20

class Eula extends React.Component {

    render() {
        return(
            <div>
                <h2>
                    Acceptance of Terms
                </h2>
                <p>
                    LockViewer Enterprise is an online key records management service (the "Service") provided by Midwest Security Products, Inc. (MWSPI). This service is provided to active End Users (“the End User”) of MWSPI, subject to the following End User License Agreement (EULA) and Terms of Service (TOS).
                </p>
                <p>
                    Upon initial login to LockViewer Enterprise, or whenever the EULA or TOS change, the End User is required to accept these terms in order to access the software.
                </p>
                <p>
                    This EULA ends whenever End User access to LockViewer Enterprise is terminated by MWSPI.
                </p>
                <p>
                    The most current version of the EULA and TOS is available for your review by clicking on the link located on the LockViewer Enterprise website home page. You are responsible for checking these terms periodically for changes.
                </p>
                <h2>
                    Description of Service
                </h2>
                <p>
                    All software and web sites related to the software is the property of MWSPI. The functionality and information displayed is controlled by MWSPI and may be altered from time to time at our discretion. Access is offered in an “AS IS” condition. All data of the End User is the property of the End User and is available upon request by authorized personnel.
                </p>
                <h2>
                    End User Account, Password and Security
                </h2>
                <p>
                    As a selected End User with access rights to Enterprise.LockViewer.com, you will receive a password and account designation. You are responsible for maintaining the confidentiality of the password and agree to (a) immediately notify MWSPI of any unauthorized use of your password or account or any other breach of security, and (b) ensure that you exit from your account at the end of each session. MWSPI cannot and will not be liable for any loss or damage arising from your failure to comply with this section.
                </p>
                <p>
                    You acknowledge and agree that MWSPI may preserve content and may also disclose content if required to do so by law or in the good faith belief that such preservation or disclosure is reasonably necessary to: (a) comply with legal process; (b) enforce the TOS; (c) respond to claims that any content violates the rights of third-parties.
                </p>
                <p>
                    You understand that the technical processing and transmission of the Service, including your content, may involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices.
                </p>
                <h2>
                    Links  
                </h2>
                <p>
                    The Service may provide, or third parties may provide, links to other World Wide Web sites or resources. Because MWSPI has no control over such sites and resources, you acknowledge and agree that MWSPI is not responsible for the availability of advertising, products, other materials on or available from such sites or resources. You further acknowledge and agree that MWSPI shall not be responsible or liable, directly or indirectly, for any damage or loss caused by or in connection with use of or reliance on any such content, goods or services available on or through any such site or resource.
                </p>
                <h2>
                    Limitation of Liability
                </h2>
                <p>
                    Under no circumstances will MWSPI or any of its affiliates be liable to End Users for any special, indirect, incidental, consequential, punitive, or exemplary damages, including any claims for business interruption, lost profits, loss of use or data, diminution of value, or lost business opportunities, in connection with this agreement, however caused and whatever theory of liability (including negligence), even if the End User has been advised of the possibility of such damages.
                </p>
                <p>
                    To the maximum extent permitted by law, MWSPI’s total liability under this agreement, for whatever cause, whether in an action in contract or in tort or otherwise, will be limited to direct money damages and will in no event exceed an amount equal to the greater of (a) the aggregate of all fees actually paid or owing under this agreement by the End User to MWSPI during the twelve month period preceding the date of the event giving rise to the claim or (b) one hundred dollars ($100).
                </p>
                <h2>
                    MWSPI Warranties and limitations
                </h2>
                <p>
                    Each party (MWSPI, and End User) represents and warrants to the other party : (a) such party has the full and unencumbered right, power, and authority to enter into this Agreement and to otherwise carry out its obligations under this Agreement; (b) this Agreement constitutes the legal, valid, and binding obligation of such Party, enforceable against it in accordance with its terms; and (c) the execution, delivery, or performance of this Agreement does not violate, conflict with, or constitute a default under any agreement or instrument to which it is a party or by which it is otherwise bound. 
                </p>
                <p>
                    The warranties set forth in this section are the sole warranties provided by MWSPI in connection with Lockviewer Enterprise Software on an “as is” and “as available” basis without warranty of any kind. to the maximum extent permitted by applicable law, MWSPI expressly disclaims all other representations, warranties, conditions, and guaranties of any kind, either express or implied, oral or written, with respect to this agreement, Lockviewer Enterprise Software, or any other items or services covered by or furnished under this agreement, including all implied warranties of merchantability, fitness for a particular purpose, non-infringement, or arising from course of performance, course of dealing, or usage of trade. MWSPI does not warrant that the Lockviewer Enterprise Software will be uninterrupted or error free, be compatible or work with any hardware, software, device, system or services, or be secure, accurate, complete, free of any virus, malware or other harmful code and makes no warranties as to the availability of the Lockviewer Enterprise Software, End User’s ability to access the Lockviewer Enterprise Software, or any loss, damage or unauthorized changes to any company data.  
                </p>
                <h2>
                    Acceptable Use
                </h2>
                <p>
                    This agreement prohibits the End User from, and allows MWSPI to suspend provision of the Lockviewer Enterprise Software for: (i) using the Lockviewer Enterprise Software for any illegal purpose or in violation of any applicable law or regulation, (ii) participating in a denial-of-service attack on the Lockviewer Enterprise Software, (iii) accessing the Lockviewer Enterprise Software in a fraudulent manner, including by using any falsified device identifier; (iv) renting, leasing, or reselling its access to Lockviewer Enterprise Software; (v) circumventing, disabling, or impairing any feature of the Lockviewer Enterprise Software,  that provides or enhances security, restricts access, protects privacy, or monitors use; (vi) materially disrupting or impairing the operation of the Lockviewer Enterprise Software, or service to others by any means including introduction of malicious code; or (vii) attempting to do, or assisting any third party to do, any of the foregoing.
                </p>
                <h2>
                    Use Restrictions
                </h2>
                <p>
                    The End User shall not do any of the following:
                    <ul>
                        <li>
                            Reverse engineer, decompile, disassemble, or attempt to derive source code or any other type of information from the Lockviewer Enterprise Software, except as explicitly provided in this Agreement.
                        </li>
                    </ul>
                </p>
                <p>
                    Unless explicitly stated otherwise, any new features that augment or enhance the current Lockviewer Enterprise Software shall be subject to the TOS. In order to use Lockviewer Enterprise Software you must obtain access to the World Wide Web and pay any service fees associated with such access. In addition, you must provide all equipment necessary to make such connection to the World Wide Web, including a computer and modem or other access device.
                </p>
            </div>
        );
    }
}

export default Eula;