import React from 'react';

import KeysComponent from '../components/KeysComponent';

//Page 3 for API, page 2 for internal routing
class Keys extends React.Component {
    constructor(props) {
        super(props);

        this.state={

        }

    }

    render() {
        return(
            <div>
                <KeysComponent employeeID={0} recordID={0} pageID={3} />
            </div>
        );
    }
}

export default Keys;