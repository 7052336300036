import React from 'react';
import validator from 'email-validator';

import Layout from '../components/Layout';
import RolesEdit from '../components/RolesEdit';
import { RolesService } from '../services/FetchServices';
import GeneralDatatable from '../components/GeneralDatatable';
import { PeopleService } from '../services/FetchServices';
import PeopleDatatable from '../components/PeopleDatatable';

import {InputText} from 'primereact/inputtext';
import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import {Messages} from 'primereact/messages';
import {InputTextarea} from 'primereact/inputtextarea';
import {InputMask} from 'primereact/inputmask';
import { TabView,TabPanel } from 'primereact/tabview';

let tempUserArray=[];
let securityRoleOption=[{label: 'SuperAdmin', value: 0 }];

//Page 15 for API, page 14 for internal routing
class SecurityRoles extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            roles: [],
            roleOptions:[],
            peopleData: '',
            globalFilter: null,
            isLoading: true,
            displayEdit: false,
            detailRoleId: 0,
            detailName: '',
            detailNotes: '',
            detailOrg: '',
            selectedColumns: [
                {field:'uiS_NAME', header:'Role Name'},
                {field:'numPeople', header:'Number of Users'},
                {field:'uiS_NOTES', header:'Notes'}
            ],
            defaultOrder:["uiS_NAME", "numPeople", "uiS_NOTES"],
            columnWidthTracker: "487.6,487.6,487.6",
            securityString: '',
            blankError: false,
            displayUsers: false,
            users: [''],
            numberUsers: 0,
            displayUserEdit: false,
            secRole: '',
            peopleSecRole: '',
            disabled: false,
            hasButton: true,
            activeIndex: 0,
            peopleIsLoading: true
        }
        
        this.handleWidth = this.handleWidth.bind(this);
        this.handleSelectedColumns = this.handleSelectedColumns.bind(this);
        this.editRecord = this.editRecord.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleNotesChange = this.handleNotesChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.showResults = this.showResults.bind(this);
        this.handleRadio = this.handleRadio.bind(this);
        this.updateRole = this.updateRole.bind(this);
        this.handleRoleDelete = this.handleRoleDelete.bind(this);
        this.handleUserEdit = this.handleUserEdit.bind(this);
        this.handleUserClose = this.handleUserClose.bind(this);
        this.handleUserSave = this.handleUserSave.bind(this);
        this.saveRoleDelete = this.saveRoleDelete.bind(this);
        this.handleDeleteCancel = this.handleDeleteCancel.bind(this);
    }

    async componentDidMount() {
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
                
        if(parsedUserData.secString[14]==='2') {
            this.setState({ disabled: true, hasButton: false })
        }

        let parsedTableData = JSON.parse(sessionStorage.getItem("rolesTableData"));
        if(parsedTableData!==null) {
            if(parsedTableData.selectedColumns !== null) {
                this.setState({ selectedColumns: parsedTableData.selectedColumns })
            }
        }

        await RolesService.fetchRoles( parsedUserData.uiU_M1ORGID )
            .then(data => 
                this.setState({ 
                    roles: data.roles, 
                    isLoading: false, 
                    detailOrg: parsedUserData.uiU_M1ORGID,
                    secRole: parsedUserData.secString[14],
                    peopleSecRole: parsedUserData.secString[13]
                }));
    }

    handleWidth(width) {
        this.setState({ columnWidthTracker: width })
    }

    handleSelectedColumns(e) {
        this.setState({ selectedColumns: e })
    }

    showResults(data) {
        if(data) {
            this.messages.show({severity: 'success', summary: 'Successfully Saved', life:1000});
        } else {
            this.messages.show({severity: 'error', summary: 'Save Unsuccessful', life:1000});
        }
    } 
    
    showUserResults(data) {
        if(data.success) {
            this.messages.show({severity: 'success', summary: data.message, life:1000});
            this.setState({ 
                displayUserEdit: false, 
                saveError: false, 
                msg:'',
                displayUsers: false
            })
        } else {
           this.setState({ 
               saveError: true,
                msg: data.message
            })
        }  
    }  

    showRoleResults(data) {
        if(data.success===false) {
            this.messages.show({severity: 'error', summary: data.message, life:3000});
        }
    }

    async editRecord(e) {
        if(this.state.secRole==='1') {
            return;
        } else {
            if(e==='New') {
                this.setState({ 
                    displayEdit: true,
                    securityString: "00000000000000000000000000000000000000000000000000",
                    title: 'New Security Role'
                })
            } else if(e.data.noedit===false) {
                this.setState({ 
                    securityString: e.data.uiS_SECSTR,
                    detailRoleId: e.data.uiS_ID,
                    detailName: e.data.uiS_NAME,
                    detailNotes: e.data.uiS_NOTES,
                    displayEdit: true,
                    title: e.data.uiS_NAME,
                    deleteNumPeople: e.data.numPeople,
                })
            }
    
            if(e!=='New') {
                if(e.data.numPeople===0) {
                    this.setState({ 
                        users: [],
                        numberUsers: 0,
                        peopleIsLoading: false
                    })
                } else {
                    let peopleData = await PeopleService.fetchUsers( this.state.detailOrg, "", 0 );
                    tempUserArray=[];
                    for(let i=0; i<peopleData.user.length; i++) {
                        if(e.data.uiS_ID===peopleData.user[i].uiU_SECROLEID) {
                            tempUserArray.push(peopleData.user[i])
                        }
                    }
                    this.setState({ 
                        users: tempUserArray, 
                        numberUsers: e.data.numPeople, 
                        peopleIsLoading: false 
                    })
                }
            }
        }
    }

    handleClose() {
        this.setState({ 
            role: '', 
            displayEdit: false,
            detailName: '', 
            detailNotes: '', 
            detailRoleId:0, 
            securityString:'',
            blankError: false,
            displayUsers: false,
            displayDelete: false,
            deleteNumPeople: '',
            deleteRoleID: '',
            activeIndex: 0,
            users: [],
            numberUsers: 0,
            peopleIsLoading: true
        })
    }

    handleNameChange(e) {
        if(e.target.id==='fname') {
            this.setState({ userFName: e.target.value })
        } else if(e.target.id==='lname') {
            this.setState({ userLName: e.target.value })
        } else if(e.target.id==='email') {
            this.setState({ userEmail: e.target.value })
        } else if(e.target.id==='phone') {
            this.setState({ userPhone: e.target.value })
        } else if(e.target.id==='dept') {
            this.setState({ userDept: e.target.value })
        } else if(e.target.id==='title') {
            this.setState({ userTitle: e.target.value })
        } else if(e.target.id==='username') {
            this.setState({ userNotes: e.target.value })
        } else if(e.target.id==='employeeId') {
            this.setState({ userEmployeeId: e.target.value })
        } else if(e.target.id==='securityrole') {
            this.setState({ userSecurityRole: e.target.value })
        } else if(e.target.id==='status') {
            return;
        } else if(e.target.id==='domain') {
            return;
        } else if(e.target.id==='dname') {
            this.setState({ detailName: e.target.value })
        }
    }

    handleNotesChange(e){
        if(e.target.id==='notes') {
            this.setState({ userNotes: e.target.value })
        } else if(e.target.id==='dNotes') {
            this.setState({ detailNotes: e.target.value })
        }
        
    }

    handleRadio(e) {
        let securityStringVar = this.state.securityString.split('');
        securityStringVar[e.target.id] = e.value;
        securityStringVar = securityStringVar.join('');
        this.setState({ securityString: securityStringVar });
    }

    updateRole = async e => {
        if(this.state.detailName === null || this.state.detailName==='') {
            this.setState({ blankError: true })
        } else {
            await RolesService.updateRoles(this.state.detailRoleId, this.state.detailName, this.state.detailNotes, this.state.detailOrg, this.state.securityString).then(data => this.showResults(data));
            await RolesService.fetchRoles( this.state.detailOrg ).then(data => this.setState({ roles: data.roles, displayEdit: false, detailName: '', detailNotes: '', detailRoleId:0, securityString:'', blankError: false }));
        }
    }

    renderEditFooter() {
        if(this.state.secRole!=='3') {
            return(
                <div style={{float:'right'}}><Button label='Exit' icon='pi pi-times' onClick={() => this.handleClose()}/></div>
            );
        } else {
            return(
                <div>
                    {this.state.blankError ? (<span style={{color: 'red', marginRight:'20px'}}>Name Cannot Be Blank</span>) : (null) }
                    {this.state.title==='New Security Role' ? (null) : (<Button label='Delete' icon='pi pi-trash' onClick={() => this.handleRoleDelete()}/>)}
                    <Button label='Save and Exit' icon='pi pi-check' onClick={() => this.updateRole()}/>
                    <Button label='Exit' icon='pi pi-times' onClick={() => this.handleClose()}/>
                </div>

            );
        }
    }

    renderUsersFooter() {
        return(
                <Button label='Exit'onClick={this.handleClose}/>
        );
    }

    handleUserEdit(user) {  
        if(this.state.peopleSecRole==='1') {
            return;
        } else if(this.state.peopleSecRole==='2') {
            this.setState({
                displayUserEdit2: true,
                userOrg: user.data.uiU_M1ORGID,
                userID: user.data.uiU_ID,
                userFName: user.data.uiU_FN,
                userLName: user.data.uiU_LN,
                userEmail: user.data.uiU_EMAIL,
                userNotes: user.data.uiU_NOTES,
                userDept: user.data.deptName,
                userTitle: user.data.uiU_TITLE,
                userPhone: user.data.uiU_PHONE,
                userEmployeeId: user.data.uiU_EMPLOYEEID,
                userSecurityRole: user.data.uiU_SECROLEID,
                userStatus: user.data.status,
                userDomain: '',
            })
        } else {
            securityRoleOption = this.state.roles.map((option, i) => {
                return {label: option.uiS_NAME, value: option.uiS_ID }
            });
    
            this.setState({
                roleOptions: [{label: 'None', value:-1}].concat(securityRoleOption),
                displayUserEdit: true,
                userOrg: user.data.uiU_M1ORGID,
                userID: user.data.uiU_ID,
                userFName: user.data.uiU_FN,
                userLName: user.data.uiU_LN,
                userEmail: user.data.uiU_EMAIL,
                userNotes: user.data.uiU_NOTES,
                userDept: user.data.deptName,
                userTitle: user.data.uiU_TITLE,
                userPhone: user.data.uiU_PHONE,
                userEmployeeId: user.data.uiU_EMPLOYEEID,
                userSecurityRole: user.data.uiU_SECROLEID,
                userStatus: user.data.status,
                userDomain: '',
            })
        }
    }

    handleUserClose() {
        this.setState({
            displayUserEdit: false,
            displayUserEdit2: false,
            userOrg: '',
            userID: '',
            userFName: '',
            userLName: '',
            userEmail: '',
            userNotes: '',
            userDept: '',
            userTitle: '',
            userPhone: '',
            userEmployeeId: '',
            userSecurityRole: '',
            userStatus: '',
            userDomain: '',
            nameError: false,
            emailError: false
        })
    }

    async handleUserSave() {
        let userData = JSON.parse(sessionStorage.getItem('userData'));

        if(validator.validate(this.state.userEmail)===false || this.state.userFName===null || this.state.userFName==='' || this.state.userLName === null || this.state.userLName==='') {
            if(validator.validate(this.state.userEmail)===false) {
                this.setState({ emailError: true })
            } else {
                this.setState({ emailError: false })
            }
            
            if(this.state.userFName===null || this.state.userFName==='' || this.state.userLName === null || this.state.userLName==='') {
                this.setState({ nameError: true })
            } else {
                this.setState({ nameError: false })
            }
        } else {
            this.setState({
                emailError: false,
                nameError: false
            })
            
            await PeopleService.storeUser(this.state.userID, this.state.userOrg, this.state.userFName, this.state.userLName, 
                this.state.userEmail, 1, this.state.userTitle, this.state.userPhone, this.state.userEmployeeId, 
                this.state.userSecurityRole, this.state.userNotes, 1
            ).then(data => this.showUserResults(data))

            let peopleData = await PeopleService.fetchUsers( this.state.detailOrg, "", 0 );
            tempUserArray=[];
            for(let i=0; i<peopleData.user.length; i++) {
                if(this.state.detailRoleId===peopleData.user[i].uiU_SECROLEID) {
                    tempUserArray.push(peopleData.user[i])
                }
            }

            await RolesService.fetchRoles(userData.uiU_M1ORGID).then(data => 
                this.setState({ 
                    users: tempUserArray,
                    roles: data.roles,
                    displayUserEdit: false,
                    userFName: '', 
                    userLName: '',
                    userEmail: '',
                    userNotes: '',
                    userDept: '',
                    userTitle: '',
                    userPhone: '',
                    userEmployeeId: '',
                    userSecurityRole: '',
                    userStatus: '',
                    emailError: false,
                    nameError: false
                })
            )
        }
    }

    renderUserEditFooter() {
        return(
            <div className='p-grid'>
                <div className='p-col-12 p-md-8'>
                    <div>{this.state.saveError ? (<div style={{color: 'red'}}>{this.state.msg}</div>) : (null)}</div>
                    <div>{this.state.emailError ? (<div style={{color: 'red'}}>Must enter a valid email</div>) : (null) }</div>
                    <div>{this.state.nameError ? (<div style={{color: 'red'}}>Name fields can not be blank</div>) : (null) }</div>
                </div>
                {this.state.peopleSecRole==='3' ? (<div className='p-col-6 p-md-2'><Button label='Save and Exit' icon='pi pi-check' onClick={this.handleUserSave} /></div>) : (null)}
                <div className='p-col-6 p-md-2'><Button label='Exit' icon='pi pi-times' onClick={this.handleUserClose}/></div>
            </div>
        );
    }

    handleRoleDelete() {
        this.setState({ 
            displayDelete: true
        })
    }

    handleDeleteCancel() {
        this.setState({displayDelete: false})
    }

    async saveRoleDelete() {
        await RolesService.deleteRole(this.state.detailRoleId, this.state.detailOrg).then(data => this.showRoleResults(data));
        await RolesService.fetchRoles( this.state.detailOrg ).then(data => this.setState({ roles: data.roles }));
        this.handleClose();
    }

    renderDeleteFooter() {
        if(this.state.deleteNumPeople>0) {
            return(
                <div><Button type='button' label='OK' onClick={() => this.handleDeleteCancel()}/></div>
            );
        } else {
            return(
                <div>
                    <Button type='button' icon='pi pi-trash' label='Delete' onClick={() => this.saveRoleDelete()}/>
                    <Button type='button' icon='pi pi-times' label='Cancel' onClick={() => this.handleDeleteCancel()}/>
                </div>
            );
        }
    }

    render() {
        const {displayEdit, displayUserEdit, displayDelete} = this.state;
        const editFooter=this.renderEditFooter();
        const userEditFooter = this.renderUserEditFooter();
        const deleteFooter=this.renderDeleteFooter();
        if((this.state.secRole==='' && this.state.isLoading===false) || (this.state.secRole==='0' && this.state.isLoading===false)) {
            return (
                <Layout><div>Security Role prevents you from seeing data</div></Layout>
            );
        } else {
            return (
                <div>
                {(this.state.roles.length===0 && this.state.isLoading===false) ? (<Layout><Button type='button' icon='pi pi-plus' label='New Role' onClick={() => this.editRecord('New')}/></Layout>) : (
                        <Layout>
                            <div className='container-div'>
                                <Messages ref={(el) => this.messages = el} />
                                <GeneralDatatable 
                                    columnArray = {[
                                        {field:'uiS_NAME', header:'Role Name'},
                                        {field:'numPeople', header:'Number of Users'},
                                        {field:'uiS_NOTES', header:'Notes'}
                                    ]}

                                    csvHeader = {[
                                        {key:'uiS_NAME', label:'Role Name'},
                                        {key:'numPeople', label:'Number of Users'},
                                        {key:'uiS_NOTES', label:'Notes'}
                                    ]}
                                    
                                    defaultOrder={["uiS_NAME", "numPeople", "uiS_NOTES"]}
                                    tableRecords = {this.state.roles}
                                    pageNumber={15}
                                    tableName={'Security Roles'}
                                    exportFileName={'security-roles.csv'}
                                    tableData={'rolesTableData'}
                                    filterData={'rolesFilterData'}
                                    columnWidthTracker={this.state.columnWidthTracker}
                                    isLoading={this.state.isLoading}
                                    selectedColumns={this.state.selectedColumns}
                                    handleRowClick={this.editRecord}
                                    handleWidth={this.handleWidth}
                                    handleSelectedColumns={this.handleSelectedColumns}
                                    hasButton={this.state.hasButton}
                                    buttonLabel={'New Role'}
                                    onButtonClick={() => this.editRecord('New')}
                                />
                            </div>
                        </Layout>
                        )}
                        <Dialog header={this.state.title} visible={displayEdit} baseZIndex={1300} maximized blockScroll focusOnShow={false} onHide={this.handleClose} footer={editFooter} closable={false}>
                            {(this.state.title!=='New Security Role' && this.state.peopleSecRole!=='0') ? (
                                <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({activeIndex: e.index})}>
                                    <TabPanel header='Edit Role'>
                                        <RolesEdit 
                                            securityString={this.state.securityString}
                                            handleRadio={this.handleRadio}
                                            disabled={this.state.disabled}
                                            handleNameChange={this.handleNameChange}
                                            handleNotesChange={this.handleNotesChange}
                                            detailName={this.state.detailName}
                                            detailNotes={this.state.detailNotes}
                                        />
                                    </TabPanel>
                                    <TabPanel header='Assigned Users'>
                                        {(this.state.numberUsers===0 && !this.state.peopleIsLoading) ? (
                                            <div>There are no users assigned to this role. Visit the People page to assign security roles</div>
                                        ) : 
                                        (
                                            <div>
                                                <h3>Total Number of Users: {this.state.numberUsers}</h3>
                                                <div className='p-grid p-justify-center'>
                                                    <PeopleDatatable
                                                        peopleData={this.state.users}
                                                        pageNumber={27}
                                                        tableName={this.state.title + ' People'}
                                                        exportFileName={this.state.title + ' People.csv'}
                                                        tableData={'rolesPeopleTableData'}
                                                        filterData={'rolesPeopleFilterData'}
                                                        isLoading={this.state.peopleIsLoading}
                                                        editRecord={this.handleUserEdit}
                                                        hasButton={false}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </TabPanel>
                                </TabView>
                            ) : 
                            (                                
                                <RolesEdit 
                                    securityString={this.state.securityString}
                                    handleRadio={this.handleRadio}
                                    disabled={this.state.disabled}
                                    handleNameChange={this.handleNameChange}
                                    handleNotesChange={this.handleNotesChange}
                                />
                            )}
                        </Dialog>
                        {/*PEOPLE SECURITY ROLE 2*/}
                        <Dialog header='Details' visible={this.state.displayUserEdit2}  baseZIndex={1300} style={{ margin: '50px 15px 50px 15px' }} focusOnShow={false} contentStyle={{maxHeight: '750px', maxWidth: '750px' }} onHide={this.handleUserClose} footer={userEditFooter} closable={false}>
                            <div className='p-grid'>
                                <div className='p-md-6 p-col-12 p-grid'><label className='p-col-4'><strong>Organization</strong></label><label className='p-col-8'>{this.state.detailOrg}</label></div>
                                <div className='p-md-6 p-col-12 p-grid'><label className='p-col-4'><strong>Employee ID</strong></label><label className='p-col-8'>{this.state.userEmployeeId}</label></div>
                                <div className='p-md-6 p-col-12 p-grid'><label className='p-col-4'><strong>First Name</strong></label><label className='p-col-8'>{this.state.userFName}</label></div>
                                <div className='p-md-6 p-col-12 p-grid'><label className='p-col-4'><strong>Last Name</strong></label><label className='p-col-8'>{this.state.userLName}</label></div>
                                <div className='p-md-6 p-col-12 p-grid'><label className='p-col-4'><strong>Email</strong></label><label className='p-col-8'>{this.state.userEmail}</label></div>
                                <div className='p-md-6 p-col-12 p-grid'><label className='p-col-4'><strong>Phone</strong></label><label className='p-col-8'>{this.state.userPhone}</label></div>
                                <div className='p-md-6 p-col-12 p-grid'><label className='p-col-4'><strong>Title</strong></label><label className='p-col-8'>{this.state.userTitle}</label></div>
                                <div className='p-md-6 p-col-12 p-grid'><label className='p-col-4'><strong>Department</strong></label><label className='p-col-8'>{this.state.userDept}</label></div>
                                <div className='p-md-6 p-col-12 p-grid'><label className='p-col-4'><strong>Domain</strong></label><label className='p-col-8'>{this.state.userDomain}</label></div>
                                <div className='p-md-6 p-col-12 p-grid'><label className='p-col-4'><strong>Security Role</strong></label><label className='p-col-8'>{this.state.userSecurityRole}</label></div>
                                <div className='p-md-6 p-col-12 p-grid'><label className='p-col-4'><strong>Status</strong></label><label className='p-col-8'>{this.state.userStatus}</label></div>
                                <div className='p-md-6 p-col-12 p-grid'><label className='p-col-4'><strong>Notes</strong></label><label className='p-col-8'>{this.state.userNotes}</label></div>
                            </div>
                        </Dialog>
                        {/*PEOPLE SECURITY ROLE 3*/}
                        <Dialog header='Details' visible={displayUserEdit}  baseZIndex={1300} style={{ margin: '50px 15px 50px 15px' }} focusOnShow={false} contentStyle={{maxHeight: '750px', maxWidth: '750px' }} onHide={this.handleUserClose} footer={userEditFooter} closable={false}>
                            <div className='p-grid'>
                                <div className='p-md-6 p-col-12 p-grid'><label className='p-col-4'><strong>Organization</strong></label><label className='p-col-8'>{this.state.detailOrg}</label></div>
                                <div className='p-md-6 p-col-12 p-grid'><label className='p-col-4'><strong>Employee ID</strong></label><InputText className='p-col-8' value={this.state.userEmployeeId} type="text" id='employeeId' onChange={(e) => this.handleNameChange(e)} /></div>
                                <div className='p-md-6 p-col-12 p-grid'><label className='p-col-4'><strong>First Name</strong></label><InputText className='p-col-8' value={this.state.userFName} type="text" id='fname' onChange={(e) => this.handleNameChange(e)} /></div>
                                <div className='p-md-6 p-col-12 p-grid'><label className='p-col-4'><strong>Last Name</strong></label><InputText className='p-col-8' value={this.state.userLName} type="text" id='lname' onChange={(e) => this.handleNameChange(e)} /></div>
                                <div className='p-md-6 p-col-12 p-grid'><label className='p-col-4'><strong>Email</strong></label><InputText className='p-col-8' value={this.state.userEmail} type="text" id='email' onChange={(e) => this.handleNameChange(e)} /></div>
                                <div className='p-md-6 p-col-12 p-grid'><label className='p-col-4'><strong>Phone</strong></label><InputMask mask='(999) 999-9999' className='p-col-8' value={this.state.userPhone} type="text" id='phone' onChange={(e) => this.handleNameChange(e)} /></div>
                                <div className='p-md-6 p-col-12 p-grid'><label className='p-col-4'><strong>Title</strong></label><InputText className='p-col-8' value={this.state.userTitle} type="text" id='title' onChange={(e) => this.handleNameChange(e)} /></div>
                                <div className='p-md-6 p-col-12 p-grid'><label className='p-col-4'><strong>Department</strong></label><select className='p-col-8' value={this.state.userDept} type="text" id='dept' onChange={(e) => this.handleNameChange(e)} /></div>
                                <div className='p-md-6 p-col-12 p-grid'><label className='p-col-4'><strong>Domain</strong></label><select className='p-col-8' value={this.state.userDomain} id='domain' onChange={(e) => this.handleNameChange(e)} /></div>
                                <div className='p-md-6 p-col-12 p-grid'><label className='p-col-4'><strong>Security Role</strong></label><select className='p-col-8' onChange={(e) => this.handleNameChange(e)} value={this.state.userSecurityRole} id='securityrole'>{this.state.roleOptions.map((roleOption) => <option key={roleOption.value} value={roleOption.value}>{roleOption.label}</option>)}</select></div>
                                <div className='p-md-6 p-col-12 p-grid'><label className='p-col-4'><strong>Status</strong></label><select className='p-col-8' value={this.state.userStatus} id='status' onChange={(e) => this.handleNameChange(e)} /></div>
                                <div className='p-md-6 p-col-12 p-grid'><label className='p-col-4'><strong>Notes</strong></label><InputTextarea className='p-col-8' value={this.state.userNotes} type="text" id='notes' onChange={(e) => this.handleNotesChange(e)} /></div>
                            </div>
                        </Dialog>
                        <Dialog header={'Delete ' + this.state.detailName} visible={displayDelete} baseZIndex={1300} focusOnShow={false} onHide={this.handleDeleteCancel} footer={deleteFooter} closable={false}>
                            <div>
                                {this.state.deleteNumPeople>0 ? (
                                        <div>In order to delete '{this.state.detailName}', no users can be assigned.</div>
                                    ) : (
                                        <div>
                                            <div>Are you sure you would like to delete '{this.state.detailName}'?</div>
                                        </div>
                                    )
                                }                          
                            </div>
                        </Dialog>
                </div>
            );
        }
    }
}

export default SecurityRoles;