import React from 'react';

import GeneralDatatable from './GeneralDatatable';
import {Messages} from 'primereact/messages';

class IndividualCores extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            coreData: [],
            selectedColumns: [
                {field:'system', header:'System'},
                {field:'corE_MARK', header:'Core Marking'},
                {field:'sn', header:'S/N'},
                {field:'status', header:'Status'},
                {field:'keyway', header:'Keyway'},  
                {field:'cross', header:'Type'},  
                {field:'loC_NAME', header:'Location'},
                {field:'dooR_NUMBER', header:'Door Number'},
            ],
            columnWidthTracker: "182.875,182.875,182.875,182.875,182.875,182.875,182.875,182.875",
            defaultOrder: ["system", "corE_MARK", "sn", "status", "keyway", "cross", "loC_NAME", "dooR_NUMBER"],
            isLoading: true
        };
        this.handleWidth = this.handleWidth.bind(this);
        this.handleSelectedColumns = this.handleSelectedColumns.bind(this);
    }

    async componentDidMount() {
        let parsedTableData = JSON.parse(sessionStorage.getItem('IndividualCoresTableData'));

        if(parsedTableData!==null) {
            if(parsedTableData.selectedColumns !== null) {
                this.setState({ selectedColumns: parsedTableData.selectedColumns })
            }
        }
        this.setState({ isLoading: false })
    }

    handleWidth(width) {
        this.setState({ columnWidthTracker: width })
    }

    handleSelectedColumns(e) {
        this.setState({ selectedColumns: e })
    }


    render() {
        if(this.props.coreData.length===0) {
            return(<div>Contact Midwest Security Products to add Cores</div>)
        } else {
            return(
                <div>
                    <Messages ref={(el) => this.messages = el} />
                    <GeneralDatatable 
                        columnArray = {[
                            {field:'system', header:'System'},
                            {field:'corE_MARK', header:'Core Marking'},
                            {field:'sn', header:'S/N'},
                            {field:'status', header:'Status'},
                            {field:'keyway', header:'Keyway'},  
                            {field:'cross', header:'Type'},  
                            {field:'loC_NAME', header:'Location'},
                            {field:'dooR_NUMBER', header:'Door Number'},
                        ]}

                        csvHeaders = {[
                            {key:'system', label:'System'},
                            {key:'corE_MARK', label:'Core Marking'},
                            {key:'sn', label:'S/N'},
                            {key:'status', label:'Status'},
                            {key:'keyway', label:'Keyway'},  
                            {key:'cross', label:'Type'},  
                            {key:'loC_NAME', label:'Location'},
                            {key:'dooR_NUMBER', label:'Door Number'},
                        ]}

                        defaultOrder={["system", "corE_MARK", "sn", "status", "keyway", "cross", "loC_NAME", "dooR_NUMBER"]}

                        tableRecords = {this.props.coreData}
                        pageNumber={29}
                        tableName={'Cores'}
                        exportFileName={'cores.csv'}
                        tableData={'IndividualCoresTableData'}
                        filterData={'IndividualCoresFilterData'}
                        columnWidthTracker={this.state.columnWidthTracker}
                        isLoading={this.state.isLoading}
                        selectedColumns={this.state.selectedColumns}
                        handleRowClick={this.props.editCore}
                        handleWidth={this.handleWidth}
                        handleSelectedColumns={this.handleSelectedColumns}   
                    />
                </div>
            );
        }
    }
}

export default IndividualCores;