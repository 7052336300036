import React from 'react';

import BuildingsComponent from '../components/BuildingsComponent';

class Buildings extends React.Component {
    constructor(props) {
        super(props);

        this.state={

        }
    }

    componentDidMount() {

    }

    render() {
        return(
            <BuildingsComponent
                buildingID={0}
                groupID={0}
            />
        );
    }
}

export default Buildings;