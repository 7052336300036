import React from 'react';

import Layout from '../components/Layout';
import { JobsService, RecordsService } from '../services/FetchServices';
import GeneralDatatable from '../components/GeneralDatatable';
import TextBox from '../components/inputComponents/textbox';
import Label from '../components/inputComponents/Label';
import ViewJob from '../components/ViewJob';

import {Button} from 'primereact/button';
import {Messages} from 'primereact/messages';
import {Dialog} from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import JobDetailsTable from '../components/JobDetailsTable';
import InputSelect from '../components/inputComponents/InputSelect';
import { v4 as uuidv4 } from 'uuid';
import AddJobDetailsTable from '../components/JobDetails';
import { InputText } from 'primereact/inputtext';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';

let editArray;
let dueDateStr;
let parsedUserData;

//Page 7 for API, page 6 for internal routing
class Jobs extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            jobs: [],
            selectedColumns: [
                {field:'woH_NOTES', header:'Notes'},
                {field:'woH_CREATEDDATE', header:'Created Date'},
                {field:'woH_REFNUM', header:'Reference Number'},
                {field:'woH_PO', header:'Purchase Order'},
                {field:'woH_DUEDATE', header:'Due Date'},
                {field:'woH_DESC', header:'Description'},
                {field:'woH_COMPLETEDATE', header:'Completed Date'},
                {field:'woH_STATUS', header:'Status'},
                {field:'woH_SYSNAME', header:'System'},
                {field:'woH_ID', header:'Job ID'},
                {field:'createD_BY_NAME', header:'Author'}
            ],
            isLoading: true,
            columnWidthTracker: "133,133,133,133,133,133,133,133,133,133,133",
            secRole: '',
            records: '',
            jobDetailsData: '',
            displayJobD: false,
            displayEditD: false,
            displayJobID: '',
            displayRefNum: "",
            displayStatus: "",
            displaySystem: "",
            guid: '',
            displayPO: "",
            displayAuthor: "",
            displayCreatedD: "",
            displayPostedD: "",
            displayDueD: "",
            displayCompletedD: "",
            displayDesc: "",
            displayNotes: "",
            disabled: false,
            systemOptions: '',
            jobSecRoleBtn: true
        }
        
        this.handleWidth = this.handleWidth.bind(this);
        this.handleSelectedColumns = this.handleSelectedColumns.bind(this);
        this.deleteJob = this.deleteJob.bind(this);
        this.editRecord = this.editRecord.bind(this);
        this.renderEditFooter = this.renderEditFooter.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleEditSave = this.handleEditSave.bind(this);
        this.addJobDetails = this.addJobDetails.bind(this);
        this.handleCloseDetails = this.handleCloseDetails.bind(this);
        this.handleNewJob = this.handleNewJob.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.showResults = this.showResults.bind(this);
        this.closeJob = this.closeJob.bind(this);
        this.closeJobConfirm = this.closeJobConfirm.bind(this);
        this.handleCloseCancel = this.handleCloseCancel.bind(this);
        this.viewJob = this.viewJob.bind(this);
        this.closeViewJob = this.closeViewJob.bind(this);
        this.fetchJobDetails = this.fetchJobDetails.bind(this);
        this.editLineDetail = this.editLineDetail.bind(this);
        this.closeLineDetail = this.closeLineDetail.bind(this);
        this.saveLineDetail = this.saveLineDetail.bind(this);
        this.deleteLineDetail = this.deleteLineDetail.bind(this);
        this.handleLineDetailQty = this.handleLineDetailQty.bind(this);
        this.handleMakeMarkCutFile = this.handleMakeMarkCutFile.bind(this);
        this.fetchJobs = this.fetchJobs.bind(this);
    }

    async componentDidMount() {
        parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
        this.fetchJobs();

        if(parsedUserData.secString[11]==='2') {
            this.setState({jobSecRoleBtn: false})
        }

        let parsedTableData = JSON.parse(sessionStorage.getItem("jobsTableData"));
        if(parsedTableData!==null) {
            if(parsedTableData.selectedColumns !== null) {
                this.setState({ selectedColumns: parsedTableData.selectedColumns })
            }
        }

        let tempArray = await RecordsService.fetchSystems( parsedUserData.uiU_M1ORGID, 0 );
        tempArray = tempArray.systemlist;
        let newArray = [];
        
        for(let i=0; i<tempArray.length; i++) {
            const obj = {};
            obj.value = tempArray[i].uiS_SYSNAME;
            obj.label = tempArray[i].uiS_SYSNAME;
            newArray.push(obj)
        }

        let statusDropdownOptionsArray = [];
        const openObj = {};
        openObj.value = "OPEN";
        openObj.label = "OPEN";
        statusDropdownOptionsArray.push(openObj);
        const closedObj = {};
        closedObj.value = "CLOSED";
        closedObj.label = "CLOSED";
        statusDropdownOptionsArray.push(closedObj);
        

        this.setState({ 
            statusDropdownOptions: statusDropdownOptionsArray,
            systemDropdownOptions: newArray,
            secRole: parsedUserData.secString[11],
            orgID: parsedUserData.uiU_M1ORGID
        })
    }

    handleWidth(width) {
        this.setState({ columnWidthTracker: width })
    }

    handleSelectedColumns(e) {
        this.setState({ selectedColumns: e })
    }

    async fetchJobs() {
        parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
        await JobsService.fetchJobs( parsedUserData.uiU_M1ORGID, "" ).then(data => editArray = data.jobslist);
        
        for (let i = 0; i < editArray.length; i++) {
            let editString = editArray[i].woH_DUEDATE;
            editString = editString.split(" ");
            editString = editString[0];
            editArray[i].woH_DUEDATE = editString;

            let editString2 = editArray[i].woH_CREATEDDATE;
            editString2 = editString2.split(" ");
            editString2 = editString2[0];
            if(editString2 === "1/1/1980") {
                editString2 = "";
            }
            editArray[i].woH_CREATEDDATE = editString2;  

            let editString3 = editArray[i].woH_COMPLETEDATE;
            editString3 = editString3.split(" ");
            editString3 = editString3[0];
            if(editString3 === "1/1/1980") {
                editString3 = "";
            }
            editArray[i].woH_COMPLETEDATE = editString3;  
        }

        this.setState({ jobs: editArray, isLoading: false })
    }

    async fetchJobDetails(jobID) {
        await JobsService.fetchJobDetails( parsedUserData.uiU_M1ORGID, jobID, 0 ).then(data => this.setState({ jobDetailsData: data.detaillist}));
    }

    async editRecord(e) {
        if(this.state.secRole==='1') {
            return;
        } else if(this.state.secRole==='2' || e.data.woH_STATUS==="CLOSED") {
            this.setState({disabled: true});
            let dueDateObj = '';
            dueDateObj = new Date(e.data.woH_DUEDATE);

           this.fetchJobDetails(e.data.woH_ID)
            
            let hasButtonVar;
            if(e.data.woH_STATUS==="OPEN"&&this.state.jobSecRoleBtn!==false) {
                hasButtonVar = true;
            } else {
                hasButtonVar = false;
            }

            this.setState({ 
                displayEditD: true,
                displayJobID: e.data.woH_ID,
                guid: e.data.woH_UNIQUEID,
                displayRefNum: e.data.woH_REFNUM,
                displayStatus: e.data.woH_STATUS,
                displaySystem: e.data.woH_SYSNAME,
                displayPO: e.data.woH_PO,
                displayAuthor: e.data.createD_BY_NAME,
                createdByID: e.data.woH_CREATEDBY,
                displayCreatedD: e.data.woH_CREATEDDATE,
                displayDueD: dueDateObj,
                displayCompletedD: e.data.woH_COMPLETEDATE,
                displayDesc: e.data.woH_DESC,
                displayNotes: e.data.woH_NOTES,
                hasButton: hasButtonVar
            })
        } else {
            let dueDateObj = '';
            dueDateObj = new Date(e.data.woH_DUEDATE);
            
            this.fetchJobDetails(e.data.woH_ID)
            
            let hasButtonVar;
            if(e.data.woH_STATUS==="OPEN") {
                hasButtonVar = true;
            } else {
                hasButtonVar = false;
            }

            this.setState({ 
                displayEditD: true,
                displayJobID: e.data.woH_ID,
                guid: e.data.woH_UNIQUEID,
                displayRefNum: e.data.woH_REFNUM,
                displayStatus: e.data.woH_STATUS,
                displaySystem: e.data.woH_SYSNAME,
                displayPO: e.data.woH_PO,
                displayAuthor: e.data.createD_BY_NAME,
                createdByID: e.data.woH_CREATEDBY,
                displayCreatedD: e.data.woH_CREATEDDATE,
                displayDueD: dueDateObj,
                displayCompletedD: e.data.woH_COMPLETEDATE,
                displayDesc: e.data.woH_DESC,
                displayNotes: e.data.woH_NOTES,
                hasButton: hasButtonVar
            })
        }
    }

    async handleNewJob() {
        let tempArray = [];
        let systems = await RecordsService.fetchSystems( parsedUserData.uiU_M1ORGID, 0 );
        systems = systems.systemlist;

        for(let i=0; i<systems.length; i++) {
            tempArray.push(systems[i].uiS_SYSNAME);
        }

        const GUID = uuidv4();
        const newDate = new Date();
        const month =((newDate.getMonth() + 1).toString());
        const day = (newDate.getDate().toString());
        const year = (newDate.getFullYear().toString());
        const createdDate = month + "/" + day + "/" + year

        this.setState({
            displayEditD: true,
            displayJobID: -1,
            displayStatus:'OPEN',
            displayCreatedD: createdDate,
            guid: GUID,
            systemOptions: tempArray,
            displayDueD: newDate,
            hasButton: true,
            createdByID: parsedUserData.uiU_ID,
            displayAuthor: parsedUserData.uiU_FN + " " + parsedUserData.uiU_LN
        })
    }

    async deleteJob() {
        await JobsService.DeleteJob( parsedUserData.uiU_M1ORGID, this.state.displayJobID ).then(data => this.showResults(data))
        this.fetchJobs();
        this.handleClose();
        this.handleCloseCancel();
    }

    handleClose() {
        this.setState({
            displayJobD: false,
            displayEditD: false,
            displayJobID: '',
            displayRefNum: "",
            displayStatus: "",
            displaySystem: "",
            guid: '',
            displayPO: "",
            displayAuthor: "",
            displayCreatedD: "",
            displayPostedD: "",
            displayDueD: "",
            displayCompletedD: "",
            displayDesc: "",
            displayNotes: "",
            disabled: false,
            jobDetailsData: '',
            systemOptions: '',
            hasButton: false,
            dateDueClassName: '',
            sysClassName: '', 
            refNumClassName: ''
        })
    }

    async handleEditSave() {
        let dateStr ='';
        let dateStrMonth = (this.state.displayDueD.getMonth() + 1).toString();
        let dateStrDay = (this.state.displayDueD.getDate()).toString();
        let dateStrYear = (this.state.displayDueD.getFullYear()).toString();
        dateStr = dateStrMonth + '/' + dateStrDay + '/' + dateStrYear;

        const dueDateError = (this.state.displayDueD==='' || this.state.displayDueD===undefined || this.state.displayDueD===null);
        const refNumError = (this.state.displayRefNum==='' || this.state.displayRefNum===undefined || this.state.displayRefNum===null);
        const sysError = (this.state.displaySystem==='' || this.state.displaySystem===undefined || this.state.displaySystem===null);

        if(dueDateError || refNumError || sysError) {
            if(dueDateError) {
                this.setState({dateDueClassName:'p-invalid'})
            }
            if(refNumError) {
                this.setState({refNumClassName:'p-invalid'})
            }
            if(sysError) {
                this.setState({sysClassName:'p-invalid'})
            }
        } else {
            await JobsService.SaveJob(this.state.displayJobID, this.state.guid, parsedUserData.uiU_M1ORGID, this.state.displayNotes, this.state.displayCreatedD, 
                this.state.displayPostedD, this.state.createdByID, this.state.displayRefNum, this.state.displayPO, dateStr, this.state.displayDesc, 
                this.state.displayCompletedD, this.state.displayStatus, this.state.displaySystem)
                .then(data => this.showResults(data));
    
            this.fetchJobs();
            this.handleClose();
        }
    }

    showResults(data) {
        if(data.success) {
            this.messages.show({severity: 'success', summary: data.message, life:1000});
        } else {
            this.messages.show({severity: 'error', summary: data.message, life:3000});
        }
    } 

    async addJobDetails() {
        if(this.state.displayJobID===-1) {
            const dueDateError = (this.state.displayDueD==='' || this.state.displayDueD===undefined || this.state.displayDueD===null);
            const refNumError = (this.state.displayRefNum==='' || this.state.displayRefNum===undefined || this.state.displayRefNum===null);
            const sysError = (this.state.displaySystem==='' || this.state.displaySystem===undefined || this.state.displaySystem===null);
    
            if(dueDateError || refNumError || sysError) {
                if(dueDateError) {
                    this.setState({dateDueClassName:'p-invalid'})
                }
                if(refNumError) {
                    this.setState({refNumClassName:'p-invalid'})
                }
                if(sysError) {
                    this.setState({sysClassName:'p-invalid'})
                }
            } else {
                await JobsService.SaveJob(this.state.displayJobID, this.state.guid, parsedUserData.uiU_M1ORGID, this.state.displayNotes, this.state.displayCreatedD, 
                    this.state.displayPostedD, this.state.createdByID, this.state.displayRefNum, this.state.displayPO, this.state.displayDueD, this.state.displayDesc, 
                    this.state.displayCompletedD, this.state.displayStatus, this.state.displaySystem)
                    .then(data => this.setState({displayJobID: data.woH_ID}));

                await RecordsService.fetchRecords( parsedUserData.uiU_M1ORGID, 0, this.state.displaySystem )
                .then(data => this.setState({records: data.recordlist, jobDetails: true}));
            }
        } else {
            await RecordsService.fetchRecords( parsedUserData.uiU_M1ORGID, 0, this.state.displaySystem )
            .then(data => this.setState({records: data.recordlist, jobDetails: true}));
        }
    }

    handleCloseDetails() {
        this.setState({jobDetails: false})
    }

    closeJobConfirm(type) {
        if(type==='close') {
            this.setState({closeJobConfirmDialog: true, headerText: 'Close', dialogMsg:'close', type: type})
        } else {
            this.setState({closeJobConfirmDialog: true, headerText: 'Delete', dialogMsg:'delete', type: type})
        }
    }

    async closeJob() {
        await JobsService.CloseJob(this.state.displayJobID, parsedUserData.uiU_M1ORGID)
        await JobsService.MakeMarkCutFile(this.state.displayJobID, parsedUserData.uiU_M1ORGID)
        
        this.fetchJobs();
        this.handleCloseCancel();
        this.handleClose();
    }

    handleCloseCancel() {
        this.setState({closeJobConfirmDialog: false, type: '', headerText: '', dialogMsg: ''})
    }

    async handleMakeMarkCutFile() {
        await JobsService.MakeMarkCutFile(this.state.displayJobID, parsedUserData.uiU_M1ORGID).then(data => this.showResults(data))
        this.handleClose()
    }

    editLineDetail(e) {
        if(this.state.displayStatus!=='CLOSED'&&!this.state.disabled) { 
            this.setState({
                editLineDetail: true,
                lineDetailQty: e.data.woD_QTY,
                lineDetailMark: e.data.woD_KEYLOCKMARK,
                lineDetailType: e.data.woD_TYPE,
                lineDetailNum: e.data.woD_LINENUM
            })

            if(e.data.woD_FIXEDCORE===false && e.data.woD_EXCLUDECONTROL===false && (e.data.woD_TYPE.split(' ', 1))[0]!=='KEYS') {
                this.setState({lineDetailCoreType:'SFIC'})
            } else if (e.data.woD_FIXEDCORE && e.data.woD_EXCLUDECONTROL && (e.data.woD_TYPE.split(' ', 1))[0]!=='KEYS') {
                this.setState({lineDetailCoreType:'FIXED'})
            } else if((e.data.woD_TYPE.split(' ', 1))[0]!=='KEYS') {
                this.setState({lineDetailCoreType:'CAM'})
            }
        }
    }

    closeLineDetail() {
        this.setState({
            editLineDetail: false,
            lineDetailQty: '',
            lineDetailMark:'',
            lineDetailType:'',
            lineDetailCoreType: '',
            lineDetailNum: ''
        })
    }

    async saveLineDetail() {
        await JobsService.updateJobDetail( parsedUserData.uiU_M1ORGID, this.state.displayJobID, this.state.lineDetailNum, this.state.lineDetailQty )
        await JobsService.fetchJobDetails( parsedUserData.uiU_M1ORGID, this.state.displayJobID, 0 ).then(data => this.setState({ jobDetailsData: data.detaillist}));
        this.closeLineDetail();
    }

    async deleteLineDetail() {
        await JobsService.updateJobDetail( parsedUserData.uiU_M1ORGID, this.state.displayJobID, this.state.lineDetailNum, 0 )
        await JobsService.fetchJobDetails( parsedUserData.uiU_M1ORGID, this.state.displayJobID, 0 ).then(data => this.setState({ jobDetailsData: data.detaillist}));
        this.closeLineDetail();
    }

    handleLineDetailQty(e) {
        this.setState({lineDetailQty: e})
    }

    renderCloseButtons() {
        if(this.state.type==='close') {
            return(
                <div>
                    <Button type='button' icon='pi pi-check' label='Close Job' onClick={this.closeJob} />
                    <Button type='button' icon='pi pi-times' label='Cancel' onClick={this.handleCloseCancel} />
                </div>
            );
        } else if(this.state.type==='delete') {
            return(
                <div>
                    <Button type='button' icon='pi pi-check' label='Delete Job' onClick={this.deleteJob} />
                    <Button type='button' icon='pi pi-times' label='Cancel' onClick={this.handleCloseCancel} />
                </div>
            );
        }
    }

    renderLineButtons() {
        return(
            <div>
                <Button type='button' icon='pi pi-trash' label='Delete' onClick={this.deleteLineDetail} />
                {(parseInt(this.state.lineDetailQty, 10) < 1 || this.state.lineDetailQty==='') ? (null) : (<Button type='button' icon='pi pi-check' label='Save and Exit' onClick={this.saveLineDetail} />)}
                <Button type='button' icon='pi pi-times' label='Exit' onClick={this.closeLineDetail} />
            </div>
        )
    }

    viewJob() {
        this.setState({viewJob: true})
    }

    closeViewJob() {
        this.setState({viewJob: false})
    }

    renderEditFooter() {
        if(this.state.secRole==='2'||this.state.hasButton===false) {
            return (
                <div>
                    {(this.state.displayStatus==='OPEN' || isMobile) ? (null) : (<Button type='button' icon='pi pi-info-circle' label='View Job' onClick={this.viewJob}/>)}
                    {(this.state.displayStatus==='OPEN' || isMobile) ? (null) : (<Button type='button' icon='pi pi-file-o' label='Remake Mark Cut File' onClick={this.handleMakeMarkCutFile}/>)}
                    <Button type='button' icon='pi pi-times' label='Exit' onClick={this.handleClose} />
                </div>
            );
        } else if (this.state.displayJobID===-1) {
            return(
                <div>
                    {this.state.refNumClassName==='p-invalid' ? (<div style={{color: 'red', display:'inline', marginRight:'15px'}}>Reference Number Can Not Be Empty</div>) : (null)}
                    {this.state.sysClassName==='p-invalid' ? (<div style={{color: 'red', display:'inline', marginRight:'15px'}}>System Can Not Be Empty</div>) : (null)}
                    {this.state.dateDueClassName==='p-invalid' ? (<div style={{color: 'red', display:'inline', marginRight:'15px'}}>Date Can Not Be Empty</div>) : (null)}
                    <Button type='button' icon='pi pi-check' label='Save and Exit' onClick={this.handleEditSave} />
                    <Button type='button' icon='pi pi-times' label='Exit' onClick={this.handleClose} />
                </div>
            );
        } else {
            return (
                <div>
                    {this.state.refNumClassName==='p-invalid' ? (<div style={{color: 'red', display:'inline', marginRight:'15px'}}>Reference Number Can Not Be Empty</div>) : (null)}
                    {this.state.sysClassName==='p-invalid' ? (<div style={{color: 'red', display:'inline', marginRight:'15px'}}>System Can Not Be Empty</div>) : (null)}
                    {this.state.dateDueClassName==='p-invalid' ? (<div style={{color: 'red', display:'inline', marginRight:'15px'}}>Date Can Not Be Empty</div>) : (null)}
                    {(this.state.displayStatus==='OPEN' || isMobile) ? (null) : (<Button type='button' icon='pi pi-info-circle' label='View Job' onClick={this.viewJob}/>)}
                    {(this.state.displayStatus==='OPEN' || isMobile) ? (null) : (<Button type='button' icon='pi pi-file-o' label='Remake Mark Cut File' onClick={this.handleMakeMarkCutFile}/>)}
                    <Button type='button' icon='pi pi-trash' label='Delete' onClick={() => this.closeJobConfirm('delete')}/>
                    <Button type='button' icon='pi pi-folder' label='Close Job' onClick={() => this.closeJobConfirm('close')}/>
                    <Button type='button' icon='pi pi-check' label='Save and Exit' onClick={this.handleEditSave} />
                    <Button type='button' icon='pi pi-times' label='Exit' onClick={this.handleClose} />
                </div>
            );
        }
    }

    handleFieldChange(e) {
        if(e.target.id==='displayRefNum') {
            this.setState({ displayRefNum: e.target.value })
            if(e.target.value!==''&&this.state.refNumClassName==="p-invalid") {
                this.setState({refNumClassName: ''})
            }
        } else if(e.target.id==='desc') {
            this.setState({ displayDesc: e.target.value })
        } else if(e.target.id==='displayPO') {
            this.setState({ displayPO: e.target.value })
        } else if(e.target.id==='notes') {
            this.setState({ displayNotes: e.target.value })
        } else if(e.target.id==='displaySystem') {
            this.setState({ displaySystem: e.target.value })
            if(e.target.value!==''&&this.state.sysClassName==="p-invalid") {
                this.setState({sysClassName: ''})
            }
        } else if(e.target.id==='dateDue') {
            this.setState({ displayDueD: e.target.value })
            if(e.target.value!==''&&this.state.dateDueClassName==="p-invalid") {
                this.setState({dateDueClassName: ''})
            }
        }
    }

    render() {
        const { displayEditD, displayJobID, displayNotes, displaySystem, displayAuthor, displayCreatedD } = this.state;
        const EditFooter=this.renderEditFooter();
        const closeConfirmFooter=this.renderCloseButtons();
        const editLineFooter = this.renderLineButtons();

        if(this.state.displayDueD!=="" && !this.state.isLoading) {
            dueDateStr = ((this.state.displayDueD.getMonth() + 1).toString() + '/' + (this.state.displayDueD.getDate()).toString() + '/' + (this.state.displayDueD.getFullYear()).toString())
        }   
        if((this.state.secRole==='' && this.state.isLoading===false) || (this.state.secRole==='0' && !this.state.isLoading)) {
            return(<Layout><div>Security Role prevents you from seeing data</div></Layout>);
        } else {
            return (
                <div> {(this.state.jobs.length===0 && !this.state.isLoading) ? (<Layout><Button type='button' icon='pi pi-plus' label='New Job' onClick={this.handleNewJob} /></Layout>) : (
                    <Layout>
                        <div className='container-div'>
                            <Messages ref={(el) => this.messages = el} />
                            <GeneralDatatable 
                                columnArray = {[
                                    {field:'woH_NOTES', header:'Notes'},
                                    {field:'woH_CREATEDDATE', header:'Created Date'},
                                    {field:'woH_REFNUM', header:'Reference Number'},
                                    {field:'woH_PO', header:'Purchase Order'},
                                    {field:'woH_DUEDATE', header:'Due Date'},
                                    {field:'woH_DESC', header:'Description'},
                                    {field:'woH_COMPLETEDATE', header:'Completed Date'},
                                    {field:'woH_STATUS', header:'Status'},
                                    {field:'woH_SYSNAME', header:'System'},
                                    {field:'woH_ID', header:'Job ID'},
                                    {field:'createD_BY_NAME', header:'Author'}
                                ]}

                                csvHeader = {[
                                    {key:'woH_NOTES', label:'Notes'},
                                    {key:'woH_CREATEDDATE', label:'Created Date'},
                                    {key:'woH_REFNUM', label:'Reference Number'},
                                    {key:'woH_PO', label:'Purchase Order'},
                                    {key:'woH_DUEDATE', label:'Due Date'},
                                    {key:'woH_DESC', label:'Description'},
                                    {key:'woH_COMPLETEDATE', label:'Completed Date'},
                                    {key:'woH_STATUS', label:'Status'},
                                    {key:'woH_SYSNAME', label:'System'},
                                    {key:'woH_ID', label:'Job ID'},
                                    {key:'createD_BY_NAME', label:'Author'}
                                ]}
                                
                                defaultOrder={["woH_NOTES", "woH_CREATEDDATE", "woH_REFNUM", "woH_PO", "woH_DUEDATE", "woH_DESC", "woH_COMPLETEDATE", "woH_STATUS", "woH_SYSNAME", "createD_BY_NAME", "woH_ID" ]}

                                tableRecords = {this.state.jobs}
                                systemDropdownOptions={this.state.systemDropdownOptions}
                                statusDropdownOptions = {this.state.statusDropdownOptions}
                                pageNumber={7}
                                tableName={'Jobs'}
                                exportFileName={'jobs.csv'}
                                tableData={'jobsTableData'}
                                filterData={'jobsFilterData'}
                                columnWidthTracker={this.state.columnWidthTracker}
                                isLoading={this.state.isLoading}
                                selectedColumns={this.state.selectedColumns}
                                handleRowClick={this.editRecord}
                                handleWidth={this.handleWidth}
                                handleSelectedColumns={this.handleSelectedColumns}   
                                hasButton={this.state.jobSecRoleBtn}
                                buttonLabel={'New Job'}
                                onButtonClick={this.handleNewJob}
                                dataKey={'WOH_ID'}
                            />
                        </div>
                    </Layout>)}
                    <Dialog header='Edit Job Details' visible={displayEditD} onHide={this.handleClose} footer={EditFooter} baseZIndex={1300} closable={false} maximized focusOnShow={false}>
                        <div>
                            <BrowserView>
                                <div className='p-grid'>
                                    <div className='p-col-4 p-grid'>
                                        <div className='p-grid' style={{width:'450px', margin:'auto'}}>
                                            <label className="p-col" style={{textAlign:'right', display:'block', margin:'auto'}} htmlFor='displayJobID'><strong>Job ID</strong></label>
                                            <div className='p-col-fixed' id='displayJobID'><p style={{width:'250px', textAlign:'left'}}>{this.state.displayJobID===-1 ? ('New') : (this.state.displayJobID)}</p></div>
                                        </div>
                                        <TextBox value={this.state.displayRefNum} className={this.state.refNumClassName} id="displayRefNum" label={"Reference Number"} handleFieldChange={this.handleFieldChange} disabled={this.state.disabled} threeCol />
                                        <TextBox value={this.state.displayDesc} id="desc" label={"Description"} handleFieldChange={this.handleFieldChange} disabled={this.state.disabled} threeCol />
                                        <TextBox value={this.state.displayPO} id="displayPO" label={"Purchase Order"} handleFieldChange={this.handleFieldChange} disabled={this.state.disabled} threeCol />
                                        {this.state.displayJobID===-1 ? 
                                            (
                                                <InputSelect disabled={this.state.disabled} value={this.state.displaySystem} 
                                                    id="displaySystem" label={"System: "} options={this.state.systemOptions} threeCol
                                                    handleFieldChange={this.handleFieldChange} className={this.state.sysClassName}
                                                />
                                            ) : 
                                            (
                                                <div className='p-grid' style={{width:'450px', margin:'auto'}}>
                                                    <label className="p-col" style={{textAlign:'right', display:'block', margin:'auto'}} htmlFor='displaySystem'><strong>System</strong></label>
                                                    <div className='p-col-fixed' id='displaySystem'><p style={{width:'250px', textAlign:'left'}}>{displaySystem}</p></div>
                                                </div>
                                            )
                                        }

                                    </div>
                                    <div className='p-col-4 p-grid'>

                                        <div className='p-grid' style={{width:'450px', margin:'auto'}}>
                                            <label className="p-col" style={{textAlign:'right', display:'block', margin:'auto'}} htmlFor='displayStatus'><strong>Status</strong></label>
                                            <div className='p-col-fixed' id='displayStatus'>
                                                <div style={{width:'250px'}}>
                                                    <p style={{float:'left'}}>{this.state.displayStatus}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='p-grid' style={{width:'450px', margin:'auto'}}>
                                            <label className="p-col" style={{textAlign:'right', display:'block', margin:'auto'}} htmlFor='dateDue'><strong>Date Due</strong></label>
                                            <div className='p-col-fixed' id='displayAuthor'>
                                                <Calendar disabled={this.state.disabled} className={this.state.dateDueClassName} style={{width:'250px', textAlign:'left'}} dateFormat="mm/dd/yy" id='dateDue' hourFormat="12" value={this.state.displayDueD} onChange={(e) => this.handleFieldChange(e)}></Calendar>
                                            </div>
                                        </div>
                                        
                                        <div className='p-grid' style={{width:'450px', margin:'auto'}}>
                                            <label className="p-col" style={{textAlign:'right', display:'block', margin:'auto'}} htmlFor='displayCreatedD'><strong>Created</strong></label>
                                            <div className='p-col-fixed' id='displayCreatedD'><p style={{width:'250px', textAlign:'left'}}>{displayCreatedD}</p></div>
                                        </div>
                                        <div className='p-grid' style={{width:'450px', margin:'auto'}}>
                                            <label className="p-col" style={{textAlign:'right', display:'block', margin:'auto'}} htmlFor='displayAuthor'><strong>Created By</strong></label>
                                            <div className='p-col-fixed' id='displayAuthor'><p style={{width:'250px', textAlign:'left'}}>{displayAuthor}</p></div>
                                        </div>
                                    </div>
                                    <div className="p-col-4 p-grid">
                                        <label className='p-col-2'><strong>Notes: </strong></label><InputTextarea disabled={this.state.disabled} className='p-col-10' id='notes' value={displayNotes} onChange={(e) => this.handleFieldChange(e)}/>
                                    </div>
                                    <div style={{width:'100%'}}>
                                        <JobDetailsTable WOD_HEADERID={displayJobID} WOD_ID={0} addJobDetails={this.addJobDetails} hasButton={this.state.hasButton} jobDetails={this.state.jobDetailsData} editRecord={this.editLineDetail} />
                                    </div>
                                </div>
                            </BrowserView>
                            <MobileView>
                                <div className='p-grid' style={{maxWidth:'100%'}}>
                                    <Label mobile value={this.state.displayJobID===-1 ? ('New') : (this.state.displayJobID)} label={'Job ID'}/>
                                    <TextBox mobile value={this.state.displayRefNum} className={this.state.refNumClassName} id="displayRefNum" label={"Reference Number"} handleFieldChange={this.handleFieldChange} disabled={this.state.disabled} />
                                    <TextBox mobile value={this.state.displayDesc} id="desc" label={"Description"} handleFieldChange={this.handleFieldChange} disabled={this.state.disabled} />
                                    <TextBox mobile value={this.state.displayPO} id="displayPO" label={"Purchase Order"} handleFieldChange={this.handleFieldChange} disabled={this.state.disabled} />
                                    {this.state.displayJobID===-1 ? 
                                        (
                                            <InputSelect mobile disabled={this.state.disabled} value={this.state.displaySystem} 
                                                id="displaySystem" label={"System: "} options={this.state.systemOptions}
                                                handleFieldChange={this.handleFieldChange} className={this.state.sysClassName}
                                            />
                                        ) : 
                                        (
                                            <Label mobile value={displaySystem} label={'System'}/>
                                        )
                                    }
                                    <Label mobile value={this.state.displayStatus} label={'Status'}/>
                                    <div style={{margin:'10px'}} className="p-col-12">
                                        <div><strong>Date Due</strong></div>
                                        <div style={{width:'100%', margin:'auto'}}>
                                            <Calendar 
                                                disabled={this.state.disabled} className={this.state.dateDueClassName} 
                                                style={{width:'250px', textAlign:'left'}} dateFormat="mm/dd/yy" id='dateDue' hourFormat="12"
                                                value={this.state.displayDueD} onChange={(e) => this.handleFieldChange(e)}>
                                            </Calendar>
                                        </div>
                                    </div>
                                    <Label mobile value={displayCreatedD} label={'Created'}/>
                                    <Label mobile value={displayAuthor} label={'Created By'}/>
                                    <TextBox mobile area value={this.state.displayNotes} id="notes" label={"Notes"} handleFieldChange={this.handleFieldChange} disabled={this.state.disabled} />
                                    <div style={{width:'100%', maxWidth:'100%'}}>
                                        <JobDetailsTable WOD_HEADERID={displayJobID} WOD_ID={0} addJobDetails={this.addJobDetails} hasButton={this.state.hasButton} jobDetails={this.state.jobDetailsData} editRecord={this.editLineDetail} />
                                    </div>
                                </div>
                            </MobileView>
                        </div>
                    </Dialog>
                    <Dialog header={this.state.headerText + ' Job'} visible={this.state.closeJobConfirmDialog} onHide={this.handleCloseCancel} footer={closeConfirmFooter} baseZIndex={1300} closable={false} focusOnShow={false}>
                        <div>Are you sure you would like to {this.state.dialogMsg} Job {this.state.displayJobID}?</div>
                    </Dialog>
                    <Dialog  header={'Edit Job Detail - Line ' + this.state.lineDetailNum} visible={this.state.editLineDetail} onHide={this.closeLineDetail} footer={editLineFooter} baseZIndex={1300} closable={false} focusOnShow={false}>
                        <div>
                            <h4>{this.state.lineDetailType}</h4>
                            {this.state.lineDetailCoreType!=='' ? (<h4>{this.state.lineDetailCoreType}</h4>) : (null)}
                            <h4>{this.state.lineDetailMark}</h4>
                            <div><h4 style={{marginBottom:'0px'}}>QTY: </h4><InputText keyfilter="pint" type="text" value={this.state.lineDetailQty} onChange={(e) => this.handleLineDetailQty(e.target.value)} style={{textAlign:'center', width:'7em'}} /></div>
                        </div>
                    </Dialog>
                    {this.state.viewJob ? (
                        <ViewJob
                            jobDetails={this.state.jobDetailsData}
                            refNum={this.state.displayRefNum}
                            dateDue={dueDateStr}
                            notes={this.state.displayNotes}
                            jobID={this.state.displayJobID}
                            status={this.state.displayStatus}
                            poNum={this.state.displayPO}
                            desc={this.state.displayDesc}
                            orgID={this.state.orgID}
                            viewJob={this.state.viewJob}
                            closeViewJob={this.closeViewJob}
                        />)
                        : (null)
                    }
                    {this.state.jobDetails ? (
                        <AddJobDetailsTable 
                            system={this.state.displaySystem} jobID={this.state.displayJobID} 
                            fetchJobDetails={this.fetchJobDetails} jobDetails={this.state.jobDetails} 
                            handleCloseDetails={this.handleCloseDetails} hasButton={this.state.hasButton} 
                        />) 
                        : (null)
                    }
                </div>
            );
        }
    }
}

export default Jobs;