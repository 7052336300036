import React from 'react';
import { Redirect } from "react-router-dom";
import publicIp from 'public-ip';

import { userService } from '../services/Auth';
import TopBarMenu from './topBarMenu';
import MobileSidebar from './MobileSidebar';
import Eula from './Eula';

import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Toolbar from '@material-ui/core/Toolbar';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Link from '@material-ui/core/Link';

const styles = theme => ({
    gridContainerClass: {
        direction: 'row',
        alignItems: 'center',
        justify: 'space-evenly'
    },
    gridItemClass: {
        textAlign:'center'
    },
    accountInfo: {
        '&:hover': {
            backgroundColor: 'transparent',
          },
    },
    eulaClass: {
        justify: 'center',
        alignItems: 'center',
        textAlign: 'center',
    }
});

class TopBarBanner extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sidebarIsOpen: false,
            toLogin: false,
            popoverOpen: false,
            eulaIsOpen: false,
            ipAddressVar: ''
        };

        this.handleDrawerOpen=this.handleDrawerOpen.bind(this);
        this.handleDrawerClose=this.handleDrawerClose.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleEulaOpen = this.handleEulaOpen.bind(this);
        this.handleEulaClose = this.handleEulaClose.bind(this);
    }

    async componentDidMount() {
        let licenseData = JSON.parse(sessionStorage.getItem('licenseData'));
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));

        let ipAddr;
        try {
            ipAddr = await publicIp.v4();
        } catch(e) {
            console.log(e,'TopBarBanner; line 82')
        }

        this.setState({ 
            fName: parsedUserData.uiU_FN,
            lName: parsedUserData.uiU_LN,
            org: parsedUserData.uiU_M1ORGID,
            licenseExp: licenseData.licenseExpDate,
            ipAddressVar: ipAddr
        })
    }

    handleDrawerClose() {
        this.setState({sidebarIsOpen: false})
    }

    handleDrawerOpen() {
        this.setState({sidebarIsOpen: true})
    }
 
    performLogout = () => {
        userService.logout();
        this.setState({ toLogin: true })
    };

    handleAccountClick = (event) => {
        this.setState({ 
            anchorEl: event.currentTarget,
            popoverOpen: true
        })
    }

    handleClose() {
        this.setState({ 
            anchorEl:null,
            popoverOpen: false
        })
    }

    handleEulaOpen() {
        this.setState({ eulaIsOpen: true })
    }

    handleEulaClose() {
        this.setState({ eulaIsOpen: false })
    }
    
    render() {
        const { classes } = this.props;
        const { toLogin, fName, lName, org, popoverOpen, anchorEl, licenseExp, eulaIsOpen } = this.state;

        if(toLogin) {
            return(
                <Redirect to='/login' />
            );
        } else {
        return (
            <div>
                <CssBaseline />
                <AppBar position="fixed">
                    <Toolbar>
                        <Grid container className={classes.gridContainerClass}>
                            <Hidden mdUp>
                                <Grid item xs={1}>
                                <IconButton edge="start" color='secondary' aria-label="open drawer" onClick={this.handleDrawerOpen}>
                                    <MenuIcon />
                                </IconButton> 
                                </Grid>
                            </Hidden>
                            <Grid item xs={11} md={2} lg={2} className={classes.gridItemClass}>
                                <Typography variant='h6' color='secondary'>
                                    LockViewer Enterprise
                                </Typography>
                            </Grid>
                                <Hidden smDown>
                                <Grid item sm={8} md={8} lg={8} className={classes.gridItemClass}>
                                    <TopBarMenu 
                                        customizeClick={this.props.customizeClick}
                                        handleNavItemClick={this.props.handleNavItemClick}
                                        openLicense={this.props.openLicense}
                                    />
                                </Grid>
                                <Grid item sm={2} md={2} lg={2} className={classes.gridItemClass}>
                                    <div>
                                        <IconButton edge='start' color='secondary' onClick={this.handleAccountClick}>
                                            <AccountCircleIcon />
                                        </IconButton>
                                        <Popover
                                            open={popoverOpen}
                                            anchorEl={anchorEl}
                                            onClose={this.handleClose}
                                            anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                            }}
                                        >
                                            <MenuList>
                                                <MenuItem className={classes.accountInfo} disableRipple divider>Logged in as: {fName + ' ' + lName}</MenuItem>
                                                <MenuItem className={classes.accountInfo} disableRipple divider>Organization: {org}</MenuItem>
                                                <MenuItem className={classes.accountInfo} disableRipple divider>License Expiration Date: {licenseExp}</MenuItem>
                                                <MenuItem className={classes.accountInfo} disableRipple divider>IP Address: {this.state.ipAddressVar}</MenuItem>
                                                <MenuItem className={classes.accountInfo} onClick={this.handleEulaOpen} divider><PlayArrowIcon/>View EULA</MenuItem>
                                                <Link color='inherit' href="https://www.mwspi.com/privacy-policy/" target='_blank' rel='noopener'><MenuItem className={classes.accountInfo} disableRipple><PlayArrowIcon/>View Privacy Policy</MenuItem></Link>
                                            </MenuList>
                                        </Popover>
                                        <Button color='secondary' onClick={this.performLogout}>
                                            Logout
                                            <ExitToAppIcon />
                                        </Button>
                                    </div>
                                </Grid>
                            </Hidden>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <div> 
                    <Dialog open={eulaIsOpen} scroll="body" onClose={this.handleEulaClose}>                       
                        <DialogTitle id="scroll-dialog-title" className={classes.eulaClass}>
                        <h3>
                            LockViewer Enterprise EULA and Terms of Service                        
                        </h3>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="scroll-dialog-description">
                                <Eula />
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={this.handleEulaClose} color="primary">
                            Exit
                        </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <MobileSidebar 
                        customizeClick={this.props.customizeClick}
                        sidebarIsOpen={this.state.sidebarIsOpen} 
                        handleDrawerClose={this.handleDrawerClose}
                        handleNavItemClick={this.props.handleNavItemClick}
                        openLicense={this.props.openLicense}
                    />
                </div>
            </div>
        );}
    }
}

export default withStyles(styles, { withTheme: true })(TopBarBanner);