import React from 'react';

import Layout from '../components/Layout';
import { RecordsService } from '../services/FetchServices';
import MasterSystemsChart from '../components/MasterSystemsChart';
import GeneralDatatable from '../components/GeneralDatatable';
import TextBox from '../components/inputComponents/textbox';
import Label from '../components/inputComponents/Label';

import {Messages} from 'primereact/messages';
import {Dialog} from 'primereact/dialog';
import { TabView,TabPanel } from 'primereact/tabview';
import {Checkbox} from 'primereact/checkbox';
import { InputTextarea } from 'primereact/inputtextarea';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import { BrowserView, MobileView } from 'react-device-detect';

let checkIcon;

class MasterSystems extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formattedData: [],
            isLoading: true,
            systems: [],
            selectedColumns: [
                {field:'uiS_SYSNAME', header:'Name'},
                {field:'uiS_DESC', header:'Description'},
                {field:'uiS_TYPE', header:'Type'},  
                {field:'uiS_KEYSTAMP', header:'Key Stamp'},
                {field:'uiS_PINS', header:'Pins'},
                {field:'uiS_KEYWAY', header:'Keyway'},     
                {field:'assignedIcon', header:'Assigned'},
                {field:'uiS_NOTES', header:'Notes'}
            ],
            columnWidthTracker: "182.875,182.875,182.875,182.875,182.875,182.875,182.875,182.875",
            treeLayout: 'OrgChart.normal',
            activePinIndex: 0,
            hideDescNodes: true
        };
        this.handleWidth = this.handleWidth.bind(this);
        this.handleSelectedColumns = this.handleSelectedColumns.bind(this);
        this.editRecord = this.editRecord.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.showResults = this.showResults.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.changeLayout = this.changeLayout.bind(this);
        this.toggleDescNodes = this.toggleDescNodes.bind(this);
    }

    async componentDidMount() {
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
        if(parsedUserData.secString[17]!=='3') {
            this.setState({disabled: true})
        }
        this.setState({secRole: parsedUserData.secString[17]})

        await this.fetchData()
    }

    async fetchData() {
        checkIcon=this.renderTrueIcon();
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));

        let tempArray = await RecordsService.fetchSystems( parsedUserData.uiU_M1ORGID, 0 );
        tempArray = tempArray.systemlist;
        let newArray = [];

        for(let i=0; i<tempArray.length; i++) {
            let tempVar = this.createObject(
                tempArray[i].uiS_ASSIGNED,
                tempArray[i].uiS_COREMARKLOC,
                tempArray[i].uiS_DESC,
                tempArray[i].uiS_ENFORCEKEYUID,
                tempArray[i].uiS_ID,
                tempArray[i].uiS_KEYSTAMP,
                tempArray[i].uiS_KEYWAY,
                tempArray[i].uiS_M1ORGID,
                tempArray[i].uiS_M6ANGLE1,
                tempArray[i].uiS_M6ANGLE2,
                tempArray[i].uiS_MODE,
                tempArray[i].uiS_NOTES,
                tempArray[i].uiS_PINS,
                tempArray[i].uiS_SERIALIZECORES,
                tempArray[i].uiS_SERIALIZEKEYS,
                tempArray[i].uiS_SYSNAME,
                tempArray[i].uiS_TYPE,
                tempArray[i].uiS_UNIQUEID
            )
            newArray.push(tempVar)
        }
        this.setState({ systems: newArray, isLoading: false })
    }

    createObject(uiS_ASSIGNED, uiS_COREMARKLOC, uiS_DESC, uiS_ENFORCEKEYUID, uiS_ID, uiS_KEYSTAMP, uiS_KEYWAY, uiS_M1ORGID, uiS_M6ANGLE1, uiS_M6ANGLE2, uiS_MODE, uiS_NOTES, 
                uiS_PINS, uiS_SERIALIZECORES, uiS_SERIALIZEKEYS, uiS_SYSNAME, uiS_TYPE, uiS_UNIQUEID) {
        let obj = {};
        if(uiS_ASSIGNED) {
            obj.assignedIcon = checkIcon;
            obj.uiS_ASSIGNED = uiS_ASSIGNED;
            obj.uiS_COREMARKLOC = uiS_COREMARKLOC;
            obj.uiS_DESC = uiS_DESC;
            obj.uiS_ENFORCEKEYUID = uiS_ENFORCEKEYUID;
            obj.uiS_ID = uiS_ID;
            obj.uiS_KEYSTAMP = uiS_KEYSTAMP;
            obj.uiS_KEYWAY = uiS_KEYWAY;
            obj.uiS_M1ORGID = uiS_M1ORGID;
            obj.uiS_M6ANGLE1 = uiS_M6ANGLE1;
            obj.uiS_M6ANGLE2 = uiS_M6ANGLE2;
            obj.uiS_MODE = uiS_MODE;
            obj.uiS_NOTES = uiS_NOTES;
            obj.uiS_PINS = uiS_PINS;
            obj.uiS_SERIALIZECORES = uiS_SERIALIZECORES;
            obj.uiS_SERIALIZEKEYS = uiS_SERIALIZEKEYS;
            obj.uiS_SYSNAME = uiS_SYSNAME;
            obj.uiS_TYPE = uiS_TYPE;
            obj.uiS_UNIQUEID = uiS_UNIQUEID;
        } else {
            obj.assignedIcon = '';
            obj.uiS_ASSIGNED = uiS_ASSIGNED;
            obj.uiS_COREMARKLOC = uiS_COREMARKLOC;
            obj.uiS_DESC = uiS_DESC;
            obj.uiS_ENFORCEKEYUID = uiS_ENFORCEKEYUID;
            obj.uiS_ID = uiS_ID;
            obj.uiS_KEYSTAMP = uiS_KEYSTAMP;
            obj.uiS_KEYWAY = uiS_KEYWAY;
            obj.uiS_M1ORGID = uiS_M1ORGID;
            obj.uiS_M6ANGLE1 = uiS_M6ANGLE1;
            obj.uiS_M6ANGLE2 = uiS_M6ANGLE2;
            obj.uiS_MODE = uiS_MODE;
            obj.uiS_NOTES = uiS_NOTES;
            obj.uiS_PINS = uiS_PINS;
            obj.uiS_SERIALIZECORES = uiS_SERIALIZECORES;
            obj.uiS_SERIALIZEKEYS = uiS_SERIALIZEKEYS;
            obj.uiS_SYSNAME = uiS_SYSNAME;
            obj.uiS_TYPE = uiS_TYPE;
            obj.uiS_UNIQUEID = uiS_UNIQUEID;
        }
        return obj;
    }

    changeLayout() {
        if(this.state.treeLayout==='OrgChart.treeRightOffset') {
            this.setState({treeLayout: 'OrgChart.normal'})
        } else {
            this.setState({treeLayout: 'OrgChart.treeRightOffset'})
        }
    }

    toggleDescNodes() {
        this.setState({hideDescNodes: !this.state.hideDescNodes})
    }

    renderTrueIcon() {
        return(<div><i className='pi pi-check'></i></div>);
    }

    handleWidth(width) {
        this.setState({ columnWidthTracker: width })
    }

    handleSelectedColumns(e) {
        this.setState({ selectedColumns: e })
    }

    editRecord(e) {
        if(this.state.secRole!=='1') {
            this.setState({
                editSystemDialog: true,
                sysName: e.data.uiS_SYSNAME,
                type: e.data.uiS_TYPE,
                keyway: e.data.uiS_KEYWAY,
                stamping: e.data.uiS_KEYSTAMP,
                pins: e.data.uiS_PINS,
                coreMarkLocation: e.data.uiS_COREMARKLOC,
                assigned: e.data.uiS_ASSIGNED,
                desc: e.data.uiS_DESC,
                notes: e.data.uiS_NOTES,
                id: e.data.uiS_ID
            })
        }
    }

    handleClose() {
        this.setState({
            editSystemDialog: false,
            sysName: '',
            type: '',
            keyway: '',
            stamping: '',
            pins: '',
            coreMarkLocation: '',
            assigned: '',
            desc: '',
            notes: '',
            id: ''
        })
    }

    async handleSave() {
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
        await RecordsService.storeSystems(parsedUserData.uiU_M1ORGID, this.state.id, this.state.assigned, this.state.desc, this.state.notes)
        .then(data => this.showResults(data))
        this.handleClose();
        await this.fetchData();
    }

    showResults(data) {
        this.messages.clear();
        if(data.success) {
            this.messages.show({severity: 'success', summary: data.message, life:1000});
        } else {
            this.messages.show({severity: 'error', summary: data.message, sticky: true});
        }
    }

    renderEditFooter() {
        if(this.state.activePinIndex===0) {
            return (
                <div>
                    {this.state.disabled ? (null) : (<Button type='button' label='Save and Exit' icon='pi pi-check' onClick={this.handleSave} />)}
                    <Button type='button' label='Exit' icon='pi pi-times' onClick={this.handleClose} />
                </div>
            );
        } else {
            return(
                <div>
                    {this.state.hideDescNodes ? (<Button type='button' label='Show All Nodes' icon='pi pi-ellipsis-v' onClick={this.toggleDescNodes} />) : (<Button type='button' label='Hide Nodes Without Description' icon='pi pi-ellipsis-v' onClick={this.toggleDescNodes} />)}
                    <Button type='button' label='Change Chart Layout' icon='pi pi-refresh' onClick={this.changeLayout} />
                    {this.state.disabled ? (null) : (<Button type='button' label='Save and Exit' icon='pi pi-check' onClick={this.handleSave} />)}
                    <Button type='button' label='Exit' icon='pi pi-times' onClick={this.handleClose} />
                </div>
            );
        }
    }

    render(){
        const editFooter=this.renderEditFooter();
        if(this.state.secRole==='0' && !this.state.isLoading) {
            return(<div>Your security role prevents you from seeing master systems</div>)
        } else if(this.state.systems.length===0 && !this.state.isLoading) {
            return(<div><Layout>Contact Midwest Security Products to add master systems</Layout></div>)
        } else {
            return(
                <div>
                    <Layout>                      
                        <div className='container-div'>
                            <Messages ref={(el) => this.messages = el} />
                            <div>
                                <GeneralDatatable 
                                    columnArray = {[
                                        {field:'uiS_SYSNAME', header:'Name'},
                                        {field:'uiS_DESC', header:'Description'},
                                        {field:'uiS_TYPE', header:'Type'},  
                                        {field:'uiS_KEYSTAMP', header:'Key Stamp'},
                                        {field:'uiS_PINS', header:'Pins'},
                                        {field:'uiS_KEYWAY', header:'Keyway'},     
                                        {field:'assignedIcon', header:'Assigned'},
                                        {field:'uiS_NOTES', header:'Notes'}
                                    ]}

                                    csvHeader = {[
                                        {key:'uiS_SYSNAME', label:'Name'},
                                        {key:'uiS_DESC', label:'Description'},
                                        {key:'uiS_TYPE', label:'Type'},  
                                        {key:'uiS_KEYSTAMP', label:'Key Stamp'},
                                        {key:'uiS_PINS', label:'Pins'},
                                        {key:'uiS_KEYWAY', label:'Keyway'},     
                                        {key:'uiS_ASSIGNED', label:'Assigned'},
                                        {key:'uiS_NOTES', label:'Notes'}
                                    ]}
                                    
                                    defaultOrder={["uiS_SYSNAME", "uiS_DESC", "uiS_TYPE", "uiS_KEYSTAMP", "uiS_PINS", "uiS_KEYWAY", "assignedIcon", "uiS_NOTES"]}

                                    tableRecords = {this.state.systems}
                                    pageNumber={11}
                                    tableName={'Master Systems'}
                                    exportFileName={'systems.csv'}
                                    tableData={'systemsTableData'}
                                    filterData={'systemsFilterData'}
                                    columnWidthTracker={this.state.columnWidthTracker}
                                    isLoading={this.state.isLoading}
                                    selectedColumns={this.state.selectedColumns}
                                    handleRowClick={this.editRecord}
                                    handleWidth={this.handleWidth}
                                    handleSelectedColumns={this.handleSelectedColumns}   
                                />
                            </div>                        
                        </div>
                    </Layout>
                    <Dialog visible={this.state.editSystemDialog} header={"Edit " + this.state.sysName} footer={editFooter} onHide={this.handleClose} maximized baseZIndex={1300} closable={false} focusOnShow={false}>
                        <div>
                            <TabView activeIndex={this.state.activePinIndex} onTabChange={(e) => this.setState({activePinIndex: e.index})}>
                                <TabPanel header="System Details" >
                                    <div>
                                        <BrowserView>
                                            <div className='form-input-container'>
                                                <div className='bottom-margin'>
                                                    <div className='inlineLabel'><strong>System </strong></div>
                                                    <div className='labeled-input'>{this.state.sysName}</div>
                                                </div>
                                                <div className='bottom-margin'>
                                                    <div className='inlineLabel'><strong>Keyway </strong></div>
                                                    <div className='labeled-input'>{this.state.keyway}</div>
                                                </div>    
                                                <div className='bottom-margin'>
                                                    <div className='inlineLabel'><strong>Type </strong></div>
                                                    <div className='labeled-input'>{this.state.type}</div>
                                                </div>
                                                <div className='bottom-margin'>
                                                    <div className='inlineLabel'><strong>Key Stamping </strong></div>
                                                    <div className='labeled-input'>{this.state.stamping}</div>
                                                </div>   
                                                <div className='bottom-margin'>
                                                    <div className='inlineLabel'><strong>Pins </strong></div>
                                                    <div className='labeled-input'>{this.state.pins}</div>
                                                </div>
                                                <div className='bottom-margin'>
                                                    <div className='inlineLabel'><strong>Core Mark Location </strong></div>
                                                    <div className='labeled-input'>{this.state.coreMarkLocation}</div>
                                                </div>
                                                <div className='bottom-margin'>
                                                    <div className='inlineLabel'><strong>Assigned? </strong></div>
                                                    <div className='labeled-input'><Checkbox onChange={e => this.setState({assigned: e.checked})} checked={this.state.assigned} disabled={this.state.disabled}></Checkbox></div>
                                                </div>
                                                <div className='bottom-margin'>
                                                    <div className='inlineLabel'><strong>Description </strong></div>
                                                    <div className='labeled-input'><InputText value={this.state.desc} onChange={(e) => this.setState({desc: e.target.value})} disabled={this.state.disabled} /></div>
                                                </div>
                                                <div className='bottom-margin'>
                                                    <div className='inlineLabel'><strong>Notes </strong></div>
                                                    <div className='labeled-input'><InputTextarea rows={5} cols={30} value={this.state.notes} onChange={(e) => this.setState({notes: e.target.value})} disabled={this.state.disabled} /></div>
                                                </div>
                                            </div>
                                        </BrowserView>
                                        <MobileView>
                                            <Label mobile value={this.state.sysName} label={'System'}/>
                                            <Label mobile value={this.state.keyway} label={'Keyway'}/>
                                            <Label mobile value={this.state.type} label={'Type'}/>
                                            <Label mobile value={this.state.stamping} label={'Key Stamping'}/>
                                            <Label mobile value={this.state.pins} label={'Pins'}/>
                                            <Label mobile value={this.state.coreMarkLocation} label={'Core Mark Location'}/>
                                            <div style={{margin:'10px'}} className="p-col-12">
                                                <div><strong>Assigned?</strong></div>
                                                <div style={{width:'100%', margin:'auto'}}><Checkbox onChange={e => this.setState({assigned: e.checked})} checked={this.state.assigned} disabled={this.state.disabled}></Checkbox></div>
                                            </div>
                                            <TextBox mobile value={this.state.desc} id="desc" label={"Description"} handleFieldChange={(e) => this.setState({desc: e.target.value})} disabled={this.state.disabled} />
                                            <TextBox mobile area value={this.state.notes} id="notes" label={"Notes"} handleFieldChange={(e) => this.setState({notes: e.target.value})} disabled={this.state.disabled} />
                                        </MobileView>
                                    </div>
                                </TabPanel>
                                <TabPanel header='System Chart'>
                                    <div>
                                        <MasterSystemsChart systemID={this.state.sysName} treeLayout={this.state.treeLayout} hideDescNodes={this.state.hideDescNodes} />
                                    </div>
                                </TabPanel>
                            </TabView>
                        </div>
                    </Dialog>
                </div>
            );
        }
    }
}

export default MasterSystems;