import React from 'react';
import { Redirect } from 'react-router-dom';

import { userService } from '../services/Auth';

class Timer extends React.Component {

    state = {
        seconds: 30,
        redirectToLogin: false
    }

    componentDidMount() {
        this.myInterval = setInterval(() => {
            const { seconds } = this.state;

            if (seconds>0) {
                this.setState(({ seconds }) => ({
                    seconds: seconds - 1
                }));
            }
            if (seconds === 0) {
                clearInterval(this.myInterval);
                userService.logout();
                this.setState({ redirectToLogin: true});
            } 
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.myInverval);
    }

    render() {
        const { redirectToLogin, seconds } = this.state;

        if (redirectToLogin) {
            return(
                <Redirect to='/login' />
            );
        } else {
        return(
            <span>{seconds}</span>
        );}
    }
}

export default Timer;