import React from 'react';

import { CoreService } from '../services/FetchServices';
import { KeyService } from '../services/FetchServices';
import ViewJobPDF from './ViewJobPDF';

import { Carousel } from 'primereact/carousel';
import {Dialog} from 'primereact/dialog';
import { Button } from 'primereact/button';

import { PDFDownloadLink } from '@react-pdf/renderer';

class ViewJob extends React.Component {
    constructor(props) {
        super(props);

        this.state={

        };
        this.detailTemplate = this.detailTemplate.bind(this);
    }

    async componentDidMount() {
        let tempArray=[];
        let pinning;
        let keyCutStr;
        let fetchPinnedCoresStr;
        
        
        for(let i=0; i<this.props.jobDetails.length; i++) {

            let typeVar;
            pinning='';
            keyCutStr='';
            fetchPinnedCoresStr='';
            let stamping ='';
            let bittingsVar;

            if(this.props.jobDetails[i].woD_EXCLUDECONTROL===false&&this.props.jobDetails[i].woD_FIXEDCORE===false) {
                typeVar="SFIC"
            } else if(this.props.jobDetails[i].woD_EXCLUDECONTROL===true&&this.props.jobDetails[i].woD_FIXEDCORE===true) {
                typeVar="FIXED"
            } else if(this.props.jobDetails[i].woD_EXCLUDECONTROL===true&&this.props.jobDetails[i].woD_FIXEDCORE===false) {
                typeVar="CAM"
            }

            if((this.props.jobDetails[i].woD_TYPE.split(' ', 1))[0]==="CORES") {
                fetchPinnedCoresStr = await CoreService.fetchPinnedCores(this.props.orgID, this.props.jobDetails[i].woD_SYSTEM, this.props.jobDetails[i].woD_KEYLOCKID, typeVar);
                pinning = fetchPinnedCoresStr.pinString;
                keyCutStr = fetchPinnedCoresStr.keyCutsString;
            } else {
                await KeyService.fetchIssuedKeys( this.props.orgID, 0, this.props.jobDetails[i].woD_KEYLOCKID, 0 ).then(data => bittingsVar=data.keylist[0].bittings);
                stamping = (this.props.jobDetails[i].woD_KEYLOCKMARK.split(' ', 1))[0] + '-' + this.props.jobDetails[i].woD_BEGSN + ' through ' + (this.props.jobDetails[i].woD_KEYLOCKMARK.split(' ', 1))[0] + '-' + this.props.jobDetails[i].woD_ENDSN
            }

            tempArray.push (
                {
                    "coreMarkID": this.props.jobDetails[i].coreMarkID,
                    "coreQty": this.props.jobDetails[i].coreQty,
                    "coreType": this.props.jobDetails[i].coreType,
                    "keyMarkID": this.props.jobDetails[i].keyMarkID,
                    "keyQty": this.props.jobDetails[i].keyQty,
                    "listofDetails": this.props.jobDetails[i].listofDetails,
                    "woD_BEGSN": this.props.jobDetails[i].woD_BEGSN,
                    "woD_DESC": this.props.jobDetails[i].woD_DESC,
                    "woD_ENDSN": this.props.jobDetails[i].woD_ENDSN,
                    "woD_EXCLUDECONTROL": this.props.jobDetails[i].woD_EXCLUDECONTROL,
                    "woD_FIXEDCORE": this.props.jobDetails[i].woD_FIXEDCORE,
                    "woD_HEADERID": this.props.jobDetails[i].woD_HEADERID,
                    "woD_ID": this.props.jobDetails[i].woD_ID,
                    "woD_KEYLOCKID": this.props.jobDetails[i].woD_KEYLOCKID,
                    "woD_KEYLOCKMARK": this.props.jobDetails[i].woD_KEYLOCKMARK,
                    "woD_KEYSNSTRING": this.props.jobDetails[i].woD_KEYSNSTRING,
                    "woD_KEYWAY": this.props.jobDetails[i].woD_KEYWAY,
                    "woD_LINECMPLT": this.props.jobDetails[i].woD_LINECMPLT,
                    "woD_LINENUM": this.props.jobDetails[i].woD_LINENUM,
                    "woD_M1ORGID": this.props.jobDetails[i].woD_M1ORGID,
                    "woD_MODE": this.props.jobDetails[i].woD_MODE,
                    "woD_NOTES": this.props.jobDetails[i].woD_NOTES,
                    "woD_QTY": this.props.jobDetails[i].woD_QTY,
                    "woD_RECORDDESC": this.props.jobDetails[i].woD_RECORDDESC,
                    "woD_STRCUTS": this.props.jobDetails[i].woD_STRCUTS,
                    "woD_STRPIN": this.props.jobDetails[i].woD_STRPIN,
                    "woD_STRSYM": this.props.jobDetails[i].woD_STRSYM,
                    "woD_SYSTEM": this.props.jobDetails[i].woD_SYSTEM,
                    "woD_TYPE": this.props.jobDetails[i].woD_TYPE,
                    "woD_UIDCMPLT": this.props.jobDetails[i].woD_UIDCMPLT,
                    "woD_UIDREQ": this.props.jobDetails[i].woD_UIDREQ,
                    "woD_UIDSTRING": this.props.jobDetails[i].woD_UIDSTRING,
                    "woD_UNIQUEID": this.props.jobDetails[i].woD_UNIQUEID,
                    "woD_CORETYPE": typeVar,
                    "pinning": pinning,
                    "keyCutStr": keyCutStr,
                    "bittings": bittingsVar,
                    "stamping": stamping
                }
            )
        }
        this.setState({jobDetails: tempArray})
    }

    detailTemplate(detail) {
        if(((detail.woD_TYPE).split(' ', 1))[0]==="CORES") {
            return(
                <div className='p-grid p-justify-center'>
                    <div className='p-col-12 p-grid' style={{borderBottom: '1px solid black'}}>
                        <h2 className='p-col-3'>Line: {detail.woD_LINENUM}</h2>
                        <h2 className='p-col-3' style={{textAlign: 'right'}}>{detail.woD_TYPE}</h2>
                        <h2 className='p-col-3' style={{textAlign: 'left'}}>{detail.woD_CORETYPE}</h2>
                        <h2 className='p-col-3'>Qty: {detail.woD_QTY}</h2>
                    </div>
                    <div className='p-col-12 p-grid p-justify-center'  style={{borderBottom: '1px solid black'}}>
                        <h1 className='p-col-12' style={{margin: '0px'}}>{detail.woD_KEYLOCKMARK}</h1>
                        <div className='p-col-12 p-grid p-justify-center'>
                            <div className='p-col-6'>
                                <h2>Pinning</h2>
                                <pre className='no-bmargin'><span style={{font:'30px Consolas'}}>{detail.pinning}</span></pre>
                            </div>
                            <div className='p-col-6'>
                                <h2 style={{textAlign:'left'}}>Cuts</h2>
                                <pre className='no-bmargin' style={{textAlign:'left'}}><span style={{font:'30px Consolas'}}>{detail.keyCutStr}</span></pre>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-grid p-justify-center">
                        <p className='p-col-6'><strong>Record Description:</strong> {detail.woD_RECORDDESC}</p>
                        <p className='p-col-6'><strong>System:</strong> {detail.woD_SYSTEM}</p>
                    </div>
                    <h5 className='p-col-12'>Page {detail.woD_LINENUM} of {this.state.jobDetails.length}</h5>
                </div>
            );
        } else {
            return (
                <div className='p-grid p-justify-center'>
                    <div className='p-col-12 p-grid' style={{borderBottom: '1px solid black'}}>
                        <h2 className='p-col-3'>Line: {detail.woD_LINENUM}</h2>
                        <h2 className='p-col-6'>{detail.woD_TYPE}</h2>
                        <h2 className='p-col-3'>Qty: {detail.woD_QTY}</h2>
                    </div>
                    <div className='p-col-12 p-grid p-justify-center'  style={{borderBottom: '1px solid black'}}>
                        <h1 className='p-col-12' style={{margin: '0px'}}>{detail.woD_KEYLOCKMARK}</h1>
                        <div className='p-col-12 p-grid p-justify-center'>
                            <div className='p-col-3'>
                                <h2>Cuts</h2>
                                <pre><span style={{font:'normal 30px Verdana, Arial, sans-serif'}}>{detail.bittings}</span></pre>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-grid p-justify-center">
                        <p className='p-col-4'><strong>Record Description:</strong> {detail.woD_RECORDDESC}</p>
                        <p className='p-col-4'><strong>System:</strong> {detail.woD_SYSTEM}</p>
                        <p className='p-col-4'><strong>Keyway:</strong> {detail.woD_KEYWAY}</p>
                        <div className='p-col-12 p-grid p-justify-center'><h2 className='p-col-6'><strong style={{marginRight:'15px'}}>Stamping:</strong>{detail.stamping}</h2></div>
                    </div>
                    <h5 className='p-col-12'>Page {detail.woD_LINENUM} of {this.state.jobDetails.length}</h5>
                </div>
            );
        }
    }

    renderHeader() {
        return(
            <div className='p-grid p-justify-center'>
                <div className="p-col-12 p-grid" style={{borderBottom: '1px solid black'}}>
                        <p className='p-col-2'><strong>Reference Number:</strong> {this.props.refNum}</p>
                        <p className='p-col-2'><strong>Due Date:</strong> {this.props.dateDue}</p>
                        <p className='p-col-2'><strong>Status:</strong> {this.props.status}</p>
                        <p className='p-col-2'><strong>PO#</strong> {this.props.poNum}</p>
                        <p className='p-col-2'><strong>Desc:</strong> {this.props.desc}</p>
                        <p className='p-col-2'><strong>Notes:</strong> {this.props.notes}</p>
                </div>
            </div>
        );
    }

    renderViewFooter() {
        return(
            <div>
                {(this.state.jobDetails !== undefined) ? (
                    <PDFDownloadLink 
                        fileName={"Job" + this.props.jobID + "details.pdf"}
                        document={
                            <ViewJobPDF
                                jobDetails={this.state.jobDetails}
                                refNum={this.props.refNum}
                                dateDue={this.props.dateDue}
                                notes={this.props.notes}
                                jobID={this.props.jobID}
                                status={this.props.status}
                                poNum={this.props.poNum}
                                desc={this.props.desc}
                            />
                        } 
                    >
                        {({ blob, url, loading, error }) =>
                            loading ? <Button label='Loading PDF...' /> : <Button icon='pi pi-download' label='Download PDF' />
                        }
                    </PDFDownloadLink>
                ) : (null)}
                <Button type='button' icon='pi pi-times' label='Exit' onClick={this.props.closeViewJob} />
            </div>
        );
    }

    render(){
        const header=this.renderHeader();
        const viewFooter = this.renderViewFooter();
        return(
            <Dialog header={<h1 style={{margin:'0px'}}>Job {this.props.jobID}</h1>} visible={this.props.viewJob} onHide={this.props.closeViewJob} footer={viewFooter} baseZIndex={1300} closable={false} maximized focusOnShow={false}>
                <div>
                    <Carousel 
                        value={this.state.jobDetails}
                        numVisible={1} numScroll={1} 
                        itemTemplate={this.detailTemplate} 
                        header={header} 
                    />     
                </div>
            </Dialog>
        );
    }
}

export default ViewJob;