import React from 'react';
import Layout from '../components/Layout';

import KeyHandOutPage from '../components/KeyHandOutPage';
/*=================================================================================*/

class KeyHandOut extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            refreshKey: false
        };
        this.handleRefresh = this.handleRefresh.bind(this);
    }

    handleRefresh() {
        this.setState({refreshKey: !this.state.refreshKey})
    }

/*=================================================================================*/
    render(){        
        return(
            <div>
                <Layout> 
                    <KeyHandOutPage key={this.state.refreshKey} handleRefresh={this.handleRefresh} />
                </Layout>
            </div>
        );
    }
}

export default KeyHandOut;