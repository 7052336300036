import React from 'react';

import verticalBanner from '../images/verticalBanner.png';

import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ButtonBase from '@material-ui/core/ButtonBase';
import Fab from '@material-ui/core/Fab';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

const styles = theme => ({
    Vdrawer: {
        width: '20vw',
        height: '100vh',
    },
    VdrawerPaper: {
        width: '20vw',
        height: '100vh',
        overflow: 'hidden',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage: 'url(' + verticalBanner + ')'
    },
    widthHeightClass: {
        width: '100%',
        height: '100%'
    },
    widthClass: {
        width: '100%'
    },
    fabClass: {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
        zIndex: 1500
    }
})

class ProductWindow extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            anchor: 'right',
            VdrawerOpen: false,
        }

        this.handleClickRight=this.handleClickRight.bind(this);
        this.handleClickOff = this.handleClickOff.bind(this);
    }

    handleClickRight() {
        this.setState({             
            anchor: 'right',
            VdrawerOpen: true
        });
    }

    handleClickOff() {
        this.setState({ VdrawerOpen: false })
    }

    render() {
        const { classes } = this.props;
        const { VdrawerOpen, anchor } = this.state;
        return(
            <div>
                <Fab color='primary' size='small' variant='extended' className={classes.fabClass} onClick={this.handleClickRight}>
                    <NavigateBeforeIcon />
                    What's New?
                </Fab>
                <Drawer 
                    anchor={anchor} 
                    open={VdrawerOpen} 
                    onClose={this.handleClickOff} 
                    className={classes.Vdrawer}
                    classes={{paper: classes.VdrawerPaper}}                
                >
                    <ButtonBase className={classes.widthHeightClass} href="https://www.mwspi.com/product-types/key-management-systems/soc-boxes/" target='_blank' rel='noopener'>
                        <List>
                            <ListItem>
                                <verticalBanner className={classes.widthClass}/>
                            </ListItem>
                        </List>
                    </ButtonBase>
                </Drawer>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(ProductWindow);