import React from 'react';
import OrgChart from './OrgChartComponent';
import { RecordsService } from '../services/FetchServices';

class MasterSystemsChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formattedData: [],
            isLoading: true,
            records: []
        };
        this.createObject = this.createObject.bind(this);
        this.drawChart = this.drawChart.bind(this);
    }

    async componentDidMount() {
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
        await RecordsService.fetchRecords( parsedUserData.uiU_M1ORGID, 0, this.props.systemID ).then(data => this.setState({ records: data.recordlist }));
        this.drawChart();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.hideDescNodes!==prevProps.hideDescNodes) {
            this.drawChart();
        }
      }

    createObject(keyMark, keySeries, keysCut, coresPinned, desc) {
        const obj = {};
        
        if(keySeries==='') {
            obj.id = keyMark;
            obj.name = keyMark;
            obj.keysCut = keysCut;
            obj.coresPinned = coresPinned;
            obj.desc = desc;
        } else {
            obj.id = keyMark;
            obj.pid = keySeries;
            obj.name = keyMark;
            obj.keysCut = keysCut;
            obj.coresPinned = coresPinned;
            obj.desc = desc;
        }
        return obj;
    }

    drawChart() {
        let tempArray = [];
        for(let i=0; i<this.state.records.length; i++) {
            if(this.state.records[i].keyMark!=="") {
                if(this.props.hideDescNodes === true) {
                    if((this.state.records[i].keyLevel==="OP" || this.state.records[i].keyLevel==="M") && this.state.records[i].description!=="") {
                        tempArray.push(
                            this.createObject(
                                this.state.records[i].keyMark,
                                this.state.records[i].keySeries,
                                this.state.records[i].keysCut,
                                this.state.records[i].coresPinned,
                                this.state.records[i].description
                            )
                        );
                    }
                    
                    if(this.state.records[i].keyLevel!=="OP" && this.state.records[i].keyLevel!=="M") {
                        tempArray.push(
                            this.createObject(
                                this.state.records[i].keyMark,
                                this.state.records[i].keySeries,
                                this.state.records[i].keysCut,
                                this.state.records[i].coresPinned,
                                this.state.records[i].description
                            )
                        );
                    }
                } else {
                    tempArray.push(
                        this.createObject(
                            this.state.records[i].keyMark,
                            this.state.records[i].keySeries,
                            this.state.records[i].keysCut,
                            this.state.records[i].coresPinned,
                            this.state.records[i].description
                        )
                    );
                }
            }
        }

        this.setState({ 
            formattedData: tempArray,
            key: Date.now(),
            isLoading: false
        })
    }

    render() {
        if(this.state.isLoading) {
            return (
                <div style={{height:'100%'}}>
                    <i className="pi pi-spin pi-spinner" style={{'fontSize': '2em'}}></i>
                </div>
            )
        } else {
            return (
                <div style={{height:'100%'}}>
                    <OrgChart nodes={this.state.formattedData} key={this.state.key} pageID={11} systemName={this.props.systemID} treeLayout={this.props.treeLayout} />
                </div>
            );
        }
    }
}

export default MasterSystemsChart;