import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Radio from '@material-ui/core/Radio';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { BrowserView} from 'react-device-detect';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';

const drawerWidth=125;

const styles = theme => ({
    drawer: {
        width: drawerWidth,
        height: 'auto',
        flexShrink: 0,
        posistion: 'fixed',
    },
    drawerPaper: {
        height: 'auto',
        width: drawerWidth,        
        posistion: 'fixed'
    },
    iconClass: {
        fontSize: 60
    },
    secondaryTextClass: {
        fontSize: 16
    },
    widthClass: {
        width: '100%'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    loading: {
        display: 'flex',
        '& > * + *': {
          marginLeft: theme.spacing(2),
        },
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        margin: theme.spacing(3, 0, 2, 0),
      },
});

class Sidebar extends React.Component {

    renderSelectOptions = () => {
        return this.props.menuNavItems.map((mappedOptions, i) => (
            <option key={i} value={mappedOptions.navId}>
                {mappedOptions.title}
            </option>
        ));
    }

    render() {
        const { classes, landingPageValue, selectedValue, selectedValue2, selectedValue3, selectedValue4, selectedValue5, customWindowOpen, isError, isLoading } = this.props;
        return(
            <div>
                <BrowserView>
                    <div>
                        <Drawer
                            className={classes.drawer}
                            classes={{paper: classes.drawerPaper}}
                            variant="permanent"
                            PaperProps={{ elevation: 5 }}
                        >
                            <Toolbar />
                            <List>
                            {this.props.selectedMenuNavItems.length > 0 ? (
                                <Grid container direction="column" justify="center" alignItems="center" spacing={0}>
                                    {/* //change 5 to navid for support cases */}
                                    <Grid item className={classes.widthClass} onClick={() => this.props.handleNavItemClick(38)}>
                                        <ListItem button>
                                            <ListItemText disableTypography 
                                                primary={<Typography align='center' color='primary'>{<ContactSupportIcon className={this.props.classes.iconClass}/>}</Typography>} 
                                                secondary={<Typography align='center' className={classes.secondaryTextClass}>New Support Case</Typography>}
                                            />
                                        </ListItem>
                                    </Grid>
                                {this.props.selectedMenuNavItems.map((selectedMenuNavItemsMapped, index) => (
                                    <Grid key={index} item className={classes.widthClass} onClick={() => this.props.handleNavItemClick(selectedMenuNavItemsMapped.navId)}>
                                        <ListItem button>
                                            <ListItemText disableTypography 
                                                primary={<Typography align='center' color='primary'>{selectedMenuNavItemsMapped.navIcon}</Typography>} 
                                                secondary={<Typography align='center' className={classes.secondaryTextClass}>{selectedMenuNavItemsMapped.title}</Typography>}
                                            />
                                        </ListItem>
                                    </Grid>
                                ))}
                            </Grid>
                            ) : (
                                null
                            )}
                            </List>
                        </Drawer>
                    </div>
                </BrowserView>
                <div>
                    <Dialog disableBackdropClick disableEscapeKeyDown open={customWindowOpen} onClose={this.props.handleClose}>
                        <DialogContent>
                            <BrowserView>
                                <h3>Choose your five quick links and desired landing page</h3>
                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="qL1">Quick Link 1</InputLabel>
                                    <Select
                                        native
                                        value={selectedValue}
                                        onChange={this.props.handleChange}
                                        inputProps={{
                                            name: 'Quick Link 1',
                                            id: 0,
                                        }}
                                        >
                                        <option aria-label="None" value=""/>  
                                        {this.renderSelectOptions()}
                                    </Select>
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="qL2">Quick Link 2</InputLabel>
                                    <Select
                                        native
                                        value={selectedValue2}
                                        onChange={this.props.handleChange2}
                                        inputProps={{
                                            name: 'Quick Link 2',
                                            id: 1,
                                        }}
                                        >
                                        <option aria-label="None" value=""/>  
                                        {this.renderSelectOptions()}
                                    </Select>
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="qL3">Quick Link 3</InputLabel>
                                    <Select
                                        native
                                        value={selectedValue3}
                                        onChange={this.props.handleChange3}
                                        inputProps={{
                                            name: 'Quick Link 3',
                                            id: 2,
                                        }}
                                        >
                                        <option aria-label="None" value=""/>  
                                        {this.renderSelectOptions()}
                                    </Select>
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="qL4">Quick Link 4</InputLabel>
                                    <Select
                                        native
                                        value={selectedValue4}
                                        onChange={this.props.handleChange4}
                                        inputProps={{
                                            name: 'Quick Link 4',
                                            id: 3,
                                        }}
                                        >
                                        <option aria-label="None" value=""/>  
                                        {this.renderSelectOptions()}
                                    </Select>
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="qL5">Quick Link 5</InputLabel>
                                        <Select
                                            native
                                            value={selectedValue5}
                                            onChange={this.props.handleChange5}
                                            inputProps={{
                                                name: 'Quick Link 5',
                                                id: 4,
                                            }}
                                            >
                                            <option aria-label="None" value=""/>  
                                            {this.renderSelectOptions()}
                                        </Select>
                                </FormControl>
                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="LP">Landing Page</InputLabel>
                                    <Select
                                        native
                                        value={landingPageValue}
                                        onChange={this.props.handleLandingPage}
                                        inputProps={{
                                            name: 'Landing Page',
                                            id: 'LP',
                                        }}
                                        >
                                        <option aria-label="None" value=""/>  
                                        {this.renderSelectOptions()}
                                    </Select>
                                </FormControl>
                            </BrowserView>
                            <h3>Choose your desired color</h3>
                            <div >
                                <Radio  value="gray" style={{color: '#607d8b'}} name='gray' checked={this.props.themeColor==='gray'} onChange={this.props.handleSetTheme} />
                                <Radio  value="green" style={{color: '#1b5e20'}} name='green' checked={this.props.themeColor==='green'} onChange={this.props.handleSetTheme} />
                                <Radio value="red" style={{color: '#d32f2f'}} name='red' checked={this.props.themeColor==='red'} onChange={this.props.handleSetTheme} />
                                <Radio  value="blue" style={{color: '#0d47a1'}} name='blue' checked={this.props.themeColor==='blue'} onChange={this.props.handleSetTheme} />
                                <Radio  value="yellow" style={{color: '#ffeb3b'}} name='yellow' checked={this.props.themeColor==='yellow'} onChange={this.props.handleSetTheme} />
                                <Radio  value="purple" style={{color: '#673ab7'}} name='purple' checked={this.props.themeColor==='purple'} onChange={this.props.handleSetTheme} />
                                <Radio  value="brown" style={{color: '#795548'}} name='brown' checked={this.props.themeColor==='brown'} onChange={this.props.handleSetTheme} />
                                <Radio  value="lightGreen" style={{color: '#cddc39'}} name='lightGreen' checked={this.props.themeColor==='lightGreen'} onChange={this.props.handleSetTheme} />
                                <Radio  value="orange" style={{color: '#f57c00'}} name='orange' checked={this.props.themeColor==='orange'} onChange={this.props.handleSetTheme} />
                                <Radio  value="lightBlue" style={{color: '#03a9f4'}} name='lightBlue' checked={this.props.themeColor==='lightBlue'} onChange={this.props.handleSetTheme} />
                                <Radio  value="pink" style={{color: '#e91e63'}} name='pink' checked={this.props.themeColor==='pink'} onChange={this.props.handleSetTheme} />
                                <Radio  value="black" style={{color: '#212121'}} name='black' checked={this.props.themeColor==='black'} onChange={this.props.handleSetTheme} />
                            </div>
                        </DialogContent>
                        <DialogActions>
                        {isError ? (
                            <Typography color='error'>
                                You must fill out all fields to save 
                            </Typography>
                        ) : (
                            null
                        )}
                        {isError ? (
                            <Button onClick={this.props.handleSubmit} color="primary" disabled>
                                Save and Exit
                            </Button>
                        ) : ( 
                            <Button onClick={this.props.handleSubmit} color="primary">
                                Save and Exit
                            </Button>
                        )}
                        <Button onClick={this.props.handleClose} color="primary">
                            Exit
                        </Button>
                        {isLoading ? (
                        <div className={classes.loading}>
                            <CircularProgress />
                        </div>
                        ) : ( null )
                        }
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        );}
    }

export default withStyles(styles, { withTheme: true })(Sidebar);