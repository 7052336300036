import React from 'react';

import DoorsComponent from '../components/DoorsComponent';
import GeneralDatatable from './GeneralDatatable';
import {DoorService} from '../services/FetchServices';
import Layout from '../components/Layout';

import {Dialog} from 'primereact/dialog';
import {Messages} from 'primereact/messages';
import { InputTextarea } from 'primereact/inputtextarea';
import {Button} from 'primereact/button';
import TextBox from '../components/inputComponents/textbox';
import InputSelect from '../components/inputComponents/InputSelect';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';

class BuildingsComponent extends React.Component {
    constructor(props) {        
        super(props);

        this.state={
            selectedColumns:[
                {field:'loC_NUM', header:'Number'},
                {field:'loC_NAME', header:'Name'},
                {field:'addR1', header:'Address'},
                {field:'city', header:'City'},
                {field:'state', header:'State'},
                {field:'zip', header:'Zip Code'},
                {field:'description', header:'Description'},
                {field:'grouP_NAME', header: 'Building Group'}
            ],
            columnWidthTracker:"182.875,182.875,182.875,182.875,182.875,182.875,182.875,182.875",
            isLoading: true,
            data: [],
            secRole: '',
            disabled: false,
            locatioN_ID: -1,
            addR1: '',
            addR2: '',
            addR3: '',
            city: '',
            class: '',
            description: '',
            domaiN_ID: '',
            domaiN_NAME: '',
            grouP_ID: 0,
            grouP_NAME: '',
            loC_NAME: '',
            loC_NUM: '',
            notes: '',
            Bstate: '',
            zip: '',
            hasButton: true,
            doorBtn: false
        }

        this.handleWidth = this.handleWidth.bind(this);
        this.handleSelectedColumns = this.handleSelectedColumns.bind(this);
        this.editRecord = this.editRecord.bind(this);
        this.saveBuilding = this.saveBuilding.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleDeleteClose = this.handleDeleteClose.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleNewBuilding = this.handleNewBuilding.bind(this);
        this.deleteBuilding = this.deleteBuilding.bind(this);
        this.createObject = this.createObject.bind(this);
    }

    async componentDidMount() {
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
        let parsedTableData = JSON.parse(sessionStorage.getItem('buildingsTableData'));

        if(isMobile) {
            this.setState({mobileDevice:true})
        } else {
            this.setState({mobileDevice:false})
        }

        await DoorService.fetchLocations(parsedUserData.uiU_M1ORGID, parsedUserData.uiU_ID, parsedUserData.uiU_FN + parsedUserData.uiU_LN, "", this.props.buildingID, this.props.groupID )
            .then(data => this.setState({ data: data.loclist}));

        this.setState({
            grouP_ID: this.props.groupID
        })

        if(parsedUserData.secString[3]==='2') {
            this.setState({disabled: true, hasButton: false})
        }

        if(parsedUserData.secString[28]==='3') {
            this.setState({doorBtn: true})
        }
    
        if(parsedTableData!==null) {
            if(parsedTableData.selectedColumns !== null) {
                this.setState({ 
                    selectedColumns: parsedTableData.selectedColumns
                })
            }
        }

        this.setState({ isLoading: false, secRole: parsedUserData.secString[3] })
    }

    handleWidth(width) {
        this.setState({ columnWidthTracker: width })
    }

    handleSelectedColumns(e) {
        this.setState({ selectedColumns: e })
    }

    createObject(label, value) {
        const obj = {};

        obj.label = label;
        obj.value = value;
        return obj;
    }

    async editRecord(e){
        if(this.state.secRole==='1') {
            return;
        } else {
            let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
            let tempArray=[];
            let groups = await DoorService.fetchGroups( parsedUserData.uiU_M1ORGID, 0, parsedUserData.uiU_ID, parsedUserData.uiU_FN + parsedUserData.uiU_LN, "" );
            groups = groups.grouplist;

            for(let i=0; i<groups.length; i++) {
                tempArray.push(this.createObject(groups[i].name, groups[i].grouP_ID));
            }

            this.setState({
                detailDialog: true,
                addR1: e.data.addR1,
                addR2: e.data.addR2,
                addR3: e.data.addR3,
                city: e.data.city,
                class: e.data.class,
                description: e.data.description,
                domaiN_ID: e.data.domaiN_ID,
                domaiN_NAME: e.data.domaiN_NAME,
                grouP_ID: e.data.grouP_ID,
                grouP_NAME: e.data.grouP_NAME,
                loC_NAME: e.data.loC_NAME,
                loC_NUM: e.data.loC_NUM,
                locatioN_ID: e.data.locatioN_ID,
                notes: e.data.notes,
                Bstate: e.data.state,
                zip: e.data.zip,
                groupOptions: tempArray
            })
        }
    }

    handleClose() {
        this.setState({
            detailDialog: false,
            newBuilding: false,
            addR1: '',
            addR2: '',
            addR3: '',
            city: '',
            class: '',
            description: '',
            domaiN_ID: '',
            domaiN_NAME: '',
            grouP_ID: 0,
            grouP_NAME: '',
            loC_NAME: '',
            loC_NUM: '',
            locatioN_ID: -1,
            notes: '',
            Bstate: '',
            zip: '',
            groupOptions: '',
            confirmDelete: false
        })
    }

    handleDeleteClose() {
        this.setState({confirmDelete: false})
    }

    async handleNewBuilding() {
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
        let tempArray=[];
        let groups = await DoorService.fetchGroups( parsedUserData.uiU_M1ORGID, 0, parsedUserData.uiU_ID, parsedUserData.uiU_FN + parsedUserData.uiU_LN, "" );
        groups = groups.grouplist;

        for(let i=0; i<groups.length; i++) {
            tempArray.push(this.createObject(groups[i].name, groups[i].grouP_ID));
        }

        this.setState({ 
            newBuilding: true,
            detailDialog: true,
            loC_NAME: 'New Building',
            groupOptions: tempArray
        })
    }

    async saveBuilding() {
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
        await DoorService.storeLocation(parsedUserData.uiU_ID, parsedUserData.uiU_FN + " " + parsedUserData.uiU_LN, parsedUserData.uiU_M1ORGID, this.state.locatioN_ID, 
            this.state.loC_NAME, this.state.addR1, this.state.addR2, this.state.addR3, this.state.city, this.state.Bstate, 
            this.state.zip, this.state.description, false, this.state.notes, this.state.grouP_ID, this.state.loC_NUM, "", 1, "", 
            this.state.grouP_NAME, "Global").then(data => this.showResults(data));
            await DoorService.fetchLocations(parsedUserData.uiU_M1ORGID, parsedUserData.uiU_ID, parsedUserData.uiU_FN + parsedUserData.uiU_LN, "", this.props.buildingID, this.props.groupID )
            .then(data => this.setState({ data: data.loclist}));
        this.handleClose();
    }

    async deleteBuilding() {
        if(this.state.confirmDelete) {
            let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
            await DoorService.deleteLocation(parsedUserData.uiU_ID, parsedUserData.uiU_FN + " " + parsedUserData.uiU_LN, parsedUserData.uiU_M1ORGID, this.state.locatioN_ID).then(data => this.showResults(data));
            await DoorService.fetchLocations(parsedUserData.uiU_M1ORGID, parsedUserData.uiU_ID, parsedUserData.uiU_FN + parsedUserData.uiU_LN, "", this.props.buildingID, this.props.groupID )
                .then(data => this.setState({ data: data.loclist, confirmDelete: false}));
            this.handleClose();
        } else {
            this.setState({confirmDelete: true})
        }

    }

    showResults(data) {
        this.messages.clear();
        if(data.success) {
            this.messages.show({severity: 'success', summary: data.message, life:1000});
        } else {
            this.messages.show({severity: 'error', summary: data.message, sticky: true});
        }
    }

    handleFieldChange(e) {
        if(e.target.id==='addR1') {
            this.setState({ addR1: e.target.value })
        } else if (e.target.id==='addR2') {
            this.setState({ addR2: e.target.value })
        } else if (e.target.id==='addR3') {
            this.setState({ addR3: e.target.value })
        } else if (e.target.id==='city') {
            this.setState({ city: e.target.value })
        } else if (e.target.id==='class') {
            this.setState({ class: e.target.value })
        } else if (e.target.id==='description') {
            this.setState({ description: e.target.value })
        } else if (e.target.id==='domaiN_ID') {
            this.setState({ domaiN_ID: e.target.value })
        } else if (e.target.id==='domaiN_NAME') {
            this.setState({ domaiN_NAME: e.target.value })
        } else if (e.target.id==='grouP_ID') {
            this.setState({ grouP_ID: e.target.value })
        } else if (e.target.id==='grouP_NAME') {
            this.setState({ grouP_NAME: e.target.value })
        } else if (e.target.id==='loC_NAME') {
            if(e.target.value==='') {
                this.setState({ nameError: true })
            } else if(this.state.nameError===true&&e.target.value!=='') {
                this.setState({ nameError: false })
            }
            this.setState({ loC_NAME: e.target.value })
        } else if (e.target.id==='loC_NUM') {
            this.setState({ loC_NUM: e.target.value })
        } else if (e.target.id==='locatioN_ID') {
            this.setState({ locatioN_ID: e.target.value })
        } else if (e.target.id==='notes') {
            this.setState({ notes: e.target.value })
        } else if (e.target.id==='Bstate') {
            this.setState({ Bstate: e.target.value })
        } else if (e.target.id==='zip') {
            this.setState({ zip: e.target.value })
        }
    }

    renderFooter() {
        if(this.state.newBuilding && this.state.nameError) {
            return(
                <div>
                    <div style={{textAlign:'center'}}>
                        <h4 style={{color:'red'}}>Name Cannot Be Blank</h4>
                    </div>
                    <div><Button style={{float:'right'}} type='button' label='Exit' icon='pi pi-times' onClick={this.handleClose} /></div>
                </div>
            );
        } else if(this.state.newBuilding!==true && this.state.nameError) {
            return(
                <div>
                    <div style={{textAlign:'center'}}>
                        <h4 style={{color:'red'}}>Name Cannot Be Blank</h4>
                    </div>
                    <div><Button style={{float:'right'}} type='button' label='Exit' icon='pi pi-times' onClick={this.handleClose} /></div>
                </div>
            );
        } else if(this.state.newBuilding) {
            return(
                <div>
                    {this.state.disabled ? (null) : (<Button type='button' label='Save and Exit' icon='pi pi-check' onClick={this.saveBuilding} />)}
                    <Button type='button' label='Exit' icon='pi pi-times' onClick={this.handleClose} />
                </div>
            );
        } else {
            return(
                <div>
                    {this.state.disabled ? (null) : (<Button type='button' label='Delete' icon='pi pi-trash' onClick={this.deleteBuilding} />)}
                    {this.state.disabled ? (null) : (<Button type='button' label='Save and Exit' icon='pi pi-check' onClick={this.saveBuilding} />)}
                    <Button type='button' label='Exit' icon='pi pi-times' onClick={this.handleClose} />
                </div>
            );
        }
    }

    renderDeleteFooter() {
        return(
            <div>
                <span><Button type='button' label='Delete' icon='pi pi-trash' onClick={this.deleteBuilding} /></span>
                <span><Button type='button' label='Cancel' icon='pi pi-times' onClick={this.handleDeleteClose} /></span>
            </div>
        );
    }

    render(){
        const footer=this.renderFooter();
        const deleteFooter=this.renderDeleteFooter();
        if((this.state.secRole==='' && !this.state.isLoading) || (this.state.secRole==='0' && !this.state.isLoading)) {
            return(<Layout><div>Security Role prevents you from seeing data</div></Layout>);
        } else if(this.state.data.length===0&&this.state.newBuilding!==true&&this.state.secRole==='3' && !this.state.isLoading) {
            return(
                <div>
                    {this.props.isComponent ? (
                        <div><Button style={{marginTop:'30px'}} type='button' label='New Building' icon='pi pi-plus' onClick={this.handleNewBuilding}/></div>
                    ) : (<Layout><div><Button type='button' label='New Building' icon='pi pi-plus' onClick={this.handleNewBuilding}/></div></Layout>)
                    }
                </div>
            );
        } else {
            return(
                <div>
                    {this.props.isComponent ? 
                        (
                            <div className='container-div'>
                                <Messages ref={(el) => this.messages = el} />
                                <GeneralDatatable 
                                    columnArray = {[
                                        {field:'loC_NUM', header:'Number'},
                                        {field:'loC_NAME', header:'Name'},
                                        {field:'addR1', header:'Address'},
                                        {field:'city', header:'City'},
                                        {field:'state', header:'State'},
                                        {field:'zip', header:'Zip Code'},
                                        {field:'description', header:'Description'},
                                        {field:'grouP_NAME', header: 'Building Group'}
                                    ]}

                                    csvHeader = {[
                                        {key:'loC_NUM', label:'Number'},
                                        {key:'loC_NAME', label:'Name'},
                                        {key:'addR1', label:'Address'},
                                        {key:'city', label:'City'},
                                        {key:'state', label:'State'},
                                        {key:'zip', label:'Zip Code'},
                                        {key:'description', label:'Description'},
                                        {key:'grouP_NAME', label: 'Building Group'}
                                    ]}

                                    defaultOrder={["loC_NUM", "loC_NAME", "addR1", 'city', "state", "zip", 'description', 'grouP_NAME' ]}
                                    tableRecords = {this.state.data}
                                    pageNumber={4}
                                    tableName={'Buildings'}
                                    exportFileName={'Buildings.csv'}
                                    tableData={'buildingsTableData'}
                                    filterData={'buildingsFilterData'}
                                    columnWidthTracker={this.state.columnWidthTracker}
                                    isLoading={this.state.isLoading}
                                    selectedColumns={this.state.selectedColumns}
                                    handleRowClick={this.editRecord}
                                    handleWidth={this.handleWidth}
                                    handleSelectedColumns={this.handleSelectedColumns}
                                    hasButton={this.state.hasButton}
                                    buttonLabel={'New Building'}
                                    onButtonClick={this.handleNewBuilding}
                                />
                            </div>
                        ) : 
                        (
                            <Layout>
                                <div className='container-div'>
                                    <Messages ref={(el) => this.messages = el} />
                                    <GeneralDatatable 
                                        columnArray = {[
                                            {field:'loC_NUM', header:'Number'},
                                            {field:'loC_NAME', header:'Name'},
                                            {field:'addR1', header:'Address'},
                                            {field:'city', header:'City'},
                                            {field:'state', header:'State'},
                                            {field:'zip', header:'Zip Code'},
                                            {field:'description', header:'Description'},
                                            {field:'grouP_NAME', header: 'Building Group'}
                                        ]}
            
                                        csvHeader = {[
                                            {key:'loC_NUM', label:'Number'},
                                            {key:'loC_NAME', label:'Name'},
                                            {key:'addR1', label:'Address'},
                                            {key:'city', label:'City'},
                                            {key:'state', label:'State'},
                                            {key:'zip', label:'Zip Code'},
                                            {key:'description', label:'Description'},
                                            {key:'grouP_NAME', label: 'Building Group'}
                                        ]}
            
                                        defaultOrder={["loC_NUM", "loC_NAME", "addR1", 'city', "state", "zip", 'description', 'grouP_NAME' ]}
                                        tableRecords = {this.state.data}
                                        pageNumber={4}
                                        tableName={'Buildings'}
                                        exportFileName={'Buildings.csv'}
                                        tableData={'buildingsTableData'}
                                        filterData={'buildingsFilterData'}
                                        columnWidthTracker={this.state.columnWidthTracker}
                                        isLoading={this.state.isLoading}
                                        selectedColumns={this.state.selectedColumns}
                                        handleRowClick={this.editRecord}
                                        handleWidth={this.handleWidth}
                                        handleSelectedColumns={this.handleSelectedColumns}
                                        hasButton={this.state.hasButton}
                                        buttonLabel={'New Building'}
                                        onButtonClick={this.handleNewBuilding}
                                    />
                                </div>
                            </Layout>
                        )}
                    <div>
                        <Dialog header={'Edit ' + this.state.loC_NAME} visible={this.state.detailDialog} footer={footer} onHide={this.handleClose} maximized baseZIndex={1300} blockScroll focusOnShow={false} closable={false}>
                            <div style={{ width: '99vw', maxWidth:'100%'}}>
                                <BrowserView>
                                    <div className='p-grid'>
                                        <div className='p-col-3'>
                                            <TextBox disabled={this.state.disabled} value={this.state.loC_NUM} id="loC_NUM" label={"Number: "} handleFieldChange={this.handleFieldChange} />
                                            <TextBox disabled={this.state.disabled} value={this.state.loC_NAME} id="loC_NAME" label={"Name: "} handleFieldChange={this.handleFieldChange} />
                                            <TextBox disabled={this.state.disabled} value={this.state.description} id="description" label={"Description: "} handleFieldChange={this.handleFieldChange} />
                                        </div>
                                        <div className='p-col-3'>
                                            <TextBox disabled={this.state.disabled} value={this.state.addR1} id="addR1" label={"Address Line 1: "} handleFieldChange={this.handleFieldChange} />
                                            <TextBox disabled={this.state.disabled} value={this.state.addR2} id="addR2" label={"Address Line 2: "} handleFieldChange={this.handleFieldChange} />
                                            <TextBox disabled={this.state.disabled} value={this.state.addR3} id="addR3" label={"Address Line 3: "} handleFieldChange={this.handleFieldChange} />
                                        </div>
                                        <div className='p-col-3'>
                                            <TextBox disabled={this.state.disabled} value={this.state.city} id="city" label={"City: "} handleFieldChange={this.handleFieldChange} />
                                            <TextBox disabled={this.state.disabled} value={this.state.Bstate} id="Bstate" label={"State: "} handleFieldChange={this.handleFieldChange} />
                                            <TextBox disabled={this.state.disabled} value={this.state.zip} id="zip" label={"ZipCode: "} handleFieldChange={this.handleFieldChange} />
                                            
                                            <InputSelect disabled={this.state.disabled} value={this.state.grouP_ID} 
                                            id="grouP_ID" label={"Building Group: "} options={this.state.groupOptions} 
                                            handleFieldChange={this.handleFieldChange} />
                                
                                        </div>
                                        <div className='p-col-3'>
                                            <div><label htmlFor="notes"><strong>Notes: </strong></label></div>
                                            <InputTextarea disabled={this.state.disabled} rows={3} style={{width:'225px', height:'170px'}} value={this.state.notes} onChange={(e) => this.handleFieldChange(e)} type='text' id="notes" />
                                        </div>
                                    </div>
                                </BrowserView>

                                <MobileView>
                                    <div className='p-grid'>
                                        <TextBox disabled={this.state.disabled} mobile={true} value={this.state.loC_NUM} id="loC_NUM" label={"Number: "} handleFieldChange={this.handleFieldChange} />
                                        <TextBox disabled={this.state.disabled} mobile={true} value={this.state.loC_NAME} id="loC_NAME" label={"Name: "} handleFieldChange={this.handleFieldChange} />
                                        <TextBox disabled={this.state.disabled} mobile={true} value={this.state.description} id="description" label={"Description: "} handleFieldChange={this.handleFieldChange} />
                                        <TextBox disabled={this.state.disabled} mobile={true} value={this.state.addR1} id="addR1" label={"Address Line 1: "} handleFieldChange={this.handleFieldChange} />
                                        <TextBox disabled={this.state.disabled} mobile={true} value={this.state.addR2} id="addR2" label={"Address Line 2: "} handleFieldChange={this.handleFieldChange} />
                                        <TextBox disabled={this.state.disabled} mobile={true} value={this.state.addR3} id="addR3" label={"Address Line 3: "} handleFieldChange={this.handleFieldChange} />
                                        <TextBox disabled={this.state.disabled} mobile={true} value={this.state.city} id="city" label={"City: "} handleFieldChange={this.handleFieldChange} />
                                        <TextBox disabled={this.state.disabled} mobile={true} value={this.state.Bstate} id="Bstate" label={"State: "} handleFieldChange={this.handleFieldChange} />
                                        <TextBox disabled={this.state.disabled} mobile={true} value={this.state.zip} id="zip" label={"ZipCode: "} handleFieldChange={this.handleFieldChange} />
                                        <InputSelect disabled={this.state.disabled} value={this.state.grouP_ID} 
                                            id="grouP_ID" label={"Building Group: "} options={this.state.groupOptions} 
                                            handleFieldChange={this.handleFieldChange} mobile={true} 
                                        />
                                        <TextBox disabled={this.state.disabled} mobile area rows={5} value={this.state.notes} id="notes" label={"Notes: "} handleFieldChange={this.handleFieldChange} />
                                    </div>
                                </MobileView>

                                {this.state.newBuilding ? (null) : (
                                    <div>
                                        <DoorsComponent
                                            buildingID={this.state.locatioN_ID}
                                            // isLoading={this.state.isLoading}
                                            isComponent={true}
                                            coreMarkID={0}
                                            keymark={""}
                                            keyway={""}
                                            system={""}
                                            hasButton={this.state.doorBtn}
                                            header={'Doors'}
                                        />
                                    </div>
                                )}

                            </div>
                        </Dialog>
                        <Dialog header={'Delete ' + this.state.loC_NAME} visible={this.state.confirmDelete} footer={deleteFooter} onHide={this.handleDeleteClose} baseZIndex={1300} blockScroll focusOnShow={false} closable={false}>
                            <h4>Are you sure you would like to delete '{this.state.loC_NAME}'?</h4>
                        </Dialog>
                    </div>
                </div>
            );
        }
    }
}

export default BuildingsComponent;