import React from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';

class TextBox extends React.Component {
    render() {
        if(this.props.mobile && this.props.area) {
            return(
                <div style={{margin:'10px'}} className="p-col-12">
                    <div><strong>{this.props.label}</strong></div>
                    <div style={{width:'100%', margin:'auto'}}><InputTextarea placeholder={this.props.placeholderText} rows={this.props.rows} className={this.props.className} disabled={this.props.disabled} value={this.props.value} onChange={(e) => this.props.handleFieldChange(e)} type='text' id={this.props.id} /></div>
                </div>
            );
        }else if(this.props.mobile) {
            return(
                <div style={{margin:'10px'}} className="p-col-12">
                    <div><strong>{this.props.label}</strong></div>
                    <div style={{width:'100%', margin:'auto'}}>
                        <InputText className={this.props.className} disabled={this.props.disabled} value={this.props.value} 
                        onChange={(e) => this.props.handleFieldChange(e)} type='text' id={this.props.id} placeholder={this.props.placeholderText} />
                        </div>
                </div>
            );
        } else if(window.screen.width>1150 && window.screen.width<1441 && this.props.area) {
            return(
                <div className='p-grid' style={{width:'335px', margin:'auto'}}>
                    <label className="p-col" style={{textAlign:'right', display:'block'}} htmlFor={this.props.id}><strong>{this.props.label}</strong></label>
                    <div className='p-col-fixed' ><InputTextarea placeholder={this.props.placeholderText} rows={this.props.rows} className={this.props.className} disabled={this.props.disabled} style={{width:'206px'}} value={this.props.value} onChange={(e) => this.props.handleFieldChange(e)} type='text' id={this.props.id} /></div>
                </div>
            );
        } else if(window.screen.width>1150 && window.screen.width<1441) {
            return(
                <div className='p-grid' style={{width:'335px', margin:'auto'}}>
                    <label className="p-col" style={{textAlign:'right', display:'block'}} htmlFor={this.props.id}><strong>{this.props.label}</strong></label>
                    <div className='p-col-fixed' ><InputText placeholder={this.props.placeholderText} className={this.props.className} disabled={this.props.disabled} style={{width:'206px'}} value={this.props.value} onChange={(e) => this.props.handleFieldChange(e)} type='text' id={this.props.id} /></div>
                </div>
            );
        } else if(this.props.threeCol && this.props.area) {
            return(
                <div className='p-grid' style={{width:'450px', margin:'auto'}}>
                    <label className="p-col" style={{textAlign:'right', display:'block'}} htmlFor={this.props.id}><strong>{this.props.label}</strong></label>
                    <div className='p-col-fixed' ><InputTextarea placeholder={this.props.placeholderText} rows={this.props.rows} className={this.props.className} disabled={this.props.disabled} style={{width:'250px'}} value={this.props.value} onChange={(e) => this.props.handleFieldChange(e)} type='text' id={this.props.id} /></div>
                </div>
            );
        } else if(this.props.threeCol) {
            return(
                <div className='p-grid' style={{width:'450px', margin:'auto'}}>
                    <label className="p-col" style={{textAlign:'right', display:'block'}} htmlFor={this.props.id}><strong>{this.props.label}</strong></label>
                    <div className='p-col-fixed' ><InputText placeholder={this.props.placeholderText} className={this.props.className} disabled={this.props.disabled} style={{width:'250px'}} value={this.props.value} onChange={(e) => this.props.handleFieldChange(e)} type='text' id={this.props.id} /></div>
                </div>
            );
        } else if(this.props.area) {
            return (
                <div className='p-grid' style={{width:'450px', margin:'auto'}}>
                    <label className="p-col" style={{textAlign:'right', display:'block'}} htmlFor={this.props.id}><strong>{this.props.label}</strong></label>
                    <div className='p-col-fixed' ><InputTextarea placeholder={this.props.placeholderText} rows={this.props.rows} className={this.props.className} disabled={this.props.disabled} style={{width:'275px'}} value={this.props.value} onChange={(e) => this.props.handleFieldChange(e)} type='text' id={this.props.id} /></div>
                </div>
            );
        } else {
            return (
                <div className='p-grid' style={{width:'450px', margin:'auto'}}>
                    <label className="p-col" style={{textAlign:'right', display:'block'}} htmlFor={this.props.id}><strong>{this.props.label}</strong></label>
                    <div className='p-col-fixed' ><InputText placeholder={this.props.placeholderText} className={this.props.className} disabled={this.props.disabled} style={{width:'275px'}} value={this.props.value} onChange={(e) => this.props.handleFieldChange(e)} type='text' id={this.props.id} /></div>
                </div>
            );
        }
    }
}

export default TextBox;