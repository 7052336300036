import React from 'react';

import {Steps} from 'primereact/steps';
import {Button} from 'primereact/button';
import { Card } from 'primereact/card';
import { BlobService } from '../services/BlobServices';
import { BrowserView, MobileView } from 'react-device-detect';

import avatarPng from '../images/avatarPng.png';
import { serverAddr } from '../services/serverAddr';
import { PeopleService } from '../services/FetchServices';
import { KeyService } from '../services/FetchServices';
import KeyHandInOutPeopleDatatable from '../components/KeyHandInOutPeopleDatatable';
import KeyHandInOutKeysDatatable from '../components/KeyHandInOutKeysDatatable';
import KeyHandOutComponent from '../components/KeyHandOutComponent';
import KeyHandOutSigConfirm from '../components/KeyHandOutSigConfirm';
import KeyHandOutReceipt from '../components/KeyHandOutReceipt';

class KeyHandOutPage extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            activeIndex: 0,            
            peopleData: [],     
            keyData:[], 
            currUserFN: null,
            currUserLN: null,
            currUserID: null,
            selectedKey: null,      
            globalFilter: null,
            isLoading: true,
            secRole: '',
            disabled: false,
            fields: {},
            receiptReady: false,
            finalImg: avatarPng
        };

        this.items = [
            {label: 'Employee'},
            {label: 'Key'},
            {label: 'Return Date'},
            {label: 'Confirm'}
        ];

        this.setActiveIndex = this.setActiveIndex.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.downloadBlob = this.downloadBlob.bind(this);
    }

    async componentDidMount() {
        let x = JSON.parse(sessionStorage.getItem("peopleTableData"));        
        if(x !== null) {
            if(x.selectedColumns !== null) {
                this.setState({ selectedColumns: x.selectedColumns })
            }
        }
        
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
        let name=parsedUserData.uiU_FN + ' ' + parsedUserData.uiU_LN;
        this.setState({issuerName: name})

        if(parsedUserData.secString[13]==='2') {
            this.setState({ disabled: true })
        }
        

        await PeopleService.fetchUsers( parsedUserData.uiU_M1ORGID, "", 0 )
            .then(data => 
                this.setState({ 
                    peopleData: data.user, 
                    isLoading: false, 
                    orgID: (parsedUserData.uiU_M1ORGID.toLowerCase()),
                    selfID: parsedUserData.uiU_ID,
                    secRole: parsedUserData.secString[13],
                    keySecRole: parsedUserData.secString[9],
                    activeIndex: 0
                })                
        );
        
    }

    async downloadBlob(userID) {
        const containerName = serverAddr.blobContainer + "-user-pic";
        const blobName = `${this.state.orgID}${userID}`
        try{
            const downloaded = await BlobService.downloadImageBlob(containerName, blobName);
            if(downloaded!==false) {
                this.setState({finalImg: downloaded})
            } else {
                this.setState({finalImg: avatarPng})
            }
        } catch(error) {
            this.setState({finalImg: avatarPng})
        }
    }

    setActiveIndex(e) {        
        switch(e.index){
            case 1:
                if(this.state.currUserFN){
                    this.setState({activeIndex: e.index})
                }
                break;
            case 2:
                if(this.state.selectedKey){
                    this.setState({activeIndex: e.index})
                } else if (this.state.currUserFN) {
                    this.setState({activeIndex: 1})
                } else {
                    this.setState({activeIndex: 0})
                }
                break;
            case 3:
                break;
            default :
                this.setState({activeIndex: e.index})
                break
        }       
    }

    handleCancel() {
        this.setState({activeIndex: 0,
                       currUserFN: null,
                       currUserLN: null,
                       selectedKey: null,
                       finalImg: avatarPng
                    });        
    }

    handleNextStep = async (inbound, idx) => {       
    //    console.log(idx, inbound)             
       
       if (idx === 1){
           this.setState(({isLoading: true}))
            let currUserFN = inbound.curr.data.uiU_FN;
            let currUserLN = inbound.curr.data.uiU_LN;
            let currUserID = inbound.curr.data.uiU_ID;
            await KeyService.fetchUnissuedKeys(this.state.orgID)
                .then(results => this.setState({ keyData: results.keylist,
                                                 activeIndex: idx,
                                                 currUserFN: currUserFN,
                                                 currUserLN: currUserLN,
                                                 currUserID: currUserID,
                                                 isLoading: false
                                                }));   
            this.downloadBlob(currUserID)
       } else if (idx === 2) {
        //    console.log('noah', inbound.curr.data)
           let selectedKey = inbound.curr.data.uiY_FULLCODE;
           let selectedKeyID = inbound.curr.data.uiY_ID;
           let systemName = inbound.curr.data.uiY_SYSTEM;
           let uiY_ID = inbound.curr.data.uiY_ID;

           this.setState({
               activeIndex: idx,
               selectedKey: selectedKey,
               selectedKeyID: selectedKeyID,
               systemName: systemName,
               uiY_ID: uiY_ID
           });
       } else if (idx === 3){
            this.setState({
                activeIndex: idx          
            });
       } 
                  
    }

    handleChange = updatedValue => {        
        this.setState({ 
            fields: {                
                ...updatedValue
            }
        });
        
    }

    submitConfirmation = () => {
        // console.log(this.state, this.props);
        this.setState({activeIndex: 4});
    }

/*=================================================================================*/
    render(){
        const { activeIndex } = this.state;
        if(this.state.secRole!=='3') {
            return(
                <div>
                    Your Security Role Prevents You From Handing Out / Handing In Keys
                </div>
            );
        } else {
            return(
                <div>
                    <BrowserView>
                        <div className="p-d-flex"> 
                            <Card title="Key Hand Out" style={{width:'40%'}}>                       
                                <Steps  model={this.items} 
                                        activeIndex={this.state.activeIndex} 
                                        onSelect={(e) => this.setActiveIndex(e)} 
                                        readOnly={this.state.activeIndex < 4 ? false : true}                                        
                                />
                                {this.state.activeIndex < 4 &&
                                    <div className="p-mt-4">
                                        <Button icon='pi pi-times' 
                                                label='Cancel' 
                                                onClick={this.handleCancel} />
                                    </div>                        
                                }
                            </Card>
                            <div className="p-p-6 p-mt-4">
                                {activeIndex === 0 ?
                                    <h4>Please select the employee from the table below</h4>
                                : activeIndex === 1 ?
                                    <div>
                                        <span style={{float: 'left', marginRight:'30px'}}><h4>Please choose the appropriate key to be assigned to {this.state.currUserFN}</h4></span>
                                        <span style={{float: 'right'}}><img alt='No User Pic' src={this.state.finalImg} style={{ maxWidth:'250px', maxHeight:'250px' }}/></span>
                                    </div>
                                : activeIndex === 2 ?
                                    <div>
                                        <span style={{float: 'left', marginRight:'30px'}}><h4>Please provide the date {this.state.currUserFN} needs to return key {this.state.selectedKey}</h4></span>
                                        <span style={{float: 'right'}}><img alt='No User Pic' src={this.state.finalImg} style={{ maxWidth:'250px', maxHeight:'250px' }}/></span>
                                    </div>
                                : activeIndex === 3 ?
                                    <div>
                                        <span style={{float: 'left', marginRight:'30px'}}><h4>Have {this.state.currUserFN} sign to confirm hand out or Cancel to quit</h4></span>
                                        <span style={{float: 'right'}}><img alt='No User Pic' src={this.state.finalImg} style={{ maxWidth:'250px', maxHeight:'250px' }}/></span>
                                    </div>
                                :
                                    <div>
                                        <span style={{float: 'left', marginRight:'30px'}}><h4>Thank you. The hand out is complete</h4></span>
                                        <span style={{float: 'right'}}><img alt='No User Pic' src={this.state.finalImg} style={{ maxWidth:'250px', maxHeight:'250px' }}/></span>
                                    </div>
                                }                            
                                <i className="pi pi-angle-double-down p-mr-2"></i>
                            </div>                                                                         
                        </div>                
                        <div className='p-shadow-2' style={{marginTop:5}}>
                            { activeIndex === 0 ?                         
                                <KeyHandInOutPeopleDatatable
                                        peopleData={this.state.peopleData}
                                        pageNumber={32}
                                        tableName={'People'}
                                        exportFileName={'People.csv'}
                                        tableData={'peopleTableData'}
                                        filterData={'peopleFilterData'}
                                        isLoading={this.state.isLoading}
                                        nextStep={(curr) => this.handleNextStep({curr}, 1)}                                
                                        buttonOnClick={() => this.handleSelect('New')}
                                        keyStatus={'issued'}
                                    />                              
                            : activeIndex === 1 ?                        
                                <KeyHandInOutKeysDatatable
                                        keyData={this.state.keyData}
                                        pageNumber={32}
                                        tableName={'Keys'}
                                        exportFileName={'Keys.csv'}
                                        tableData={'keysTableData'}
                                        filterData={'keysFilterData'}
                                        isLoading={this.state.isLoading}
                                        nextStep={(curr) => this.handleNextStep({curr}, 2)}                                
                                        buttonOnClick={() => this.handleSelect('New')}
                                    />                           
                            : activeIndex === 2 ?
                                <div><KeyHandOutComponent 
                                        handleNextStep={this.handleNextStep}
                                        handleChange={this.handleChange}
                                        /></div>
                            : activeIndex === 3 ?
                                <div><KeyHandOutSigConfirm {...this.state} 
                                        handleChange={this.handleChange}
                                        submitConfirmation={this.submitConfirmation} />
                                </div>
                            :
                                <div>
                                    <KeyHandOutReceipt {...this.state} handleRefresh={this.props.handleRefresh} finalImg={this.state.finalImg}/>                            
                                </div>                    
                            }
                        </div>
                    </BrowserView>
                    <MobileView>
                        <div>
                            <Card title="Key Hand Out">                       
                                <Steps  model={this.items} 
                                        activeIndex={this.state.activeIndex} 
                                        onSelect={(e) => this.setActiveIndex(e)} 
                                        readOnly={this.state.activeIndex < 4 ? false : true}                                        
                                />
                                {this.state.activeIndex < 4 &&
                                    <div className="p-mt-4">
                                        <Button icon='pi pi-times' 
                                                label='Cancel' 
                                                onClick={this.handleCancel} />
                                    </div>                        
                                }
                            </Card>
                            <div>
                                {activeIndex === 0 ?
                                    <h4>Please select the employee from the table below</h4>
                                : activeIndex === 1 ?
                                    <div>
                                        <div><h4>Please choose the appropriate key to be assigned to {this.state.currUserFN}</h4></div>
                                        <div><img alt='No User Pic' src={this.state.finalImg} style={{ maxWidth:'125px', maxHeight:'125px' }}/></div>
                                    </div>
                                : activeIndex === 2 ?
                                    <div>
                                        <div><h4>Please provide the date {this.state.currUserFN} needs to return key {this.state.selectedKey}</h4></div>
                                        <div><img alt='No User Pic' src={this.state.finalImg} style={{ maxWidth:'125px', maxHeight:'125px' }}/></div>
                                    </div>
                                : activeIndex === 3 ?
                                    <div>
                                        <div><h4>Have {this.state.currUserFN} sign to confirm hand out or Cancel to quit</h4></div>
                                        <div><img alt='No User Pic' src={this.state.finalImg} style={{ maxWidth:'125px', maxHeight:'125px' }}/></div>
                                    </div>
                                :
                                    <div>
                                        <div><h4>Thank you. The hand out is complete</h4></div>
                                        <div><img alt='No User Pic' src={this.state.finalImg} style={{ maxWidth:'125px', maxHeight:'125px' }}/></div>
                                    </div>
                                }                            
                                <i className="pi pi-angle-double-down p-mr-2"></i>
                            </div>                                                                         
                            <div className='p-shadow-2' style={{marginTop:5}}>
                                { activeIndex === 0 ?                         
                                    <KeyHandInOutPeopleDatatable
                                            peopleData={this.state.peopleData}
                                            pageNumber={32}
                                            tableName={'People'}
                                            exportFileName={'People.csv'}
                                            tableData={'peopleTableData'}
                                            filterData={'peopleFilterData'}
                                            isLoading={this.state.isLoading}
                                            nextStep={(curr) => this.handleNextStep({curr}, 1)}                                
                                            buttonOnClick={() => this.handleSelect('New')}
                                            keyStatus={'issued'}
                                        />                              
                                : activeIndex === 1 ?                        
                                    <KeyHandInOutKeysDatatable
                                            keyData={this.state.keyData}
                                            pageNumber={32}
                                            tableName={'Keys'}
                                            exportFileName={'Keys.csv'}
                                            tableData={'keysTableData'}
                                            filterData={'keysFilterData'}
                                            isLoading={this.state.isLoading}
                                            nextStep={(curr) => this.handleNextStep({curr}, 2)}                                
                                            buttonOnClick={() => this.handleSelect('New')}
                                        />                           
                                : activeIndex === 2 ?
                                    <div><KeyHandOutComponent 
                                            handleNextStep={this.handleNextStep}
                                            handleChange={this.handleChange}
                                            /></div>
                                : activeIndex === 3 ?
                                    <div><KeyHandOutSigConfirm {...this.state} 
                                            handleChange={this.handleChange}
                                            submitConfirmation={this.submitConfirmation} />
                                    </div>
                                :
                                    <div>
                                        <KeyHandOutReceipt {...this.state} handleRefresh={this.props.handleRefresh} finalImg={this.state.finalImg}/>                            
                                    </div>                    
                                }
                            </div>
                        </div>
                    </MobileView>
                </div>
            );
        }
    }
}

export default KeyHandOutPage;