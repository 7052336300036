import React from 'react';

import { RadioButton } from 'primereact/radiobutton';
import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';

//Page 3 for API, page 2 for internal routing
class RolesEdit extends React.Component {
    constructor(props) {
        super(props);

        this.state={

        }
    }

    componentDidMount() {

    }

    render() {
        return (
            <div className="p-grid">

                <div className='p-col-12 p-grid'><label className='p-col-4'><strong>Name</strong></label><InputText className='p-col-8' id='dname' value={this.props.detailName} type="text" onChange={(e) => this.props.handleNameChange(e)} disabled={this.props.disabled} /></div>
                <div className='p-col-12 p-grid'><label className='p-col-4'><strong>Notes</strong></label><InputTextarea className='p-inputtextarea p-col-8' id='dNotes' value={this.props.detailNotes} onChange={(e) => this.props.handleNotesChange(e)} disabled={this.props.disabled} type="text" rows={5}/></div>

                <label className="p-col-12 p-md-4"><strong>View Pinning</strong></label>
                <div className=' p-col-12 p-md-8 p-grid'>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} className='radio' value="0" id={0} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[0]==='0'}/>
                        <label className="p-radiobutton-label">None</label>
                    </div>
                    <div className="p-col-3" />
                    <div className="p-col-3" />
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="3" id={0} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[0]==='3'}/>
                        <label className="p-radiobutton-label">Full</label>
                    </div>
                </div>

                <label className="p-col-12 p-md-4"><strong>View Keys</strong></label>
                <div className=' p-col-12 p-md-8 p-grid'>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="0" id={1} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[1]==='0'}/>
                        <label className="p-radiobutton-label">None</label>
                    </div>
                    <div className="p-col-3" />
                    <div className="p-col-3" />
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="3" id={1} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[1]==='3'}/>
                        <label className="p-radiobutton-label">Full</label>
                    </div>
                </div>

                <label className="p-col-12 p-md-4"><strong>Building Groups</strong></label>
                <div className=' p-col-12 p-md-8 p-grid'>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="0" id={2} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[2]==='0'}/>
                        <label className="p-radiobutton-label">None</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="1" id={2} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[2]==='1'}/>
                        <label className="p-radiobutton-label">List</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="2" id={2} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[2]==='2'}/>
                        <label className="p-radiobutton-label">View</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="3" id={2} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[2]==='3'}/>
                        <label className="p-radiobutton-label">Full</label>
                    </div>
                </div>

                <label className="p-col-12 p-md-4"><strong>Buildings</strong></label>
                <div className=' p-col-12 p-md-8 p-grid'>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="0" id={3} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[3]==='0'}/>
                        <label className="p-radiobutton-label">None</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="1" id={3} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[3]==='1'}/>
                        <label className="p-radiobutton-label">List</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="2" id={3} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[3]==='2'}/>
                        <label className="p-radiobutton-label">View</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="3" id={3} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[3]==='3'}/>
                        <label className="p-radiobutton-label">Full</label>
                    </div>
                </div>

                <label className="p-col-12 p-md-4"><strong>Departments</strong></label>
                <div className=' p-col-12 p-md-8 p-grid'>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="0" id={4} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[4]==='0'}/>
                        <label className="p-radiobutton-label">None</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="1" id={4} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[4]==='1'}/>
                        <label className="p-radiobutton-label">List</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="2" id={4} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[4]==='2'}/>
                        <label className="p-radiobutton-label">View</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="3" id={4} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[4]==='3'}/>
                        <label className="p-radiobutton-label">Full</label>
                    </div>
                </div>

                <label className="p-col-12 p-md-4"><strong>Key Storage</strong></label>
                <div className=' p-col-12 p-md-8 p-grid'>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="0" id={5} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[5]==='0'}/>
                        <label className="p-radiobutton-label">None</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="1" id={5} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[5]==='1'}/>
                        <label className="p-radiobutton-label">List</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="2" id={5} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[5]==='2'}/>
                        <label className="p-radiobutton-label">View</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="3" id={5} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[5]==='3'}/>
                        <label className="p-radiobutton-label">Full</label>
                    </div>
                </div>
                
                <label className="p-col-12 p-md-4"><strong>Credential Types</strong></label>
                <div className=' p-col-12 p-md-8 p-grid'>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="0" id={6} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[6]==='0'}/>
                        <label className="p-radiobutton-label">None</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="1" id={6} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[6]==='1'}/>
                        <label className="p-radiobutton-label">List</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="2" id={6} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[6]==='2'}/>
                        <label className="p-radiobutton-label">View</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="3" id={6} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[6]==='3'}/>
                        <label className="p-radiobutton-label">Full</label>
                    </div>
                </div>

                <label className="p-col-12 p-md-4"><strong>Credentials</strong></label>
                <div className=' p-col-12 p-md-8 p-grid'>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="0" id={7} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[7]==='0'}/>
                        <label className="p-radiobutton-label">None</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="1" id={7} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[7]==='1'}/>
                        <label className="p-radiobutton-label">List</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="2" id={7} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[7]==='2'}/>
                        <label className="p-radiobutton-label">View</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="3" id={7} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[7]==='3'}/>
                        <label className="p-radiobutton-label">Full</label>
                    </div>
                </div>

                <label className="p-col-12 p-md-4"><strong>Records</strong></label>
                <div className=' p-col-12 p-md-8 p-grid'>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="0" id={8} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[8]==='0'}/>
                        <label className="p-radiobutton-label">None</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="1" id={8} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[8]==='1'}/>
                        <label className="p-radiobutton-label">List</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="2" id={8} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[8]==='2'}/>
                        <label className="p-radiobutton-label">View</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="3" id={8} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[8]==='3'}/>
                        <label className="p-radiobutton-label">Full</label>
                    </div>
                </div>
                
                <label className="p-col-12 p-md-4"><strong>Keys</strong></label>
                <div className=' p-col-12 p-md-8 p-grid'>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="0" id={9} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[9]==='0'}/>
                        <label className="p-radiobutton-label">None</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="1" id={9} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[9]==='1'}/>
                        <label className="p-radiobutton-label">List</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="2" id={9} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[9]==='2'}/>
                        <label className="p-radiobutton-label">View</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="3" id={9} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[9]==='3'}/>
                        <label className="p-radiobutton-label">Full</label>
                    </div>
                </div>

                <label className="p-col-12 p-md-4"><strong>Cores</strong></label>
                <div className=' p-col-12 p-md-8 p-grid'>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="0" id={10} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[10]==='0'}/>
                        <label className="p-radiobutton-label">None</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="1" id={10} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[10]==='1'}/>
                        <label className="p-radiobutton-label">List</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="2" id={10} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[10]==='2'}/>
                        <label className="p-radiobutton-label">View</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="3" id={10} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[10]==='3'}/>
                        <label className="p-radiobutton-label">Full</label>
                    </div>
                </div>

                <label className="p-col-12 p-md-4"><strong>Work Orders</strong></label>
                <div className=' p-col-12 p-md-8 p-grid'>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="0" id={11} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[11]==='0'}/>
                        <label className="p-radiobutton-label">None</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="1" id={11} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[11]==='1'}/>
                        <label className="p-radiobutton-label">List</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="2" id={11} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[11]==='2'}/>
                        <label className="p-radiobutton-label">View</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="3" id={11} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[11]==='3'}/>
                        <label className="p-radiobutton-label">Full</label>
                    </div>
                </div>
                
                <label className="p-col-12 p-md-4"><strong>Customization</strong></label>
                <div className=' p-col-12 p-md-8 p-grid'>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="0" id={12} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[12]==='0'}/>
                        <label className="p-radiobutton-label">None</label>
                    </div>
                    <div className="p-col-3" />
                    <div className="p-col-3" />
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="3" id={12} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[12]==='3'}/>
                        <label className="p-radiobutton-label">Full</label>
                    </div>
                </div>

                <label className="p-col-12 p-md-4"><strong>People</strong></label>
                <div className=' p-col-12 p-md-8 p-grid'>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="0" id={13} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[13]==='0'}/>
                        <label className="p-radiobutton-label">None</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="1" id={13} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[13]==='1'}/>
                        <label className="p-radiobutton-label">List</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="2" id={13} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[13]==='2'}/>
                        <label className="p-radiobutton-label">View</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="3" id={13} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[13]==='3'}/>
                        <label className="p-radiobutton-label">Full</label>
                    </div>
                </div>

                <label className="p-col-12 p-md-4"><strong>Security Roles</strong></label>
                <div className=' p-col-12 p-md-8 p-grid'>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="0" id={14} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[14]==='0'}/>
                        <label className="p-radiobutton-label">None</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="1" id={14} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[14]==='1'}/>
                        <label className="p-radiobutton-label">List</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="2" id={14} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[14]==='2'}/>
                        <label className="p-radiobutton-label">View</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="3" id={14} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[14]==='3'}/>
                        <label className="p-radiobutton-label">Full</label>
                    </div>
                </div>
                
                <label className="p-col-12 p-md-4"><strong>Contractors</strong></label>
                <div className=' p-col-12 p-md-8 p-grid'>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="0" id={15} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[15]==='0'}/>
                        <label className="p-radiobutton-label">None</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="1" id={15} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[15]==='1'}/>
                        <label className="p-radiobutton-label">List</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="2" id={15} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[15]==='2'}/>
                        <label className="p-radiobutton-label">View</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="3" id={15} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[15]==='3'}/>
                        <label className="p-radiobutton-label">Full</label>
                    </div>
                </div>

                <label className="p-col-12 p-md-4"><strong>Hand In/Out Keys</strong></label>
                <div className=' p-col-12 p-md-8 p-grid'>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="0" id={16} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[16]==='0'}/>
                        <label className="p-radiobutton-label">None</label>
                    </div>
                    <div className="p-col-3" />
                    <div className="p-col-3" />
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="3" id={16} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[16]==='3'}/>
                        <label className="p-radiobutton-label">Full</label>
                    </div>
                </div>

                <label className="p-col-12 p-md-4"><strong>Master Systems</strong></label>
                <div className=' p-col-12 p-md-8 p-grid'>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="0" id={17} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[17]==='0'}/>
                        <label className="p-radiobutton-label">None</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="1" id={17} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[17]==='1'}/>
                        <label className="p-radiobutton-label">List</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="2" id={17} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[17]==='2'}/>
                        <label className="p-radiobutton-label">View</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="3" id={17} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[17]==='3'}/>
                        <label className="p-radiobutton-label">Full</label>
                    </div>
                </div>
                
                <label className="p-col-12 p-md-4"><strong>Hand In/Out Credentials</strong></label>
                <div className=' p-col-12 p-md-8 p-grid'>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="0" id={18} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[18]==='0'}/>
                        <label className="p-radiobutton-label">None</label>
                    </div>
                    <div className="p-col-3" />
                    <div className="p-col-3" />
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="3" id={18} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[18]==='3'}/>
                        <label className="p-radiobutton-label">Full</label>
                    </div>
                </div>

                <label className="p-col-12 p-md-4"><strong>Key Reports</strong></label>
                <div className=' p-col-12 p-md-8 p-grid'>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="0" id={19} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[19]==='0'}/>
                        <label className="p-radiobutton-label">None</label>
                    </div>
                    <div className="p-col-3" />
                    <div className="p-col-3" />
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="3" id={19} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[19]==='3'}/>
                        <label className="p-radiobutton-label">Full</label>
                    </div>
                </div>

                <label className="p-col-12 p-md-4"><strong>Core Reports</strong></label>
                <div className=' p-col-12 p-md-8 p-grid'>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="0" id={20} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[20]==='0'}/>
                        <label className="p-radiobutton-label">None</label>
                    </div>
                    <div className="p-col-3" />
                    <div className="p-col-3" />
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="3" id={20} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[20]==='3'}/>
                        <label className="p-radiobutton-label">Full</label>
                    </div>
                </div>
                                
                <label className="p-col-12 p-md-4"><strong>Door Reports</strong></label>
                <div className=' p-col-12 p-md-8 p-grid'>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="0" id={21} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[21]==='0'}/>
                        <label className="p-radiobutton-label">None</label>
                    </div>
                    <div className="p-col-3" />
                    <div className="p-col-3" />
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="3" id={21} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[21]==='3'}/>
                        <label className="p-radiobutton-label">Full</label>
                    </div>
                </div>

                <label className="p-col-12 p-md-4"><strong>People Reports</strong></label>
                <div className=' p-col-12 p-md-8 p-grid'>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="0" id={22} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[22]==='0'}/>
                        <label className="p-radiobutton-label">None</label>
                    </div>
                    <div className="p-col-3" />
                    <div className="p-col-3" />
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="3" id={22} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[22]==='3'}/>
                        <label className="p-radiobutton-label">Full</label>
                    </div>
                </div>

                <label className="p-col-12 p-md-4"><strong>Destroy Key</strong>s</label>
                <div className=' p-col-12 p-md-8 p-grid'>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="0" id={23} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[23]==='0'}/>
                        <label className="p-radiobutton-label">None</label>
                    </div>
                    <div className="p-col-3" />
                    <div className="p-col-3" />
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="3" id={23} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[23]==='3'}/>
                        <label className="p-radiobutton-label">Full</label>
                    </div>
                </div>
                            
                <label className="p-col-12 p-md-4"><strong>Delete Buildings</strong></label>
                <div className=' p-col-12 p-md-8 p-grid'>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="0" id={24} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[24]==='0'}/>
                        <label className="p-radiobutton-label">None</label>
                    </div>
                    <div className="p-col-3" />
                    <div className="p-col-3" />
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="3" id={24} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[24]==='3'}/>
                        <label className="p-radiobutton-label">Full</label>
                    </div>
                </div>

                <label className="p-col-12 p-md-4"><strong>Report Keys Lost or Broken</strong></label>
                <div className=' p-col-12 p-md-8 p-grid'>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="0" id={25} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[25]==='0'}/>
                        <label className="p-radiobutton-label">None</label>
                    </div>
                    <div className="p-col-3" />
                    <div className="p-col-3" />
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="3" id={25} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[25]==='3'}/>
                        <label className="p-radiobutton-label">Full</label>
                    </div>
                </div>

                <label className="p-col-12 p-md-4"><strong>Key Rings</strong></label>
                <div className=' p-col-12 p-md-8 p-grid'>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="0" id={26} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[26]==='0'}/>
                        <label className="p-radiobutton-label">None</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="1" id={26} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[26]==='1'}/>
                        <label className="p-radiobutton-label">List</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="2" id={26} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[26]==='2'}/>
                        <label className="p-radiobutton-label">View</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="3" id={26} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[26]==='3'}/>
                        <label className="p-radiobutton-label">Full</label>
                    </div>
                </div>

                <label className="p-col-12 p-md-4"><strong>Devices</strong></label>
                <div className=' p-col-12 p-md-8 p-grid'>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="0" id={27} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[27]==='0'}/>
                        <label className="p-radiobutton-label">None</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="1" id={27} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[27]==='1'}/>
                        <label className="p-radiobutton-label">List</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="2" id={27} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[27]==='2'}/>
                        <label className="p-radiobutton-label">View</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="3" id={27} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[27]==='3'}/>
                        <label className="p-radiobutton-label">Full</label>
                    </div>
                </div>

                <label className="p-col-12 p-md-4"><strong>Doors</strong></label>
                <div className=' p-col-12 p-md-8 p-grid'>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="0" id={28} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[28]==='0'}/>
                        <label className="p-radiobutton-label">None</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="1" id={28} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[28]==='1'}/>
                        <label className="p-radiobutton-label">List</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="2" id={28} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[28]==='2'}/>
                        <label className="p-radiobutton-label">View</label>
                    </div>
                    <div className="p-col-3">
                        <RadioButton disabled={this.props.disabled} value="3" id={28} onChange={(e) => this.props.handleRadio(e)} checked={this.props.securityString[28]==='3'}/>
                        <label className="p-radiobutton-label">Full</label>
                    </div>
                </div>
            </div>
        );
    }
}

export default RolesEdit;