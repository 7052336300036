import React from 'react';
import { Redirect } from 'react-router-dom';
import publicIp from 'public-ip';

import { userService } from '../services/Auth';

import { withStyles } from '@material-ui/core/styles';
import ApartmentIcon from '@material-ui/icons/Apartment';
import SettingsIcon from '@material-ui/icons/Settings';
import InfoIcon from '@material-ui/icons/Info';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import SecurityIcon from '@material-ui/icons/Security';
// import SearchIcon from '@material-ui/icons/Search';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';

const styles = theme => ({
    nested: {
        paddingLeft: theme.spacing(4)
    },
});

class MobileSidebar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            toLogin: false,
            workIsOpen: false,
            sysInfoIsOpen: false,
            adminIsOpen: false,
            settingsIsOpen: false,
            infoIsOpen: false,
            secString: ''
        };

        this.handleWorkOpen = this.handleWorkOpen.bind(this);
        this.handleSysInfoOpen = this.handleSysInfoOpen.bind(this);
        this.handleAdminOpen = this.handleAdminOpen.bind(this);
        this.handleSettingsOpen = this.handleSettingsOpen.bind(this);
        this.handleInfoOpen = this.handleInfoOpen.bind(this);
        this.performLogout = this.performLogout.bind(this);
        this.handleUserGuide = this.handleUserGuide.bind(this);
    }

    async componentDidMount() {
        let licenseData = JSON.parse(sessionStorage.getItem('licenseData'));
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));

        let ipAddr;
        try {
            ipAddr = await publicIp.v4();
        } catch(e) {
        }

        this.setState({ 
            fName: parsedUserData.uiU_FN,
            lName: parsedUserData.uiU_LN,
            org: parsedUserData.uiU_M1ORGID,
            licenseExp: licenseData.licenseExpDate,
            ipAddressVar: ipAddr,
            secString: parsedUserData.secString
        })
    }

    performLogout() {
        userService.logout();
        this.setState({ toLogin: true })
    };

    handleUserGuide() {
        window.open("https://mwspi.atlassian.net/wiki/spaces/LUG/overview?homepageId=441942324", "_blank")
    }

    handleWorkOpen() {
        this.setState({ 
            workIsOpen: !this.state.workIsOpen,
            sysInfoIsOpen: false,
            adminIsOpen: false,
            settingsIsOpen: false,
            infoIsOpen: false
        })
    }

    handleSysInfoOpen() {
        this.setState({ 
            workIsOpen: false,
            sysInfoIsOpen: !this.state.sysInfoIsOpen,
            adminIsOpen: false,
            settingsIsOpen: false,
            infoIsOpen: false
        })
    }

    handleAdminOpen() {
        this.setState({ 
            workIsOpen: false,
            sysInfoIsOpen: false,
            adminIsOpen: !this.state.adminIsOpen,
            settingsIsOpen: false,
            infoIsOpen: false
        })
    }

    handleSettingsOpen() {
        this.setState({ 
            workIsOpen: false,
            sysInfoIsOpen: false,
            adminIsOpen: false,
            settingsIsOpen: !this.state.settingsIsOpen,
            infoIsOpen: false
        })
    }

    handleInfoOpen() {
        this.setState({ 
            workIsOpen: false,
            sysInfoIsOpen: false,
            adminIsOpen: false,
            settingsIsOpen: false,
            infoIsOpen: !this.state.infoIsOpen
        })
    }
 
    render() {
        const { classes, sidebarIsOpen } = this.props;
        const { toLogin, workIsOpen, sysInfoIsOpen, adminIsOpen, settingsIsOpen } = this.state;

        if(toLogin) {
            return (
                <Redirect to='/login' />
            );
        } else { 
        return (
                // {/* ///////////////////////////////Mobile Sidebar//////////////////////////////// */}
                <Hidden mdUp>
                    <Drawer open={sidebarIsOpen} anchor='left' onClose={this.props.handleDrawerClose}>
                        <List>
                            <ListItem button onClick={this.props.handleDrawerClose}>
                                <ListItemIcon>
                                    <ChevronLeftIcon />
                                </ListItemIcon>
                            </ListItem>
                        <Divider />
                {/* ////////////////////////////////////////////////////////////////////////////////// */}

                            <ListItem button onClick={() => this.props.handleNavItemClick(38)} divider>
                                <ListItemIcon>
                                    <ContactSupportIcon />
                                </ListItemIcon>
                                <ListItemText primary='New Support Case' />
                            </ListItem>

                {/* ////////////////////////////////////////////////////////////////////////////////// */}
                            
                            <ListItem button onClick={this.handleInfoOpen} divider>
                                <ListItemIcon>
                                    <InfoIcon />
                                </ListItemIcon>
                                <ListItemText primary='Info' />
                                {this.state.infoIsOpen ? <ExpandLess /> : <ExpandMore /> }
                            </ListItem>
                            <Collapse in={this.state.infoIsOpen} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem className={classes.nested} divider>
                                        <ListItemText primary={"Logged in as: " + this.state.fName + " " + this.state.lName}/>
                                    </ListItem>
                                    <ListItem className={classes.nested} divider>
                                        <ListItemText primary={"Organization: " + this.state.org} />
                                    </ListItem>
                                    <ListItem className={classes.nested} divider>
                                        <ListItemText primary={"License Expiration Date: " + this.state.licenseExp} />
                                    </ListItem>
                                    <ListItem className={classes.nested} divider>
                                        <ListItemText primary={"IP Address: " + this.state.ipAddressVar} />
                                    </ListItem>
                                </List>
                            </Collapse>                
                {/* ///////////////////////////////work//////////////////////////////// */}
                            {/* <Divider /> */}
                            {(this.state.secString[16]!=='0' && this.state.secString[25]!=='0' && this.state.secString[19]!=='0' && this.state.secString[20]!=='0' && this.state.secString[21]!=='0' && this.state.secString[22]!=='0') ? (
                                <ListItem button onClick={this.handleWorkOpen} divider>
                                    <ListItemIcon>
                                        <BusinessCenterIcon />
                                    </ListItemIcon>
                                    <ListItemText primary='Work' />
                                    {workIsOpen ? <ExpandLess /> : <ExpandMore /> }
                                </ListItem>) : (null)}
                                <Collapse in={workIsOpen} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {this.state.secString[16]!=='0' ?
                                            (<ListItem button className={classes.nested} divider onClick={() => this.props.handleNavItemClick(31)}>
                                                <ListItemIcon>
                                                    <BusinessCenterIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Key Hand Out" />
                                            </ListItem>) : (null)}
                                        {this.state.secString[16]!=='0' ?
                                            (<ListItem button className={classes.nested} divider onClick={() => this.props.handleNavItemClick(15)}>
                                                <ListItemIcon>
                                                    <BusinessCenterIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Key Hand In" />
                                            </ListItem>) : (null)}
                                        {/* {this.state.secString[25]!=='0' ? 
                                            (<ListItem button className={classes.nested} divider onClick={() => this.props.handleNavItemClick(0)}>
                                                <ListItemIcon>
                                                    <BusinessCenterIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Report Keys Lost" />
                                            </ListItem>) : (null)}
                                        {this.state.secString[25]!=='0' ? 
                                            (<ListItem button className={classes.nested} divider onClick={() => this.props.handleNavItemClick(0)}>
                                                <ListItemIcon>
                                                    <BusinessCenterIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Report Keys Broken" />
                                            </ListItem>) : (null)}
                                        {this.state.secString[25]!=='0' ? 
                                            (<ListItem button className={classes.nested} divider onClick={() => this.props.handleNavItemClick(32)}>
                                                <ListItemIcon>
                                                    <BusinessCenterIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Report Keys Destroyed" />
                                            </ListItem>) : (null)}
                                        {(this.state.secString[19]!=='0' && this.state.secString[20]!=='0' && this.state.secString[21]!=='0' && this.state.secString[22]!=='0') ? 
                                            (<ListItem button className={classes.nested} divider onClick={() => this.props.handleNavItemClick(33)}>
                                                <ListItemIcon>
                                                    <BusinessCenterIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Reports" />
                                            </ListItem>) : (null)} */}
                                    </List>
                                </Collapse>
                    {/* ///////////////////////////////System Info//////////////////////////////// */}
                            <ListItem button onClick={this.handleSysInfoOpen} divider>
                                <ListItemIcon>
                                    <ApartmentIcon />
                                </ListItemIcon>
                                <ListItemText primary='System Info' />
                                {sysInfoIsOpen ? <ExpandLess /> : <ExpandMore /> }
                            </ListItem>
                            <Collapse in={sysInfoIsOpen} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {this.state.secString[2]!=='0' ? 
                                        (<ListItem button className={classes.nested} divider onClick={() => this.props.handleNavItemClick(8)}>
                                            <ListItemIcon>
                                                <ApartmentIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Building Groups" />
                                        </ListItem>) : (null)}
                                    {this.state.secString[3]!=='0' ? 
                                        (<ListItem button onClick={() => this.props.handleNavItemClick(3)} className={classes.nested} divider>
                                            <ListItemIcon>
                                                <ApartmentIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Buildings" />
                                        </ListItem>) : (null)}
                                    {this.state.secString[28]!=='0' ? 
                                        (<ListItem button className={classes.nested} divider  onClick={() => this.props.handleNavItemClick(19)}>
                                            <ListItemIcon>
                                                <ApartmentIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Doors" />
                                        </ListItem>) : (null)}
                                    {this.state.secString[4]!=='0' ? 
                                        (<ListItem button className={classes.nested} divider onClick={() => this.props.handleNavItemClick(9)}>
                                            <ListItemIcon>
                                                <ApartmentIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Departments" />
                                        </ListItem>) : (null)}
                                    {this.state.secString[13]!=='0' ? 
                                        (<ListItem onClick={() => this.props.handleNavItemClick(1)} button className={classes.nested} divider>
                                            <ListItemIcon>
                                                <ApartmentIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="People" />
                                        </ListItem>) : (null)}
                                    {/* <ListItem button className={classes.nested} divider  onClick={() => this.props.handleNavItemClick(16)}>
                                        <ListItemIcon>
                                            <ApartmentIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Keys Past Due" />
                                    </ListItem> */}
                                </List>
                            </Collapse>
                {/* ///////////////////////////////administration//////////////////////////////// */}
                            <ListItem button onClick={this.handleAdminOpen} divider>
                                <ListItemIcon>
                                    <SecurityIcon />
                                </ListItemIcon>
                                <ListItemText primary='Administration' />
                                {adminIsOpen ? <ExpandLess /> : <ExpandMore /> }
                            </ListItem>
                            <Collapse in={adminIsOpen} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                        <ListItem button className={classes.nested} divider onClick={() => this.props.handleNavItemClick(36)}>
                                            <ListItemIcon>
                                                <SecurityIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Support Cases" />
                                        </ListItem>
                                    {this.state.secString[11]!=='0' ? 
                                        (<ListItem button className={classes.nested} divider onClick={() => this.props.handleNavItemClick(6)}>
                                            <ListItemIcon>
                                                <SecurityIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Jobs" />
                                        </ListItem>) : (null)}
                                    {this.state.secString[17]!=='0' ? 
                                        (<ListItem button className={classes.nested} divider onClick={() => this.props.handleNavItemClick(10)}>
                                            <ListItemIcon>
                                                <SecurityIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Master Systems" />
                                        </ListItem>) : (null)}
                                    {this.state.secString[8]!=='0' ? 
                                        (<ListItem button className={classes.nested} divider onClick={() => this.props.handleNavItemClick(5)}>
                                            <ListItemIcon>
                                                <SecurityIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Records" />
                                        </ListItem>) : (null)}
                                    {this.state.secString[9]!=='0' ? 
                                        (<ListItem button className={classes.nested} divider onClick={() => this.props.handleNavItemClick(2)}>
                                            <ListItemIcon>
                                                <SecurityIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Keys" />
                                        </ListItem>) : (null)}
                                    {this.state.secString[10]!=='0' ? 
                                        (<ListItem button className={classes.nested} divider  onClick={() => this.props.handleNavItemClick(4)}>
                                            <ListItemIcon>
                                                <SecurityIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Cores" />
                                        </ListItem>) : (null)}
                                    {/* {this.state.secString[26]!=='0' ? 
                                        (<ListItem button className={classes.nested} divider onClick={() => this.props.handleNavItemClick(13)}>
                                            <ListItemIcon>
                                                <SecurityIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Key Rings" />
                                        </ListItem>) : (null)}
                                    {this.state.secString[7]!=='0' ? 
                                        (<ListItem button className={classes.nested} divider onClick={() => this.props.handleNavItemClick(11)}>
                                            <ListItemIcon>
                                                <SecurityIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Credentials" />
                                        </ListItem>) : (null)} */}
                                    {this.state.secString[5]!=='0' ? 
                                        (<ListItem button className={classes.nested} divider onClick={() => this.props.handleNavItemClick(7)}>
                                            <ListItemIcon>
                                                <SecurityIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Key Storage" />
                                        </ListItem>) : (null)}
                                    {/* <ListItem button className={classes.nested} divider onClick={() => this.props.handleNavItemClick(20)}>
                                        <ListItemIcon>
                                            <SecurityIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Domains" />
                                    </ListItem>
                                    {this.state.secString[27]!=='0' ? 
                                        (<ListItem button className={classes.nested} divider onClick={() => this.props.handleNavItemClick(21)}>
                                            <ListItemIcon>
                                                <SecurityIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Devices" />
                                        </ListItem>) : (null)} */}
                                </List>
                            </Collapse>
                {/* ///////////////////////////////Settings//////////////////////////////// */}
                <ListItem button onClick={this.handleSettingsOpen} divider>
                                <ListItemIcon>
                                    <SettingsIcon />
                                </ListItemIcon>
                                <ListItemText primary='Settings' />
                                {settingsIsOpen ? <ExpandLess /> : <ExpandMore /> }
                            </ListItem>
                            <Collapse in={settingsIsOpen} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                {/* {this.state.secString[6]!=='0' ? 
                                    (<ListItem button className={classes.nested} divider onClick={() => this.props.handleNavItemClick(12)}>
                                        <ListItemIcon>
                                            <SettingsIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Credential Types" />
                                    </ListItem>) : (null)} */}
                                {this.state.secString[12]!=='0' ? 
                                    (<ListItem button className={classes.nested} divider onClick={this.props.customizeClick}>
                                        <ListItemIcon>
                                            <SettingsIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Customization" />
                                    </ListItem>) : (null)}
                                <ListItem button onClick={this.props.openLicense} className={classes.nested} divider>
                                    <ListItemIcon>
                                        <SettingsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="License Info" />
                                </ListItem>
                                {this.state.secString[14]!=='0' ? 
                                    (<ListItem button onClick={() => this.props.handleNavItemClick(14)} className={classes.nested} divider>
                                        <ListItemIcon>
                                            <SettingsIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Security Roles" />
                                    </ListItem>) : (null)}
                                <ListItem button className={classes.nested} divider onClick={() => this.handleUserGuide()}>
                                    <ListItemIcon>
                                        <SettingsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="User Guide" />
                                </ListItem>
                                </List>
                            </Collapse>                              
           {/* ///////////////////////////////logout//////////////////////////////// */} 
                            <ListItem button onClick={this.performLogout} divider>
                                <ListItemIcon>
                                    <ExitToAppIcon />
                                </ListItemIcon>
                                <ListItemText primary='Logout' />
                            </ListItem>                                             
                        </List>
                    </Drawer>
                </Hidden>
        );}
    }
}

export default withStyles(styles, { withTheme: true })(MobileSidebar);