import React from 'react';

import GeneralDatatable from '../components/GeneralDatatable';
import {Messages} from 'primereact/messages';

class Hooks extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            selectedColumns: [
                {field:'uiT_HOOKNUMBER', header:'Hook #'},
                {field:'uiT_DESC', header:'Hook Description'},
                {field:'keY_LIST', header:'Code'},
                {field:'uiT_NOTES', header:'Keyway'}
            ],
            columnWidthTracker: "365.75,365.75,365.75,365.75",
            defaultOrder: ["uiT_HOOKNUMBER", "uiT_DESC", "keY_LIST", "uiT_NOTES"],
            hookData: []
        };
        this.handleWidth = this.handleWidth.bind(this);
        this.handleSelectedColumns = this.handleSelectedColumns.bind(this);
    }

    async componentDidMount() {
        let parsedTableData = JSON.parse(sessionStorage.getItem('HooksTableData'));

        if(parsedTableData!==null) {
            if(parsedTableData.selectedColumns !== null) {
                this.setState({ selectedColumns: parsedTableData.selectedColumns })
            }
        }
    }

    handleWidth(width) {
        this.setState({ columnWidthTracker: width })
    }

    handleSelectedColumns(e) {
        this.setState({ selectedColumns: e })
    }

    render() {
        return(
            <div>
                <Messages ref={(el) => this.messages = el} />
                <GeneralDatatable 
                    columnArray = {[
                        {field:'uiT_HOOKNUMBER', header:'Hook #'},
                        {field:'uiT_DESC', header:'Hook Description'},
                        {field:'keY_LIST', header:'Code'},
                        {field:'uiT_NOTES', header:'Keyway'}
                    ]}

                    csvHeaders = {[
                        {key:'uiT_HOOKNUMBER', label:'Hook #'},
                        {key:'uiT_DESC', label:'Hook Description'},
                        {key:'keY_LIST', label:'Code'},
                        {key:'uiT_NOTES', label:'Keyway'}
                    ]}

                    defaultOrder={["uiT_HOOKNUMBER", "uiT_DESC", "keY_LIST", "uiT_NOTES"]}

                    tableRecords = {this.props.hookData}
                    pageNumber={30}
                    tableName={'Hooks'}
                    exportFileName={'Hooks.csv'}
                    tableData={'HooksTableData'}
                    filterData={'HooksFilterData'}
                    columnWidthTracker={this.state.columnWidthTracker}
                    isLoading={this.props.isLoading}
                    selectedColumns={this.state.selectedColumns}
                    handleRowClick={this.props.handleRowClick}
                    handleWidth={this.handleWidth}
                    handleSelectedColumns={this.handleSelectedColumns}   
                />
            </div>
        );
    }
}

export default Hooks;