import { serverAddr } from "./serverAddr";

export const BlobService = {
    downloadImageBlob,
    downloadPdfBlob,
    deleteBlob,
    uploadBlob
}
async function downloadImageBlob(containerName, blobName) {
    let userData = sessionStorage.getItem('userData');
    let parsedUserData = JSON.parse(userData);
    let bearerToken = parsedUserData.token;
    let Directory = containerName;
    let Resource = blobName;
  
    const data = JSON.stringify({ Directory, Resource });
  
    try {
      const response = await fetch(serverAddr.apiUrl + '/BlobResource/fetchImageBlob', {
        method: 'POST',
        // mode: 'cors',
        headers: {
          'Authorization': `Bearer ${bearerToken}`,
          'Content-Type': 'application/json'
        },
        body: data
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error: ${response.status}`);
      }
        // Assuming your server returns a Blob, you can use the response.blob() method
      const blobData = await response.blob();
  
//       // Do something with the blobData, such as creating a download link
      const blobUrl = URL.createObjectURL(blobData);
      return blobUrl;
    } catch (error) {
      console.error('Error downloading Img blob:', error);
      return false; // Or handle the error differently
    }
  }

  async function downloadPdfBlob(containerName, blobName) {
    let userData = sessionStorage.getItem('userData');
    let parsedUserData = JSON.parse(userData);
    let bearerToken = parsedUserData.token;
    let Directory = containerName;
    let Resource = blobName;
  
    const data = JSON.stringify({ Directory, Resource });
  
    try {
      const response = await fetch(serverAddr.apiUrl + '/BlobResource/fetchPdfBlob', {
        method: 'POST',
        //mode: 'cors',
        headers: {
          'Authorization': `Bearer ${bearerToken}`,
          'Content-Type': 'application/json'
        },
        body: data
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error: ${response.status}`);
      }
        // Assuming your server returns a Blob, you can use the response.blob() method
      const blobData = await response.blob();
  
//       // Do something with the blobData, such as creating a download link
      const blobUrl = URL.createObjectURL(blobData);
      return blobUrl;
    } catch (error) {
      console.error('Error downloading PDF blob:', error);
      return false; // Or handle the error differently
    }
  }
  
  async function deleteBlob(containerName, blobName) {
    let userData = sessionStorage.getItem('userData');
    let parsedUserData = JSON.parse(userData);
    let bearerToken = parsedUserData.token;
    let Directory = containerName;
    let Resource = blobName;
  
    const data = JSON.stringify({ Directory, Resource });
  
    try {
      const response = await fetch(serverAddr.apiUrl + '/BlobResource/deleteBlob', {
        method: 'POST',
        //mode: 'cors',
        headers: {
          'Authorization': `Bearer ${bearerToken}`,
          'Content-Type': 'application/json'
        },
        body: data
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error: ${response.status}`);
      }     
      return true;
    } catch (error) {
      console.error('Error deleting blob:', error);
      return false; // Or handle the error differently
    }
  }

  async function uploadBlob(containerName, blobName, imageFile) {
    let userData = sessionStorage.getItem('userData');
    let parsedUserData = JSON.parse(userData);
    let bearerToken = parsedUserData.token;

    try {
      const formData = new FormData();
      formData.append('Directory', containerName);
      formData.append('Resource', blobName);

      let content = await (await fetch(imageFile)).blob() ;
      formData.append('BlobData', content);

      // debugger;

      const response = await fetch(serverAddr.apiUrl + '/BlobResource/uploadBlob', {
        method: 'POST',
        // mode: 'cors',
        headers: {
          'Authorization': `Bearer ${bearerToken}`
        },
        body: formData
      });

      if (!response.ok) {
        throw new Error(`HTTP error: ${response.status}`);
      }

      return true;
    } catch (error) {
      // Handle the error differently, e.g., display an error message
      console.error(error);
      return false;
    }
}

