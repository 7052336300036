import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';

import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';
import {Button} from 'primereact/button';



class KeyHandOutComponent extends React.Component {
    constructor(props) {
        super(props);
        
        this.state={            
            isLoading: false,         
            disabled: false,
            permanentIssueFlag: false,
            returnInNDays: 0,
            keyReturnDate: null,
            dynaFields: {}

        }        
        this.minDate = new Date();
     
    }

    renderEditFooter() {
        if(this.state.secRole==='2') {
            return(
                <div>
                    <Button type='button' label='Exit' onClick={this.handleClose} />
                </div>
            );
        } else {
            return (
                <div>          
                    <Button type='button' label='Save and Exit' icon='pi pi-check' onClick={this.handleFieldSave} />
                    <Button type='button' label='Exit' icon='pi pi-times' onClick={this.handleClose} />
                </div>
            );
        }
    }

    nextStep = (e) => {
        let fields = {};
        
        fields.keyReturnDate = null;
        fields.returnInNDays = null;
        fields.permanentIssueFlag = 0;
        if (e.checked){    
            fields.permanentIssueFlag = 1;
            this.props.handleChange(fields);            
            this.props.handleNextStep(null,3);
        }       
    }

    calcReturnDays = (e) => {
      
        let oneDay = 1000 * 60 * 60 * 24;        
        let rtnDte = new Date(e.value);
        let totDays = Math.round(rtnDte.getTime() - this.minDate.getTime()) / oneDay + 1;
        let finalCalc = totDays.toFixed(0);

        let fields = {};
        fields.keyReturnDate = e.value;
        fields.returnInNDays = finalCalc;
        fields.permanentIssueFlag = 0;
        this.props.handleChange(fields);
        this.props.handleNextStep(null,3);
        
    }

    render() {       
        return(
            <div>
                <BrowserView>
                    <div className="p-grid p-dir-row p-mt-2">
                        <div className="p-col">
                            <div className="box" style={{marginTop:100}}>                    
                                <h3>Do you want to permanently issue key {this.props.selectedKey}?</h3>
                                <h5>YES
                                <Checkbox   id="permanentIssueFlag" 
                                            onChange={(e) => this.nextStep(e)} 
                                            className="p-ml-4"/>
                                </h5>                    
                            </div>
                        </div>   
                        <div className="p-col">
                            <div className="box">        
                                <h3>KEY RETURN DATE</h3>                    
                                <Calendar id="keyReturnDate" 
                                    value={this.state.keyReturnDate} 
                                    onChange={(e) => this.calcReturnDays(e)} 
                                    readOnlyInput
                                    minDate={this.minDate}
                                    inline
                                />                                       
                            </div>
                        </div>
                    </div>
                </BrowserView>
                <MobileView>
                    <div className="p-grid p-dir-row p-mt-2">
                        <div className="p-col">
                            <div className="box">                    
                                <h3>Do you want to permanently issue key {this.props.selectedKey}?</h3>
                                <h5>YES
                                <Checkbox   id="permanentIssueFlag" 
                                            onChange={(e) => this.nextStep(e)} 
                                            className="p-ml-4"/>
                                </h5>                    
                            </div>
                        </div>   
                        <div className="p-col" style={{width:'325px'}}>
                            <div className="box">        
                                <h3>KEY RETURN DATE</h3>                    
                                <Calendar id="keyReturnDate" 
                                    value={this.state.keyReturnDate} 
                                    onChange={(e) => this.calcReturnDays(e)} 
                                    readOnlyInput
                                    minDate={this.minDate}
                                    inline
                                    style={{maxWidth:'100%'}}
                                />                                       
                            </div>
                        </div>
                    </div>
                </MobileView>
            </div>
        );
    }
}
      


export default KeyHandOutComponent;