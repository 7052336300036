import React from 'react';
import validator from 'email-validator';

import { serverAddr } from '../services/serverAddr';
import Layout from '../components/Layout';
import PeopleKeysDatatable from '../components/PeopleKeysDatatable';
import { PeopleService, RolesService, DeptService, KeyService } from '../services/FetchServices';
import { BlobService } from '../services/BlobServices';
import avatarJpeg from '../images/avatarJpeg.jpg';
import PeopleDatatable from '../components/PeopleDatatable';
import TextBox from '../components/inputComponents/textbox';
import InputSelect from '../components/inputComponents/InputSelect';

import {InputText} from 'primereact/inputtext';
import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import {Messages} from 'primereact/messages';
import {InputMask} from 'primereact/inputmask';
import { InputTextarea } from 'primereact/inputtextarea';
import { FileUpload } from 'primereact/fileupload';
import { Slider } from 'primereact/slider';

import AvatarEditor from 'react-avatar-editor';
import Webcam from "react-webcam";
import { v4 as uuidv4 } from 'uuid';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

let roles;
let departments;

class People extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            peopleData: [],
            roleOptions: [],
            deptOptions: [],
            isLoading: true,
            displayEdit: false,
            detailFName: '', 
            detailLName: '',
            detailEmail: '',
            detailNotes: '',
            detailDept: 0,
            detailTitle: '',
            detailPhone: '',
            detailEmployeeId: '',
            detailSecurityRole: -1,
            detailStatus: '',
            nameError: false,
            emailError: false,
            saveError: false,
            msg: '',
            displayKeys: false,
            displayDelete: false,
            detailUserID: -1,
            imgState: '',
            finalImg: avatarJpeg,
            scaleSlider: 12,
            rotateImg: 0,
            newUserEntry: false,
            keyData: [],
            keysIsLoading: true,
            secRole: '',
            disabled: false,
            webcamOn: false,
            hasButton: false
        }
        
        this.handleSelect = this.handleSelect.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.showResults = this.showResults.bind(this);
        this.handleRadio = this.handleRadio.bind(this);
        this.storeUser = this.storeUser.bind(this);
        this.handleRoleDelete = this.handleRoleDelete.bind(this);
        this.keyActionTemplate = this.keyActionTemplate.bind(this);
        this.handleKeySelect = this.handleKeySelect.bind(this);
        this.handleKeyReturn = this.handleKeyReturn.bind(this);
        this.myUploader = this.myUploader.bind(this);
        this.handleSliderChange = this.handleSliderChange.bind(this);
        this.handleLeftRotate = this.handleLeftRotate.bind(this);
        this.handleRightRotate = this.handleRightRotate.bind(this);
        this.handleWebcam = this.handleWebcam.bind(this);
        this.capture = this.capture.bind(this);
        this.cancelWebcam = this.cancelWebcam.bind(this);
        this.uploadBlob = this.uploadBlob.bind(this)
        this.downloadBlob = this.downloadBlob.bind(this);
        this.handleDeleteClose = this.handleDeleteClose.bind(this);
        this.handleRoleChange = this.handleRoleChange.bind(this);
        this.createObject = this.createObject.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
    }

    async componentDidMount() {
        let x = JSON.parse(sessionStorage.getItem("peopleTableData"));
        if(x !== null) {
            if(x.selectedColumns !== null) {
                this.setState({ selectedColumns: x.selectedColumns })
            }
        }
        
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));

        if(parsedUserData.secString[13]==='2') {
            this.setState({ disabled: true })
        } else if(parsedUserData.secString[13]==='3') {
            this.setState({ hasButton: true })
        }

        await PeopleService.fetchUsers( parsedUserData.uiU_M1ORGID, "", 0 )
            .then(data => 
                this.setState({ 
                    peopleData: data.user, 
                    isLoading: false, 
                    orgID: (parsedUserData.uiU_M1ORGID.toLowerCase()),
                    selfID: parsedUserData.uiU_ID,
                    secRole: parsedUserData.secString[13],
                    keySecRole: parsedUserData.secString[9]
                })
            );
    }

    createObject(label, value) {
        const obj = {};

        obj.label = label;
        obj.value = value;
        return obj;
    }

    saveLayoutResults(data) {
        if(data) {
            this.messages.show({severity: 'success', summary: 'Successfully Saved', life:1000});
        } else {
            this.messages.show({severity: 'error', summary: 'Save Unsuccessful', life:1000});
        }
    }

    showResults(data) {
        if(data.success) {
            this.messages.show({severity: 'success', summary: data.message, life:1500});
            this.setState({ 
                displayEdit: false, 
                saveError: false, 
                msg:''
            })
        } else {
            this.messages.show({severity: 'error', summary: data.message, life:1500});
           this.setState({ 
               saveError: true,
                msg: data.message
            })
        }  
    } 

    async handleSelect(e) {
        let tempArray=[];
        let tempDept=[];
        if(this.state.secRole!=='1') {
            //role options
            roles = await RolesService.fetchRoles( this.state.orgID )

            for(let i=0; i<roles.roles.length; i++) {
                tempArray.push(this.createObject(roles.roles[i].uiS_NAME, roles.roles[i].uiS_ID));
            }

            this.setState({ roleOptions: [{label: 'None', value:-1}].concat(tempArray) });

            //department options
            departments = await DeptService.FetchDept( this.state.orgID, 0, "" )
            for(let i = 0; i<departments.depts.length; i++) {
                tempDept.push(this.createObject(departments.depts[i].uiD_NAME, departments.depts[i].uiD_ID));
            }
            this.setState({deptOptions: tempDept})

            if(e!=='New') {
                await KeyService.fetchIssuedKeys(e.data.uiU_M1ORGID, e.data.uiU_ID, 0, 0).then(data => this.setState({ keyData: data.keylist, keysIsLoading: false }));
            }

            if(e==='New') {
                this.setState({ 
                    displayEdit: true,
                    newUserEntry: true,
                    keysIsLoading: false
                })
            } else {
                this.downloadBlob(e.data.uiU_ID)
                if(e.data.status==null) {
                    this.setState({detailStatus: ''})
                } else {
                    this.setState({detailStatus: e.data.status})
                }
                this.setState({ 
                    detailUserID: e.data.uiU_ID,
                    detailFName: e.data.uiU_FN,
                    detailLName: e.data.uiU_LN,
                    detailEmail: e.data.uiU_EMAIL,
                    detailNotes: e.data.uiU_NOTES,
                    detailDept: e.data.uiU_DEPTID,
                    detailTitle: e.data.uiU_TITLE,
                    detailPhone: e.data.uiU_PHONE,
                    detailEmployeeId: e.data.uiU_EMPLOYEEID,
                    detailSecurityRole: e.data.uiU_SECROLEID,
                    detailDomain: '',
                    displayEdit: true 
                })
            }
        }
    }

    async uploadBlob(userID) {        
        const containerName = serverAddr.blobContainer + "-user-pic";
        const blobName = `${this.state.orgID}${userID}`
        const canvas = this.editor.getImageScaledToCanvas().toDataURL();
        await BlobService.uploadBlob(containerName, blobName, canvas);
    }

    async downloadBlob(userID) {
        const containerName = serverAddr.blobContainer + "-user-pic";
        const blobName = `${this.state.orgID}${userID}`
        try{
            const downloaded = await BlobService.downloadImageBlob(containerName, blobName);
            if(downloaded!==false) {
                this.setState({finalImg: downloaded})
            } else {
                this.setState({finalImg: avatarJpeg})
            }
        } catch(error) {
            this.setState({finalImg: avatarJpeg})
        }
    }

    handleClose() {
        this.setState({ 
            displayEdit: false,
            detailUserID: -1,
            detailFName: '', 
            detailLName: '',
            detailEmail: '',
            detailNotes: '',
            detailDept: 0,
            detailTitle: '',
            detailPhone: '',
            detailEmployeeId: '',
            detailSecurityRole: -1,
            detailStatus: '',
            emailError: false,
            nameError: false,
            displayDelete: false,
            msg: '',
            keyData: [],
            keysIsLoading: true,
            imgState: '',
            finalImg: avatarJpeg,
            scaleSlider: 12,
            rotateImg: 0,
            newUserEntry: false
        })
    }

    handleNameChange(e) {
        if(e.target.id==='fname') {
            this.setState({ detailFName: e.target.value })
        } else if(e.target.id==='lname') {
            this.setState({ detailLName: e.target.value })
        } else if(e.target.id==='email') {
            this.setState({ detailEmail: e.target.value })
        } else if(e.target.id==='phone') {
            this.setState({ detailPhone: e.target.value })
        } else if(e.target.id==='dept') {
            this.setState({ detailDept: e.target.value })
        } else if(e.target.id==='title') {
            this.setState({ detailTitle: e.target.value })
        } else if(e.target.id==='notes') {
            this.setState({ detailNotes: e.target.value })
        } else if(e.target.id==='employeeId') {
            this.setState({ detailEmployeeId: e.target.value })
        } else if(e.target.id==='status') {
            this.setState({ detailStatus: e.target.value })
        } else if(e.target.id==='domain') {
            return;
        } else if(e.target.id==='securityrole') {
            this.setState({ detailSecurityRole: e.target.value })
        }
    }

    handleRoleChange = e => {
        this.setState({detailSecurityRole: e.value})
    }

    handleRadio(e) {
        let securityStringVar = this.state.securityString.split('');
        securityStringVar[e.target.id] = e.value;
        securityStringVar = securityStringVar.join('');
        this.setState({ securityString: securityStringVar });
    }

    async storeUser() {
        let userData = JSON.parse(sessionStorage.getItem('userData'));
        const GUID = uuidv4();
        
        if(this.editor&&this.state.detailUserID!==-1) {
            this.uploadBlob(this.state.detailUserID);
        } 

        if(this.state.detailEmail==="" && this.state.detailSecurityRole===-1 && (this.state.detailFName!==null || this.state.detailFName!=='' || this.state.detailLName !== null || this.state.detailLName!=='') ) {
            this.setState({
                emailError: false,
                nameError: false
            })   

            if(this.state.detailUserID===-1 && this.editor) {
                await PeopleService.storeUser(this.state.detailUserID, this.state.orgID, this.state.detailFName, this.state.detailLName, 
                    this.state.detailEmail, this.state.detailDept, this.state.detailTitle, this.state.detailPhone, this.state.detailEmployeeId, 
                    this.state.detailSecurityRole, this.state.detailNotes, 1, GUID
                )
                const uID = await PeopleService.fetchUsers(this.state.orgID, GUID, 0)
                this.uploadBlob(uID.user[0].uiU_ID);
            } else {
                await PeopleService.storeUser(this.state.detailUserID, this.state.orgID, this.state.detailFName, this.state.detailLName, 
                    this.state.detailEmail, this.state.detailDept, this.state.detailTitle, this.state.detailPhone, this.state.detailEmployeeId, 
                    this.state.detailSecurityRole, this.state.detailNotes, 1, GUID
                ).then(data => this.showResults(data))
            }
            
            await PeopleService.fetchUsers(userData.uiU_M1ORGID, "", 0).then(data => 
                this.setState({  peopleData: data.user})
            )
            this.handleClose();
        } else if(validator.validate(this.state.detailEmail)===false || this.state.detailFName===null || this.state.detailFName==='' || this.state.detailLName === null || this.state.detailLName==='') {
            if(validator.validate(this.state.detailEmail)===false) {
                this.setState({ emailError: true })
            } else {
                this.setState({ emailError: false })
            }
            
            if(this.state.detailFName===null || this.state.detailFName==='' || this.state.detailLName === null || this.state.detailLName==='') {
                this.setState({ nameError: true })
            } else {
                this.setState({ nameError: false })
            }
        } else {
            this.setState({
                emailError: false,
                nameError: false
            })           

            if(this.state.detailUserID===-1 && this.editor) {
                await PeopleService.storeUser(this.state.detailUserID, this.state.orgID, this.state.detailFName, this.state.detailLName, 
                    this.state.detailEmail, this.state.detailDept, this.state.detailTitle, this.state.detailPhone, this.state.detailEmployeeId, 
                    this.state.detailSecurityRole, this.state.detailNotes, 1, GUID
                )
                const uID = await PeopleService.fetchUsers(this.state.orgID, GUID, 0)
                this.uploadBlob(uID.user[0].uiU_ID);
            } else {
                await PeopleService.storeUser(this.state.detailUserID, this.state.orgID, this.state.detailFName, this.state.detailLName, 
                    this.state.detailEmail, this.state.detailDept, this.state.detailTitle, this.state.detailPhone, this.state.detailEmployeeId, 
                    this.state.detailSecurityRole, this.state.detailNotes, 1, GUID
                ).then(data => this.showResults(data))
            }   
            
            await PeopleService.fetchUsers(userData.uiU_M1ORGID, "", 0).then(data => 
                this.setState({ peopleData: data.user})
            )
            this.handleClose();
        }
    }

    myUploader(event) {
        this.setState({ imgState: event.files[0].objectURL })
    }

    handleWebcam() {
        this.setState({ 
            webcamOn: true,
            imgState: 'new'
        })
    }

    capture() {
        const captureImg = this.webcamRef.getScreenshot();
        this.setState({ 
            imgState: captureImg,
            webcamOn: false
        })
    }

    cancelWebcam() {
        this.setState({ 
            webcamOn: false,
            imgState: ''
        })
    }

    handleLeftRotate() {
        if(this.state.rotateImg===360) {
            this.setState({ rotateImg: 0 })
        } else {
            this.setState({ rotateImg: (this.state.rotateImg+90) })
        }
    }

    handleRightRotate() {
        if(this.state.rotateImg===0) {
            this.setState({ rotateImg: 270 })
        } else {
            this.setState({ rotateImg: (this.state.rotateImg-90) })
        }
    }

    renderEditFooter() {
        if(this.state.secRole!=="3") {
            return(
                <div style={{float:'right'}} className='p-col-3'>
                    <Button label='Exit' icon='pi pi-times' onClick={this.handleClose}/>
                </div>
            );
        } else if(isBrowser) {
            return(
                <div className='p-grid p-justify-between'>
                    <div className='p-col-9'>
                        <div>{this.state.saveError ? (<div style={{color: 'red'}}>{this.state.msg}</div>) : (null)}</div>
                        <div>{this.state.emailError ? (<div style={{color: 'red'}}>Must enter a valid email</div>) : (null) }</div>
                        <div>{this.state.nameError ? (<div style={{color: 'red'}}>Name fields can not be blank</div>) : (null) }</div>
                    </div>
                    <div className='p-col-3'>
                        {(this.state.detailUserID !== -1 && this.state.detailUserID !== this.state.selfID) ? (<Button label='Delete' icon='pi pi-trash' onClick={this.handleRoleDelete}/>) : (null)}
                        <Button label='Save and Exit' icon='pi pi-check' onClick={this.storeUser}/>
                        <Button label='Exit' icon='pi pi-times' onClick={this.handleClose}/>
                    </div>
                </div>
            );
        } else if(isMobile) {
            return(
                <div>
                    {(this.state.detailUserID !== -1 && this.state.detailUserID !== this.state.selfID) ? (<Button label='Delete' icon='pi pi-trash' onClick={this.handleRoleDelete}/>) : (null)}
                    <Button label='Save and Exit' icon='pi pi-check' onClick={this.storeUser}/>
                    <Button label='Exit' icon='pi pi-times' onClick={this.handleClose}/>
                </div>
            );
        }
    }

    handleRoleDelete() {
        this.setState({ 
            displayDelete: true
        })
    }

    async deleteUser() {    
        await PeopleService.deleteUser(this.state.detailUserID, this.state.orgID).then(data => this.showResults(data));
        await PeopleService.fetchUsers( this.state.orgID, "", 0 ).then(data => this.setState({ peopleData: data.user }));
        this.handleDeleteClose();
        this.handleClose();
    }

    handleKeySelect(rowData) {

    }

    handleKeyReturn(rowData) {

    }

    handleSliderChange(e) {
        this.setState({ scaleSlider: e.value })
    }

    setEditorRef = (editor) => this.editor = editor;

    setWebcamRef = (webcamRef) => {this.webcamRef = webcamRef};

    keyActionTemplate(rowData, column) {
        return (
            <div>
                <Button type="button" icon="pi pi-pencil" onClick={() => this.handleKeySelect(rowData)} style={{marginTop: '.25em', marginRight: '.25em', marginBottom: '.25em'}}/>
                <Button type="button" icon="pi pi-check-square" onClick={() => this.handleKeyReturn(rowData)} style={{marginTop: '.25em', marginRight: '.25em'}}/>
            </div>
        );
    }

    renderDeleteFooter() {
        return(
            <div>
                <Button type='button' label='Delete' icon="pi pi-trash" onClick={this.deleteUser}/>
                <Button type='button' label='Cancel' onClick={this.handleDeleteClose} icon="pi pi-times"/>
            </div>
        );
    }

    handleDeleteClose() {
        this.setState({ displayDelete: false })
    }

    render() {
        const { displayEdit, displayDelete, imgState, finalImg } = this.state;
        const editFooter=this.renderEditFooter();
        const deleteFooter=this.renderDeleteFooter();

        if((this.state.secRole==='' && this.state.isLoading===false) || (this.state.secRole==='0' && this.state.isLoading===false)) {
            return(<Layout><div>Security Role prevents you from seeing this data</div></Layout>);
        } else {
            return (
                <div> {(this.state.peopleData.length===0 && this.state.isLoading===false) ? (<Layout><Button type='button' label='New User' onClick={() => this.handleSelect('New')} icon="pi pi-plus"/></Layout>) : (
                    <Layout>
                        <div className='container-div'>
                            <Messages ref={(el) => this.messages = el} />
                            <PeopleDatatable
                                peopleData={this.state.peopleData}
                                pageNumber={2}
                                tableName={'People'}
                                exportFileName={'People.csv'}
                                tableData={'peopleTableData'}
                                filterData={'peopleFilterData'}
                                isLoading={this.state.isLoading}
                                editRecord={this.handleSelect}
                                buttonOnClick={() => this.handleSelect('New')}
                                hasButton={this.state.hasButton}
                            />
                        </div>
                    </Layout>)}
                    <div>
                        <Dialog header='Details' visible={displayEdit} maximized onHide={this.handleClose} footer={editFooter} baseZIndex={1300} blockScroll focusOnShow={false} closable={false}>
                            <BrowserView>
                                {(window.screen.width>1150&&window.screen.width<1441) ? (
                                    <div className='p-grid'>
                                        <div className='p-col-12 p-grid'>
                                            <div className='p-col-12 p-grid p-justify-center'>
                                                <div className='p-col-2'>
                                                    {imgState==='' ? 
                                                        (<div>
                                                            {(this.state.secRole==='2') ? (null) : (
                                                                <div>
                                                                    <FileUpload name="profile pic" customUpload uploadHandler={this.myUploader} mode="basic" auto/>
                                                                    <Button onClick={this.handleWebcam} label='Camera' icon='pi pi-camera' />
                                                                </div>
                                                            )}
                                                        </div>    
                                                        ) : 
                                                        (null) }
                                                </div>
                                                {imgState==='' ? 
                                                    (<img className='p-col-8' alt='profile pic' src={finalImg} style={{ maxWidth:'250px', maxHeight:'250px' }}/>) :
                                                    <div>
                                                        {this.state.webcamOn ? 
                                                            (<div>
                                                                <div>
                                                                    <Webcam audio={false} ref={this.setWebcamRef} screenshotFormat="image/jpeg" style={{maxWidth: '375px', maxHeight:'275px'}} />
                                                                </div>
                                                                <Button label='Capture' icon='pi pi-camera' onClick={this.capture} style={{ marginRight: '10px' }}/>
                                                                <Button label='Cancel' onClick={this.cancelWebcam} />
                                                            </div>) : 
                                                            (
                                                            <div>
                                                                <AvatarEditor
                                                                    ref={this.setEditorRef}
                                                                    image={imgState}
                                                                    width={250}
                                                                    height={250}
                                                                    border={50}
                                                                    color={[123, 123, 123, 0.6]}
                                                                    scale={(this.state.scaleSlider/10)}
                                                                    rotate={this.state.rotateImg}
                                                                />
                                                                <div>
                                                                    <Slider value={this.state.scaleSlider} onChange={(e) => this.handleSliderChange(e)} min={2} max={30} step={1} />
                                                                </div>
                                                                <div>
                                                                    <Button label="Left" style={{marginRight:'10px'}} onClick={this.handleLeftRotate} />
                                                                    <Button label="Right" onClick={this.handleRightRotate} />
                                                                </div>
                                                            </div>
                                                            )
                                                        }
                                                    </div>
                                                }   
                                            </div>
                                        </div>
                                        <div className='p-col-12 p-grid'>
                                            <div className='p-col-4 p-grid'>
                                                <TextBox value={this.state.detailFName} id="fname" label={"First Name"} handleFieldChange={this.handleNameChange} disabled={this.state.disabled} threeCol />
                                                <TextBox value={this.state.detailEmail} id="email" label={"Email"} handleFieldChange={this.handleNameChange} disabled={this.state.disabled} threeCol />
                                                <TextBox value={this.state.detailTitle} id="title" label={"Title"} handleFieldChange={this.handleNameChange} disabled={this.state.disabled} threeCol />
                                                <TextBox value={this.state.detailStatus} id="status" label={"Status"} handleFieldChange={this.handleNameChange} disabled={this.state.disabled} threeCol />
                                            </div>
                                            <div className='p-col-4 p-grid'>
                                                <TextBox value={this.state.detailLName} id="lname" label={"Last Name"} handleFieldChange={this.handleNameChange} disabled={this.state.disabled} threeCol />
                                                <div className='p-grid' style={{width:'335px', margin:'auto'}}>
                                                    <label className="p-col" style={{textAlign:'right', display:'block'}} htmlFor={"phone"}><strong>Phone</strong></label>
                                                    <div className='p-col-fixed' >
                                                        <InputMask disabled={this.state.disabled} mask='(999) 999-9999' style={{width:'206px'}} value={this.state.detailPhone} type="text" id='phone' onChange={(e) => this.handleNameChange(e)} />
                                                    </div>
                                                </div>
                                                <TextBox value={this.state.detailDomain} id="domain" label={"Domain"} handleFieldChange={this.handleNameChange} disabled={this.state.disabled} threeCol />
                                                <InputSelect value={this.state.detailDept} options={this.state.deptOptions} id="dept" label={"Department"} handleFieldChange={this.handleNameChange} disabled={this.state.disabled} threeCol />
                                            </div>
                                            <div className='p-col-4 p-grid'>
                                                <TextBox value={this.state.detailEmployeeId} id="employeeId" label={"Employee ID"} handleFieldChange={this.handleNameChange} disabled={this.state.disabled} threeCol />                                                                                                                                    
                                                <InputSelect key={this.state.detailSecurityRole} 
                                                    value={this.state.detailSecurityRole} id={"securityrole"} label={"Security Role"} 
                                                    options={this.state.roleOptions} disabled={this.state.disabled}
                                                    handleFieldChange={this.handleNameChange} threeCol
                                                />
                                                <TextBox value={this.state.detailNotes} id="notes" label={"Notes"} handleFieldChange={this.handleNameChange} disabled={this.state.disabled} area threeCol />
                                            </div>  
                                        </div>
                                    </div>
                                ) : (
                                    <div className='p-grid'>
                                        <div className='p-col-9 p-grid'>
                                            <div className='p-col-4 p-grid'>
                                                <TextBox value={this.state.detailFName} id="fname" label={"First Name"} handleFieldChange={this.handleNameChange} disabled={this.state.disabled} threeCol />
                                                <TextBox value={this.state.detailEmail} id="email" label={"Email"} handleFieldChange={this.handleNameChange} disabled={this.state.disabled} threeCol />
                                                <TextBox value={this.state.detailTitle} id="title" label={"Title"} handleFieldChange={this.handleNameChange} disabled={this.state.disabled} threeCol />
                                                <TextBox value={this.state.detailStatus} id="status" label={"Status"} handleFieldChange={this.handleNameChange} disabled={this.state.disabled} threeCol />
                                            </div>
                                            <div className='p-col-4 p-grid'>
                                                <TextBox value={this.state.detailLName} id="lname" label={"Last Name"} handleFieldChange={this.handleNameChange} disabled={this.state.disabled} threeCol />
                                                <div className='p-grid' style={{width:'450px', margin:'auto'}}>
                                                    <label className="p-col" style={{textAlign:'right', display:'block'}} htmlFor={"phone"}><strong>Phone</strong></label>
                                                    <div className='p-col-fixed' >
                                                        <InputMask disabled={this.state.disabled} mask='(999) 999-9999' style={{width:'250px'}} value={this.state.detailPhone} type="text" id='phone' onChange={(e) => this.handleNameChange(e)} />
                                                    </div>
                                                </div>
                                                <TextBox value={this.state.detailDomain} id="domain" label={"Domain"} handleFieldChange={this.handleNameChange} disabled={this.state.disabled} threeCol />
                                                <InputSelect value={this.state.detailDept} options={this.state.deptOptions} id="dept" label={"Department"} handleFieldChange={this.handleNameChange} disabled={this.state.disabled} threeCol />
                                            </div>
                                            <div className='p-col-4 p-grid'>
                                                <TextBox value={this.state.detailEmployeeId} id="employeeId" label={"Employee ID"} handleFieldChange={this.handleNameChange} disabled={this.state.disabled} threeCol />                                                                                                                                    
                                                <InputSelect key={this.state.detailSecurityRole} 
                                                    value={this.state.detailSecurityRole} id={"securityrole"} label={"Security Role"} 
                                                    options={this.state.roleOptions} disabled={this.state.disabled}
                                                    handleFieldChange={this.handleNameChange} threeCol
                                                />
                                                <TextBox value={this.state.detailNotes} id="notes" label={"Notes"} handleFieldChange={this.handleNameChange} disabled={this.state.disabled} area threeCol />
                                            </div>  
                                        </div>
                                        <div className='p-col-3 p-grid'>
                                            <div className='p-col-12 p-grid'>
                                                <div className='p-col-4'>
                                                    {imgState==='' ? 
                                                        (<div>
                                                            {(this.state.secRole==='2') ? (null) : (
                                                                <div>
                                                                    <FileUpload name="profile pic" customUpload uploadHandler={this.myUploader} mode="basic" auto/>
                                                                    <Button onClick={this.handleWebcam} label='Camera' icon='pi pi-camera' />
                                                                </div>
                                                            )}
                                                        </div>    
                                                        ) : 
                                                        (null) }
                                                </div>
                                                {imgState==='' ? 
                                                    (<img className='p-col-8' alt='profile pic' src={finalImg} style={{ maxWidth:'250px', maxHeight:'250px' }}/>) :
                                                    <div>
                                                        {this.state.webcamOn ? 
                                                            (<div>
                                                                <div>
                                                                    <Webcam audio={false} ref={this.setWebcamRef} screenshotFormat="image/jpeg" style={{maxWidth: '375px', maxHeight:'275px'}} />
                                                                </div>
                                                                <Button label='Capture' icon='pi pi-camera' onClick={this.capture} style={{ marginRight: '10px' }}/>
                                                                <Button label='Cancel' onClick={this.cancelWebcam} />
                                                            </div>) : 
                                                            (
                                                            <div>
                                                                <AvatarEditor
                                                                    ref={this.setEditorRef}
                                                                    image={imgState}
                                                                    width={250}
                                                                    height={250}
                                                                    border={50}
                                                                    color={[123, 123, 123, 0.6]}
                                                                    scale={(this.state.scaleSlider/10)}
                                                                    rotate={this.state.rotateImg}
                                                                />
                                                                <div>
                                                                    <Slider value={this.state.scaleSlider} onChange={(e) => this.handleSliderChange(e)} min={2} max={30} step={1} />
                                                                </div>
                                                                <div>
                                                                    <Button label="Left" style={{marginRight:'10px'}} onClick={this.handleLeftRotate} />
                                                                    <Button label="Right" onClick={this.handleRightRotate} />
                                                                </div>
                                                            </div>
                                                            )
                                                        }
                                                    </div>
                                                }   
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </BrowserView>
                            <MobileView>
                                <div className='p-col-12 p-grid'>
                                    <div className='p-col-4'>
                                        {imgState==='' && this.state.secRole==='3' ? 
                                            (<div>
                                                <FileUpload name="profile pic" customUpload uploadHandler={this.myUploader} mode="basic" auto/>
                                                <Button onClick={this.handleWebcam} label='Camera' icon='pi pi-camera' />
                                            </div>    
                                            ) : 
                                            (null) }
                                    </div>
                                    {imgState==='' ? 
                                        (<img className='p-col-8' alt='profile pic' src={finalImg} style={{ maxWidth:'250px', maxHeight:'250px' }}/>) :
                                        [(this.state.webcamOn ? 
                                            (<div>
                                                <div><Webcam audio={false} ref={this.setWebcamRef} screenshotFormat="image/jpeg" style={{maxWidth: '375px', maxHeight:'275px'}} /></div>
                                                <Button label='Capture' icon='pi pi-camera' onClick={this.capture} style={{ marginRight: '10px' }}/>
                                                <Button label='Cancel' onClick={this.cancelWebcam} />
                                            </div>) : 
                                            (
                                            <div>
                                                <AvatarEditor
                                                    ref={this.setEditorRef}
                                                    image={imgState}
                                                    width={250}
                                                    height={250}
                                                    border={50}
                                                    color={[123, 123, 123, 0.6]}
                                                    scale={(this.state.scaleSlider/10)}
                                                    rotate={this.state.rotateImg}
                                                />
                                                <div>
                                                    <Slider value={this.state.scaleSlider} onChange={(e) => this.handleSliderChange(e)} min={2} max={30} step={1} />
                                                </div>
                                                <div>
                                                    <Button label="Left" style={{marginRight:'10px'}} onClick={this.handleLeftRotate} />
                                                    <Button label="Right" onClick={this.handleRightRotate} />
                                                </div>
                                            </div>
                                            )
                                        )]
                                    }   
                                </div>
                                <div className='p-col-12 p-grid'><label className='p-col-4'><strong>Organization</strong></label><label className='p-col-8'>{this.state.orgID}</label></div>
                                <div className='p-col-12 p-grid'><label className='p-col-4'><strong>First Name</strong></label><InputText className='p-col-8' value={this.state.detailFName} type="text" id='fname' onChange={(e) => this.handleNameChange(e)} disabled={this.state.disabled} /></div>
                                <div className='p-col-12 p-grid'><label className='p-col-4'><strong>Last Name</strong></label><InputText className='p-col-8' value={this.state.detailLName} type="text" id='lname' onChange={(e) => this.handleNameChange(e)} disabled={this.state.disabled} /></div>
                                <div>{this.state.nameError ? (<div style={{color: 'red'}}>Name fields can not be blank</div>) : (null) }</div>
                                <div className='p-col-12 p-grid'><label className='p-col-4'><strong>Email</strong></label><InputText className='p-col-8' value={this.state.detailEmail} type="email" id='email' onChange={(e) => this.handleNameChange(e)} disabled={this.state.disabled} /></div>
                                <div>{this.state.emailError ? (<div style={{color: 'red'}}>Must enter a valid email</div>) : (null) }</div>
                                <div className='p-col-12 p-grid'><label className='p-col-4'><strong>Phone</strong></label><InputMask mask='(999) 999-9999' className='p-col-8' value={this.state.detailPhone} type="text" id='phone' onChange={(e) => this.handleNameChange(e)}  disabled={this.state.disabled} /></div>
                                <div className='p-col-12 p-grid'><label className='p-col-4'><strong>Employee ID</strong></label><InputText className='p-col-8' value={this.state.detailEmployeeId} type="text" id='employeeId' onChange={(e) => this.handleNameChange(e)}  disabled={this.state.disabled} /></div>    
                                <div className='p-col-12 p-grid'><label className='p-col-4'><strong>Title</strong></label><InputText className='p-col-8' value={this.state.detailTitle} type="text" id='title' onChange={(e) => this.handleNameChange(e)} disabled={this.state.disabled} /></div>
                                <div className='p-col-12 p-grid'><label className='p-col-4'><strong>Domain</strong></label><select className='p-col-8' value={this.state.detailDomain} id='domain' onChange={(e) => this.handleNameChange(e)} disabled={this.state.disabled} /></div>
                                <div className='p-col-12 p-grid'><label className='p-col-4'><strong>Security Role</strong></label><select className='p-col-8' disabled={this.state.disabled} onChange={(e) => this.handleNameChange(e)} value={this.state.detailSecurityRole} id='securityrole'>{this.state.roleOptions.map((roleOption) => <option key={roleOption.value} value={roleOption.value}>{roleOption.label}</option>)}</select></div>
                                <div className='p-col-12 p-grid'><label className='p-col-4'><strong>Status</strong></label><select className='p-col-8' value={this.state.detailStatus} id='status' onChange={(e) => this.handleNameChange(e)} disabled={this.state.disabled} /></div>
                                <div className='p-col-12 p-grid'><label className='p-col-4'><strong>Department</strong></label><select className='p-col-8' style={{marginLeft:'0px'}} value={this.state.detailDept} type="text" id='dept' onChange={(e) => this.handleNameChange(e)} disabled={this.state.disabled} /></div>
                                <div className='p-col-12 p-grid'><label className='p-col-4'><strong>Notes</strong></label><InputTextarea rows={1} className='p-col-8' value={this.state.detailNotes} type="text" id='notes' onChange={(e) => this.handleNameChange(e)} disabled={this.state.disabled} /></div>
                                <div>{this.state.saveError ? (<div style={{color: 'red'}}>{this.state.msg}</div>) : (null)}</div>
                            </MobileView>
                            <div>
                                <div>
                                    {((this.state.keyData===undefined || this.state.newUserEntry || this.state.keyData.length===0 || this.state.keySecRole===0) && !this.state.keysIsLoading) ? (null) :
                                        (<PeopleKeysDatatable keyData={this.state.keyData} isLoading={this.state.keysIsLoading}/>)
                                    }
                                </div>
                                <div>
                                    {(this.state.newUserEntry===false && this.state.keyData.length===0 && !this.state.keysIsLoading) ? 
                                        (<h3>This user currently has no keys assigned</h3>)
                                        :
                                        (null)
                                    }
                                </div>
                            </div>
                        </Dialog>
                        <Dialog header={'Delete ' + this.state.detailFName + ' ' + this.state.detailLName} visible={displayDelete} 
                            onHide={this.handleDeleteClose} footer={deleteFooter} baseZIndex={1400} closable={false} focusOnShow={false}
                        >
                            <div>
                                <div>Are you sure you would like to delete '{this.state.detailFName + ' ' + this.state.detailLName}'? </div>
                            </div>
                        </Dialog>
                    </div>
                </div>
            );
        }
    }
}

export default People;