import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import {RadioButton} from 'primereact/radiobutton';
import { JobsService, RecordsService } from '../services/FetchServices';
import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import { BrowserView, MobileView } from 'react-device-detect';

export class AddJobDetailsTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            records: "",
            editingRows: {},
            globalFilter: null
        };

        this.onRowEditChange = this.onRowEditChange.bind(this);
        this.createObject = this.createObject.bind(this);
        this.clearRecords = this.clearRecords.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.renderMobileHeader = this.renderMobileHeader.bind(this);
        this.bodyTemplate = this.bodyTemplate.bind(this);
        this.pathLookup = this.pathLookup.bind(this);
    }

    async componentDidMount() {
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
        await RecordsService.fetchRecords( parsedUserData.uiU_M1ORGID, 0, this.props.system )
        .then(data => this.createObject(data.recordlist));
    }

    async clearRecords() {
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
        await RecordsService.fetchRecords( parsedUserData.uiU_M1ORGID, 0, this.props.system )
        .then(data => this.createObject(data.recordlist));
    }

    createObject(data) {
        let tempArray = [];
        let records = [...data];
        let editingRows;

        for(let i=0; i<data.length; i++) {
            tempArray.push(
                {
                    "recordID": data[i].recordID,
                    "orgID": data[i].orgID,
                    "coreMark": data[i].coreMark,
                    "keyMark": data[i].keyMark,
                    "coreMarkID": data[i].coreMarkID,
                    "keyMarkID": data[i].keyMarkID,
                    "system": data[i].system,
                    "keysCut": data[i].keysCut,
                    "coresPinned": data[i].coresPinned,
                    "keyLevel": data[i].keyLevel,
                    "keySeries": data[i].keySeries,
                    "description": data[i].description,
                    "notes": data[i].notes,
                    "bittings": data[i].bittings,
                    "keyQty": "",
                    "coreQty": "",
                    "coreType": "",
                    "keyway": data[i].keyway
                }
            );
            editingRows = { ...editingRows, ...{ [`${records[i].id}`]: true } };
        }

        this.setState({records: tempArray, editingRows})
    }

   onRowEditChange(event) {
        this.setState({ editingRows: event.data });
    }

    onEditorValueChange(props, value) {
        if(props.field==="coreQty"&&value!==''&&(this.state.records[props.rowIndex]['coreType']==='' || this.state.records[props.rowIndex]['coreType']===undefined)) {
            let updatedRecords = [...props.value];
            updatedRecords[props.rowIndex][props.field] = value;
            updatedRecords[props.rowIndex]['coreType'] = 'SFIC';
            this.setState({ records: updatedRecords });
        } else if(props.field==='coreQty'&&value==='') {
            let updatedRecords = [...props.value];
            updatedRecords[props.rowIndex][props.field] = value;
            updatedRecords[props.rowIndex]['coreType'] = '';
            this.setState({ records: updatedRecords });
        } else {
            let updatedRecords = [...props.value];
            updatedRecords[props.rowIndex][props.field] = value;
            this.setState({ records: updatedRecords });
        }
    }

    inputTextEditor(props, field) {
        return (
            <div>
                <BrowserView>
                    <InputText keyfilter="pint" type="text" value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(props, e.target.value)} style={{textAlign:'center', padding:'0.15rem 0.429rem', fontSize:'small'}} />
                </BrowserView>
                <MobileView>
                    <span style={{marginRight: '10px'}}><strong>{props.header}</strong></span><InputText keyfilter="pint" type="text" value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(props, e.target.value)} style={{textAlign:'center', maxWidth:'80px'}} />
                </MobileView>
            </div>
        );
    }

    radioEditor(props, field) {
        return( 
            <div>
                <BrowserView>
                    <div style={{display:'inline-block', marginRight:'5px'}}>
                        <RadioButton style={{width:'20px'}} inputId="rb1" name="coreType" value={props.rowData[field]} 
                            onChange={(e) => this.onEditorValueChange(props, "SFIC")} checked={props.rowData[field] === 'SFIC'}
                        />
                        <label htmlFor="rb1" className="p-radiobutton-label">SFIC</label>
                    </div>
                    <div style={{display:'inline-block', marginRight:'5px'}}>
                        <RadioButton  style={{width:'20px'}} inputId="rb2" name="coreType" value={props.rowData[field]} 
                            onChange={(e) => this.onEditorValueChange(props, "CAM")} checked={props.rowData[field] === 'CAM'}
                        />
                        <label htmlFor="rb2" className="p-radiobutton-label">CAM</label>
                    </div>
                    <div style={{display:'inline-block', marginRight:'5px'}}>
                        <RadioButton  style={{width:'20px'}} inputId="rb3" name="coreType" value={props.rowData[field]} 
                            onChange={(e) => this.onEditorValueChange(props, "FIXED")} checked={props.rowData[field] === 'FIXED'}
                        />
                        <label htmlFor="rb3" className="p-radiobutton-label">FIXED</label>
                    </div>
                </BrowserView>
                <MobileView>
                    <div style={{display:'inline-block', marginRight:'5px'}}>
                        <span style={{marginRight:'10px'}}><strong>{props.header}</strong></span>
                        <RadioButton style={{width:'20px'}} inputId="rb1" name="coreType" value={props.rowData[field]} 
                            onChange={(e) => this.onEditorValueChange(props, "SFIC")} checked={props.rowData[field] === 'SFIC'}
                        />
                        <label htmlFor="rb1" className="p-radiobutton-label">SFIC</label>
                    </div>
                    <div style={{display:'inline-block', marginRight:'5px'}}>
                        <RadioButton  style={{width:'20px'}} inputId="rb2" name="coreType" value={props.rowData[field]} 
                            onChange={(e) => this.onEditorValueChange(props, "CAM")} checked={props.rowData[field] === 'CAM'}
                        />
                        <label htmlFor="rb2" className="p-radiobutton-label">CAM</label>
                    </div>
                    <div style={{display:'inline-block', marginRight:'5px'}}>
                        <RadioButton  style={{width:'20px'}} inputId="rb3" name="coreType" value={props.rowData[field]} 
                            onChange={(e) => this.onEditorValueChange(props, "FIXED")} checked={props.rowData[field] === 'FIXED'}
                        />
                        <label htmlFor="rb3" className="p-radiobutton-label">FIXED</label>
                    </div>
                </MobileView>
            </div>
        );
    }

    keyQtyEditor(props) {
        if(props.rowData.keyMark==="") {
            return;
        } else {
            return this.inputTextEditor(props, 'keyQty');
        }
    }

    coreQtyEditor(props) {
        if(props.rowData.coreMark==="") {
            return;
        } else {
            return this.inputTextEditor(props, 'coreQty');
        }
    }

    coreTypeEditor(props) {
        if(props.rowData.coreMark==="") {
            return;
        } else {
            return this.radioEditor(props, 'coreType');
        }
    }

    handleClose() {
        this.clearRecords();
        this.props.handleCloseDetails();
    }

    async handleSave() {
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
        let jobDetails = [];
        for(let i=0; i<this.state.records.length; i++) {
            if(this.state.records[i].coreQty!=="" && this.state.records[i].keyQty!=="") {
                jobDetails.push(
                    {
                        "woD_HEADERID": this.props.jobID,
                        "woD_M1ORGID": parsedUserData.uiU_M1ORGID,
                        "woD_SYSTEM": this.state.records[i].system,
                        "KeyMarkID": this.state.records[i].keyMarkID,
                        "KeyQty": parseInt(this.state.records[i].keyQty, 10),
                        "CoreMarkID": this.state.records[i].coreMarkID,
                        "CoreQty": parseInt(this.state.records[i].coreQty, 10),
                        "CoreType": this.state.records[i].coreType,
                        "WOD_RECORDDESC": this.state.records[i].description
                    }
                )
            } else if(this.state.records[i].coreQty==="" && this.state.records[i].keyQty!=="") {
                jobDetails.push(
                    {
                        "woD_HEADERID": this.props.jobID,
                        "woD_M1ORGID": parsedUserData.uiU_M1ORGID,
                        "woD_SYSTEM": this.state.records[i].system,
                        "KeyMarkID": this.state.records[i].keyMarkID,
                        "KeyQty": parseInt(this.state.records[i].keyQty, 10),
                        "CoreMarkID": this.state.records[i].coreMarkID,
                        "CoreQty": 0,
                        "CoreType": "",
                        "WOD_RECORDDESC": this.state.records[i].description
                    }
                )
            } else if(this.state.records[i].coreQty!=="" && this.state.records[i].keyQty==="") {
                jobDetails.push(
                    {
                        "woD_HEADERID": this.props.jobID,
                        "woD_M1ORGID": parsedUserData.uiU_M1ORGID,
                        "woD_SYSTEM": this.state.records[i].system,
                        "KeyMarkID": this.state.records[i].keyMarkID,
                        "KeyQty": 0,
                        "CoreMarkID": this.state.records[i].coreMarkID,
                        "CoreQty": parseInt(this.state.records[i].coreQty, 10),
                        "CoreType": this.state.records[i].coreType,
                        "WOD_RECORDDESC": this.state.records[i].description
                    }
                )
            }
        }

        await JobsService.addJobDetails(this.props.jobID, parsedUserData.uiU_M1ORGID, jobDetails );
        this.props.fetchJobDetails(this.props.jobID);
        this.handleClose();
    }

    renderMobileHeader() {
        return(
            <div>
                <div className='table-title'>{'Add Job Details'}</div>
                <div><InputText type ='search' onInput={(e) => this.setState({globalFilter: e.target.value})} placeholder='Search' /></div>
            </div>
        )
    }
    
    renderDetailsFooter() {
        return(
            <div>
                <Button type='button' icon='pi pi-check' label='Update' onClick={() => this.handleSave()} />   
                <Button type='button' icon='pi pi-times' label='Cancel' onClick={() => this.handleClose()} />
                <Button type='button' icon='pi pi-ban' label='Clear' onClick={() => this.clearRecords()} />
            </div>
        );
    }

    pathLookup(obj, path) {
        let parts = path.split(".");
        if (parts.length===1){
            return obj[parts[0]];
        }
        return this.pathLookup(obj[parts[0]], parts.slice(1).join("."));
    }

    bodyTemplate(rowData, field, header) {
        if(field==='keyQty' || field==='coreQty' || field==='coreType') {
            return(
                <React.Fragment>
                    <span className='p-column-title'>{header}</span>
                </React.Fragment>

            );
        } else {
            return(
                <React.Fragment>
                    <span className='p-column-title'>{header}</span>
                    {this.pathLookup(rowData,field.toString())}
                </React.Fragment>

            );
        }
    }

    render() {
        const detailsFooter=this.renderDetailsFooter();
        const mobileHeader = this.renderMobileHeader();
        return (
            <div>
                <BrowserView>
                    <Dialog header='Add Job Details' visible={this.props.jobDetails} onHide={this.handleCloseDetails} footer={detailsFooter} baseZIndex={1300} closable={false} maximized focusOnShow={false}>
                        <DataTable value={this.state.records} editMode="row" dataKey="id" editingRows={this.state.editingRows} onRowEditChange={this.onRowEditChange}
                            className='p-datatable-striped p-datatable-sm' paginator rows={50} emptyMessage="No Records found" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[10,25,50]}
                            scrollable scrollHeight="71vh"
                        >
                            <Column field="keyMark" header="Key Marking" sortable filter filterPlaceholder="Key Marking"style={{fontSize: 'small', margin:'0px'}} />
                            <Column field="keyLevel" header="Key Level" sortable filter filterPlaceholder="Key Level"style={{fontSize: 'small', margin:'0px'}} />
                            <Column field="keyway" header="Keyway" sortable filter filterPlaceholder="Keyway"style={{fontSize: 'small', margin:'0px'}} />
                            <Column field="keysCut" header="Keys Cut" sortable filter filterPlaceholder="Keys Cut" style={{fontSize: 'small', margin:'0px', textAlign:'center'}} />
                            <Column field="keyQty" header="Key QTY" editor={(props) => this.keyQtyEditor(props)} style={{fontSize: 'small', margin:'0px', width:'7em'}}></Column>
                            <Column field="coreMark" header="Core Marking" sortable filter filterPlaceholder="Core Marking"style={{fontSize: 'small', margin:'0px'}} />
                            <Column field="coresPinned" header="Cores Pinned" sortable filter filterPlaceholder="Cores Pinned" style={{fontSize: 'small', margin:'0px', textAlign:'center'}} />
                            <Column field="coreQty" header="Core QTY" editor={(props) => this.coreQtyEditor(props)} style={{fontSize: 'small', margin:'0px', width:'7em'}}></Column>
                            <Column field="coreType" header="Core Type" editor={(props) => this.coreTypeEditor(props)} style={{fontSize: 'small', margin:'0px', width:'15em'}} />
                            <Column field="description" header="Description" sortable filter filterPlaceholder="Description" style={{fontSize: 'small', margin:'0px', width:'13em'}} />
                        </DataTable>
                    </Dialog>
                </BrowserView>
                <MobileView>
                    <Dialog header='Add Job Details' visible={this.props.jobDetails} onHide={this.handleCloseDetails} footer={detailsFooter} baseZIndex={1300} closable={false} maximized focusOnShow={false}>
                        <div className='datatable-responsive-mobile'>
                            <div className='card'>
                                <DataTable className='p-datatable-responsive-mobile' style={{ marginBottom:'40px' }}
                                    value={this.state.records} 
                                    header={mobileHeader} rows={50}
                                    paginator={true} paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                                    globalFilter={this.state.globalFilter} emptyMessage="No records found" 
                                    editingRows={this.state.editingRows} onRowEditChange={this.onRowEditChange}
                                    editMode="row" dataKey="id"
                                >
                                    <Column field="keyMark" header="Key Marking" body={(e) => this.bodyTemplate(e, 'keyMark', 'Key Marking')} />
                                    <Column field="keyLevel" header="Key Level" body={(e) => this.bodyTemplate(e, 'keyLevel', 'Key Level')} />
                                    <Column field="keyway" header="Keyway" body={(e) => this.bodyTemplate(e, 'keyway', 'Keyway')}/>
                                    <Column field="keysCut" header="Keys Cut" body={(e) => this.bodyTemplate(e, 'keysCut', 'Key Cut')} />
                                    <Column field="keyQty" header="Key QTY" editor={(props) => this.keyQtyEditor(props)} body={(e) => this.bodyTemplate(e, 'keyQty', 'Key QTY')} ></Column>
                                    <Column field="coreMark" header="Core Marking" body={(e) => this.bodyTemplate(e, 'coreMark', 'Core Marking')} />
                                    <Column field="coresPinned" header="Cores Pinned" body={(e) => this.bodyTemplate(e, 'coresPinned', 'Cores Pinned')} />
                                    <Column field="coreQty" header="Core QTY" editor={(props) => this.coreQtyEditor(props)} body={(e) => this.bodyTemplate(e, 'coreQty', 'Core QTY')} ></Column>
                                    <Column field="coreType" header="Core Type" editor={(props) => this.coreTypeEditor(props)} body={(e) => this.bodyTemplate(e, 'coreType', 'Core Type')} />
                                    <Column field="description" header="Description" body={(e) => this.bodyTemplate(e, 'description', 'Description')} />
                                </DataTable>
                            </div>
                        </div>
                    </Dialog>
                </MobileView>
            </div>
        );
    }
}
                
export default AddJobDetailsTable;