import React from "react";
import { Redirect } from "react-router-dom";

import publicIp from 'public-ip';

import { userService } from '../services/Auth';
import { TableService } from '../services/FetchServices';
import Eula from '../components/Eula';
import MWlogo from '../images/MWlogo.png';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import InputAdornment from '@material-ui/core/InputAdornment';
import WorkIcon from '@material-ui/icons/Work';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import LockIcon from '@material-ui/icons/Lock';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from "@material-ui/core/SnackbarContent";
import blueGrey from '@material-ui/core/colors/blueGrey';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';
import { ThemeProvider } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.mwspi.com/" target='_blank' rel='noopener'>
        Midwest Security Products Inc.
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

const loginTheme = createMuiTheme({
  palette: {
    primary: {
      main: blueGrey[500],
    },
    secondary: {
      main: grey[300],
    },
    error: {
      main: red[600]
    }
  }
});

const styles = loginTheme => ({
  paper: {
    marginTop: loginTheme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  form: {
    width: '100%', 
    marginTop: loginTheme.spacing(1),
  },
  submitButton: {
    margin: loginTheme.spacing(3, 0, 2, 0)
  },
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: loginTheme.spacing(2),
    },
  },
  loading: {
    display: 'flex',
    '& > * + *': {
      marginLeft: loginTheme.spacing(2),
    },
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    margin: loginTheme.spacing(3, 0, 2, 0),
  },
  eulaClass: {
    alignItems: 'center',
    textAlign: 'center',
  },
  h3Class: {
    alignItems: 'center',
    textAlign: 'center',
    color: 'black',
  }
});

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      toLandingPage: false,
      landingPageNum: null,
      toRecoverPassword: false,
      errorMsg: false,
      isLoading: false,
      userId: "",
      token: false,
      otpIsOpen: "",
      otpPassword: '',
      tosAccepted: true,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.onSubmitAuth = this.onSubmitAuth.bind(this);
    this.performLogin = this.performLogin.bind(this);
  }

  handleInputChange(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }

  onSubmitAuth = async e => {
    e.preventDefault();
    this.setState({ isLoading: true });

    let ipAddr = await publicIp.v4();

    await userService.login(this.state.username, this.state.password, this.state.orgId, ipAddr)
    
    let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
    const landingPageVar = parsedUserData.landingPage;
    try {        
      //token or secString cannot be empty
      if(parsedUserData.token !== "" && parsedUserData.secString!=="") {
        await TableService.fetchTableState(parsedUserData.uiU_M1ORGID, parsedUserData.uiU_ID, 2);
        await TableService.fetchTableState(parsedUserData.uiU_M1ORGID, parsedUserData.uiU_ID, 3);
        await TableService.fetchTableState(parsedUserData.uiU_M1ORGID, parsedUserData.uiU_ID, 6);
        await TableService.fetchTableState(parsedUserData.uiU_M1ORGID, parsedUserData.uiU_ID, 7);
        await TableService.fetchTableState(parsedUserData.uiU_M1ORGID, parsedUserData.uiU_ID, 15);
        await TableService.fetchTableState(parsedUserData.uiU_M1ORGID, parsedUserData.uiU_ID, 22);
        await TableService.fetchTableState(parsedUserData.uiU_M1ORGID, parsedUserData.uiU_ID, 23);
        await TableService.fetchTableState(parsedUserData.uiU_M1ORGID, parsedUserData.uiU_ID, 24);
        await TableService.fetchTableState(parsedUserData.uiU_M1ORGID, parsedUserData.uiU_ID, 20);
        await TableService.fetchTableState(parsedUserData.uiU_M1ORGID, parsedUserData.uiU_ID, 9);
        await TableService.fetchTableState(parsedUserData.uiU_M1ORGID, parsedUserData.uiU_ID, 4);
        await TableService.fetchTableState(parsedUserData.uiU_M1ORGID, parsedUserData.uiU_ID, 31);
        await TableService.fetchTableState(parsedUserData.uiU_M1ORGID, parsedUserData.uiU_ID, 11);
        await TableService.fetchTableState(parsedUserData.uiU_M1ORGID, parsedUserData.uiU_ID, 35);
        await TableService.fetchTableState(parsedUserData.uiU_M1ORGID, parsedUserData.uiU_ID, 37);
        await TableService.fetchTableState(parsedUserData.uiU_M1ORGID, parsedUserData.uiU_ID, 38);
        await userService.getLicense(parsedUserData.uiU_M1ORGID);
        this.setState({
          userId: parsedUserData.uiU_ID,
          token: parsedUserData.token,
          otpIsOpen: parsedUserData.uiU_OTP_VAL,
          tosAccepted: parsedUserData.uiU_TOS,
          isLoading: false,
          errorMsg: false,
          landingPageNum: landingPageVar
        }); 
        if(parsedUserData.uiU_TOS===true && parsedUserData.uiU_OTP_VAL==="") {
          this.setState({ 
            toLandingPage: true,
            landingPageNum: landingPageVar
          });
        } else {
          this.setState({ 
            eulaOpen: true,
            landingPageNum: landingPageVar
          })
        }
      } else {
        this.setState({
          errorMsg: true,	
          isLoading: false,	
          orgId:"",	
          username:"",
          password:""
        });
      }	
    } catch (error) {
      console.log(error);
    }
  };

  performLogin = async e => {
    e.preventDefault();

    this.setState({ isLoading: true })

    await userService.authOTP(this.state.userId, this.state.otpPassword, this.state.orgId)
    let otpData = sessionStorage.getItem('otpData');
    try {
      if (otpData) {
        let parsedOtpData = JSON.parse(otpData);
        if (parsedOtpData.uiU_OTP_VAL === "VALID") {
          if ((this.state.tosAccepted) && (parsedOtpData.token !== "")) {
            this.setState({
            eulaOpen: false,	
            otpIsOpen: false,	
            isLoading: false,
            toLandingPage: true
            });
          } else {
            this.setState({
              eulaOpen: true,
              otpIsOpen: false,	
              isLoading: false,
            });
        }
        } else {
          this.setState({
            errorMsg: true,	
            isLoading: false,	
            otpPassword:"",
          });
        }
      }
    } catch (error) {
      console.log(error);
      this.setState({ isLoading: false });
    }
  }

  handleAcceptEula = async e => {
    e.preventDefault();

    this.setState({ isLoading: true })

    await userService.acceptTOS(this.state.userId, this.state.orgId)
    let parsedTOSData = JSON.parse(sessionStorage.getItem('TOSData'));
    try {
      if (parsedTOSData.token !== "") {
        this.setState({
          toLandingPage: true,	
          eulaOpen: false,	
        });
      }
    } catch (error) {
    }
  };

  handleCancelEula = () => {
    userService.logout();
    this.setState({ eulaOpen: false, toLogin: true })
    sessionStorage.removeItem('otpData');
    sessionStorage.removeItem('userData');
  };

  handleClickClose = () => {
    this.setState({
      otpIsOpen: false,	
      isLoading: false,	
      orgId:"",	
      username:"",
      password:"",
    });
    sessionStorage.removeItem('otpData');
    sessionStorage.removeItem('userData');
  }

  handleRecoverPassword = () => {
    this.setState({ toRecoverPassword: true })
  }

  render() {
    const { classes } = this.props;
    const { eulaOpen, otpIsOpen, toRecoverPassword, isLoading, toLandingPage, errorMsg, otpPassword, landingPageNum } = this.state;
    if (toRecoverPassword) {
        return(
            <Redirect to='/recoverPassword' />
        );
    } else if (otpIsOpen === "YES"){
      return(
        // <ConsoleLog />
        <ThemeProvider theme={loginTheme}>
          <div>
            {errorMsg ? (
                <div className={classes.root}>
                  <Snackbar 
                    open={errorMsg} 
                    anchorOrigin={{vertical: 'top', horizontal: 'center' }}
                  >	
                    <SnackbarContent 
                      severity="error" 
                      elevation={6} 
                      variant='outlined' 
                      message='Invalid Credentials'
                      style={{backgroundColor:'red'}}
                    />	
                  </Snackbar>
              </div>
              ) : (
                null
              )  
              }
            <Dialog
              open={otpIsOpen === "YES"}
              disableBackdropClick
              disableEscapeKeyDown
            >
            <DialogTitle id="form-dialog-title">One Time Password</DialogTitle>
            <form onSubmit={this.performLogin}>
              <DialogContent>
                <DialogContentText>
                  A one time password has been sent to your email, please enter it
                  here:
                </DialogContentText>
                <TextField  
                  autoFocus
                  margin="dense"
                  id="otp"
                  label="One Time Password"
                  name="otpPassword"
                  type="password"
                  value={otpPassword}
                  onChange={this.handleInputChange}
                  fullWidth
                  required
                />
              </DialogContent>
              {
                isLoading ? (
                  <div className={classes.loading}>
                    <CircularProgress />
                  </div>
                ) : (
                  <div>
                    <DialogActions>
                      <Button onClick={this.handleClickClose} color="primary">
                        Cancel
                      </Button>
                      <Button type='submit' color="primary">
                        Submit
                      </Button>
                    </DialogActions>
                </div>
                )
              }
            </form>
          </Dialog>
        </div>
      </ThemeProvider>
      );
    } else if(eulaOpen){
      return(
        <ThemeProvider theme={loginTheme}>
          <div>
              <Dialog open={eulaOpen} scroll="body">                       
                  <DialogTitle id="scroll-dialog-title" className={classes.eulaClass}>
                      <h3>
                          LockViewer Enterprise EULA and Terms of Service                        
                      </h3>
                      <h3 className={classes.h3Class}>Scroll Down To Accept</h3>
                  </DialogTitle>
                      <DialogContent>
                          <DialogContentText id="scroll-dialog-description">
                              <Eula />
                          </DialogContentText>
                      </DialogContent>
                    {
                      isLoading ? (
                        <div className={classes.loading}>
                          <CircularProgress />
                        </div>
                      ) : (
                            <DialogActions>
                                <Button onClick={this.handleCancelEula} color="primary">
                                    Cancel
                                </Button>
                                <Button onClick={this.handleAcceptEula} color="primary">
                                    Accept
                                </Button>
                            </DialogActions>
                          )
                    }
              </Dialog>
          </div>
        </ThemeProvider>
      );
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    } else if(toLandingPage&&landingPageNum===0) {
      return(
        <Redirect to='/keyslost' />
      );
    } else if(toLandingPage&&landingPageNum===1) {
        return(
          <Redirect to='/people' />
        );
    } else if(toLandingPage&&landingPageNum===2) {
      return(
        <Redirect to='/keys' />
      );
    } else if(toLandingPage&&landingPageNum===3) {
      return(
        <Redirect to='/buildings' />
      );
    } else if(toLandingPage&&landingPageNum===4) {
        return(
          <Redirect to='/cores' />
        );
    } else if(toLandingPage&&landingPageNum===5) {
      return(
        <Redirect to='/records' />
      );
    } else if(toLandingPage&&landingPageNum===6) {
      return(
        <Redirect to='/jobs' />
      );
    } else if(toLandingPage&&landingPageNum===7) {
        return(
          <Redirect to='/keystorage' />
        );
    } else if(toLandingPage&&landingPageNum===8) {
      return(
        <Redirect to='/buildinggroups' />
      );
    } else if(toLandingPage&&landingPageNum===9) {
      return(
        <Redirect to='/departments' />
      );
    } else if(toLandingPage&&landingPageNum===10) {
        return(
          <Redirect to='/mastersystems' />
        );
    } else if(toLandingPage&&landingPageNum===11) {
      return(
        <Redirect to='/credentials' />
      );
    } else if(toLandingPage&&landingPageNum===12) {
      return(
        <Redirect to='/credentialtypes' />
      );
  } else if(toLandingPage&&landingPageNum===13) {
    return(
      <Redirect to='/keyrings' />
    );
    } else if(toLandingPage&&landingPageNum===14) {
      return(
        <Redirect to='/securityroles' />
      );
    } else if(toLandingPage&&landingPageNum===15) {
      return(
        <Redirect to='/keyhandin' />
      );
    } else if(toLandingPage&&landingPageNum===16) {
      return(
        <Redirect to='/keyspastdue' />
      );
    } else if(toLandingPage&&landingPageNum===17) {
        return(
          <Redirect to='/reports' />
        );
    } else if(toLandingPage&&landingPageNum===19) {
      return(
        <Redirect to='/doors' />
      );
  } else if(toLandingPage&&landingPageNum===20) {
    return(
      <Redirect to='/domains' />
    );
    } else if(toLandingPage&&landingPageNum===21) {
      return(
        <Redirect to='/devices' />
      );
    } else if(toLandingPage&&landingPageNum===31) {
      return(
        <Redirect to='/keyhandout' />
      );
    } else if(toLandingPage&&landingPageNum===32) {
      return(
        <Redirect to='/keysbroken' />
      );
    } else if(toLandingPage&&landingPageNum===33) {
      return(
        <Redirect to='/keysdestroyed' />
      );
    } else if(toLandingPage&&landingPageNum===34) {
      return(
        <Redirect to='/keyhistory' />
      );
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    } else {
      return (
        <ThemeProvider theme={loginTheme}>
          <div>
            <Container component="main" maxWidth="xs">
              {errorMsg ? (
                <div className={classes.root}>
                  <Snackbar 
                    open={errorMsg} 
                    anchorOrigin={{vertical: 'top', horizontal: 'center' }}
                  >	
                    <SnackbarContent 
                      severity="error" 
                      elevation={6} 
                      variant='outlined' 
                      message='Invalid Credentials'
                      style={{backgroundColor:'red'}}
                    />	
                  </Snackbar>
              </div>
              ) : (
                null
              )  
              }
              <div className={classes.paper}>
                <a href='https://www.mwspi.com/' target='_blank' rel='noopener noreferrer'><img src={MWlogo} alt='logo' width='355' height='128' /></a>
                <Typography variant='h5' color='error' gutterBottom>
                  Solutions. Designed. Delivered.
                </Typography>
                <form onSubmit={this.onSubmitAuth} className={classes.form}>
                  <h1>LockViewer Enterprise
                  </h1>
                  <h2>Sign in</h2>
                  <TextField
                    color='primary'
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="orgId"
                    label="Organization"
                    name="orgId"
                    placeholder="Organization"
                    autoFocus
                    value={this.state.orgId}
                    onChange={this.handleInputChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment posistion="start">
                                <WorkIcon />
                            </InputAdornment>
                        ),
                    }}
                  />
                  <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="username"
                      label="Email"
                      name="username"
                      placeholder="Email"
                      value={this.state.username}
                      onChange={this.handleInputChange}
                      InputProps={{
                          startAdornment: (
                              <InputAdornment posistion="start">
                                  <AccountBoxIcon />
                              </InputAdornment>
                          ),
                      }}
                  />
                  <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      value={this.state.password}
                      onChange={this.handleInputChange}
                      placeholder="Password"
                      InputProps={{
                          startAdornment: (
                              <InputAdornment posistion="start">
                                  <LockIcon />
                              </InputAdornment>
                          ),
                      }}
                  />
                  {
                  isLoading ? (
                    <div className={classes.loading}>
                      <CircularProgress />
                    </div>
                  ) : (
                    <div className={classes.submitButton}>
                      <Button
                        type="submit"
                        size="large"
                        fullWidth
                        variant="contained"
                        color="primary"
                      >
                        Sign In
                      </Button>
                    </div>
                  )
                  }
                  <Box m={2}>
                    <Button onClick={this.handleRecoverPassword}>
                      Forgot password?
                    </Button>
                  </Box>
                </form>
                </div>
            </Container>
            <Copyright />
          </div>
        </ThemeProvider>
      );
    }
  }
}

export default withStyles(styles, { withTheme: true })(Login);