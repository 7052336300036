import React from 'react';

import Layout from '../components/Layout';
import KeyHistoryComponent from '../components/KeyHistoryComponent';

class KeyHistory extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            isLoading: true,
            secRole: '3',
            disabled: false,
        }
    }

    async componentDidMount() {
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));

        if(parsedUserData.secString[9]==='2') {
            this.setState({ disabled: true })
        }
        
        let parsedTableData = JSON.parse(sessionStorage.getItem("keysTableData"));
        if(parsedTableData!==null) {
            if(parsedTableData.selectedColumns !== null) {
                this.setState({ selectedColumns: parsedTableData.selectedColumns })
            }
        }

        this.setState({isLoading: false})
    }

    render() {
        if((this.state.secRole==='' && !this.state.isLoading) || (this.state.secRole==='0' && !this.state.isLoading)) {
            return(<Layout><div>Security Role prevents you from seeing data</div></Layout>)
        } else {
            return (
                <div>
                    <Layout>
                        <KeyHistoryComponent userID={0} keyID={0} />
                    </Layout>
                </div>
            );
        }
    }
}

export default KeyHistory;