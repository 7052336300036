import React from 'react';

import DoorsComponent from '../components/DoorsComponent';

class Doors extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            hasButton: false
        }
    }

    componentDidMount() {
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
        if (parsedUserData.secString[28]==='3') {
            this.setState({hasButton: true})
        }
    }

    render() {
        return(
            <DoorsComponent 
                doorID={0}
                buildingID={0}
                isComponent={false}
                coreMarkID={0}
                keymark={""}
                keyway={""}
                system={""}
                hasButton={this.state.hasButton}
                header={'Doors'}
            />
        );
    }
}

export default Doors;