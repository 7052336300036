import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { userService } from '../services/Auth';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    userService.authUser() ? (
      <Component {...props} />
    ) : (
      <Redirect to={{
        pathname: '/login'
      }} />
    )
  )} />
);

export default PrivateRoute;