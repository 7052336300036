import React from 'react';

import Layout from '../components/Layout';

class Search extends React.Component {

    render(){
        return(
            <Layout>
                <h1>Search</h1>
            </Layout>
        );
    }
}

export default Search;