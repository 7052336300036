import React from "react";
import OrgChart from './OrgChartComponent';

class DomainChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formattedDomainData: []
        };
        this.createDomainObject = this.createDomainObject.bind(this);
        this.drawChart = this.drawChart.bind(this);
    }

    componentDidMount() {
        this.drawChart();
    }

    createDomainObject(id, pid, name, desc) {
        const obj = {};
        
        if(pid===0) {
            obj.id = id;
            obj.Name = name;
            obj.Description = desc;
        } else {
            obj.id = id;
            obj.pid = pid;
            obj.Name = name;
            obj.Description = desc;
        }
        return obj;
    }

    drawChart() {
        let tempArray = [];
        for(let i=0; i<this.props.domainData.length; i++) {
            tempArray.push(
                this.createDomainObject(
                    this.props.domainData[i].domaiN_ID,
                    this.props.domainData[i].parenT_ID,
                    this.props.domainData[i].domain,
                    this.props.domainData[i].description,
                )
            );
        }

        this.setState({ 
            formattedDomainData: tempArray,
        })
    }

    render() {
        return (
            <div style={{height:'100%', border:'1px solid lightgray'}}>
                <OrgChart nodes={this.state.formattedDomainData} pageID={21} />
            </div>
        );
    }
}

export default DomainChart;