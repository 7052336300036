import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  
  title: {
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 'bold',
    marginTop: '15px'
  },
  pinningtitle: {
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: 'bold'
  },
  headerItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent:'space-around',
    borderTop:'1px solid black',
    margin: '15px',
    paddingTop:'15px'
  },
  headerItemContainerNoBorder: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent:'space-around',
    marginBottom:'15px'
  },
  lineItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent:'space-around',
    borderTop:'1px solid black',
    padding: '15px'
  },
  headerItem: {
    fontWeight: 'bold',
    fontSize:'16px'
  },
  // regItem: {
  //   fontSize:'20.8px'
  // },
  section: {
    fontFamily: 'Roboto Mono',
    margin: '10px'
  },
  cuts: {
    margin: '15px',
    textAlign: 'center',
  },
  twoCol: {
    display: 'flex',
    flexDirection:'row',
    justifyContent: 'center'
  },
  pinHeader: {
    fontWeight: 'bold',
    fontSize:'16px',
    margin:'10px'
  },
  cutsHeader: {
    fontWeight: 'bold',
    fontSize:'16px',
    margin:'10px'
  },
  pinningText: {
    fontSize:'18px'
  },
  keyCutStr: {
    fontSize:'10px'
  },
  paginator: {
    textAlign:'center',
    fontWeight: 'bold',
    fontSize:'16px',
    // margin:'10px'
  },
  stamping: {
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: 'bold',
    margin: '20px'
  },
});

Font.register({family: 'Roboto Mono', fonts:[{src: '../assets/fonts/roboto-mono-v13-latin-regular.ttf'}]});

class ViewJobPDF extends React.Component {
  constructor(props) {
    super(props);

    this.state={

    }
}

  componentDidMount() {
    const mappedJobDetails = this.props.jobDetails.map((jobDetails) => {
      if(((jobDetails.woD_TYPE).split(' ', 1))[0]==="CORES") {
        let pinStr = jobDetails.pinning;
        let pinArr = pinStr.split('\r\n');
        let newStr = "";
        // if(pinArr.length>8) {
        //   pinArr.pop();
        //   pinArr.pop();
        // }
        for (let i = 0; i < pinArr.length-1; i++) {
          let changeStr = pinArr[i];
          if(changeStr[0] === " ") {
            changeStr = changeStr.replace(" ", "\r\n\u00A0");
          } else if(i!==0){
            changeStr = "\r\n" + changeStr;
          }
          newStr = newStr + changeStr;          
        }
        return(
          <Page key={jobDetails.woD_ID} size="A4" orientation="landscape" wrap={false}>
            <View>
              <View>
                <Text style={styles.title}>Job {jobDetails.woD_HEADERID}</Text>
                <View>
                  <View style={styles.headerItemContainer}>
                    <Text style={styles.headerItem}>Reference Number: {this.props.refNum}</Text>
                    <Text style={styles.headerItem}>Due Date: {this.props.dateDue}</Text>
                    <Text style={styles.headerItem}>Status: {this.props.status}</Text>
                  </View>
                  <View style={styles.headerItemContainerNoBorder}>
                    <Text style={styles.headerItem}>PO # {this.props.poNum}</Text>
                    <Text style={styles.headerItem}>Desc: {this.props.desc}</Text>
                    <Text style={styles.headerItem}>Notes: {this.props.notes}</Text>
                  </View>
                  <View style={styles.lineItemContainer}>
                    <Text style={styles.headerItem}>Line: {jobDetails.woD_ID}</Text>
                    <Text style={styles.headerItem}>Cores</Text>
                    <Text style={styles.headerItem}>{jobDetails.woD_CORETYPE}</Text>
                    <Text style={styles.headerItem}>QTY: {jobDetails.woD_QTY}</Text>
                  </View>
                  <View>
                    <Text style={styles.pinningtitle}>{jobDetails.woD_KEYLOCKMARK}</Text>
                    <View style={styles.twoCol}>
                      <View style={styles.section}>
                        <Text style={styles.pinHeader}>Pinning</Text>
                        <Text style={styles.pinningText}>{newStr}</Text>
                      </View>
                      <View style={styles.section}>
                        <Text style={styles.cutsHeader}>Cuts</Text>
                        <Text style={styles.keyCutStr}><pre>{jobDetails.keyCutStr}</pre></Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.lineItemContainer}>
                    <Text style={styles.headerItem}>Record Description: {jobDetails.woD_RECORDDESC}</Text>
                    <Text style={styles.headerItem}>System: {jobDetails.woD_SYSTEM}</Text>
                  </View>
                  <View>
                    <Text style={styles.paginator}>Page {jobDetails.woD_LINENUM} of {this.props.jobDetails.length}</Text>
                  </View>
                </View>
              </View>
            </View>
          </Page>
        )
      } else {
        return(
          <Page key={jobDetails.woD_ID} size="A4" orientation="landscape" wrap={false} style={styles.page}>
            <View>
              <View>
              <Text style={styles.title}>Job {jobDetails.woD_HEADERID}</Text>
                <View>
                  <View style={styles.headerItemContainer}>
                    <Text style={styles.headerItem}>Reference Number: {this.props.refNum}</Text>
                    <Text style={styles.headerItem}>Due Date: {this.props.dateDue}</Text>
                    <Text style={styles.headerItem}>Status: {this.props.status}</Text>
                  </View>
                  <View style={styles.headerItemContainerNoBorder}>
                    <Text style={styles.headerItem}>PO # {this.props.poNum}</Text>
                    <Text style={styles.headerItem}>Desc: {this.props.desc}</Text>
                    <Text style={styles.headerItem}>Notes: {this.props.notes}</Text>
                  </View>
                  <View>
                    <View style={styles.lineItemContainer}>
                      <Text style={styles.headerItem}>Line: {jobDetails.woD_ID}</Text>
                      <Text style={styles.headerItem}>Keys</Text>
                      <Text style={styles.headerItem}>QTY: {jobDetails.woD_QTY}</Text>
                    </View>
                  </View>
                  <View>
                    <Text style={styles.pinningtitle}>{jobDetails.woD_KEYLOCKMARK}</Text>
                    <View style={styles.cuts}>
                      <Text>Cuts</Text>
                      <Text>{jobDetails.bittings}</Text>
                    </View>
                  </View>
                  <View style={styles.lineItemContainer}>
                    <Text style={styles.headerItem}>Record Description: {jobDetails.woD_RECORDDESC}</Text>
                    <Text style={styles.headerItem}>System: {jobDetails.woD_SYSTEM}</Text>
                    <Text style={styles.headerItem}>Keyway: {jobDetails.woD_KEYWAY}</Text>
                  </View>
                  <View>
                    <Text style={styles.stamping}>Stamping: {jobDetails.stamping}</Text>
                  </View>
                  <View>
                    <Text style={styles.paginator}>Page {jobDetails.woD_LINENUM} of {this.props.jobDetails.length}</Text>
                  </View>
                </View>
              </View>
            </View>
          </Page>
        )
      }
    })
    this.setState({mappedJobDetails: mappedJobDetails})
  }

  render() {
      return (
          <Document>
            {this.state.mappedJobDetails}
          </Document>
      );
  }
}

export default ViewJobPDF