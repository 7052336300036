import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Popover from "@material-ui/core/Popover";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ApartmentIcon from '@material-ui/icons/Apartment';
import SettingsIcon from '@material-ui/icons/Settings';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import SecurityIcon from '@material-ui/icons/Security';
// import SearchIcon from '@material-ui/icons/Search';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const styles = theme => ({
    spanClass: {
        textAlign: 'center',
    },
    popover: {
        pointerEvents: "auto"
    },
    popoverContent: {
        pointerEvents: "auto"
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
            width: '20ch',
            },
        },
    }
});

class TopBarMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state= {
            value: 0,
            anchorEl: null,
            popno: -1,
            secString: '',
            reportsNestedOpen: false
        }
    }

    componentDidMount() {
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
        this.setState({ secString: parsedUserData.secString })
    }
    
    handlePopoverClose = () => {
        this.setState({ anchorEl: null, popno: -1 });
    };

    handleClick = (e, _popno) => {
        this.setState({ anchorEl: e.currentTarget, popno: _popno });
    };

    handleReportsNested = () => {
        this.setState({reportsNestedOpen: !this.state.reportsNestedOpen})
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <ClickAwayListener mouseEvent='onClick' onClickAway={this.handlePopoverClose} >
                    <div>
                        <Grid container className={classes.root} justify='center' spacing={0} alignItems='flex-end'>
                            {(this.state.secString[16]!=='0' || this.state.secString[25]!=='0' || this.state.secString[19]!=='0' || this.state.secString[20]!=='0' || this.state.secString[21]!=='0' || this.state.secString[22]!=='0') ? (
                                <Grid item xs={12} sm={4} md={3} className={classes.spanClass}>
                                    <Button endIcon={<BusinessCenterIcon />} color='secondary' onClick={e => this.handleClick(e, 1)} onMouseEnter={e => this.handleClick(e, 1)}>Work</Button>
                                </Grid>) : (null)
                            }
                            <Grid item xs={12} sm={4} md={3} className={classes.spanClass}>
                                <Button endIcon={<ApartmentIcon />} color='secondary' onClick={e => this.handleClick(e, 2)} onMouseEnter={e => this.handleClick(e, 2)}>System Info</Button>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3} className={classes.spanClass}>
                                <Button endIcon={<SecurityIcon />} color='secondary' onClick={e => this.handleClick(e, 3)} onMouseEnter={e => this.handleClick(e, 3)}>Administration</Button>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3} className={classes.spanClass}>
                                <Button color='secondary' endIcon={<SettingsIcon />} onClick={e => this.handleClick(e, 4)} onMouseEnter={e => this.handleClick(e, 4)}>Settings </Button>
                            </Grid>
                        </Grid>
                        <Popover
                            id="menu2Popover"
                            className={classes.popover}
                            classes={{
                                paper: classes.popoverContent
                            }}
                            open={this.state.anchorEl !== null}
                            onClose={this.handlePopoverClose}
                            anchorEl={this.state.anchorEl}
                            onMouseLeave={this.handlePopoverClose}
                            style={{ pointerEvents: 'none' }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                            }}
                        >
                        
                            {this.state.popno === 1 && (
                            <MenuList>
                                
                                {this.state.secString[16]!=='0' ? 
                                    (<MenuItem onClick={() => this.props.handleNavItemClick(31)}>Key Hand Out</MenuItem>)
                                     : (null)
                                }
                                
                                {this.state.secString[16]!=='0' ? 
                                    (<MenuItem onClick={() => this.props.handleNavItemClick(15)}>Key Hand In</MenuItem>)
                                     : (null)
                                }

                                {/* {this.state.secString[25]!=='0' ? 
                                    (<MenuItem onClick={() => this.props.handleNavItemClick(0)}>Report Keys Lost</MenuItem>)
                                     : (null)
                                }

                                {this.state.secString[25]!=='0' ? 
                                    (<MenuItem onClick={() => this.props.handleNavItemClick(32)}>Report Keys Broken</MenuItem>)
                                     : (null)
                                }

                                {this.state.secString[25]!=='0' ? 
                                    (<MenuItem onClick={() => this.props.handleNavItemClick(33)}>Report Keys Destroyed</MenuItem>)
                                     : (null)
                                } */}

                                {(this.state.secString[19]!=='0' || this.state.secString[20]!=='0' || this.state.secString[21]!=='0' || this.state.secString[22]!=='0') ? 
                                    (
                                        <List className={classes.root}>
                                            <ListItem button onClick={this.handleReportsNested}>
                                                <ListItemText primary="Reports" />
                                                {this.state.reportsNestedOpen ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.reportsNestedOpen} timeout="auto" unmountOnExit>
                                                <List component="div" disablePadding>
                                                    {this.state.secString[19]!=='0' ? 
                                                        (
                                                            <ListItem button className={classes.nested} onClick={() => this.props.handleNavItemClick(34)}>
                                                                <ListItemText primary="Key History" />
                                                            </ListItem>
                                                        ) : (null)
                                                    }
                                                </List>
                                            </Collapse>
                                        </List>
                                    ) : (null)}


                                {/* {(this.state.secString[19]!=='0' && this.state.secString[20]!=='0' && this.state.secString[21]!=='0' && this.state.secString[22]!=='0' ) ? 
                                    (<MenuItem onClick={() => this.props.handleNavItemClick(17)}>Reports</MenuItem>)
                                     : (null)
                                }                                 */}
                            </MenuList>
                            )}
                            {this.state.popno === 2 && (
                            <MenuList>
                                {this.state.secString[2]!=='0' ? 
                                    (<MenuItem onClick={() => this.props.handleNavItemClick(8)}>Building Groups</MenuItem>)
                                     : (null)
                                }

                                {this.state.secString[3]!=='0' ? 
                                    (<MenuItem onClick={() => this.props.handleNavItemClick(3)}>Buildings</MenuItem>)
                                     : (null)
                                }
                                
                                {this.state.secString[28]!=='0' ? 
                                    (<MenuItem onClick={() => this.props.handleNavItemClick(19)}>Doors</MenuItem>)
                                    : (null)
                                }

                                {this.state.secString[4]!=='0' ? 
                                    (<MenuItem onClick={() => this.props.handleNavItemClick(9)}>Departments</MenuItem>)
                                     : (null)
                                }

                                {this.state.secString[13]!=='0' ? 
                                    (<MenuItem onClick={() => this.props.handleNavItemClick(1)}>People</MenuItem>)
                                     : (null)
                                }

                                {/* <MenuItem onClick={() => this.props.handleNavItemClick(16)}>Keys Past Due</MenuItem> */}
                            </MenuList>
                            )}
                            {this.state.popno === 3 && (
                            <MenuList>
                                <MenuItem onClick={() => this.props.handleNavItemClick(36)}>Support Cases</MenuItem>

                                {this.state.secString[11]!=='0' ? 
                                    (<MenuItem onClick={() => this.props.handleNavItemClick(6)}>Jobs</MenuItem>)
                                     : (null)
                                }
                                
                                {this.state.secString[17]!=='0' ? 
                                    (<MenuItem onClick={() => this.props.handleNavItemClick(10)}>Master Systems</MenuItem>)
                                     : (null)
                                }

                                {this.state.secString[8]!=='0' ? 
                                    (<MenuItem onClick={() => this.props.handleNavItemClick(5)}>Records</MenuItem>)
                                     : (null)
                                }
                                
                                {this.state.secString[9]!=='0' ? 
                                    (<MenuItem onClick={() => this.props.handleNavItemClick(2)}>Keys</MenuItem>)
                                     : (null)
                                }
                                        
                                {this.state.secString[10]!=='0' ? 
                                    (<MenuItem onClick={() => this.props.handleNavItemClick(4)}>Cores</MenuItem>)
                                     : (null)
                                }
                                
                                {/* {this.state.secString[26]!=='0' ? 
                                    (<MenuItem onClick={() => this.props.handleNavItemClick(13)}>Key Rings</MenuItem>)
                                     : (null)
                                }

                                {this.state.secString[7]!=='0' ? 
                                    (<MenuItem onClick={() => this.props.handleNavItemClick(11)}>Credentials</MenuItem>)
                                     : (null)
                                } */}

                                {this.state.secString[5]!=='0' ? 
                                    (<MenuItem onClick={() => this.props.handleNavItemClick(7)}>Key Storage</MenuItem>)
                                     : (null)
                                }
                                
                                {/* <MenuItem onClick={() => this.props.handleNavItemClick(20)}>Domains</MenuItem>
                                
                                {this.state.secString[27]!=='0' ? 
                                    (<MenuItem onClick={() => this.props.handleNavItemClick(21)}>Devices</MenuItem>)
                                     : (null)
                                } */}
                            </MenuList>
                            )}
                            {this.state.popno === 4 && (
                            <MenuList>

                                {/* {this.state.secString[6]!=='0' ? 
                                    (<MenuItem onClick={() => this.props.handleNavItemClick(12)}>Credential Types</MenuItem>)
                                     : (null)
                                } */}

                                {this.state.secString[12]!=='0' ? 
                                    (<MenuItem onClick={this.props.customizeClick}>Customization</MenuItem>)
                                     : (null)
                                }

                                <MenuItem onClick={this.props.openLicense}>License Info</MenuItem>

                                {this.state.secString[14]!=='0' ? 
                                    (<MenuItem onClick={() => this.props.handleNavItemClick(14)}>Security Roles</MenuItem>)
                                     : (null)
                                }
                                
                                <Link color='inherit' href="https://mwspi.atlassian.net/wiki/spaces/LUG/overview?homepageId=441942324" target='_blank' rel='noopener'><MenuItem>User Guide</MenuItem></Link>
                            </MenuList>
                            )}
                        </Popover> 
                    </div>
                </ClickAwayListener>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(TopBarMenu);