import axios from 'axios';
import { serverAddr } from './serverAddr';

export const customService = {
    buttonLayout,
};

async function buttonLayout( ID, OrgID, landingPageID, button1ID, button2ID, button3ID, button4ID, button5ID, theme ) {
    let userData = sessionStorage.getItem('userData');
    let parsedUserData = JSON.parse(userData);
    let bearerToken = parsedUserData.token;

    const config = {
    method: 'post',
    url: serverAddr.apiUrl + '/users/SavePagelayout',
    headers: { 
        'Authorization': 'Bearer ' + bearerToken,
        'Content-Type': 'application/json'
    },
    data : JSON.stringify({ ID, OrgID, landingPageID, button1ID, button2ID, button3ID, button4ID, button5ID, theme })    
};

    try {
        const response = await axios(config)
        sessionStorage.setItem('customData', JSON.stringify(response.data));
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
}