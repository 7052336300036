import React from 'react';
import { Route, Switch } from "react-router-dom"; 

import Login from '../pages/Login';
import Records from '../pages/Records';
import Domains from '../pages/Domains';
import People from '../pages/People';
import SecurityRoles from '../pages/SecurityRoles';
import RecoverPassword from '../pages/RecoverPassword';
import ResetPassword from '../pages/ResetPassword';
import NoMatch from '../pages/NoMatch';
import Keys from '../pages/Keys';
import Buildings from '../pages/Buildings';
import Cores from '../pages/Cores';
import Jobs from '../pages/Jobs';
import KeyStorage from '../pages/KeyStorage';
import BuildingGroups from '../pages/BuildingGroups';
import Departments from '../pages/Departments';
import MasterSystems from '../pages/MasterSystems';
import Credentials from '../pages/Credentials';
import CredentialTypes from '../pages/CredentialTypes';
import KeyRings from '../pages/KeyRings';
import KeysPastDue from '../pages/KeysPastDue';
import Reports from '../pages/Reports';
import KeysLost from '../pages/KeysLost';
import KeysBroken from '../pages/KeysBroken';
import KeysDestroyed from '../pages/KeysDestroyed';
import Search from '../pages/Search';
import Doors from '../pages/Doors';
import Devices from '../pages/Devices';
import KeyHandOut from '../pages/KeyHandOut';
import KeyHandIn from '../pages/KeyHandIn';
import KeyHistory from '../pages/KeyHistory';
import SupportCases from '../pages/SupportCases';
import NewSupportTicket from '../components/NewSupportTicket';

import PrivateRoute from './PrivateRoute';


const App = () => (
  <Switch>
    <Route path='/login' component={Login} />
    <Route path='/recoverpassword' component={RecoverPassword} />
    <Route path='/resetpassword' component={ResetPassword} />
    <PrivateRoute path='/people' component={People} />
    <PrivateRoute path='/keys' component={Keys} />
    <PrivateRoute path='/buildings' component={Buildings} />
    <PrivateRoute path='/cores' component={Cores} />
    <PrivateRoute path='/records' component={Records} />
    <PrivateRoute path='/jobs' component={Jobs} />
    <PrivateRoute path='/keystorage' component={KeyStorage} />
    <PrivateRoute path='/buildinggroups' component={BuildingGroups} />
    <PrivateRoute path='/departments' component={Departments} />
    <PrivateRoute path='/mastersystems' component={MasterSystems} />
    <PrivateRoute path='/credentials' component={Credentials} />
    <PrivateRoute path='/credentialtypes' component={CredentialTypes} />
    <PrivateRoute path='/keyrings' component={KeyRings} />
    <PrivateRoute path='/securityroles' component={SecurityRoles} />
    <PrivateRoute path='/keyhandin' component={KeyHandIn} />
    <PrivateRoute path='/keyspastdue' component={KeysPastDue} />
    <PrivateRoute path='/reports' component={Reports} />
    <PrivateRoute path='/keyslost' component={KeysLost} />
    <PrivateRoute path='/keysbroken' component={KeysBroken} />
    <PrivateRoute path='/keysdestroyed' component={KeysDestroyed} />
    <PrivateRoute path='/search' component={Search} />
    <PrivateRoute path='/doors' component={Doors} />
    <PrivateRoute path='/domains' component={Domains} />
    <PrivateRoute path='/devices' component={Devices} />
    <PrivateRoute path='/keyhandout' component={KeyHandOut} />
    <PrivateRoute path='/keyhistory' component={KeyHistory} />
    <PrivateRoute path='/supportcases' component={SupportCases} />
    <PrivateRoute path='/newsupportcase' component={NewSupportTicket} />
    <PrivateRoute exact path='/' component={Records} />
    <Route component={NoMatch} />
  </Switch>
);


export default App;