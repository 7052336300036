import React from "react";
import { Redirect } from "react-router-dom";

import { userService } from '../services/Auth';
import MWlogo from '../images/MWlogo.png';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import InputAdornment from '@material-ui/core/InputAdornment';
import WorkIcon from '@material-ui/icons/Work';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import blueGrey from '@material-ui/core/colors/blueGrey';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';
import { ThemeProvider } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';

function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://www.mwspi.com/" target='_blank' rel='noopener'>
          Midwest Security Products Inc.
        </Link>{' '}
        {new Date().getFullYear()}
      </Typography>
    );
  }

  const loginTheme = createMuiTheme({
    palette: {
      primary: {
        main: blueGrey[500],
      },
      secondary: {
        main: grey[300],
      },
      error: {
        main: red[600]
      }
    }
  });

const styles = loginTheme => ({
    paper: {
        marginTop: loginTheme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      },
      form: {
        width: '100%', 
        marginTop: loginTheme.spacing(1),
      },
      submitButton: {
        margin: loginTheme.spacing(3, 0, 2, 0),
      },
      loading: {
        display: 'flex',
        '& > * + *': {
          marginLeft: loginTheme.spacing(2),
        },
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        margin: loginTheme.spacing(3, 0, 2, 0),
      }
  });

class RecoverPassword extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            toLogin: false,
            passwordWindowOpen: false,
        };

        this.handleInputChange = this.handleInputChange.bind(this);

    }

    handleInputChange(e) {
        const target = e.target;
        const value = target.value;
        const name = target.name;
        this.setState({ [name]: value });
    }

    getPasswordLink = async e => {
        e.preventDefault();

        await userService.passwordLink(this.state.username, this.state.org) 
        this.setState({ passwordWindowOpen: true })
    }
    
    handleClickClose = () => {
        this.setState({ passwordWindowOpen: false })
        this.setState({ toLogin: true })
    }

    render() {
        const { classes } = this.props;
        const { isLoading, toLogin, passwordWindowOpen } = this.state;

        if(toLogin) {
            return(
                <Redirect to='/login' />
            );
        } else if (passwordWindowOpen){
            return(
                <ThemeProvider theme={loginTheme}>
                    <div>
                        <Dialog
                            open={passwordWindowOpen}
                            disableBackdropClick
                            disableEscapeKeyDown
                        >
                            <DialogTitle id="alert-dialog-title">
                                Password Reset Link Sent
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    If the entered email and organization ID are valid, you will shortly recieve an email with further instructions on how to reset your password.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleClickClose} color="primary" autoFocus>
                                    Return to Login
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </ThemeProvider>
            );
        } else {
        return(
        <ThemeProvider theme={loginTheme}>
            <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <a href='https://www.mwspi.com/' target='_blank' rel='noopener noreferrer'><img src={MWlogo} alt='logo' width='355' height='128' /></a>
                <Typography variant='h5' color='error' gutterBottom>
                    Solutions. Designed. Delivered.
                </Typography>
                <form onSubmit={this.getPasswordLink} className={classes.form}>
                    <h1>LockViewer Enterprise</h1>
                    <h2>Forgot Password?</h2>
                    <p>
                        If you have forgotten your Organization ID, please contact:{' '}
                        <Link color="primary" href="https://www.mwspi.com/contact/" target='_blank' rel='noopener'>Midwest Security Products Inc.</Link>
                    </p>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="org"
                    label="Organization"
                    name="org"
                    placeholder="Organization"
                    value={this.state.org}
                    onChange={this.handleInputChange}
                    autoFocus
                    InputProps={{
                        startAdornment: (
                            <InputAdornment posistion="start">
                                <WorkIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Email"
                    name="username"
                    placeholder="Email"
                    value={this.state.username}
                    onChange={this.handleInputChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment posistion="start">
                                <AccountBoxIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                {
                    isLoading ? (
                        <div className={classes.loading}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <div className={classes.submitButton}>
                            <Button
                            type="submit"
                            size="large"
                            fullWidth
                            variant="contained"
                            color="primary"
                            >
                            Reset Password
                            </Button>
                        </div>
                    )
                }
                </form>
                </div>
                <Copyright />
            </Container>
        </ThemeProvider>
        );
    }
}
}

export default withStyles(styles, { withTheme: true })(RecoverPassword);