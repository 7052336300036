import React from 'react';

class Label extends React.Component {
    render() {
        if(this.props.mobile) {
            return(
                <div style={{margin:'10px'}} className="p-col-12">
                    <div><strong>{this.props.label}</strong></div>
                    <div style={{width:'100%', margin:'auto'}}>{this.props.value}</div>
                </div>
            );
        } else {
            return (
                <div className='p-col-12 p-grid'>
                    <label className="p-col-6" style={{textAlign:'right'}}>
                        <strong>{this.props.label}</strong>
                    </label>
                    <label style={{textAlign:'left'}} className='p-col-6'>
                        {this.props.value}
                    </label>
                </div>
            );
        }
    }
}

export default Label;