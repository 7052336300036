import React from "react";
import { Redirect } from 'react-router-dom';

import Sidebar from './Sidebar';
import TopBarBanner from './TopBarBanner';
import { customService } from '../services/Custom';
import ProductWindow from './ProductWindow';
import Timeout from './Timeout';

import { ThemeProvider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Portal from '@material-ui/core/Portal';
import Dialog from'@material-ui/core/Dialog';
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from '@material-ui/core/Button';
//Icons//
import ApartmentIcon from '@material-ui/icons/Apartment';
import DomainIcon from '@material-ui/icons/Domain';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import QueueIcon from '@material-ui/icons/Queue';
import DescriptionIcon from '@material-ui/icons/Description';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import StoreIcon from '@material-ui/icons/Store';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PeopleIcon from '@material-ui/icons/People';
import TripOriginIcon from '@material-ui/icons/TripOrigin';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import StorageIcon from '@material-ui/icons/Storage';
import LockIcon from '@material-ui/icons/Lock';
import AssessmentIcon from '@material-ui/icons/Assessment';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';
import ArchiveIcon from '@material-ui/icons/Archive';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import LocationOffIcon from '@material-ui/icons/LocationOff';
import HistoryIcon from '@material-ui/icons/History';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
///////////////////////////////////////////////////////////////
import {Messages} from 'primereact/messages';

import { createMuiTheme } from '@material-ui/core/styles';
import blueGrey from '@material-ui/core/colors/blueGrey';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import deepPurple from '@material-ui/core/colors/deepPurple';
import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import brown from '@material-ui/core/colors/brown';
import yellow from '@material-ui/core/colors/yellow';
import lime from '@material-ui/core/colors/lime';
import lightBlue from '@material-ui/core/colors/lightBlue';
import pink from '@material-ui/core/colors/pink';

let theme = createMuiTheme({
    palette: {
      primary: {
        main: blueGrey[500],
      },
      secondary: {
        main: grey[300],
      },
      error: {
        main: red[600]
      }
    },
    zIndex: {
      mobileStepper: 1000,
      speedDial: 1050,
      drawer: 1100,
      appBar: 1200,
      modal: 1300,
      snackbar: 1400,
      tooltip: 1500
    }
});

let styles = theme => ({
    root: {
        display: 'flex',
    },
    iconClass: {
        fontSize: 60
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(),
        zIndex: 1150,
    },
    snackbarClass: {
        textAlign: 'center'
    },
    license: {
        width:'100%',
        backgroundColor:'yellow',
        marginTop: '0px'
    },
    toolbar: {
        marginBottom: '0px'
    },
});

let changeValArray=[];
let changeValString1='';
let changeValString2='';
let changeValString3='';
let changeValString4='';
let changeValString5='';

class Layout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            menuNavItems : [
                { title: 'Report Keys Lost', navId: 0, navIcon:<LocationOffIcon className={this.props.classes.iconClass} /> },
                { title: 'People', navId: 1, navIcon:<PeopleIcon className={this.props.classes.iconClass} /> },
                { title: 'Keys', navId: 2, navIcon:<VpnKeyIcon className={this.props.classes.iconClass} /> },
                { title: 'Buildings', navId: 3, navIcon:<StoreIcon className={this.props.classes.iconClass} /> },
                { title: 'Cores', navId: 4, navIcon:<LockIcon className={this.props.classes.iconClass} /> },
                { title: 'Records', navId: 5, navIcon:<DescriptionIcon className={this.props.classes.iconClass} /> },
                { title: 'Jobs', navId: 6, navIcon:<AssignmentIcon className={this.props.classes.iconClass} />},
                { title: 'Key Storage', navId: 7, navIcon:<StorageIcon className={this.props.classes.iconClass} /> },
                { title: 'Building Groups', navId: 8, navIcon:<ApartmentIcon className={this.props.classes.iconClass} /> },
                { title: 'Departments', navId: 9, navIcon:<FolderSharedIcon className={this.props.classes.iconClass} /> },
                { title: 'Master Systems', navId: 10, navIcon:<AccountTreeIcon className={this.props.classes.iconClass} /> },
                { title: 'Credentials', navId: 11, navIcon:<VerifiedUserIcon className={this.props.classes.iconClass} /> },
                { title: 'Credential Types', navId: 12, navIcon:<FingerprintIcon className={this.props.classes.iconClass} /> },
                { title: 'Key Rings', navId: 13, navIcon:<TripOriginIcon className={this.props.classes.iconClass} /> },
                { title: 'Security Roles', navId: 14, navIcon:<AssignmentIndIcon className={this.props.classes.iconClass} /> },
                { title: 'Key Hand In', navId: 15, navIcon:<ArchiveIcon className={this.props.classes.iconClass} /> },
                { title: 'Keys Past Due', navId: 16, navIcon:<CalendarTodayIcon className={this.props.classes.iconClass} /> },
                { title: 'Reports', navId: 17, navIcon:<AssessmentIcon className={this.props.classes.iconClass} /> },
                { title: 'Doors', navId: 19, navIcon:<MeetingRoomIcon className={this.props.classes.iconClass} /> },
                { title: 'Domains', navId: 20, navIcon:<DomainIcon className={this.props.classes.iconClass} />},
                { title: 'Devices', navId: 21, navIcon:<EnhancedEncryptionIcon className={this.props.classes.iconClass} />},
                { title: 'Key Hand Out', navId: 31, navIcon:<QueueIcon className={this.props.classes.iconClass} />},
                { title: 'Report Keys Broken', navId: 32, navIcon:<LinkOffIcon className={this.props.classes.iconClass} />},
                { title: 'Report Keys Destroyed', navId: 33, navIcon:<BrokenImageIcon className={this.props.classes.iconClass} />},
                { title: 'Key History', navId: 34, navIcon:<HistoryIcon className={this.props.classes.iconClass} />},
                { title: 'Support Cases', navId: 36, navIcon:<ContactSupportIcon className={this.props.classes.iconClass} />}
            ],
            updatedSelectedMenuNavItems: [],
            customWindowOpen: false,
            isError: false,
            licenseData: '',
            licenseOpen: false,
            themeColor:'gray',
            hasLoaded: false,
            selectedMenuNavItems: [],
            newCase: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.customizeClick = this.customizeClick.bind(this);
        this.handleNavItemClick = this.handleNavItemClick.bind(this);
        this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
        this.openLicense = this.openLicense.bind(this);
        this.closeLicense = this.closeLicense.bind(this);
        this.handleThemeChange = this.handleThemeChange.bind(this);
        this.handleSetTheme = this.handleSetTheme.bind(this);
    }

    customizeClick() {
        this.setState({ customWindowOpen: true, oldTheme: this.state.themeColor })
    }

    openLicense() {
        this.setState({ licenseOpen: true })
    }

    closeLicense() {
        this.setState({ licenseOpen: false })
    }

    componentDidMount() {
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
        const parsedViewedDrawer = JSON.parse(sessionStorage.getItem('viewedDrawerData'));
        let licenseDataVar = JSON.parse(sessionStorage.getItem('licenseData'));

        let btnArray = [];
        btnArray.push(parsedUserData.button1);
        btnArray.push(parsedUserData.button2);
        btnArray.push(parsedUserData.button3);
        btnArray.push(parsedUserData.button4);
        btnArray.push(parsedUserData.button5);
        
        let tempArray=[];
        for(let j=0; j<btnArray.length; j++) {
            for(let i=0;i<this.state.menuNavItems.length; i++) {
                if(btnArray[j]===this.state.menuNavItems[i].navId) {
                    tempArray.push(this.state.menuNavItems[i])
                }
            }
        }

        if(parsedUserData.theme!=='') {
            this.setState({themeColor: parsedUserData.theme})
        }

        this.setState({
            welcomeMsg: !parsedViewedDrawer.viewedDrawer,
            fName: parsedUserData.uiU_FN,
            lName: parsedUserData.uiU_LN,
            button1ID: parsedUserData.button1,
            button2ID: parsedUserData.button2,
            button3ID: parsedUserData.button3,
            button4ID: parsedUserData.button4,
            button5ID: parsedUserData.button5,
            userId: parsedUserData.uiU_ID,  
            orgId: parsedUserData.uiU_M1ORGID,
            selectedValue: parsedUserData.button1,
            selectedValue2: parsedUserData.button2,
            selectedValue3: parsedUserData.button3,
            selectedValue4: parsedUserData.button4,
            selectedValue5: parsedUserData.button5,  
            landingPageValue: parsedUserData.landingPage,      
            selectedMenuNavItems : tempArray,
            licenseData: licenseDataVar,
            hasLoaded: true
            
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.state.themeColor !== prevState.themeColor) {
            this.handleThemeChange();
        }
        if(this.state.selectedValue !== prevState.selectedValue) {
            if(this.state.selectedValue === '') {
                this.setState({ isError: true })
            } else {
                this.setState({ isError: false })
            }
        }
        if(this.state.selectedValue2 !== prevState.selectedValue2) {
            if(this.state.selectedValue2 === '') {
                this.setState({ isError: true })
            } else {
                this.setState({ isError: false })
            }
        }
        if(this.state.selectedValue3 !== prevState.selectedValue3) {
            if(this.state.selectedValue3 === '') {
                this.setState({ isError: true })
            } else {
                this.setState({ isError: false })
            }
        }
        if(this.state.selectedValue4 !== prevState.selectedValue4) {
            if(this.state.selectedValue4 === '') {
                this.setState({ isError: true })
            } else {
                this.setState({ isError: false })
            }
        }
        if(this.state.selectedValue5 !== prevState.selectedValue5) {
            if(this.state.selectedValue5 === '') {
                this.setState({ isError: true })
            } else {
                this.setState({ isError: false })
            }
        }
        if(this.state.landingPageValue !== prevState.landingPageValue) {
            if(this.state.landingPageValue === '') {
                this.setState({ isError: true })
            } else {
                this.setState({ isError: false })
            }
        }
    }

    handleSetTheme(e) {
        if(e.target.value==='gray') {
            this.setState({themeColor: 'gray', hasLoaded: false})
        } else if(e.target.value==='red') {
            this.setState({themeColor: 'red', hasLoaded: false})
        } else if(e.target.value==='blue') {
            this.setState({themeColor: 'blue', hasLoaded: false})
        } else if(e.target.value==='purple') {
            this.setState({themeColor: 'purple', hasLoaded: false})
        } else if(e.target.value==='green') {
            this.setState({themeColor: 'green', hasLoaded: false})
        } else if(e.target.value==='brown') {
            this.setState({themeColor: 'brown', hasLoaded: false})
        } else if(e.target.value==='orange') {
            this.setState({themeColor: 'orange', hasLoaded: false})
        } else if(e.target.value==='yellow') {
            this.setState({themeColor: 'yellow', hasLoaded: false})
        } else if(e.target.value==='lightGreen') {
            this.setState({themeColor: 'lightGreen', hasLoaded: false})
        } else if(e.target.value==='lightBlue') {
            this.setState({themeColor: 'lightBlue', hasLoaded: false})
        } else if(e.target.value==='pink') {
            this.setState({themeColor: 'pink', hasLoaded: false})
        } else if(e.target.value==='black') {
            this.setState({themeColor: 'black', hasLoaded: false})
        }
    }

    handleThemeChange() {
        if(this.state.themeColor==='gray') {
            theme = createMuiTheme({
              palette: {
                primary: {
                  main: blueGrey[500],
                },
                secondary: {
                  main: grey[300],
                },
                error: {
                  main: red[600]
                }
              },
              zIndex: {
                mobileStepper: 1000,
                speedDial: 1050,
                drawer: 1100,
                appBar: 1200,
                modal: 1300,
                snackbar: 1400,
                tooltip: 1500
              }
            });

            document.documentElement.style.setProperty(
                '--userCustomColor',
                '#607d8b'
            );
            document.documentElement.style.setProperty(
                '--userHoverColor',
                '#455a64'
            );
            document.documentElement.style.setProperty(
                '--userCustomText',
                '#e0e0e0'
            );
        } else if(this.state.themeColor==='red') {
            theme = createMuiTheme({
                palette: {
                  primary: {
                    main: red[700],
                  },
                  secondary: {
                    main: grey[300],
                  },
                  error: {
                    main: red[600]
                  }
                },
                zIndex: {
                  mobileStepper: 1000,
                  speedDial: 1050,
                  drawer: 1100,
                  appBar: 1200,
                  modal: 1300,
                  snackbar: 1400,
                  tooltip: 1500
                }
            });

            document.documentElement.style.setProperty(
                '--userCustomColor',
                '#d32f2f'
            );
            document.documentElement.style.setProperty(
                '--userHoverColor',
                '#b71c1c'
            );
            document.documentElement.style.setProperty(
                '--userCustomText',
                '#e0e0e0'
            );
        } else if(this.state.themeColor==='blue') {
            theme = createMuiTheme({
                palette: {
                  primary: {
                    main: blue[900],
                  },
                  secondary: {
                    main: grey[300],
                  },
                  error: {
                    main: red[600]
                  }
                },
                zIndex: {
                  mobileStepper: 1000,
                  speedDial: 1050,
                  drawer: 1100,
                  appBar: 1200,
                  modal: 1300,
                  snackbar: 1400,
                  tooltip: 1500
                }
            });
            document.documentElement.style.setProperty(
                '--userCustomColor',
                '#0d47a1'
            );
            document.documentElement.style.setProperty(
                '--userHoverColor',
                '#1976d2'
            );
            document.documentElement.style.setProperty(
                '--userCustomText',
                '#e0e0e0'
            );
        } else if(this.state.themeColor==='black') {
            theme = createMuiTheme({
                palette: {
                  primary: {
                    main: grey[900],
                  },
                  secondary: {
                    main: grey[300],
                  },
                  error: {
                    main: red[600]
                  }
                },
                zIndex: {
                  mobileStepper: 1000,
                  speedDial: 1050,
                  drawer: 1100,
                  appBar: 1200,
                  modal: 1300,
                  snackbar: 1400,
                  tooltip: 1500
                }
            });
            document.documentElement.style.setProperty(
                '--userCustomColor',
                '#212121'
            );
            document.documentElement.style.setProperty(
                '--userHoverColor',
                '#424242'
            );
            document.documentElement.style.setProperty(
                '--userCustomText',
                '#e0e0e0'
            );
        } else if(this.state.themeColor==='purple') {
            theme = createMuiTheme({
                palette: {
                  primary: {
                    main: deepPurple[500],
                  },
                  secondary: {
                    main: grey[300],
                  },
                  error: {
                    main: red[600]
                  }
                },
                zIndex: {
                  mobileStepper: 1000,
                  speedDial: 1050,
                  drawer: 1100,
                  appBar: 1200,
                  modal: 1300,
                  snackbar: 1400,
                  tooltip: 1500
                }
            });
            document.documentElement.style.setProperty(
                '--userCustomColor',
                '#673ab7'
            );
            document.documentElement.style.setProperty(
                '--userHoverColor',
                '#512da8'
            );
            document.documentElement.style.setProperty(
                '--userCustomText',
                '#e0e0e0'
            );
        } else if(this.state.themeColor==='green') {
            theme = createMuiTheme({
                palette: {
                  primary: {
                    main: green[900],
                  },
                  secondary: {
                    main: grey[300],
                  },
                  error: {
                    main: red[600]
                  }
                },
                zIndex: {
                  mobileStepper: 1000,
                  speedDial: 1050,
                  drawer: 1100,
                  appBar: 1200,
                  modal: 1300,
                  snackbar: 1400,
                  tooltip: 1500
                }
            });
            document.documentElement.style.setProperty(
                '--userCustomColor',
                '#1b5e20'
            );
            document.documentElement.style.setProperty(
                '--userHoverColor',
                '#388e3c'
            );
            document.documentElement.style.setProperty(
                '--userCustomText',
                '#e0e0e0'
            );
        } else if(this.state.themeColor==='brown') {
            theme = createMuiTheme({
                palette: {
                  primary: {
                    main: brown[500],
                  },
                  secondary: {
                    main: grey[300],
                  },
                  error: {
                    main: red[600]
                  }
                },
                zIndex: {
                  mobileStepper: 1000,
                  speedDial: 1050,
                  drawer: 1100,
                  appBar: 1200,
                  modal: 1300,
                  snackbar: 1400,
                  tooltip: 1500
                }
            });
            document.documentElement.style.setProperty(
                '--userCustomColor',
                '#795548'
            );
            document.documentElement.style.setProperty(
                '--userHoverColor',
                '#5d4037'
            );
            document.documentElement.style.setProperty(
                '--userCustomText',
                '#e0e0e0'
            );
        } else if(this.state.themeColor==='orange') {
            theme = createMuiTheme({
                palette: {
                  primary: {
                    main: orange[600],
                  },
                  secondary: {
                    main: grey[700],
                  },
                  error: {
                    main: red[600]
                  }
                },
                zIndex: {
                  mobileStepper: 1000,
                  speedDial: 1050,
                  drawer: 1100,
                  appBar: 1200,
                  modal: 1300,
                  snackbar: 1400,
                  tooltip: 1500
                }
            });
            document.documentElement.style.setProperty(
                '--userCustomColor',
                '#fb8c00'
            );
            document.documentElement.style.setProperty(
                '--userHoverColor',
                '#ef6c00'
            );
            document.documentElement.style.setProperty(
                '--userCustomText',
                '#616161'
            );
        } else if(this.state.themeColor==='yellow') {
            theme = createMuiTheme({
                palette: {
                  primary: {
                    main: yellow[500],
                  },
                  secondary: {
                    main: grey[700],
                  },
                  error: {
                    main: red[600]
                  }
                },
                zIndex: {
                  mobileStepper: 1000,
                  speedDial: 1050,
                  drawer: 1100,
                  appBar: 1200,
                  modal: 1300,
                  snackbar: 1400,
                  tooltip: 1500
                }
            });
            document.documentElement.style.setProperty(
                '--userCustomColor',
                '#ffeb3b'
            );
            document.documentElement.style.setProperty(
                '--userHoverColor',
                '#fbc02d'
            );
            document.documentElement.style.setProperty(
                '--userCustomText',
                '#616161'
            );
        } else if(this.state.themeColor==='lightGreen') {
            theme = createMuiTheme({
                palette: {
                  primary: {
                    main: lime[500],
                  },
                  secondary: {
                    main: grey[700],
                  },
                  error: {
                    main: red[600]
                  }
                },
                zIndex: {
                  mobileStepper: 1000,
                  speedDial: 1050,
                  drawer: 1100,
                  appBar: 1200,
                  modal: 1300,
                  snackbar: 1400,
                  tooltip: 1500
                }
            });
            document.documentElement.style.setProperty(
                '--userCustomColor',
                '#cddc39'
            );
            document.documentElement.style.setProperty(
                '--userHoverColor',
                '#afb42b'
            );
            document.documentElement.style.setProperty(
                '--userCustomText',
                '#616161'
            );
        } else if(this.state.themeColor==='lightBlue') {
            theme = createMuiTheme({
                palette: {
                  primary: {
                    main: lightBlue[500],
                  },
                  secondary: {
                    main: grey[300],
                  },
                  error: {
                    main: red[600]
                  }
                },
                zIndex: {
                  mobileStepper: 1000,
                  speedDial: 1050,
                  drawer: 1100,
                  appBar: 1200,
                  modal: 1300,
                  snackbar: 1400,
                  tooltip: 1500
                }
            });
            document.documentElement.style.setProperty(
                '--userCustomColor',
                '#03a9f4'
            );
            document.documentElement.style.setProperty(
                '--userHoverColor',
                '#0288d1'
            );
            document.documentElement.style.setProperty(
                '--userCustomText',
                '#e0e0e0'
            );
        } else if(this.state.themeColor==='pink') {
            theme = createMuiTheme({
                palette: {
                  primary: {
                    main: pink[500],
                  },
                  secondary: {
                    main: grey[300],
                  },
                  error: {
                    main: red[600]
                  }
                },
                zIndex: {
                  mobileStepper: 1000,
                  speedDial: 1050,
                  drawer: 1100,
                  appBar: 1200,
                  modal: 1300,
                  snackbar: 1400,
                  tooltip: 1500
                }
            });
            document.documentElement.style.setProperty(
                '--userCustomColor',
                '#e91e63'
            );
            document.documentElement.style.setProperty(
                '--userHoverColor',
                '#c2185b'
            );
            document.documentElement.style.setProperty(
                '--userCustomText',
                '#e0e0e0'
            );
        }

        styles = theme => ({
            root: {
                display: 'flex',
            },
            iconClass: {
                fontSize: 60
            },
            content: {
                flexGrow: 1,
                padding: theme.spacing(3),
                zIndex: 1150,
            },
            snackbarClass: {
                textAlign: 'center'
            },
            license: {
                width:'100%',
                backgroundColor:'yellow',
                marginTop: '0px'
            },
            toolbar: {
                marginBottom: '0px'
            },
        });

        this.setState({hasLoaded:true})
    }

    handleNavItemClick(navId) {
        switch (navId) {
            case 0: 
                if(window.location.pathname!=='/keyslost') {
                    this.setState({ toKeysLost: true });
                }
                break;
            case 1: 
                if(window.location.pathname!=='/people') {
                    this.setState({ toPeople: true });
                }
                break;
            case 2: 
                if(window.location.pathname!=='/keys') {
                    this.setState({ toCutKeys: true });
                }
                break;
            case 3: 
                if(window.location.pathname!=='/buildings') {
                    this.setState({ toBuildings: true });
                }
                break;
            case 4: 
                if(window.location.pathname!=='/cores') {
                    this.setState({ toCores: true });
                }
                break;
            case 5:
                if(window.location.pathname!=='/records') {
                    this.setState({ toRecords: true });
                }
                break;
            case 6:
                if(window.location.pathname!=='/jobs') {
                    this.setState({ toJobs: true });
                }
                break;
            case 7:
                if(window.location.pathname!=='/keystorage') {
                    this.setState({ toStorage: true });
                }
                break;
            case 8:
                if(window.location.pathname!=='/buildinggroups') {
                    this.setState({ toBuilidngGroups: true });
                }
                break;
            case 9:
                if(window.location.pathname!=='/departments') {
                    this.setState({ toDepartments: true });
                }
                break;
            case 10:
                if(window.location.pathname!=='/mastersystems') {
                    this.setState({ toMasterSystems: true });
                }
                break;
            case 11:
                if(window.location.pathname!=='/credentials') {
                    this.setState({ toCredentials: true });
                }
                break;
            case 12:
                if(window.location.pathname!=='/credentialtypes') {
                    this.setState({ toCredTypes: true });
                }
                break;
            case 13:
                if(window.location.pathname!=='/keyrings') {
                    this.setState({ toKeyRings: true });
                }
                break;
            case 14:
                if(window.location.pathname!=='/securityRoles') {
                    this.setState({ toSecurityRoles: true });
                }
                break;
            case 15:
                if(window.location.pathname!=='/keyhandin') {
                    this.setState({ toKeyHandIn: true });
                }
                break;
            case 16:
                if(window.location.pathname!=='/keyspastdue') {
                    this.setState({ toKeysPastDue: true });
                }
                break;
            case 17:
                if(window.location.pathname!=='/reports') {
                    this.setState({ toReports: true });
                }
                break;
            case 19:
                if(window.location.pathname!=='/doors') {
                    this.setState({ toDoors: true });
                }
                break;
            case 20:
                if(window.location.pathname!=='/domains') {
                    this.setState({ toDomains: true });
                }
                break;
            case 21:
                if(window.location.pathname!=='/devices') {
                    this.setState({ toDevices: true });
                }
                break;
            case 31:
                if(window.location.pathname!=='/keyhandout') {
                    this.setState({ toKeyHandOut: true });
                }
                break;
            case 32: 
                if(window.location.pathname!=='/keysbroken') {
                    this.setState({ toKeysBroken: true });
                }
                break;
            case 33: 
                if(window.location.pathname!=='/keysdestroyed') {
                    this.setState({ toKeysDestroyed: true });
                }
                break;
            case 34: 
                if(window.location.pathname!=='/keyhistory') {
                    this.setState({ toKeyHistory: true });
                }
                break; 
            case 36: 
                if(window.location.pathname!=='/supportcases') {
                    this.setState({toSupportCases: true});
                }
                break;
            case 38: 
                this.setState({toNewSupportCase: true});
                break;
            default:
                console.log('Redirect Error, Layout, line 901')
        }
    }

    ///NEED TO CLEAN THIS UP BELOW WHEN YOU HAVE TIME, make one func instead of five/////////////////////////////////////////
    handleSubmit = async e => {
        e.preventDefault();
        this.setState({ isLoading: true });
        
        if(changeValArray[0] === undefined) {
            this.state.updatedSelectedMenuNavItems.push(this.state.selectedMenuNavItems[0])
        } else {
            for( let i=0; i<this.state.menuNavItems.length; i++) {
                if(changeValArray[0]===this.state.menuNavItems[i].navId) {
                    this.state.updatedSelectedMenuNavItems.push(this.state.menuNavItems[i])
                }
            }
        }

        if(changeValArray[1] === undefined) {
            this.state.updatedSelectedMenuNavItems.push(this.state.selectedMenuNavItems[1])
        } else {
            for( let i=0; i<this.state.menuNavItems.length; i++) {
                if(changeValArray[1]===this.state.menuNavItems[i].navId) {
                    this.state.updatedSelectedMenuNavItems.push(this.state.menuNavItems[i])
                }
            }
        }

        if(changeValArray[2] === undefined) {
            this.state.updatedSelectedMenuNavItems.push(this.state.selectedMenuNavItems[2])
        } else {
            for( let i=0; i<this.state.menuNavItems.length; i++) {
                if(changeValArray[2]===this.state.menuNavItems[i].navId) {
                    this.state.updatedSelectedMenuNavItems.push(this.state.menuNavItems[i])
                }
            }
        }

        if(changeValArray[3] === undefined) {
            this.state.updatedSelectedMenuNavItems.push(this.state.selectedMenuNavItems[3])
        } else {
            for( let i=0; i<this.state.menuNavItems.length; i++) {
                if(changeValArray[3]===this.state.menuNavItems[i].navId) {
                    this.state.updatedSelectedMenuNavItems.push(this.state.menuNavItems[i])
                }
            }
        }

        if(changeValArray[4] === undefined) {
            this.state.updatedSelectedMenuNavItems.push(this.state.selectedMenuNavItems[4])
        } else {
            for( let i=0; i<this.state.menuNavItems.length; i++) {
                if(changeValArray[4]===this.state.menuNavItems[i].navId) {
                    this.state.updatedSelectedMenuNavItems.push(this.state.menuNavItems[i])
                }
            }
        }

        await customService.buttonLayout(
            this.state.userId, 
            this.state.orgId, 
            this.state.landingPageValue, 
            this.state.button1ID,
            this.state.button2ID,
            this.state.button3ID,
            this.state.button4ID,
            this.state.button5ID,
            this.state.themeColor
        )

        let customData = sessionStorage.getItem('customData');
        let parsedCustomData = JSON.parse(customData);

        this.setState(() => ({
            isValid: parsedCustomData.uiU_OTP_VAL
        }));

        if(this.state.isValid==='VALID') {
            this.messages.show({severity: 'success', summary: 'Successfully Saved', life:1000});
        } else {
            this.messages.show({severity: 'error', summary: 'Save Unsuccessful', life:1000});
        }

        let userData= sessionStorage.getItem('userData');
        let parsedUserData = JSON.parse(userData);

        let newUserData = {
            "uiU_M1ORGID":parsedUserData.uiU_M1ORGID,
            "uiU_ID":parsedUserData.uiU_ID,
            "uiU_FN":parsedUserData.uiU_FN,
            "uiU_LN":parsedUserData.uiU_LN,
            "uiU_TOS":parsedUserData.uiU_TOS,
            "uiU_OTP_VAL":parsedUserData.uiU_OTP_VAL,
            "landingPage":this.state.landingPageValue,
            "button1":this.state.button1ID,
            "button2":this.state.button2ID,
            "button3":this.state.button3ID,
            "button4":this.state.button4ID,
            "button5":this.state.button5ID,
            "theme":this.state.themeColor,
            "token":parsedUserData.token,
            "secString":parsedUserData.secString
        }

        sessionStorage.setItem('userData', JSON.stringify(newUserData));

        if(this.state.updatedSelectedMenuNavItems.length<1){
            this.setState({ 
                isLoading: false,
                customWindowOpen: false,
            })
        } else {
            this.setState({ 
                isLoading: false,
                selectedMenuNavItems: this.state.updatedSelectedMenuNavItems,
                customWindowOpen: false,
                updatedSelectedMenuNavItems: [],
            })
        }
    }

    handleClose() {
        let userData = sessionStorage.getItem('userData');
        let parsedUserData = JSON.parse(userData);

        if(this.state.selectedValue==='') {
            this.setState({ selectedValue: parsedUserData.button1 })
        }
        if(this.state.selectedValue2==='') {
            this.setState({ selectedValue2: parsedUserData.button2 })
        }
        if(this.state.selectedValue3==='') {
            this.setState({ selectedValue3: parsedUserData.button3 })
        }
        if(this.state.selectedValue4==='') {
            this.setState({ selectedValue4: parsedUserData.button4 })
        }
        if(this.state.selectedValue5==='') {
            this.setState({ selectedValue5: parsedUserData.button5 })
        }
        if(this.state.landingPageValue==='') {
            this.setState({ landingPageValue: parsedUserData.landingPage })
        }

        this.setState({ 
            customWindowOpen: false,
            themeColor: this.state.oldTheme,
            oldTheme: ''
        });
    }

    ///NEED TO CLEAN THIS UP BELOW WHEN YOU HAVE TIME, make one func instead of five/////////////////////////////////////////
    handleChange = (event) => {
        const index=parseInt(event.target.value, 10);
        this.setState({ selectedValue: event.target.value });
        let i;
        for(i=0; i<this.state.menuNavItems.length; i++){
            if(this.state.menuNavItems[i].navId===index) {
                changeValString1=this.state.menuNavItems[i].navId;
                changeValArray[0]=changeValString1;
                this.setState({ button1ID: changeValArray[0] })
            }
        }
    }

    handleChange2 = (event) => {
        const index2=parseInt(event.target.value, 10);
        this.setState({ selectedValue2: event.target.value });
        let i;
        for(i=0; i<this.state.menuNavItems.length; i++){
            if(this.state.menuNavItems[i].navId===index2) {
                changeValString2=this.state.menuNavItems[i].navId;
                changeValArray[1]=changeValString2;
                this.setState({ button2ID: changeValArray[1] })
            }
        }
    }

    handleChange3 = (event) => {
        const index3=parseInt(event.target.value, 10);
        this.setState({ selectedValue3: event.target.value });
        let i;
        for(i=0; i<this.state.menuNavItems.length; i++){
            if(this.state.menuNavItems[i].navId===index3) {
                changeValString3=this.state.menuNavItems[i].navId;
                changeValArray[2]=changeValString3;
                this.setState({ button3ID: changeValArray[2] })
            }
        }
    }

    handleChange4 = (event) => {
        const index4=parseInt(event.target.value, 10);
        this.setState({ selectedValue4: event.target.value });
        let i;
        for(i=0; i<this.state.menuNavItems.length; i++){
            if(this.state.menuNavItems[i].navId===index4) {
                changeValString4=this.state.menuNavItems[i].navId;
                changeValArray[3]=changeValString4;
                this.setState({ button4ID: changeValArray[3] })
            }
        }
    }

    handleChange5 = (event) => {
        const index5=parseInt(event.target.value, 10);
        this.setState({ selectedValue5: event.target.value });
        let i;
        for(i=0; i<this.state.menuNavItems.length; i++){
            if(this.state.menuNavItems[i].navId===index5) {
                changeValString5=this.state.menuNavItems[i].navId;
                changeValArray[4]=changeValString5;
                this.setState({ button5ID: changeValArray[4] })
            }
        }
    }

    handleLandingPage = (event) => {
        this.setState({ landingPageValue: event.target.value })
    }

    handleSnackbarClose() {
        let msgData = {"viewedDrawer": true}
        sessionStorage.setItem('viewedDrawerData', JSON.stringify(msgData));
        this.setState({ welcomeMsg: false });
    }
  
    render() {
        const { toNewSupportCase, toSupportCases, toKeyHandOut, toKeyHandIn , licenseOpen, welcomeMsg, licenseData, toReports, toDevices, toKeysLost, toDoors, toRecords, toKeysPastDue, toDomains, toKeyRings, toCredTypes, toCredentials, toMasterSystems, toDepartments, toBuilidngGroups, toSecurityRoles, toStorage, toPeople, toCutKeys, toBuildings, toCores, toJobs } = this.state;
        const { classes } = this.props;
        if(toKeysLost) {
            return(
                <Redirect to='/keyslost' />
            )
        } else if(this.state.toKeysBroken) {
            return(
                <Redirect to='/keysbroken' />
            )
        } else if(this.state.toKeysDestroyed) {
            return(
                <Redirect to='/keysdestroyed' />
            )
        }  else if(this.state.toKeyHistory) {
            return(
                <Redirect to='/keyhistory' />
            )
        } else if(toPeople) {
            return(
                <Redirect to='/people' />
            )
        } else if(toCutKeys) {
            return(
                <Redirect to='/keys' />
            )
        } else if(toBuildings) {
            return(
                <Redirect to='/buildings' />
            )
        } else if(toCores) {
            return(
                <Redirect to='/cores' />
            )
        } else if(toRecords) {
            return(
                <Redirect to='/records' />
            )
        } else if(toStorage) {
            return(
                <Redirect to='/keystorage' />
            )
        } else if(toJobs) {
            return(
                <Redirect to='/jobs' />
            )
        } else if(toBuilidngGroups) {
            return(
                <Redirect to='/buildinggroups' />
            )
        } else if(toDepartments) {
            return(
                <Redirect to='/departments' />
            )
        } else if(toMasterSystems) {
            return(
                <Redirect to='/mastersystems' />
            )
        } else if(toCredentials) {
            return(
                <Redirect to='/credentials' />
            )
        } else if(toCredTypes) {
            return(
                <Redirect to='/credentialtypes' />
            )
        } else if(toKeyRings) {
            return(
                <Redirect to='/keyrings' />
            )
        } else if(toKeysPastDue) {
            return(
                <Redirect to='/keyspastdue' />
            )
        } else if(toDomains) {
            return(
                <Redirect to='/domains' />
            )
        } else if(toSecurityRoles) {
            return(
                <Redirect to='/securityRoles' />
            )
        } else if(toReports) {
            return(
                <Redirect to='/reports' />
            )
        } else if(toDoors) {
            return(
                <Redirect to='/doors' />
            )
        } else if(toDevices) {
            return(
                <Redirect to='/devices' />
            )
        } else if(toKeyHandOut) {
            return(
                <Redirect to='/keyhandout' />
            )
        } else if(toKeyHandIn) {
            return(
                <Redirect to='/keyhandin' />
            )
        } else if(toSupportCases) {
            return(
                <Redirect to='/supportcases' />
            );
        } else if(toNewSupportCase) {
            return(
                <Redirect to='/newsupportcase' />
            );
        } else {
        return(
            <ThemeProvider theme={theme}>
                <div className={classes.root}>
                <Timeout />
                    <Sidebar 
                        selectedMenuNavItems={this.state.selectedMenuNavItems} 
                        menuNavItems={this.state.menuNavItems}  
                        selectedValue={this.state.selectedValue}
                        selectedValue2={this.state.selectedValue2}
                        selectedValue3={this.state.selectedValue3}
                        selectedValue4={this.state.selectedValue4}
                        selectedValue5={this.state.selectedValue5}
                        landingPageValue={this.state.landingPageValue}
                        customWindowOpen={this.state.customWindowOpen} 
                        isError={this.state.isError}
                        isLoading={this.state.isLoading}
                        handleChange={this.handleChange}       
                        handleChange2={this.handleChange2} 
                        handleChange3={this.handleChange3} 
                        handleChange4={this.handleChange4} 
                        handleChange5={this.handleChange5}
                        handleLandingPage={this.handleLandingPage}  
                        handleSubmit={this.handleSubmit}    
                        handleClose={this.handleClose}
                        handleNavItemClick={this.handleNavItemClick}
                        handleSetTheme={this.handleSetTheme}
                        themeColor={this.state.themeColor}
                    />
                <TopBarBanner customizeClick={this.customizeClick} openLicense={this.openLicense} handleNavItemClick={this.handleNavItemClick}/>
                <main className={classes.content}>
                    <ProductWindow />
                    <Toolbar className={classes.toolbar}/>
                    {licenseData.licenseMessage==='' ? (null) : (<div className={classes.license}>{this.state.licenseData.licenseMessage}</div>)}
                    {welcomeMsg ? (
                    <div>
                        <Portal>
                            <Snackbar 
                            open={welcomeMsg} 
                            anchorOrigin={{vertical: 'top', horizontal: 'center' }}
                            autoHideDuration={1000} 
                            onClose={this.handleSnackbarClose}
                            >	
                                <SnackbarContent 
                                    className={classes.snackbarClass}
                                    elevation={6} 
                                    variant='outlined' 
                                    message={'Welcome ' + this.state.fName + ' ' + this.state.lName + '!'}
                                    style={{backgroundColor:'midnightblue', textAlign: 'center'}}
                                />	
                            </Snackbar>
                        </Portal>
                    </div>
                    ) : (
                    null
                    )  
                    }
                    <div>
                        <Dialog open={licenseOpen} onClose={this.closeLicense}>
                            <DialogTitle id="form-dialog-title">License Info</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                        <p>Organization: {licenseData.orgID}</p>
                                        <p>Full Licenses: {licenseData.numLicenses}</p>
                                        <p>Full Licensed Users: {licenseData.licensedUsers}</p>
                                        <p>View Only Licenses: {licenseData.num_VO_Licenses}</p>
                                        <p>View Only Licensed Users: {licenseData.licensed_VO_Users}</p>
                                        <p>License Expiration Date: {licenseData.licenseExpDate}</p>
                                </DialogContentText>
                                <Button color="primary" variant="outlined" onClick={this.closeLicense}>OK</Button>
                            </DialogContent>
                        </Dialog>
                    </div>
                    <Messages ref={(el) => this.messages = el} />
                    <div>{this.props.children}</div>
                </main>
            </div>                  
            </ThemeProvider>
        );}
    }
}

export default withStyles(styles, { withTheme: true })(Layout);