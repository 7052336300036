import React from 'react';

import {Messages} from 'primereact/messages';
import GeneralDatatable from '../components/GeneralDatatable';

class KeyHandInOutKeysDatatable extends React.Component {

    constructor(props) {
        super(props);

        this.state={
            keyData: [],
            selectedColumns: [
                {field:'uiY_DUEDATE', header:'Date Due'},
                {field:'uiY_SYSTEM', header:'System'},
                {field:'uiY_FULLCODE', header:'Full Code'},
                {field:'uiY_DESC', header:'Description'},
                {field:'bittings', header:'Bittings'},
                {field:'uiY_NOTES', header:'Notes'},  
                {field:'fullname', header:'Associate'},
                {field:'uiY_KEYWAY', header:'Keyway'},
                {field:'hookdesc', header:'Hook Description'}
            ],
            columnWidthTracker: "162.5,162.5,162.5,162.5,162.5,162.5,162.5,162.5,162.5",
            defaultOrder: ["uiY_DUEDATE", "uiY_SYSTEM", "uiY_FULLCODE", "uiY_DESC", "bittings", "uiY_NOTES", "uiY_STATUS", "uiY_KEYWAY", "hookdesc"],
        };
        this.handleWidth = this.handleWidth.bind(this);
        this.handleSelectedColumns = this.handleSelectedColumns.bind(this);
    }


    async componentDidMount() {
        let parsedTableData = JSON.parse(sessionStorage.getItem('keyDatatableTableData'));

        if(parsedTableData!==null) {
            if(parsedTableData.selectedColumns !== null) {
                this.setState({ selectedColumns: parsedTableData.selectedColumns })
            }
        }
    }

    handleWidth(width) {
        this.setState({ columnWidthTracker: width })
    }

    handleSelectedColumns(e) {
        this.setState({ selectedColumns: e })
    }

    render() {
        if(this.props.keyData.length===0&&!this.props.isLoading) {
            return(<div><h3>All keys are {this.props.keyStatus}.</h3></div>);
        } else {
            return(
                <div>
                    <Messages ref={(el) => this.messages = el} />
                    <GeneralDatatable 
                        columnArray = {[
                            {field:'uiY_DUEDATE', header:'Date Due'},
                            {field:'uiY_SYSTEM', header:'System'},
                            {field:'uiY_FULLCODE', header:'Full Code'},
                            {field:'uiY_DESC', header:'Description'},
                            {field:'bittings', header:'Bittings'},
                            {field:'uiY_NOTES', header:'Notes'},  
                            {field:'fullname', header:'Associate'},
                            {field:'uiY_KEYWAY', header:'Keyway'},
                            {field:'hookdesc', header:'Hook Description'}
                        ]}

                        csvHeaders = {[
                            {key:'uiY_DUEDATE', label:'Date Due'},
                            {key:'uiY_SYSTEM', label:'System'},
                            {key:'uiY_FULLCODE', label:'Full Code'},
                            {key:'uiY_DESC', label:'Description'},
                            {key:'bittings', label:'Bittings'},
                            {key:'uiY_NOTES', label:'Notes'},  
                            {key:'fullname', label:'Associate'},
                            {key:'uiY_KEYWAY', label:'Keyway'},
                            {key:'hookdesc', label:'Hook Description'}
                        ]}
                        
                        defaultOrder={["uiY_DUEDATE", "uiY_SYSTEM", "uiY_FULLCODE", "uiY_DESC", "bittings", "uiY_NOTES", "fullname", "uiY_KEYWAY", "hookdesc"]}

                        tableRecords = {this.props.keyData}
                        pageNumber={23}
                        tableName={'Keys'}
                        exportFileName={'keys.csv'}
                        tableData={'keyHandOutInDatatableTableData'}
                        filterData={'keyHandOutInDatatableFilterData'}
                        columnWidthTracker={this.state.columnWidthTracker}
                        isLoading={this.props.isLoading}
                        selectedColumns={this.state.selectedColumns}
                        handleRowClick={this.props.nextStep}
                        handleWidth={this.handleWidth}
                        handleSelectedColumns={this.handleSelectedColumns}   
                    />
                </div>
            );
        }
    }
}

export default KeyHandInOutKeysDatatable;