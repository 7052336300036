import React from 'react';

import { TableService } from '../services/FetchServices';

import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Messages} from 'primereact/messages';
import {InputText} from 'primereact/inputtext';
import {MultiSelect} from 'primereact/multiselect';
import {Button} from 'primereact/button';
import { CSVLink } from "react-csv";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

let tempString='';
let tempArray=[];
let tempWidthValue;

//Page Six for API, page five for internal routing
class GeneralDatatable extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            globalFilter: null,
            selectedColumns: this.props.columnArray,
            columnWidthsTracker: this.props.columnWidthTracker,
            defaultOrder: this.props.defaultOrder,
            csvRecords: null,
            filteredState: false
        };

        this.onColumnToggle = this.onColumnToggle.bind(this);
        this.handleStateSave = this.handleStateSave.bind(this);
        this.handleRestoreState = this.handleRestoreState.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleReorder = this.handleReorder.bind(this);
        this.showResults = this.showResults.bind(this);
        this.bodyTemplate = this.bodyTemplate.bind(this);
        this.pathLookup = this.pathLookup.bind(this);
        this.onStateRestore = this.onStateRestore.bind(this);
        this.sleep = this.sleep.bind(this);
        this.onSystemDropdownChange = this.onSystemDropdownChange.bind(this);
        this.onWohSystemDropdownChange = this.onWohSystemDropdownChange.bind(this);
        this.onWohStatusDropdownChange = this.onWohStatusDropdownChange.bind(this);
        this.onKeyLevelDropdownChange = this.onKeyLevelDropdownChange.bind(this);
        this.itemTemplate = this.itemTemplate.bind(this);
    }

    async componentDidMount() {
        let parsedTableData = JSON.parse(sessionStorage.getItem(this.props.tableData));
        let parsedFilterData = JSON.parse(sessionStorage.getItem(this.props.filterData));

        if(parsedFilterData !== null) {
            this.setState({isFilterData: true})
        } else {
            this.setState({isFilterData: false})
        }

        if(parsedTableData!==null) {
            if(parsedTableData.columnWidths===null) {
                this.props.handleWidth("146.3,146.3,146.3,146.3,146.3,146.3,146.3,146.3,146.3,146.3");
            } else {
                this.props.handleWidth(parsedTableData.columnWidths);
            }
        }

        // if(this.props.systemDropdownOptions !== null) {
        //     this.setState({selectedSystems: this.props.systemDropdownOptions})
        // }
    }

    onColumnToggle(event) {
        event.preventDefault();
        let selectedColumns = event.value;
        let orderedSelectedColumns = this.props.columnArray.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
        this.props.handleSelectedColumns(orderedSelectedColumns);

        switch (event.value.length) {
            case 0:
                this.props.handleSelectedColumns(this.props.columnArray);
                break;
            case 1:
                this.props.handleWidth("1463");
                break;
            case 2:
                this.props.handleWidth("731.5,731.5");
                break;
            case 3:
                this.props.handleWidth("487.6,487.6,487.6");
                break;
            case 4:               
                this.props.handleWidth("365.75,365.75,365.75,365.75");
                break;
            case 5:
                this.props.handleWidth("292.6,292.6,292.6,292.6,292.6");
                break;
            case 6:
                this.props.handleWidth("243.9,243.9,243.9,243.9,243.9,243.9");
                break;
            case 7:
                this.props.handleWidth("209,209,209,209,209,209,209");
                break;
            case 8:
                this.props.handleWidth("182.875,182.875,182.875,182.875,182.875,182.875,182.875,182.875");
                break;
            case 9:
                this.props.handleWidth("162.5,162.5,162.5,162.5,162.5,162.5,162.5,162.5,162.5");
                break;
            case 10:
                this.props.handleWidth("146.3,146.3,146.3,146.3,146.3,146.3,146.3,146.3,146.3,146.3");
                break;
            case 11:
                this.props.handleWidth("133,133,133,133,133,133,133,133,133,133,133");
                break;
            case 12:
                this.props.handleWidth("121.91,121.91,121.91,121.91,121.91,121.91,121.91,121.91,121.91,121.91,121.91,121.91");
                break;
            default:
                console.log('Width Error, general datatable, line 111');
        }
    }

    handleReorder(e) {
        switch (e.columns.length) {
            case 0:
                this.props.handleSelectedColumns(this.props.columnArray);
                break;
            case 1:
                this.props.handleWidth("1463");
                break;
            case 2:
                this.props.handleWidth("731.5,731.5");
                break;
            case 3:
                this.props.handleWidth("487.6,487.6,487.6");
                break;
            case 4:               
                this.props.handleWidth("365.75,365.75,365.75,365.75");
                break;
            case 5:
                this.props.handleWidth("292.6,292.6,292.6,292.6,292.6");
                break;
            case 6:
                this.props.handleWidth("243.9,243.9,243.9,243.9,243.9,243.9");
                break;
            case 7:
                this.props.handleWidth("209,209,209,209,209,209,209");
                break;
            case 8:
                this.props.handleWidth("182.875,182.875,182.875,182.875,182.875,182.875,182.875,182.875");
                break;
            case 9:
                this.props.handleWidth("162.5,162.5,162.5,162.5,162.5,162.5,162.5,162.5,162.5");
                break;
            case 10:
                this.props.handleWidth("146.3,146.3,146.3,146.3,146.3,146.3,146.3,146.3,146.3,146.3");
                break;
            case 11:
                this.props.handleWidth("133,133,133,133,133,133,133,133,133,133,133");
                break;
            case 12:
                this.props.handleWidth("121.91,121.91,121.91,121.91,121.91,121.91,121.91,121.91,121.91,121.91,121.91,121.91");
                break;
            default:
                console.log('Width Error, general datatable, line 157');
        }        
    }

    handleStateSave() {
        if(this.dt !== null) {
            if(this.dt.state.filters !== null) {
                let filterData = this.dt.state.filters;
                sessionStorage.setItem(this.props.filterData, JSON.stringify(filterData));
            }
        } 
    }

    async onStateRestore(e) {
        if(window.screen.width>1250&&window.screen.width<1500) {
            let tempArray = e.selectedColumns;        
            await this.sleep(100);
            this.props.handleSelectedColumns([tempArray.pop()])
            await this.sleep(100);
            this.props.handleSelectedColumns(this.props.columnArray)
        }
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    handleRestoreState() {
        let userData = JSON.parse(sessionStorage.getItem("userData"));
        let x = JSON.parse(sessionStorage.getItem(this.props.tableData));
        let filterData = JSON.parse(sessionStorage.getItem(this.props.filterData));
        if(filterData&&x!==null) {
            let newTable = {
                "orgID": x.orgID,
                "user_ID": x.user_ID,
                "page_ID": x.page_ID,
                "rows": x.rows,
                "columnOrder": x.columnOrder,
                "columnWidths": x.columnWidths,
                "filters": filterData,
                "selectedColumns": x.selectedColumns
            }
            sessionStorage.setItem(this.props.tableData, JSON.stringify(newTable));
            let newTableData = JSON.parse(sessionStorage.getItem(this.props.tableData));
            return(newTableData);
        } else if(x===null) {
            let newUsers = {
                "orgID": userData.uiU_M1ORGID,
                "user_ID": userData.uiU_ID,
                "page_ID": this.props.pageNumber,
                "rows": 10,
                "columnOrder": this.props.defaultOrder,
                "columnWidths": this.props.columnWidthTracker,
                "selectedColumns": this.props.columnArray
            }
            return(newUsers)
        } else {
            return(x);
        }
    }

    handleSave = async e => {
        e.preventDefault();
        let userData = JSON.parse(sessionStorage.getItem('userData'));
        let filterData = JSON.parse(sessionStorage.getItem(this.props.filterData));
        if(this.dt.state.columnOrder===undefined) {
            await TableService.saveTableState(userData.uiU_M1ORGID, userData.uiU_ID, this.props.pageNumber, this.dt.state.rows, this.props.columnWidthTracker, this.props.defaultOrder, this.props.selectedColumns).then(data => this.showResults(data));
            let newTable = {
                "orgID": userData.uiU_M1ORGID,
                "user_ID": userData.uiU_ID,
                "page_ID": this.props.pageNumber,
                "rows": this.dt.state.rows, 
                "columnOrder": this.props.defaultOrder,
                "columnWidths": this.props.columnWidthTracker,
                "filters": filterData,
                "selectedColumns": this.props.selectedColumns
            }
            sessionStorage.setItem(this.props.tableData, JSON.stringify(newTable));
        } else {
            await TableService.saveTableState(userData.uiU_M1ORGID, userData.uiU_ID, this.props.pageNumber, this.dt.state.rows, this.props.columnWidthTracker, this.dt.state.columnOrder, this.props.selectedColumns).then(data => this.showResults(data));
            let newTable = {
                "orgID": userData.uiU_M1ORGID,
                "user_ID": userData.uiU_ID,
                "page_ID": this.props.pageNumber,
                "rows": this.dt.state.rows,
                "columnOrder": this.dt.state.columnOrder,
                "columnWidths": this.props.columnWidthTracker,
                "filters": filterData,
                "selectedColumns": this.props.selectedColumns
            }
            sessionStorage.setItem(this.props.tableData, JSON.stringify(newTable));
        }
    }

    handleResize(e) {
        let delta = e.delta;
        tempString='';
        tempArray=[];
        tempWidthValue=0;
        if(delta>0 || delta<0) {
            tempString = this.props.columnWidthTracker;
            tempArray = tempString.split(',');
            tempArray[e.element.cellIndex] = e.element.clientWidth.toString();
            tempWidthValue = parseFloat(tempArray[e.element.cellIndex + 1]);
            tempWidthValue = tempWidthValue - delta;
            tempArray[e.element.cellIndex + 1] = tempWidthValue.toString();
            tempArray = tempArray.toString();
            this.props.handleWidth(tempArray);
        } else {
            console.log('Resize Error, General Datatable, line 258')
        }
    }
    
    showResults(data) {
        if(data) {
            this.messages.show({severity: 'success', summary: 'Successfully Saved', life:1000});
        } else {
            this.messages.show({severity: 'error', summary: 'Save Unsuccessful', life:1000});
        }
    } 

    handleChange(data) {
        this.setState({ csvRecords: data })
    }

    onSystemDropdownChange(e) {
        // console.log(e)
        this.dt.filter(e.value, 'system', 'in');
        this.setState({selectedSystems: e.value});
    }

    onWohSystemDropdownChange(e) {
        // console.log(e)
        this.dt.filter(e.value, 'woH_SYSNAME', 'in');
        this.setState({selectedSystems: e.value});
    }

    onWohStatusDropdownChange(e) {
        // console.log(e)
        this.dt.filter(e.value, 'woH_STATUS', 'in');
        this.setState({selectedStatus: e.value});
    }

    onKeyLevelDropdownChange(e) {
        // console.log(e)
        this.dt.filter(e.value, 'keyLevel', 'in');
        this.setState({selectedKeyLevels: e.value});
    }

    renderHeader() {
        if(isBrowser && this.props.hasButton) {
            return (
                <div className='p-grid'>
                    <div className='p-col-12 tbPadding'>
                        <div style={{float:'left'}} >
                            <div className='table-title' style={{float:'left', marginRight:'10px'}}>{this.props.tableName}</div>
                            <MultiSelect style={{float:'left', marginRight:'10px'}} value={this.props.selectedColumns} options={this.props.columnArray} optionLabel="header"
                                onChange={this.onColumnToggle} fixedPlaceholder placeholder={<div style={{paddingTop: '0.429rem'}}>Selected Columns</div>}
                            />
                            <InputText style={{float:'left'}} type ='search' onInput={(e) => this.setState({globalFilter: e.target.value})} placeholder='Search' />
                        </div>
                        <div style={{float:'right'}}>
                            <div style={{float:'left', marginRight:'10px'}}>
                                <Button type='button' label={this.props.buttonLabel} icon='pi pi-plus' onClick={this.props.onButtonClick}/>
                            </div>
                            <div style={{float:'left', marginRight:'10px'}}>
                                <CSVLink 
                                    data={this.state.csvRecords ? this.state.csvRecords : this.props.tableRecords} 
                                    headers={this.props.csvHeader}
                                    filename={this.props.exportFileName}
                                >
                                    <Button type='button' icon='pi pi-external-link' iconPos='left' label='CSV Export' />
                                </CSVLink>
                            </div>
                            <div style={{float:'left'}}>
                                <Button type='button' icon='pi pi-save' iconPos='left' label='Save Layout' onClick={this.handleSave} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if(isBrowser) {
            return (
                <div className='p-grid'>
                    <div className='p-col-12 tbPadding'>
                        <div style={{float:'left'}} >
                            <div className='table-title' style={{float:'left', marginRight:'10px'}}>{this.props.tableName}</div>
                            <MultiSelect style={{float:'left', marginRight:'10px'}} value={this.props.selectedColumns} options={this.props.columnArray} optionLabel='header' onChange={this.onColumnToggle} fixedPlaceholder placeholder={<div style={{paddingTop: '0.429rem'}}>Selected Columns</div>}/>
                            <InputText style={{float:'left'}} type ='search' onInput={(e) => this.setState({globalFilter: e.target.value})} placeholder='Search' />
                        </div>
                        <div style={{float:'right'}}>
                            <div style={{float:'left', marginRight:'10px'}}>
                                <CSVLink 
                                    data={this.state.csvRecords ? this.state.csvRecords : this.props.tableRecords} 
                                    headers={this.props.csvHeader}
                                    filename={this.props.exportFileName}
                                >
                                    <Button type='button' icon='pi pi-external-link' iconPos='left' label='CSV Export' />
                                </CSVLink>
                            </div>
                            <div style={{float:'left'}}>
                                <Button type='button' icon='pi pi-save' iconPos='left' label='Save Layout' onClick={this.handleSave} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if(isMobile && this.props.hasButton) {
            return(
                <div>
                    <div className='table-title'>{this.props.tableName}</div>
                    <div><InputText type ='search' onInput={(e) => this.setState({globalFilter: e.target.value})} placeholder='Search' /></div>
                    <div style={{marginTop:'5px'}}><Button type='button' label={this.props.buttonLabel} icon='pi pi-plus' onClick={this.props.onButtonClick}/></div>
                </div>
            );
        } else if(isMobile) {
            return(
                <div>
                    <div className='table-title'>{this.props.tableName}</div>
                    <div><InputText type ='search' onInput={(e) => this.setState({globalFilter: e.target.value})} placeholder='Search' /></div>
                </div>
            );
        }

    } 

    pathLookup(obj, path) {
        let parts = path.split(".");
        if (parts.length===1){
            return obj[parts[0]];
        }
        return this.pathLookup(obj[parts[0]], parts.slice(1).join("."));
    }
    
    bodyTemplate(rowData, field, header) {
        return(
            <React.Fragment>
                <span className='p-column-title'>{header}</span>
                {this.pathLookup(rowData,field.toString())}
            </React.Fragment>

        );
    }

    itemTemplate(option) {
        // console.log(option)
        return (
            <div>{option.label}</div>
        )
    }

    render(){
        // console.log("selected systen props - ", this.state.selectedSystems)
        const header=this.renderHeader();

        const columnComponents = this.props.selectedColumns.map(col=> {
            if(col.field==='system' && this.props.pageNumber === 6) {
                const systemFilter = <MultiSelect itemTemplate={this.itemTemplate} value={this.state.selectedSystems} 
                options={this.props.systemDropdownOptions} onChange={(e) => this.onSystemDropdownChange(e)} 
                placeholder="All" className="p-column-filter-multi" />
                return <Column field={col.field} header={col.header} sortable filter filterElement={systemFilter}  style={{textAlign: 'left', fontSize:'small', margin:'0px'}}/>
            } else if(col.field==='woH_STATUS' && this.props.pageNumber === 7) {
                const systemFilter = <MultiSelect itemTemplate={this.itemTemplate} value={this.state.selectedStatus} 
                options={this.props.statusDropdownOptions} onChange={(e) => this.onWohStatusDropdownChange(e)} 
                placeholder="All" className="p-column-filter-multi" />
                return <Column field={col.field} header={col.header} sortable filter filterElement={systemFilter}  style={{textAlign: 'left', fontSize:'small', margin:'0px'}}/>
            } else if(col.field==='woH_SYSNAME' && this.props.pageNumber === 7) {
                const systemFilter = <MultiSelect itemTemplate={this.itemTemplate} value={this.state.selectedSystems} 
                options={this.props.systemDropdownOptions} onChange={(e) => this.onWohSystemDropdownChange(e)} 
                placeholder="All" className="p-column-filter-multi" />
                return <Column field={col.field} header={col.header} sortable filter filterElement={systemFilter}  style={{textAlign: 'left', fontSize:'small', margin:'0px'}}/>
            }  else if(col.field==='keyLevel' && this.props.pageNumber === 6) {
                const systemFilter = <MultiSelect itemTemplate={this.itemTemplate} value={this.state.selectedKeyLevels} 
                options={this.props.keyLevelDropdownOptions} onChange={(e) => this.onKeyLevelDropdownChange(e)} 
                placeholder="All" className="p-column-filter-multi" />
                return <Column field={col.field} header={col.header} sortable filter filterElement={systemFilter}  style={{textAlign: 'left', fontSize:'small', margin:'0px'}}/>
            } else if(col.field==='uiU_NUMKEYS' || col.field==='bittings' || col.field==='uiY_DUEDATE' || col.field==='evenT_DATE_TIME' || col.field==='zip' || col.field==='woD_LINENUM' 
                || col.field==='evenT_DATE' || col.field==='closE_DATE' || col.field==='creatE_DATE' || col.field==='woH_ID' || col.field==='woH_COMPLETEDATE' || col.field==='woH_DUEDATE' 
                || col.field==='woH_CREATEDDATE' || col.field==='woD_QTY' || col.field==='uiS_PINS' || col.field==='keyMarkID' || col.field==='coreMarkID' || col.field==='keysCut' 
                || col.field==='coresPinned' || col.field==='uiY_ISSUEDATE' || col.field==='uiY_HOOKNUMBER' || col.field==='sn' || col.field==='uiB_NUMHOOKS' || col.field==='numPeople') {
                return <Column key={col.field} field={col.field} responsive header={col.header} sortable filter filterMatchMode="contains" filterPlaceholder={col.header} style={{textAlign: 'center', fontSize:'small', margin:'0px'}} />;
            } else {
                return <Column key={col.field} field={col.field} responsive header={col.header} sortable filter filterMatchMode="contains" filterPlaceholder={col.header} style={{textAlign: 'left', fontSize:'small', margin:'0px'}} />;
            }
        })

        const staticColumnComponents = this.props.columnArray.map(col=> {
            return <Column field={col.field} header={col.header} body={(e) => this.bodyTemplate(e, col.field, col.header)} />;
        })
        
        if(this.props.isLoading) {
            return(<div style={{marginTop: '200px'}}><i className="pi pi-spin pi-spinner" style={{'fontSize': '3em'}}></i></div>);
        } else {
            return(
                    <div className='p-shadow-2' style={{maxWidth:'100%'}}>
                        <Messages style={{textAlign:'center'}} ref={(el) => this.messages = el} />
                        <BrowserView>
                            <DataTable 
                                ref={(el) => this.dt = el}
                                value={this.props.tableRecords} 
                                scrollable scrollHeight="72vh"
                                header={header}
                                stateStorage="custom" customSaveState={this.handleStateSave} customRestoreState={this.handleRestoreState} onStateRestore={e => this.onStateRestore(e)}
                                loading={this.props.isLoading} loadingIcon="pi pi-spinner"
                                responsive={true} resizableColumns={true} reorderableColumns={true}
                                onColumnResizeEnd={e => this.handleResize(e)} onColReorder={e => this.handleReorder(e)}
                                onRowClick={e => this.props.handleRowClick(e)}
                                className="p-datatable-striped p-datatable-sm" columnResizeMode="fit"
                                dataKey={this.props.dataKey} globalFilter={this.state.globalFilter}
                                paginator={true} emptyMessage="No records found" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" 
                                rows={10} rowsPerPageOptions={[10, 25, 50]} rowHover onValueChange={filteredData => this.handleChange(filteredData)}
                            >
                                {columnComponents}
                            </DataTable>
                        </BrowserView>
                        <MobileView>
                            <div className='datatable-responsive-mobile'>
                                <div className='card'>
                                    <DataTable className='p-datatable-responsive-mobile' style={{ marginBottom:'40px' }} onRowClick={e => this.props.handleRowClick(e)}
                                        value={this.props.tableRecords} 
                                        loading={this.props.isLoading} loadingIcon="pi pi-spinner"
                                        header={header} rows={10}
                                        paginator={true} paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                                        globalFilter={this.state.globalFilter} emptyMessage="No records found" 
                                        onValueChange={filteredData => this.handleChange(filteredData)}
                                    >
                                        {staticColumnComponents}
                                    </DataTable>
                                </div>
                            </div>
                        </MobileView>
                    </div>
                
            );
        }
    }
}

export default GeneralDatatable;