import React from 'react';

import GeneralDatatable from '../components/GeneralDatatable';

import {Messages} from 'primereact/messages';
import {Button} from 'primereact/button';

class JobDetailsTable extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            selectedColumns: [
                {field:'woD_LINENUM', header:'Line #'},
                {field:'woD_SYSTEM', header:'System'},
                {field:'woD_TYPE', header:'Keys/Cores'},
                {field:'woD_KEYLOCKMARK', header:'Marking'},
                {field:'woD_QTY', header:'QTY'},
                {field:'woD_RECORDDESC', header:'Record Description'},  
                {field:'woD_KEYWAY', header:'Keyway'},
                {field:'woD_UIDREQ', header:'UID Req?'},
                {field:'woD_UIDSTRING', header:'UID Cmplt?'}    
            ],
            columnWidthTracker: "162.5,162.5,162.5,162.5,162.5,162.5,162.5,162.5,162.5",
            displayRowD: false,
            secRole: '',
            disabled: false,
            isLoading: true
        };

        this.handleWidth = this.handleWidth.bind(this);
        this.handleSelectedColumns = this.handleSelectedColumns.bind(this);
        this.showResults = this.showResults.bind(this);
    }

    async componentDidMount() {
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
        let parsedTableData = JSON.parse(sessionStorage.getItem('jobDetailsTableData'));

        if(parsedTableData!==null) {
            if(parsedTableData.selectedColumns !== null) {
                this.setState({ selectedColumns: parsedTableData.selectedColumns })
            }
        }

        this.setState({ 
            isLoading: false, 
            secRole: parsedUserData.secString[8],
        })
    }

    handleWidth(width) {
        this.setState({ columnWidthTracker: width })
    }

    handleSelectedColumns(e) {
        this.setState({ selectedColumns: e })
    }  

    showResults(data) {
        if(data.success) {
            this.messages.show({severity: 'success', summary: data.message, life:1000});
        } else {
            this.messages.show({severity: 'error', summary: data.message, life:3000});
        }
    }

    render(){
        if(this.props.jobDetails.length===0&&this.props.hasButton) {
            return(<div><Button type='button' icon='pi pi-plus' label='Add Job Details' onClick={this.props.addJobDetails} style={{margin:'auto'}} /></div>)
        } else {
            return(
                <div className='container-div'>
                    <Messages ref={(el) => this.messages = el} />
                    <div>
                        <GeneralDatatable 
                            columnArray = {[
                                {field:'woD_LINENUM', header:'Line #'},
                                {field:'woD_SYSTEM', header:'System'},
                                {field:'woD_TYPE', header:'Keys/Cores'},
                                {field:'woD_KEYLOCKMARK', header:'Marking'},
                                {field:'woD_QTY', header:'QTY'},
                                {field:'woD_RECORDDESC', header:'Record Description'},  
                                {field:'woD_KEYWAY', header:'Keyway'},
                                {field:'woD_UIDREQ', header:'UID Req?'},
                                {field:'woD_UIDSTRING', header:'UID Cmplt?'}  
                            ]}

                            csvHeader = {[
                                {key:'woD_LINENUM', label:'Line #'},
                                {key:'woD_SYSTEM', label:'System'},
                                {key:'woD_TYPE', label:'Keys/Cores'},
                                {key:'woD_KEYLOCKMARK', label:'Marking'},
                                {key:'woD_QTY', label:'QTY'},
                                {key:'woD_RECORDDESC', label:'Record Description'},  
                                {key:'woD_KEYWAY', label:'Keyway'},
                                {key:'woD_UIDREQ', label:'UID Req?'},
                                {key:'woD_UIDSTRING', label:'UID Cmplt?'} 
                            ]}
                            
                            defaultOrder={["woD_LINENUM", "woD_SYSTEM", "woD_TYPE", "woD_KEYLOCKMARK", "woD_QTY", "woD_RECORDDESC", "woD_KEYWAY", "woD_UIDREQ", "woD_UIDSTRING"]}

                            tableRecords = {this.props.jobDetails}
                            pageNumber={31}
                            tableName={'Job Details'}
                            exportFileName={'jobDetails.csv'}
                            tableData={'jobDetailsTableData'}
                            filterData={'jobDetailsFilterData'}
                            columnWidthTracker={this.state.columnWidthTracker}
                            isLoading={this.state.isLoading}
                            selectedColumns={this.state.selectedColumns}
                            handleRowClick={this.props.editRecord}
                            handleWidth={this.handleWidth}
                            handleSelectedColumns={this.handleSelectedColumns}   
                            hasButton={this.props.hasButton}
                            buttonLabel={'Add Job Details'}
                            onButtonClick={this.props.addJobDetails}
                            dataKey={'woD_ID'}
                        />
                    </div>                        
                </div>
            );
        }
    }
}

export default JobDetailsTable;