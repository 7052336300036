import React from 'react';

import {Messages} from 'primereact/messages';
import GeneralDatatable from '../components/GeneralDatatable';

class PeopleKeysDatatable extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            keyData: [],
            selectedColumns: [
                {field:'uiY_DUEDATE', header:'Date Due'},
                {field:'uiY_SYSTEM', header:'System'},
                {field:'uiY_FULLCODE', header:'Full Code'},
                {field:'uiY_DESC', header:'Description'},
                {field:'bittings', header:'Bittings'},
                {field:'uiY_NOTES', header:'Notes'},  
                {field:'uiY_STATUS', header:'Status'},
                {field:'uiY_KEYWAY', header:'Keyway'},
                {field:'hookdesc', header:'Hook Description'}
            ],
            columnWidthTracker: "162.5,162.5,162.5,162.5,162.5,162.5,162.5,162.5,162.5",
        };
        this.showResults = this.showResults.bind(this);
        this.handleWidth = this.handleWidth.bind(this);
        this.handleSelectedColumns = this.handleSelectedColumns.bind(this);
        this.editRecord = this.editRecord.bind(this);
    }

    handleWidth(width) {
        this.setState({ columnWidthTracker: width })
    }

    handleSelectedColumns(e) {
        this.setState({ selectedColumns: e })
    }

    componentDidMount() {
        this.setState({isLoading: false})
    }

    editRecord() {
        return;
    }

    showResults(data) {
        if(data) {
            this.messages.show({severity: 'success', summary: 'Successfully Saved', life:1000});
        } else {
            this.messages.show({severity: 'error', summary: 'Save Unsuccessful', life:1000});
        }
    } 

    render() {
        return(
            <div>
                <Messages ref={(el) => this.messages = el} />
                <div>
                    <GeneralDatatable 
                        columnArray = {[
                            {field:'uiY_DUEDATE', header:'Date Due'},
                            {field:'uiY_SYSTEM', header:'System'},
                            {field:'uiY_FULLCODE', header:'Full Code'},
                            {field:'uiY_DESC', header:'Description'},
                            {field:'bittings', header:'Bittings'},
                            {field:'uiY_NOTES', header:'Notes'},  
                            {field:'uiY_STATUS', header:'Status'},
                            {field:'uiY_KEYWAY', header:'Keyway'},
                            {field:'hookdesc', header:'Hook Description'}
                        ]}

                        csvHeader = {[
                            {key:'uiY_DUEDATE', label:'Date Due'},
                            {key:'uiY_SYSTEM', label:'System'},
                            {key:'uiY_FULLCODE', label:'Full Code'},
                            {key:'uiY_DESC', label:'Description'},
                            {key:'bittings', label:'Bittings'},
                            {key:'uiY_NOTES', label:'Notes'},  
                            {key:'uiY_STATUS', label:'Status'},
                            {key:'uiY_KEYWAY', label:'Keyway'},
                            {key:'hookdesc', label:'Hook Description'}
                        ]}
                        
                        defaultOrder={["uiY_DUEDATE", "uiY_SYSTEM", "uiY_FULLCODE", "uiY_DESC", "bittings", "uiY_NOTES", "uiY_STATUS", "uiY_KEYWAY", "hookdesc"]}

                        tableRecords = {this.props.keyData}
                        pageNumber={24}
                        tableName={'Issued Keys'}
                        exportFileName={'LVE-issued-keys.csv'}
                        tableData={'PeopleKeyDatatableTableData'}
                        filterData={'PeopleKeyDatatableFilterData'}
                        columnWidthTracker={this.state.columnWidthTracker}
                        isLoading={this.props.isLoading}
                        selectedColumns={this.state.selectedColumns}
                        handleRowClick={this.editRecord}
                        handleWidth={this.handleWidth}
                        handleSelectedColumns={this.handleSelectedColumns}   
                    />
                </div>
            </div>
        );
    }
}

export default PeopleKeysDatatable;