import React from 'react';
import { Redirect } from 'react-router-dom';

class NoMatch extends React.Component {
    state = {
        seconds: 5,
        redirectBack: false
    }

    componentDidMount() {
        this.myInterval = setInterval(() => {
            const { seconds } = this.state;

            if (seconds>0) {
                this.setState(({ seconds }) => ({
                    seconds: seconds - 1
                }));
            }
            if (seconds === 0) {
                clearInterval(this.myInterval);
                this.setState({ toLogin: true })
            } 
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.myInverval);
    }

    render() {
        if(this.state.toLogin) {
            return(
                <Redirect to='/login' />
            );
        } else {
        return(
            <div>
                <h1>This Page Does Not Exist</h1>
                <h3>Redirecting in {this.state.seconds} seconds...</h3>
            </div>
        );}
    }
}

export default NoMatch;