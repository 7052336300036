import React from 'react';

import KeysComponent from '../components/KeysComponent';
import { CoreService, RecordsService, PeopleService } from '../services/FetchServices';
import Layout from '../components/Layout'
import GeneralDatatable from '../components/GeneralDatatable';
import TextBox from '../components/inputComponents/textbox';
import Label from '../components/inputComponents/Label';

import {Button} from 'primereact/button';
import {Messages} from 'primereact/messages';
import {Dialog} from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { TabView,TabPanel } from 'primereact/tabview';
import { ScrollPanel } from 'primereact/scrollpanel';
import IndividualCores from '../components/IndividualCores';  
import { BrowserView, MobileView } from 'react-device-detect';

//Page Six for API, page five for internal routing
class Records extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            records: [],
            selectedColumns: [
                {field:'coreMark', header:'Core Marking'},
                {field:'keyMark', header:'Key Marking'},
                {field:'coreMarkID', header:'Core Record ID'},
                {field:'keyMarkID', header:'Key Record ID'},
                {field:'system', header:'System'},
                {field:'keysCut', header:'Keys Cut'},  
                {field:'coresPinned', header:'Cores Pinned'},
                {field:'keyLevel', header:'Key Level'},
                {field:'keySeries', header:'Key Series'},     
                {field:'description', header:'Description'},
                {field:'keyway', header:'Keyway'}
            ],
            columnWidthTracker: "133,133,133,133,133,133,133,133,133,133,133",
            displayRowD: false,
            secRole: '',
            disabled: false,
            isLoading: true,
            activePinIndex: 0,
            activeIndex: 0, 
            newCoreKeyMark: "",
            editMarkText: "",
            editMarkHeader: "",
            editMarkDialog: false, 
            lengthError: false,
            changeMarkDisabled: true
        };

        this.handleWidth = this.handleWidth.bind(this);
        this.handleSelectedColumns = this.handleSelectedColumns.bind(this);
        this.editRecord = this.editRecord.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleLoading = this.handleLoading.bind(this);
        this.saveRecord = this.saveRecord.bind(this);
        this.showResults = this.showResults.bind(this);
        this.editCore = this.editCore.bind(this);
        this.editKeyMark = this.editKeyMark.bind(this);
        this.editCoreMark = this.editCoreMark.bind(this);
        this.changeKeyMark = this.changeKeyMark.bind(this);
        this.changeCoreMark = this.changeCoreMark.bind(this);
        this.handleCloseMarkDialog = this.handleCloseMarkDialog.bind(this);
    }

    async componentDidMount() {
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
        let parsedTableData = JSON.parse(sessionStorage.getItem('recordsTableData'));
        
        let tempArray = await RecordsService.fetchSystems( parsedUserData.uiU_M1ORGID, 0 );
        tempArray = tempArray.systemlist;
        let newArray = [];
        
        for(let i=0; i<tempArray.length; i++) {
            const obj = {};
            obj.value = tempArray[i].uiS_SYSNAME;
            obj.label = tempArray[i].uiS_SYSNAME;
            newArray.push(obj)
        }
        this.setState({ systemDropdownOptions: newArray})

        let levelTempArray = await RecordsService.FetchKeyLevels( parsedUserData.uiU_M1ORGID );
        levelTempArray = levelTempArray.levelList;
        let levelNewArray = [];
        
        for(let i=0; i<levelTempArray.length; i++) {
            const obj = {};
            obj.value = levelTempArray[i].keyLevel;
            obj.label = levelTempArray[i].keyLevel;
            levelNewArray.push(obj)
        }
        this.setState({ keyLevelDropdownOptions: levelNewArray})

        if(parsedUserData.secString[8]==='2') {
            this.setState({disabled: true})
        }

        if(parsedTableData!==null) {
            if(parsedTableData.selectedColumns !== null) {
                this.setState({ selectedColumns: parsedTableData.selectedColumns })
            }
        }

        await RecordsService.fetchRecords( parsedUserData.uiU_M1ORGID, 0, "" ).then(data => 
            this.setState({ 
                records: data.recordlist, 
                isLoading: false, 
                secRole: parsedUserData.secString[8],
                orgId: parsedUserData.uiU_M1ORGID
        }));

        const loggedInData = await PeopleService.fetchUsers( parsedUserData.uiU_M1ORGID, "", parsedUserData.uiU_ID )
        const loggedInEmail = loggedInData.user[0].uiU_EMAIL;
        const loggedInEmailArr = loggedInEmail.split("@");
        
        if(loggedInEmailArr[1] === "mwspi.com") {
            this.setState({changeMarkDisabled: false})
        }; 
    }

    handleWidth(width) {
        this.setState({ columnWidthTracker: width })
    }

    handleSelectedColumns(e) {
        this.setState({ selectedColumns: e })
    }

    handleLoading() {
        this.setState({ isLoading: true })
    }

    async editRecord(e) {
        if(this.state.secRole==='1') {
            return;
        } else {
            let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
            await this.handleLoading();
            await CoreService.fetchPinnedCores(e.data.orgID, e.data.system, e.data.coreMarkID, "SFIC").then(data => this.setState({ SFICKeyCutsStr:data.keyCutsString, SFICPinStr: data.pinString }));
            await CoreService.fetchPinnedCores(e.data.orgID, e.data.system, e.data.coreMarkID, "FIXEDCORE").then(data => this.setState({ FCKeyCutsStr:data.keyCutsString, FCPinStr: data.pinString }));
            await CoreService.fetchPinnedCores(e.data.orgID, e.data.system, e.data.coreMarkID, "CAMLOCK").then(data => this.setState({ CLKeyCutsStr:data.keyCutsString, CLPinStr: data.pinString }));
            await CoreService.fetchCores(e.data.coreMarkID, e.data.orgID, parsedUserData.uiU_ID, parsedUserData.uiU_FN + ' ' + parsedUserData.uiU_LN, 0 ).then(data => this.setState({ coreData: data.corelist}));

            this.setState({ 
                displayRowD: true,
                editRecordID: e.data.recordID,
                editCoreMark: e.data.coreMark,
                editCoreMarkId: e.data.coreMarkID,
                editCoresPinned: e.data.coresPinned,
                editDescription: e.data.description,
                editKeyLevel: e.data.keyLevel,
                editKeyMark: e.data.keyMark,
                editKeyMarkId: e.data.keyMarkID,
                editKeySeries: e.data.keySeries,
                editKeysCut: e.data.keysCut,
                editBittings: e.data.bittings,
                editSystem: e.data.system,
                editNotes: e.data.notes,
                isLoading: false
            })
        }
    }    

    handleClose() {
        this.setState({ 
            displayRowD: false,
            globalDetailsFilter: null,
            editRecordID: '',
            editCoreMark: '',
            editCoreMarkId: '',
            editCoresPinned: '',
            editDescription: '',
            editKeyLevel: '',
            editKeyMark: '',
            editKeyMarkId: 0,
            editKeySeries: '',
            editKeysCut: '',
            editBittings: '',
            editSystem: '',
            editNotes: '',
            SFICKeyCutsStr: '',
            FCKeyCutsStr: '',
            CLKeyCutsStr: '',
            SFICPinStr: '',
            FCPinStr: '',
            CLPinStr: '', 
            coreData: [],
            activePinIndex: 0,
            activeIndex: 0
        })
    }

    async saveRecord() {
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
        await RecordsService.storeRecord( parsedUserData.uiU_M1ORGID, this.state.editRecordID, this.state.editDescription, this.state.editNotes )
        .then(data => this.showResults(data));
        this.handleClose();
        await RecordsService.fetchRecords( parsedUserData.uiU_M1ORGID, 0, "" ).then(data => this.setState({ records: data.recordlist }));
    }

    showResults(data) {
        if(data.success) {
            this.messages.show({severity: 'success', summary: data.message, life:1000});
        } else {
            this.messages.show({severity: 'error', summary: data.message, life:3000});
        }
    }

    renderEditFooter() {
        return (
            <div>
                {((this.state.editKeysCut === '' || this.state.editKeysCut === 0) && this.state.changeMarkDisabled === false && this.state.editKeyMark!=="") ? (<Button type='button' label='Change Key Marking' icon='pi pi-key' onClick={this.editKeyMark} />) : (null)}
                {((this.state.editCoresPinned === '' || this.state.editCoresPinned === 0) && this.state.changeMarkDisabled === false && this.state.editCoreMark!=="") ? (<Button type='button' label='Change Core Marking' icon='pi pi-lock' onClick={this.editCoreMark} />) : (null)}
                {this.state.disabled ? (null) : (<Button type='button' label='Save and Exit' icon='pi pi-check' onClick={this.saveRecord} />)}
                <Button type='button' label='Exit' icon='pi pi-times' onClick={this.handleClose} />
            </div>
        );
    }

    renderEditMarkFooter() {
        return (
            <div>
                {this.state.lengthError===true ? (<p style={{color:'red'}}>New Marking must be at least 1 character and less than 10 characters</p>) : (null)}
                {(this.state.editMarkText === "New Core Marking:" && this.state.lengthError===false) ? ((<Button type='button' label='Save and Exit' icon='pi pi-check' onClick={this.changeCoreMark} />)) : (null)}
                {(this.state.editMarkText === "New Key Marking:" && this.state.lengthError===false) ? (<Button type='button' label='Save and Exit' icon='pi pi-check' onClick={this.changeKeyMark} />) : (null)}
                <Button type='button' label='Exit' icon='pi pi-times' onClick={this.handleCloseMarkDialog} />
            </div>
        );
    }

    handleFieldChange(e) {
        if(e.target.id==='desc') {
            this.setState({ editDescription: e.target.value })
        } else if (e.target.id==='notes') {
            this.setState({ editNotes: e.target.value })
        } else if (e.target.id==='newCoreKeyMark') {
            this.setState({ newCoreKeyMark: e.target.value })
            if(e.target.value.length > 10 || e.target.value.length < 1) {
                this.setState({lengthError: true})
            } else {
                if(this.state.lengthError===true) {
                    this.setState({lengthError: false})
                }
            }
        }
    }

    editCoreMark() {
        this.setState({editMarkDialog: true, editMarkText: "New Core Marking:", editMarkHeader: `Change Core Marking for ${this.state.editCoreMark}`})
    }

    editKeyMark() {
        this.setState({editMarkDialog: true, editMarkText: "New Key Marking:", editMarkHeader: `Change Key Marking for ${this.state.editKeyMark}`})
    }

    handleCloseMarkDialog() {
        this.setState({editMarkDialog: false, editMarkText: "", newCoreKeyMark: "", lengthError: false, editMarkHeader: ""})
    }

    async changeCoreMark() {
        const newCoreMark = this.state.newCoreKeyMark.toUpperCase();
        const response = await RecordsService.ChangeCoremark(this.state.orgId, this.state.newCoreKeyMark, this.state.editCoreMarkId, this.state.editSystem);
        await RecordsService.fetchRecords( this.state.orgId, 0, "" ).then(data => this.setState({ records: data.recordlist }));
        this.handleCloseMarkDialog();
        // this.handleClose();
        if(response.success === false) {
            this.dialogMessages.show({severity: 'error', summary: response.message, life:3000});
        } else {
            this.dialogMessages.show({severity: 'success', summary: "Core Mark Change Successful", life:1000});
            this.setState({editCoreMark: newCoreMark});
        }
    }

    async changeKeyMark() {
        const newKeyMark = this.state.newCoreKeyMark.toUpperCase();
        const response = await RecordsService.ChangeKeymark(this.state.orgId, this.state.newCoreKeyMark, this.state.editKeyMarkId, this.state.editSystem );
        await RecordsService.fetchRecords( this.state.orgId, 0, "" ).then(data => this.setState({ records: data.recordlist }));
        this.handleCloseMarkDialog();
        // this.handleClose();
        if(response.success === false) {
            this.dialogMessages.show({severity: 'error', summary: response.message, life:3000});
        } else {
            this.dialogMessages.show({severity: 'success', summary: "Key Mark Change Successful", life:1000});
            this.setState({editKeyMark: newKeyMark});
        }
    }

    editCore() {
        
    }

    render(){
        // console.log(this.state)
        const { displayRowD, editCoreMark, editCoresPinned, editKeyLevel, editKeyMark, editKeySeries, editKeysCut, editBittings, editSystem } = this.state;
        const editFooter=this.renderEditFooter();
        const editMarkFooter = this.renderEditMarkFooter();

        if((this.state.secRole==='' && !this.state.isLoading) || (this.state.secRole==='0' && !this.state.isLoading)) {
            return(<Layout><div>Security Role prevents you from seeing data</div></Layout>);
        } else if(this.state.records.length===0 && !this.state.isLoading) {
            return(<div><Layout>Contact Midwest Security Products to add Key/Core Records</Layout></div>)
        } else {
            return(
                <div>
                    <Layout>
                        <div className='container-div'>
                            <Messages ref={(el) => this.messages = el} />
                            <div>
                                <GeneralDatatable 
                                    columnArray = {[
                                        {field:'coreMark', header:'Core Marking'},
                                        {field:'keyMark', header:'Key Marking'},
                                        {field:'coreMarkID', header:'Core Record ID'},
                                        {field:'keyMarkID', header:'Key Record ID'},
                                        {field:'system', header:'System'},
                                        {field:'keysCut', header:'Keys Cut'},  
                                        {field:'coresPinned', header:'Cores Pinned'},
                                        {field:'keyLevel', header:'Key Level'},
                                        {field:'keySeries', header:'Key Series'},     
                                        {field:'description', header:'Description'},
                                        {field:'keyway', header:'Keyway'}
                                    ]}

                                    csvHeader = {[
                                        {key:'coreMark', label:'Core Marking'},
                                        {key:'keyMark', label:'Key Marking'},
                                        {key:'coreMarkID', label:'Core Record ID'},
                                        {key:'keyMarkID', label:'Key Record ID'},
                                        {key:'system', label:'System'},
                                        {key:'keysCut', label:'Keys Cut'},  
                                        {key:'coresPinned', label:'Cores Pinned'},
                                        {key:'keyLevel', label:'Key Level'},
                                        {key:'keySeries', label:'Key Series'},     
                                        {key:'description', label:'Description'},
                                        {key:'keyway', label:'Keyway'}
                                    ]}
                                    
                                    defaultOrder={["coreMark", "keyMark", "coreMarkID", "keyMarkID", "system", "keysCut", "coresPinned", "keyLevel", "keySeries", "description, keyway"]}

                                    tableRecords = {this.state.records}
                                    pageNumber={6}
                                    tableName={'Records'}
                                    exportFileName={'records.csv'}
                                    tableData={'recordsTableData'}
                                    filterData={'recordsFilterData'}
                                    columnWidthTracker={this.state.columnWidthTracker}
                                    isLoading={this.state.isLoading}
                                    selectedColumns={this.state.selectedColumns}
                                    handleRowClick={this.editRecord}
                                    handleWidth={this.handleWidth}
                                    handleSelectedColumns={this.handleSelectedColumns}
                                    systemDropdownOptions={this.state.systemDropdownOptions}
                                    keyLevelDropdownOptions={this.state.keyLevelDropdownOptions}
                                />
                            </div>                        
                        </div>
                    </Layout>
                    <div>
                        <Dialog header='Edit Record' visible={displayRowD} onHide={this.handleClose} footer={editFooter} maximized baseZIndex={1300} blockScroll focusOnShow={false} closable={false}>
                            <Messages ref={(el) => this.dialogMessages = el} />
                            <BrowserView>
                                <div className='p-grid' style={{maxWidth:'100%'}}>
                                    <div className='p-md-4 p-col-12 p-align-center'>
                                        <div className='p-grid'>
                                            <div className='p-col-4'><div><strong>Core Marking: </strong>{editCoreMark}</div></div>
                                            <div className='p-col-4'><div><strong>Cores Pinned: </strong>{editCoresPinned}</div></div>
                                            <div className='p-col-4'><div><strong>System: </strong>{editSystem}</div></div>

                                            <div className='p-col-4'><div><strong>Key Marking: </strong>{editKeyMark}</div></div>
                                            <div className='p-col-4'><div><strong>Keys Cut: </strong>{editKeysCut}</div></div>
                                            <div className='p-col-4'><div><strong>Key Series: </strong>{editKeySeries}</div></div>

                                            <div className='p-col-4'><div><strong>Key Level: </strong>{editKeyLevel}</div></div>
                                            <div className='p-col-4'><div><strong>Bittings: </strong>{editBittings}</div></div>
                                            <div className='p-col-4'></div>            

                                            <TextBox disabled={this.state.disabled} value={this.state.editDescription} id="desc" label={"Description: "} handleFieldChange={this.handleFieldChange} area />
                                        </div>
                                    </div>
                                    <div className='p-md-4 p-col-12'>
                                        <TabView activeIndex={this.state.activePinIndex} onTabChange={(e) => this.setState({activePinIndex: e.index})}>
                                            <TabPanel header="SFIC">
                                                <ScrollPanel style={{ width: '100%', height:'248px'}}>
                                                    <div className='p-grid p-justify-center p-nogutter no-bmargin'>
                                                        <p className='p-col-4 no-bmargin' style={{textAlign:'left' }}>
                                                            Key Access
                                                            <pre className='no-bmargin'>{this.state.SFICKeyCutsStr}</pre> 
                                                        </p>
                                                        <p className='p-col-4 no-bmargin'>
                                                            Pinning
                                                            <pre className='no-bmargin'>{this.state.SFICPinStr}</pre>
                                                        </p>
                                                    </div>
                                                </ScrollPanel>
                                            </TabPanel>
                                            <TabPanel header="Fixed Core">
                                                <ScrollPanel style={{ width: '100%', height:'248px'}}>
                                                    <div className='p-grid p-justify-center p-nogutter no-bmargin'>
                                                        <p className='p-col-4 no-bmargin' style={{textAlign:'left' }}>
                                                            Key Access
                                                            <pre className='no-bmargin'>{this.state.FCKeyCutsStr}</pre> 
                                                        </p>
                                                        <p className='p-col-4 no-bmargin'>
                                                            Pinning
                                                            <pre className='no-bmargin'>{this.state.FCPinStr}</pre>
                                                        </p>
                                                    </div>
                                                </ScrollPanel>
                                            </TabPanel>
                                            <TabPanel header="Cam Lock">
                                                <ScrollPanel style={{ width: '100%', height:'248px'}}>
                                                    <div className='p-grid p-justify-center p-nogutter no-bmargin'>
                                                        <p className='p-col-4 no-bmargin' style={{textAlign:'left' }}>
                                                            Key Access
                                                            <pre className='no-bmargin'>{this.state.CLKeyCutsStr}</pre> 
                                                        </p>
                                                        <p className='p-col-4 no-bmargin'>
                                                            Pinning
                                                            <pre className='no-bmargin'>{this.state.CLPinStr}</pre>
                                                        </p>
                                                    </div>
                                                </ScrollPanel>
                                            </TabPanel>
                                        </TabView>
                                    </div>
                                    <div className='p-md-4 p-col-12' >
                                        <h4 style={{ marginTop: '5px' }}><strong>Notes</strong></h4>
                                        <InputTextarea id='notes' disabled={this.state.disabled} value={this.state.editNotes} onChange={(e) => this.handleFieldChange(e)} rows={11} cols={40}/>
                                    </div>
                                    <div>
                                        <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({activeIndex: e.index})}>
                                            <TabPanel header='Keys'>
                                                <KeysComponent employeeID={0} recordID={this.state.editKeyMarkId} pageID={23} isComponent />
                                            </TabPanel>
                                            <TabPanel header='Cores'>
                                                <IndividualCores 
                                                    coreData={this.state.coreData} 
                                                    editCore={this.editCore} 
                                                />
                                            </TabPanel>
                                        </TabView>
                                    </div>
                                </div>
                            </BrowserView>
                            <MobileView>
                                <div className='p-grid' style={{maxWidth:'100%'}}>
                                    <div className='p-grid'>
                                        <Label mobile value={this.state.editCoreMark} label={'Core Marking'}/>
                                        <Label mobile value={this.state.editCoresPinned} label={'Cores Pinned'}/>
                                        <Label mobile value={this.state.editSystem} label={'System'}/>
                                        <Label mobile value={this.state.editKeyMark} label={'Key Marking'}/>
                                        <Label mobile value={this.state.editKeysCut} label={'Keys Cut'}/>
                                        <Label mobile value={this.state.editKeySeries} label={'Key Series'}/>
                                        <Label mobile value={this.state.editKeyLevel} label={'Key Level'}/>
                                        <Label mobile value={this.state.editBittings} label={'Bittings'}/>       
                                        <TextBox mobile value={this.state.editDescription} id="desc" label={"Description"} handleFieldChange={(e) => this.handleFieldChange(e)} disabled={this.state.disabled} />
                                        <TextBox mobile area rows={5} value={this.state.editNotes} id="notes" label={"Notes"} handleFieldChange={(e) => this.handleFieldChange(e)} disabled={this.state.disabled} />
                                        <div style={{margin:'10px'}}>
                                            <TabView activeIndex={this.state.activePinIndex} onTabChange={(e) => this.setState({activePinIndex: e.index})}>
                                                <TabPanel header="SFIC">
                                                    <div className='p-grid p-justify-center p-nogutter no-bmargin'>
                                                        <p className='p-col-8 no-bmargin' style={{textAlign:'left' }}>
                                                            Key Access
                                                            <pre className='no-bmargin'>{this.state.SFICKeyCutsStr}</pre> 
                                                        </p>
                                                        <p className='p-col-12 no-bmargin'>
                                                            Pinning
                                                            <pre className='no-bmargin'>{this.state.SFICPinStr}</pre>
                                                        </p>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel header="Fixed Core">
                                                    <div className='p-grid p-justify-center p-nogutter no-bmargin'>
                                                        <p className='p-col-8 no-bmargin' style={{textAlign:'left' }}>
                                                            Key Access
                                                            <pre className='no-bmargin'>{this.state.FCKeyCutsStr}</pre> 
                                                        </p>
                                                        <p className='p-col-12 no-bmargin'>
                                                            Pinning
                                                            <pre className='no-bmargin'>{this.state.FCPinStr}</pre>
                                                        </p>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel header="Cam Lock">
                                                    <div className='p-grid p-justify-center p-nogutter no-bmargin'>
                                                        <p className='p-col-8 no-bmargin' style={{textAlign:'left' }}>
                                                            Key Access
                                                            <pre className='no-bmargin'>{this.state.CLKeyCutsStr}</pre> 
                                                        </p>
                                                        <p className='p-col-12 no-bmargin'>
                                                            Pinning
                                                            <pre className='no-bmargin'>{this.state.CLPinStr}</pre>
                                                        </p>
                                                    </div>
                                                </TabPanel>
                                            </TabView>
                                        </div>
                                        <div>
                                            <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({activeIndex: e.index})}>
                                                <TabPanel header='Keys'>
                                                    <KeysComponent employeeID={0} recordID={this.state.editKeyMarkId} pageID={23} isComponent />
                                                </TabPanel>
                                                <TabPanel header='Cores'>
                                                    <IndividualCores 
                                                        coreData={this.state.coreData} 
                                                        editCore={this.editCore} 
                                                    />
                                                </TabPanel>
                                            </TabView>
                                        </div>
                                    </div>
                                </div>
                            </MobileView>
                        </Dialog>
                    </div>
                    <div>
                        <Dialog header='Edit Key' visible={this.state.keyEditDialog} onHide={this.hideKeyEdit} maximized baseZIndex={1300} blockScroll focusOnShow={false}>
                            key edit
                        </Dialog>
                    </div>
                    <div>
                        <Dialog header={this.state.editMarkHeader} visible={this.state.editMarkDialog} onHide={this.handleCloseMarkDialog} footer={editMarkFooter} baseZIndex={1300} blockScroll focusOnShow={false} closable={false}>
                            <div className='p-grid p-justify-start'>
                                <div className="p-col-12" style={{textAlign:'left'}}>
                                        <strong>{this.state.editMarkText}</strong>
                                </div>
                                <div className='p-col-12' >
                                    <InputText style={{width:'100%'}} 
                                    value={this.state.newCoreKeyMark} onChange={(e) => this.handleFieldChange(e)} type='text' id={"newCoreKeyMark"} />
                                </div>
                            </div>
                        </Dialog>
                    </div>
                </div>
            );
        }
    }
}

export default Records;