import React from 'react';

import Layout from '../components/Layout';
import GeneralDatatable from '../components/GeneralDatatable';
import BuildingsComponent from '../components/BuildingsComponent';
import { DoorService } from '../services/FetchServices';

import {Dialog} from 'primereact/dialog';
import TextBox from '../components/inputComponents/textbox';
import {Button} from 'primereact/button';
import { BrowserView, MobileView } from 'react-device-detect';
import {Messages} from 'primereact/messages';

class BuildingGroups extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            selectedColumns:[
                {field:'name', header:'Name'},
                {field:'desc', header:'Description'},
                {field:'notes', header:'Notes'}
            ],
            columnWidthTracker:'487.6,487.6,487.6',
            isLoading: true,
            data: [],
            groupID: 0,
            secRole: '',
            disabled: false,
            hasButton: true,
            desc: '',
            notes:''
        }

        this.editRecord = this.editRecord.bind(this);
        this.handleWidth = this.handleWidth.bind(this);
        this.handleSelectedColumns = this.handleSelectedColumns.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleGroupSave = this.handleGroupSave.bind(this);
        this.handleGroupDelete = this.handleGroupDelete.bind(this);
        this.handleNewGroup = this.handleNewGroup.bind(this);
        this.handleLoading = this.handleLoading.bind(this);
        this.handleDeleteCancel = this.handleDeleteCancel.bind(this);
    }

    async componentDidMount() {
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
        let parsedTableData = JSON.parse(sessionStorage.getItem('buildingGroupsTableData'));

        await DoorService.fetchGroups( parsedUserData.uiU_M1ORGID, 0, parsedUserData.uiU_ID, parsedUserData.uiU_FN + parsedUserData.uiU_LN, "" ).then(data => this.setState({ data: data.grouplist, orgID: parsedUserData.uiU_M1ORGID}));
    
        if(parsedTableData!==null) {
            if(parsedTableData.selectedColumns !== null) {
                this.setState({ selectedColumns: parsedTableData.selectedColumns })
            }
        }

        if(parsedUserData.secString[2]==='2') {
            this.setState({disabled: true, hasButton: false})
        }

        this.setState({ 
            isLoading: false,
            secRole: parsedUserData.secString[2]
        })
    }

    handleLoading(){
        this.setState({ isLoading: true })
    }

    editRecord(e) {
        if(this.state.secRole==='1') {
            return;
        } else {
            this.handleLoading();

            this.setState({ 
                buildingsDialog: true,
                desc: e.data.desc,
                groupID: e.data.grouP_ID,
                groupName: e.data.name,
                notes: e.data.notes,
                isLoading: false
            })
        }
    }

    handleCancel(data) {
        this.setState({ 
            buildingsDialog: false,
            newGroup: false,
            desc: '',
            groupID: 0,
            groupName: '',
            notes: '',
            pendingDelete: false
        })
    }

    handleDeleteCancel() {
        this.setState({pendingDelete: false})
    }

    showResults(data) {
        this.messages.clear();
        if(data.success) {
            this.messages.show({severity: 'success', summary: data.message, life:1000});
        } else {
            this.messages.show({severity: 'error', summary: data.message, sticky: true});
        }
    }

    handleWidth(width) {
        this.setState({ columnWidthTracker: width })
    }

    handleSelectedColumns(e) {
        this.setState({ selectedColumns: e })
    }

    async handleGroupSave() {
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
        await DoorService.storeGroup( parsedUserData.uiU_ID, parsedUserData.uiU_FN + parsedUserData.uiU_LN, this.state.orgID, this.state.groupID, 
            this.state.groupName, this.state.desc, this.state.notes, "").then(data => this.showResults(data));
        await DoorService.fetchGroups( this.state.orgID, 0, parsedUserData.uiU_ID, parsedUserData.uiU_FN + parsedUserData.uiU_LN, "" ).then(data => this.setState({ data: data.grouplist, buildingsDialog:false }));
    }

    async handleGroupDelete() {
        if(this.state.pendingDelete) {
            let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
            await DoorService.deleteGroup(parsedUserData.uiU_ID, parsedUserData.uiU_FN + parsedUserData.uiU_LN, this.state.orgID, this.state.groupID).then(data => this.showResults(data));
            await DoorService.fetchGroups( this.state.orgID, 0, parsedUserData.uiU_ID, parsedUserData.uiU_FN + parsedUserData.uiU_LN, "" ).then(data => this.setState({ data: data.grouplist}));
            this.handleCancel();
        } else {
            this.setState({pendingDelete: true})
        }
    }

    handleNewGroup() {
        this.setState({ 
            newGroup: true,
            buildingsDialog: true,
            groupName: 'New Group',
            groupID: -1
        })
    }

    handleFieldChange(e) {
        if(e.target.id==='groupName') {
            this.setState({ groupName: e.target.value })
        } else if (e.target.id==='desc') {
            this.setState({ desc: e.target.value })
        } else if (e.target.id==='notes') {
            this.setState({ notes: e.target.value })
        }
    }

    renderButtons() {
        if(this.state.newGroup) {
            return(
                <div>
                    <Button type='button' label='Save and Exit' icon='pi pi-check' onClick={this.handleGroupSave} />
                    <Button type='button' label='Exit' icon='pi pi-times' onClick={this.handleCancel} />
                </div>
                );
        } else if(this.state.secRole!=='3') {
            return(<div><Button type='button' label='Exit' icon='pi pi-times' onClick={this.handleCancel} /></div>)
        } else {
            return(
                <div>
                    <Button type='button' label='Delete' icon='pi pi-trash' onClick={this.handleGroupDelete} />
                    <Button type='button' label='Save and Exit' icon='pi pi-check' onClick={this.handleGroupSave} />
                    <Button type='button' label='Exit' icon='pi pi-times' onClick={this.handleCancel} />
                </div>
            )
        }
    }

    renderFooter() {
        return(
            <div>
                <Button type='button' label='Delete' icon='pi pi-trash' onClick={this.handleGroupDelete} />
                <Button type='button' label='Cancel' icon='pi pi-times' onClick={this.handleDeleteCancel} />
            </div>
            );
    }

    render(){    
        const buttonFooter=this.renderButtons();
        const deleteFooter=this.renderFooter();
        if((this.state.secRole==='' && !this.state.isLoading) || (this.state.secRole==='0' && !this.state.isLoading)) {
            return(<Layout><div>Security Role prevents you from seeing data</div></Layout>);
        } else if(this.state.data.length===0&&this.state.newGroup!==true&&this.state.secRole==='3' && !this.state.isLoading) {
            return(
                <div>
                    <Layout><div style={{marginBottom:'40px'}}><Button label='New Building Group' icon='pi pi-plus' onClick={this.handleNewGroup}/></div></Layout>
                </div>
            );  
        } else {
            return(
                <div>
                    <Layout>
                        <Messages ref={(el) => this.messages = el} />
                        <GeneralDatatable
                            columnArray = {[
                                {field:'name', header:'Name'},
                                {field:'desc', header:'Description'},
                                {field:'notes', header:'Notes'}
                            ]}

                            csvHeader = {[
                                {key:'name', label:'Name'},
                                {key:'desc', label:'Description'},
                                {key:'notes', label:'Notes'}
                            ]}
                            
                            defaultOrder={["name", "desc", "notes"]}
                            tableRecords = {this.state.data}
                            pageNumber={9}
                            tableName={'Building Groups'}
                            exportFileName={'BuildingGroups.csv'}
                            tableData={'buildingGroupsTableData'}
                            filterData={'buildingGroupsFilterData'}
                            columnWidthTracker={this.state.columnWidthTracker}
                            isLoading={this.state.isLoading}
                            selectedColumns={this.state.selectedColumns}
                            handleRowClick={this.editRecord}
                            handleWidth={this.handleWidth}
                            handleSelectedColumns={this.handleSelectedColumns}
                            hasButton={this.state.hasButton}
                            buttonLabel={'New Building Group'}
                            onButtonClick={this.handleNewGroup}
                        />
                    </Layout>
                    <Dialog header={"Edit " + this.state.groupName}  visible={this.state.buildingsDialog} footer={buttonFooter} onHide={this.handleCancel} baseZIndex={1300} maximized blockScroll focusOnShow={false} closable={false}>
                        <div style={{width:'99vw', maxWidth:'100%'}}>
                            <BrowserView>
                                <div>
                                    <TextBox value={this.state.groupName} id="groupName" label={"Name: "} disabled={this.state.disabled} handleFieldChange={this.handleFieldChange} />
                                    <TextBox value={this.state.desc} id="desc" label={"Description: "} disabled={this.state.disabled} handleFieldChange={this.handleFieldChange} />
                                    <TextBox value={this.state.notes} id="notes" label={"Notes: "} disabled={this.state.disabled} area handleFieldChange={this.handleFieldChange} />
                                    {this.state.newGroup ? (null) : (
                                        <BuildingsComponent
                                            locationIDReq={0}
                                            groupID={this.state.groupID}
                                            buildingID={0}
                                            isComponent
                                        />
                                    )}
                                </div>
                            </BrowserView>
                            <MobileView>
                                <div>
                                    <TextBox value={this.state.groupName} id="groupName" label={"Name: "} disabled={this.state.disabled} handleFieldChange={this.handleFieldChange} mobile />
                                    <TextBox value={this.state.desc} id="desc" label={"Description: "} disabled={this.state.disabled} handleFieldChange={this.handleFieldChange} mobile />
                                    <TextBox value={this.state.notes} id="notes" label={"Notes: "} disabled={this.state.disabled} handleFieldChange={this.handleFieldChange} mobile area rows={5} />
                                    {this.state.newGroup ? (null) : (
                                        <BuildingsComponent
                                            locationIDReq={0}
                                            groupID={this.state.groupID}
                                            buildingID={0}
                                            isComponent
                                        />
                                    )}
                                </div>
                            </MobileView>

                        </div>
                    </Dialog>
                    <Dialog header={"Delete " + this.state.groupName}  visible={this.state.pendingDelete} footer={deleteFooter} onHide={this.handleDeleteCancel} baseZIndex={1300} blockScroll focusOnShow={false} closable={false}>
                        <h4>Are you sure you would like to delete '{this.state.groupName}'?</h4>
                    </Dialog>
                </div>
            );
        }
    }
}

export default BuildingGroups;