import { serverAddr } from './serverAddr';
import axios from 'axios';

export const userService = {
    login,
    logout,
    authUser,
    authOTP,
    acceptTOS,
    passwordLink,
    resetPassword,
    getLicense
};

async function login(Login, Password, OrgID, FACTORY_IP) {
  const config = {
    method: 'post',
    url: serverAddr.apiUrl + '/users/authenticate',
    headers: { 
      'Content-Type': 'application/json; charset=utf-8'
    },
    data : JSON.stringify({ Login, Password, OrgID, FACTORY_IP})
  };
  
  try {
    const response = await axios(config)
    sessionStorage.setItem('userData', JSON.stringify(response.data));
    let drawerData = {"viewedDrawer": false}
    sessionStorage.setItem('viewedDrawerData', JSON.stringify(drawerData));
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

async function resetPassword(Token, Password, OrgID) {
  const config = {
    method: 'post',
    url: serverAddr.apiUrl + '/users/ResetPassword',
    headers: { 
      'Content-Type': 'application/json'
    },
    data : JSON.stringify({ Token, Password, OrgID})
  };

  try {
    const response = await axios(config)
    sessionStorage.setItem('isResetPasswordValid', JSON.stringify(response.data));
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

async function passwordLink(Login, OrgID) {
  const config = {
    method: 'post',
    url: serverAddr.apiUrl + '/users/GetPasswordLink',
    headers: { 
      'Content-Type': 'application/json'
    },
    data : JSON.stringify({ Login, OrgID})
  };
  
  try {
    await axios(config)
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

async function acceptTOS(ID, OrgID) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
    method: 'post',
    url: serverAddr.apiUrl + '/users/AcceptTOS',
    headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
    },
    data : JSON.stringify({ ID, OrgID})
  };
  
  try {
    const response = await axios(config)
    sessionStorage.setItem('TOSData', JSON.stringify(response.data));
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

async function authOTP(ID, Password, OrgID) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
    method: 'post',
    url: serverAddr.apiUrl + '/users/authenticateOTP',
    headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
    },
    
    data : JSON.stringify({ ID, Password, OrgID })
  };
  
  try {
    const response = await axios(config)
    sessionStorage.setItem('otpData', JSON.stringify(response.data));
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

async function getLicense(orgID) {
  let userData = sessionStorage.getItem('userData');
  let parsedUserData = JSON.parse(userData);
  let bearerToken = parsedUserData.token;

  const config = {
    method: 'post',
    url: serverAddr.apiUrl + '/users/GetLicense',
    headers: { 
      'Authorization': 'Bearer ' + bearerToken,
      'Content-Type': 'application/json'
    },
    
    data : JSON.stringify({ orgID })
  }

  try {
    const response = await axios(config)
    sessionStorage.setItem('licenseData', JSON.stringify(response.data.orgLicense));
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

function logout() {
  sessionStorage.clear();
  return true;
}

function authUser() {
  let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
  let TOSData = JSON.parse(sessionStorage.getItem('TOSData'))

  if(parsedUserData!==null) {
    //if user organization requires OTP
    if (parsedUserData.uiU_OTP_VAL==="YES") {
      let parsedOtpData = JSON.parse(sessionStorage.getItem('otpData'));
    //if the OTP is valid and token returned, return true
      if((parsedOtpData.token) && (parsedUserData.uiU_TOS)) {
        return true;
        //OTP was invalid and did not return token
      } else {
        return false;
      }
    //if the user organization does not require OTP, but token was returned for login, return true
    } else if (parsedUserData.token && (parsedUserData.uiU_TOS)) {
        return true;
    //if the user organization does not require OTP, but token was returned for login, and they just accepted EULA
    } else if (TOSData.token!=='' && (TOSData.uiU_OTP_VAL==='VALID')) {
      return true;
    //invalid login
    } else {
        return false;
    }
  } else {
    return false;
  }
}