import React from 'react';

import GeneralDatatable from './GeneralDatatable';
import {Button} from 'primereact/button';
import {Messages} from 'primereact/messages';

class DoorCoreDatatable extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            coreData: [],
            selectedColumns: [
                {field:'loC_NAME', header:'Location'},
                {field:'dooR_NUMBER', header:'Door Number'},
                {field:'system', header:'System'},
                {field:'corE_MARK', header:'Core Marking'},
                {field:'status', header:'Status'},
                {field:'product', header:'Product'},  
                {field:'notes', header:'Notes'},
                {field:'fulL_CODE', header:'Full Code'},
                {field:'keyway', header:'Keyway'},  
                {field:'cross', header:'Cross'},
                {field:'fixeD_CORE', header:'Fixed Core'}
            ],
            columnWidthTracker: "133,133,133,133,133,133,133,133,133,133,133",
            defaultOrder: ["loC_NAME", "dooR_NUMBER", "system", "corE_MARK", "status", "product", "notes", "fulL_CODE", "keyway", "cross", "fixeD_CORE"],
            isLoading: true
        };
        this.handleWidth = this.handleWidth.bind(this);
        this.handleSelectedColumns = this.handleSelectedColumns.bind(this);
    }

    async componentDidMount() {
        let parsedTableData = JSON.parse(sessionStorage.getItem('DoorCoreDatatableTableData'));

        if(parsedTableData!==null) {
            if(parsedTableData.selectedColumns !== null) {
                this.setState({ selectedColumns: parsedTableData.selectedColumns })
            }
        }
        this.setState({ isLoading: false })
    }

    handleWidth(width) {
        this.setState({ columnWidthTracker: width })
    }

    handleSelectedColumns(e) {
        this.setState({ selectedColumns: e })
    }


    render() {
        return(
            <div>
                <Messages ref={(el) => this.messages = el} />
                {(this.props.coreData.length===0) ? 
                    (
                    <div>
                        <h3>This door currently has no core assigned.</h3>
                        {this.props.disabled ? (null) : (<Button type='button' icon='pi pi-plus' iconPos='left' label='Assign Core' onClick={this.props.assignCore}/>)}
                    </div>
                    ) : (
                    <div>
                        <GeneralDatatable 
                            columnArray = {[
                                {field:'loC_NAME', header:'Location'},
                                {field:'dooR_NUMBER', header:'Door Number'},
                                {field:'system', header:'System'},
                                {field:'corE_MARK', header:'Core Marking'},
                                {field:'status', header:'Status'},
                                {field:'product', header:'Product'},  
                                {field:'notes', header:'Notes'},
                                {field:'fulL_CODE', header:'Full Code'},
                                {field:'keyway', header:'Keyway'},  
                                {field:'cross', header:'Cross'},
                                {field:'fixeD_CORE', header:'Fixed Core'}
                            ]}

                            csvHeaders = {[
                                {key:'loC_NAME', label:'Location'},
                                {key:'dooR_NUMBER', label:'Door Number'},
                                {key:'system', label:'System'},
                                {key:'corE_MARK', label:'Core Marking'},
                                {key:'status', label:'Status'},
                                {key:'product', label:'Product'},  
                                {key:'notes', label:'Notes'},
                                {key:'fulL_CODE', label:'Full Code'},
                                {key:'keyway', label:'Keyway'},  
                                {key:'cross', label:'Cross'},
                                {key:'fixeD_CORE', label:'Fixed Core'}
                            ]}

                            defaultOrder={["loC_NAME", "dooR_NUMBER", "system", "corE_MARK", "status", "product", "notes", "fulL_CODE", "keyway", "cross", "fixeD_CORE"]}

                            tableRecords = {this.props.coreData}
                            pageNumber={25}
                            tableName={'Assigned Cores'}
                            exportFileName={'assigned-cores.csv'}
                            tableData={'DoorCoreDatatableTableData'}
                            filterData={'DoorCoreDatatableFilterData'}
                            columnWidthTracker={this.state.columnWidthTracker}
                            isLoading={this.state.isLoading}
                            selectedColumns={this.state.selectedColumns}
                            handleRowClick={this.props.unassignCore}
                            handleWidth={this.handleWidth}
                            handleSelectedColumns={this.handleSelectedColumns}
                            hasButton={!this.props.disabled}
                            buttonLabel={'Assign Core'}
                            onButtonClick={this.props.assignCore}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default DoorCoreDatatable;