import React from 'react';
import { Redirect } from "react-router-dom";
import IdleTimer from 'react-idle-timer';

import Timer from './Timer';
import { userService } from '../services/Auth';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const styles = theme => ({
    modalClass: {
        alignItems: 'center',
        textAlign: 'center',
      },
    buttonClass: {
        alignItems: 'left',
        textAlign: 'center',
    },
    MainContent: {
        marginLeft: 200
    }
});

const timeoutMinutes = 20;
const timeoutValue = (1000 * 60 * timeoutMinutes);

class Timeout extends React.Component {
    constructor(props) {
        super(props);
        this.idleTimer = null;

        this.state = {
            toLogin: false,
            timeoutModalOpen: false,
            setTimeout: timeoutValue,
        };

        this.onIdle = this._onIdle.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }

    _onIdle(e) {
        this.setState({ timeoutModalOpen: true });
    }

    handleCancel() {
        this.setState({ 
            timeoutModalOpen: false,
            setTimeout: timeoutValue
         });
    }

    handleLogout() {
        userService.logout();
        this.setState({ toLogin: true })
    };


    render() {
        const { classes } = this.props;
        const { timeoutModalOpen, toLogin, setTimeout } = this.state;

        if (toLogin) {
            return (
                <Redirect to='/login' />
            );
        } else if (timeoutModalOpen) {
            return(
                <div>
                    <Dialog open={timeoutModalOpen} scroll="body">                       
                        <DialogTitle id="scroll-dialog-title" className={classes.modalClass}>
                            <h3>
                                LockViewer Enterprise                        
                            </h3>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="scroll-dialog-description">
                                Due to inactivity you will be automatically logged out in <Timer /> seconds, either click to stay logged in or log out.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <div>
                                <Button onClick={this.handleCancel} color="primary"  className={classes.buttonClass}>
                                    Stay Logged In
                                </Button>
                                <Button onClick={this.handleLogout} color="primary">
                                    Log Out
                                </Button>
                            </div>
                        </DialogActions>
                    </Dialog>
                </div>
            );
        } else {
        return(
            <div>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    stopOnIdle={true}
                    startOnMount
                    element={document}
                    onIdle={this.onIdle}
                    onActive={this.onActive}
                    onAction={this.onAction}
                    debounce={250}
                    timeout={setTimeout} 
                />
            </div>
        );}
    }         
}

export default withStyles(styles, { withTheme: true })(Timeout);