import React from 'react';
import { Dropdown } from 'primereact/dropdown';

class InputSelect extends React.Component {
    render() {
        if(this.props.mobile) {
            return(
                <div style={{margin:'10px'}} className="p-col-12">
                    <div><strong>{this.props.label}</strong></div>
                    <div style={{minWidth:'100%', margin:'auto'}}>
                        <Dropdown disabled={this.props.disabled} className={this.props.className} placeholder="Select" id={this.props.id} options={this.props.options} value={this.props.value} onChange={(e) => this.props.handleFieldChange(e)} />
                    </div>
                </div>
            );
        }else if(window.screen.width>1150 && window.screen.width<1441) {
            return (
                <div className='p-grid' style={{width:'335px', margin:'auto'}}>
                    <label className="p-col" style={{textAlign:'right', display:'block'}} htmlFor={this.props.id}><strong>{this.props.label}</strong></label>
                    <div className='p-col-fixed'>
                        <Dropdown disabled={this.props.disabled} className={this.props.className} 
                            style={{width:'206px'}} 
                            placeholder="Select"
                            id={this.props.id} 
                            options={this.props.options} 
                            value={this.props.value} 
                            onChange={(e) => this.props.handleFieldChange(e)}
                        />
                    </div>
                </div>
            );
        }else if(this.props.threeCol) {
            return (
                <div className='p-grid' style={{width:'450px', margin:'auto'}}>
                    <label className="p-col" style={{textAlign:'right', display:'block'}} htmlFor={this.props.id}><strong>{this.props.label}</strong></label>
                    <div className='p-col-fixed'>
                        <Dropdown disabled={this.props.disabled} className={this.props.className} 
                            style={{width:'250px'}} 
                            placeholder="Select"
                            id={this.props.id} 
                            options={this.props.options} 
                            value={this.props.value} 
                            onChange={(e) => this.props.handleFieldChange(e)}
                        />
                    </div>
                </div>
            );
        } else {
            return (
                <div className='p-grid' style={{width:'450px', margin:'auto'}}>
                    <label className="p-col" style={{textAlign:'right', display:'block'}} htmlFor={this.props.id}><strong>{this.props.label}</strong></label>
                    <div className='p-col-fixed'>
                        <Dropdown disabled={this.props.disabled} className={this.props.className} 
                            style={{width:'275px'}} 
                            placeholder="Select"
                            id={this.props.id} 
                            options={this.props.options} 
                            value={this.props.value} 
                            onChange={(e) => this.props.handleFieldChange(e)}
                        />
                    </div>
                </div>
            );
        }
    }
}

export default InputSelect;