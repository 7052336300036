import React from 'react';

import GeneralDatatable from '../components/GeneralDatatable';
import Hooks from '../components/Hooks';
import Layout from '../components/Layout';
import {RecordsService} from '../services/FetchServices';
import {DoorService} from '../services/FetchServices';
import Label from '../components/inputComponents/Label';
import TextBox from '../components/inputComponents/textbox';
import InputSelect from '../components/inputComponents/InputSelect';

import { v4 as uuidv4 } from 'uuid';

import {Button} from 'primereact/button';
import {Messages} from 'primereact/messages';
import {Dialog} from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { BrowserView, MobileView } from 'react-device-detect';

let orgID;

class KeyStorage extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            selectedColumns: [
                {field:'uiB_NAME', header:'Name'},
                {field:'uiB_DESC', header:'Description'},
                {field:'caB_LOC', header:'Building'},
                {field:'uiB_NUMHOOKS', header:'# Hooks'}
            ],
            columnWidthTracker: "365.75,365.75,365.75,365.75",
            defaultOrder: ["uiB_NAME", "uiB_DESC", "caB_LOC", "uiB_NUMHOOKS"],
            isLoading: true,
            cabData: [],
            disabled: false,
            secRole:0,
            hooksIsLoading: true
        };
        this.handleWidth = this.handleWidth.bind(this);
        this.handleSelectedColumns = this.handleSelectedColumns.bind(this);
        this.editCabinet = this.editCabinet.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.saveStorage = this.saveStorage.bind(this);
        this.editHook = this.editHook.bind(this);
        this.closeEditHook = this.closeEditHook.bind(this);
        this.saveHook = this.saveHook.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.showResults = this.showResults.bind(this);
        this.handleNewCabinet = this.handleNewCabinet.bind(this);
        this.addHooks = this.addHooks.bind(this);
        this.deleteCabinet = this.deleteCabinet.bind(this);
        this.addHookDialog = this.addHookDialog.bind(this);
        this.closeAddHook = this.closeAddHook.bind(this);
        this.confirmDeleteCabinet = this.confirmDeleteCabinet.bind(this);
        this.closeConfirmDeleteCabinet = this.closeConfirmDeleteCabinet.bind(this);
    }

    async componentDidMount() {
        let parsedTableData = JSON.parse(sessionStorage.getItem('KeyStorageTableData'));
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
        orgID = parsedUserData.uiU_M1ORGID;

        if(parsedTableData!==null) {
            if(parsedTableData.selectedColumns !== null) {
                this.setState({ selectedColumns: parsedTableData.selectedColumns })
            }
        }

        if(parsedUserData.secString[5]==='2') {
            this.setState({ disabled: true, secRole: 2 })
        } else if(parsedUserData.secString[5]==='0') {
            this.setState({secRole:0})
        } else if(parsedUserData.secString[5]==='1') {
            this.setState({secRole:1, disabled: true})
        } else if(parsedUserData.secString[5]==='3') {
            this.setState({secRole:3})
        }

        await RecordsService.fetchCabinets( orgID, 0 ).then(data => this.setState({ cabData: data.cabinetlist, isLoading: false }));
    }

    handleWidth(width) {
        this.setState({ columnWidthTracker: width })
    }

    handleSelectedColumns(e) {
        this.setState({ selectedColumns: e })
    }

    async handleNewCabinet() {
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
        let tempArray=[];
        let optionsArray=[];
        const guid = uuidv4();

        await DoorService.fetchLocations(orgID, parsedUserData.uiU_ID, parsedUserData.uiU_FN + parsedUserData.uiU_LN, "", 0, 0 )
        .then(data => tempArray = data.loclist); 

        for(let i=0; i<tempArray.length; i++) {
            optionsArray.push({
                label: tempArray[i].loC_NAME,
                value: tempArray[i].locatioN_ID
            })
        }

        this.setState({
            buildingOptions: optionsArray,
            cabDialog: true,
            cabinetID: -1,
            cabName: '',
            building: 0,
            numHooks: '',
            notes: '',
            desc: '',
            cabUUID: guid,
            uid: '',
            deleted: false,
            typeID: 1,
            model: '',
            sn: '',
            physicalLoc: '',
            typeName: '',
            loc: ''
        })
    }

    async editCabinet(e) {
        if(this.state.secRole!==1) {
            let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
            let tempArray=[];
            let optionsArray=[];

            await DoorService.fetchLocations(orgID, parsedUserData.uiU_ID, parsedUserData.uiU_FN + parsedUserData.uiU_LN, "", 0, 0 )
            .then(data => tempArray = data.loclist); 

            await RecordsService.fetchHooks( orgID, e.data.uiB_ID ).then(data => this.setState({ hookData: data.hooklist, hooksIsLoading: false}));

            for(let i=0; i<tempArray.length; i++) {
                optionsArray.push({
                    label: tempArray[i].loC_NAME,
                    value: tempArray[i].locatioN_ID
                })
            }

            this.setState({
                buildingOptions: optionsArray,
                cabDialog: true,
                cabinetID: e.data.uiB_ID,
                cabName: e.data.uiB_NAME,
                building: e.data.uiB_LOCATIONID,
                numHooks: e.data.uiB_NUMHOOKS,
                notes: e.data.uiB_NOTES,
                desc: e.data.uiB_DESC,
                cabUUID: e.data.uiB_UNIQUEID,
                uid: e.data.uiB_UID,
                deleted: e.data.uiB_DELETED,
                typeID: e.data.caB_TYPE_ID,
                model: e.data.caB_MODEL,
                sn: e.data.caB_SN,
                physicalLoc: e.data.caB_PHYSICAL_LOC,
                typeName: e.data.caB_TYPE_NAME,
                loc: e.data.caB_LOC
            })
        }
    }

    editHook(e) {
        if(!this.state.disabled) {
            this.setState({
                editHook: true,
                hookDesc: e.data.uiT_DESC,
                hookNum: e.data.uiT_HOOKNUMBER,
                hookID: e.data.uiT_ID
            })
        }
    }

    closeEditHook() {
        this.setState({
            editHook: false,
            hookDesc: '',
            hookNum: '',
            hookID: ''
        })
    }

    async saveHook() {
        await RecordsService.StoreHook( orgID, this.state.hookID, this.state.hookDesc )
        await RecordsService.fetchHooks( orgID, this.state.cabinetID ).then(data => this.setState({ hookData: data.hooklist}));
        this.closeEditHook();
    }

    handleClose() {
        this.setState({
            cabDialog: false,
            cabinetID: '',
            cabName: '',
            building: 0,
            numHooks: '',
            notes: '',
            buildingOptions: '',
            desc: '',
            cabUUID: '',
            uid: '',
            deleted: '',
            typeID: '',
            model: '',
            sn: '',
            physicalLoc: '',
            typeName: '',
            loc: '',
            hookData:[],
            hooksIsLoading: true
        })
    }

    async saveStorage() {
        if(this.state.cabName==='') {
            this.setState({cabNameError: true})
        } else if(this.state.cabNameError) {
            this.setState({cabNameError: false})
        }

        if(this.state.numHooks==='') {
            this.setState({numHooksError: true})
        } else if(this.state.numHooksError) {
            this.setState({numHooksError: false})
        }

        if(this.state.cabName!==''&&this.state.numHooks!=='') {
            await RecordsService.storeCabinet(this.state.cabinetID, this.state.cabUUID, this.state.cabName, this.state.desc, this.state.notes, 
                orgID, this.state.uid, parseInt(this.state.numHooks, 10), this.state.deleted, this.state.building, this.state.typeID, this.state.model, this.state.sn,
                this.state.physicalLoc, this.state.typeName, this.state.loc).then(data => this.showResults(data))
            await RecordsService.fetchCabinets( orgID, 0 ).then(data => this.setState({ cabData: data.cabinetlist}));
            this.setState({
                cabNameError: false,
                numHooksError: false
            })
            this.handleClose();
        }
    }

    async addHooks() {
        await RecordsService.AddHooks( orgID, this.state.cabinetID, this.state.numAddHooks );
        await RecordsService.fetchHooks( orgID, this.state.cabinetID ).then(data => this.setState({ hookData: data.hooklist, numHooks: data.hooklist.length }));
        await RecordsService.fetchCabinets( orgID, 0 ).then(data => this.setState({ cabData: data.cabinetlist}));
        this.closeAddHook();
    }

    async deleteCabinet() {
        await RecordsService.DeleteCabinet(this.state.cabinetID, orgID).then(data => this.showResults(data))
        await RecordsService.fetchCabinets( orgID, 0 ).then(data => this.setState({ cabData: data.cabinetlist}));
        this.handleClose();
        this.closeConfirmDeleteCabinet();
    }

    confirmDeleteCabinet() {
        this.setState({confirmDeleteCabinet: true})
    }

    closeConfirmDeleteCabinet() {
        this.setState({confirmDeleteCabinet: false})
    }

    addHookDialog() {
        this.setState({
            addHookDialog: true,
            numAddHooks: null
        })
    }

    closeAddHook() {
        this.setState({
            addHookDialog: false,
            numAddHooks: null
        })
    }

    showResults(data) {
        if(data.success) {
            this.messages.show({severity: 'success', summary: data.message, life:1000});
        } else {
            this.messages.show({severity: 'error', summary: data.message, life:3000});
        }
    }

    handleFieldChange(e) {
        if(e.target.id==='hookDesc') {
            this.setState({hookDesc: e.target.value})
        } else if(e.target.id==='cabName') {
            this.setState({cabName: e.target.value})
        } else if(e.target.id==='desc') {
            this.setState({desc: e.target.value})
        } else if(e.target.id==='numHooks') {
            if(e.target.value>1000) {
                this.setState({numHooks: 1000})
            } else {
                this.setState({numHooks: e.target.value})
            }
        } else if(e.target.id==='numAddHooks') {
            let numHookVar = parseInt(this.state.numHooks, 10);
            let addHookVar = parseInt(e.target.value, 10);
            if((numHookVar + addHookVar)>1000) {
                this.setState({numAddHooks: 1000})
            } else {
                this.setState({numAddHooks: e.target.value})
            }
        } else if(e.target.id==='building') {
            this.setState({building: e.target.value})
        } else if(e.target.id==='notes') {
            this.setState({notes: e.target.value})
        }        
    }

    renderFooter() {
        return(
            <div>
                {this.state.cabNameError ? (<span style={{color:'red', marginRight:'20px'}}>Name Cannot Be Empty</span>) : (null)}
                {this.state.numHooksError ? (<span style={{color:'red', marginRight:'20px'}}># Hooks Cannot Be Empty</span>) : (null)}
                {(this.state.cabinetID===-1 || this.state.disabled) ? (null): (<Button type='button' label='Add Hooks' icon='pi pi-plus' onClick={this.addHookDialog} />)}
                {(this.state.cabinetID===-1 || this.state.disabled) ? (null): (<Button type='button' label='Delete' icon='pi pi-trash' onClick={this.confirmDeleteCabinet} />)}
                {this.state.disabled ? (null) : (<Button type='button' label='Save and Exit' icon='pi pi-check' onClick={this.saveStorage} />)}
                <Button type='button' label='Exit' icon='pi pi-times' onClick={this.handleClose} />
            </div>
        );
    }

    renderHookFooter() {
        return(
            <div>
                <Button type='button' label='Save and Exit' icon='pi pi-check' onClick={this.saveHook} />
                <Button type='button' label='Exit' icon='pi pi-times' onClick={this.closeEditHook} />
            </div>
        );
    }

    renderAddHookFooter() {
        return(
            <div>
                <Button type='button' label='Save and Exit' icon='pi pi-check' onClick={this.addHooks} />
                <Button type='button' label='Exit' icon='pi pi-times' onClick={this.closeAddHook} />
            </div>
        );
    }

    renderDeleteCabFooter() {
        return(
            <div>
                <Button type='button' label='Yes' icon='pi pi-check' onClick={this.deleteCabinet} />
                <Button type='button' label='Cancel' icon='pi pi-times' onClick={this.closeConfirmDeleteCabinet} />
            </div>
        );
    }

    render() {
        const btnFooter = this.renderFooter();
        const hookBtnFooter = this.renderHookFooter();
        const addHookFooter = this.renderAddHookFooter();
        const confirmDeleteFooter = this.renderDeleteCabFooter();
        if(this.state.secRole===0 && !this.state.isLoading) {
            return(
                <Layout>
                    <div>Security Role prevents you from seeing data</div>
                </Layout>
            )
        } else {
            return(
                <div>
                    {(this.state.cabData.length===0 && !this.state.isLoading) ? (<Layout><div><Button type='button' label='New Key Storage' icon='pi pi-plus' onClick={this.handleNewCabinet}  /></div></Layout>) : 
                    (
                    <div>
                        <Layout>
                            <Messages ref={(el) => this.messages = el} />
                            <GeneralDatatable 
                                columnArray = {[
                                    {field:'uiB_NAME', header:'Name'},
                                    {field:'uiB_DESC', header:'Description'},
                                    {field:'caB_LOC', header:'Building'},
                                    {field:'uiB_NUMHOOKS', header:'# Hooks'}
                                ]}

                                csvHeaders = {[
                                    {key:'uiB_NAME', label:'Name'},
                                    {key:'uiB_DESC', label:'Description'},
                                    {key:'caB_LOC', label:'Building'},
                                    {key:'uiB_NUMHOOKS', label:'# Hooks'}
                                ]}

                                defaultOrder={["uiB_NAME", "uiB_DESC", "caB_LOC", "uiB_NUMHOOKS"]}

                                tableRecords = {this.state.cabData}
                                pageNumber={8}
                                tableName={'Key Storage'}
                                exportFileName={'KeyStorage.csv'}
                                tableData={'KeyStorageTableData'}
                                filterData={'KeyStorageFilterData'}
                                columnWidthTracker={this.state.columnWidthTracker}
                                isLoading={this.state.isLoading}
                                selectedColumns={this.state.selectedColumns}
                                handleRowClick={this.editCabinet}
                                handleWidth={this.handleWidth}
                                handleSelectedColumns={this.handleSelectedColumns}
                                hasButton={!this.state.disabled}
                                buttonLabel={'New Key Storage'}
                                onButtonClick={this.handleNewCabinet}
                            />
                        </Layout>
                    </div>
                    )}
                    <div>
                        <Dialog visible={this.state.cabDialog} onHide={this.handleClose} header={'Edit ' + this.state.cabName} footer={btnFooter} baseZIndex={1300} maximized blockScroll focusOnShow={false} closable={false}>
                            <div>
                                <BrowserView>
                                    <div className='p-grid p-justify-center'>
                                        <div className='p-col-4 p-grid'>
                                            <div className='p-col-12 p-grid'>
                                                <label className="p-col-6" style={{textAlign:'right'}}>
                                                    <strong>{"Name: "}</strong>
                                                </label>
                                                <InputText style={{width:'250px'}} disabled={this.state.disabled} className='p-col-6'value={this.state.cabName} onChange={this.handleFieldChange} type='text' id={'cabName'} />
                                            </div>
                                            <div className='p-col-12 p-grid'>
                                                <label className="p-col-6" style={{textAlign:'right'}}>
                                                    <strong>{"Description: "}</strong>
                                                </label>
                                                <InputText style={{width:'250px'}} disabled={this.state.disabled} className='p-col-6'value={this.state.desc} onChange={this.handleFieldChange} type='text' id={'desc'} />
                                            </div>
                                            {this.state.cabinetID===-1 ? (
                                                <div className='p-col-12 p-grid'>
                                                    <label className="p-col-6" style={{textAlign:'right'}}>
                                                        <strong>{"# Hooks: "}</strong>
                                                    </label>
                                                    <InputText style={{width:'250px'}} keyfilter='pint' disabled={this.state.disabled}
                                                        className='p-col-6'value={this.state.numHooks} onChange={this.handleFieldChange}
                                                        type='text' id={'numHooks'} 
                                                    />
                                                </div>
                                            ) : (
                                                <Label label={'# Hooks: '} value={this.state.numHooks} />
                                            )}
                                            {this.state.numHooks===1000 ? (<div className='p-col-12' style={{color:'red'}}>Maximum Number of Hooks is 1000</div>) : (null)}
                                            <div className='p-col-12 p-grid'>
                                                <label className="p-col-6" style={{textAlign:'right'}}><strong>{'Building: '}</strong></label>
                                                <Dropdown
                                                    className='p-col-6'
                                                    style={{width:'250px', height:'40px', paddingTop:'0px'}}
                                                    placeholder="Select"
                                                    id={'building'} 
                                                    options={this.state.buildingOptions} 
                                                    value={this.state.building} 
                                                    onChange={this.handleFieldChange}
                                                    disabled={this.state.disabled}
                                                />
                                            </div>
                                        </div>
                                        <div className='p-col-4 p-grid'>
                                            <div className='p-col-12 p-grid'>
                                                <label className='p-col-4' style={{textAlign:'right'}}><strong>Notes</strong></label>
                                                <InputTextarea 
                                                    style={{textAlign:'left', width:'250px', marginLeft:'5px', marginRight:'0px'}}
                                                    rows={5} className='p-col-8' disabled={this.state.disabled} 
                                                    value={this.state.notes} type="text" id='notes' onChange={(e) => this.handleFieldChange(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </BrowserView>
                                <MobileView>
                                    <div className='p-grid p-justify-center'>
                                        <TextBox mobile value={this.state.cabName} id="cabName" label={"Name:"} handleFieldChange={(e) => this.handleFieldChange(e)} disabled={this.state.disabled} />
                                        <TextBox mobile value={this.state.desc} id="desc" label={"Description:"} handleFieldChange={(e) => this.handleFieldChange(e)} disabled={this.state.disabled} />
                                        {this.state.cabinetID===-1 ? (
                                            <TextBox mobile value={this.state.numHooks} id="numHooks" label={"# Hook:"} handleFieldChange={(e) => this.handleFieldChange(e)} disabled={this.state.disabled} />

                                        ) : (
                                            <Label mobile label={'# Hooks: '} value={this.state.numHooks} />

                                        )}
                                        {this.state.numHooks===1000 ? (<div className='p-col-12' style={{color:'red'}}>Maximum Number of Hooks is 1000</div>) : (null)}
                                        <InputSelect mobile value={this.state.building} id={'building'} handleFieldChange={this.handleFieldChange} label={'Building: '} options={this.state.buildingOptions} disabled={this.state.disabled} />
                                        <TextBox mobile area rows={5} value={this.state.notes} id="notes" label={"Notes:"} handleFieldChange={(e) => this.handleFieldChange(e)} disabled={this.state.disabled} />
                                    </div>
                                </MobileView>
                            </div>
                            {this.state.cabinetID===-1 ? (null) : 
                                (<Hooks
                                    hookData={this.state.hookData}
                                    handleRowClick={this.editHook}
                                    isLoading={this.state.hooksIsLoading}
                                />)
                            }
                        </Dialog>
                        <Dialog visible={this.state.editHook} onHide={this.closeEditHook} header={'Edit Hook ' + this.state.hookNum} footer={hookBtnFooter} baseZIndex={1300} blockScroll focusOnShow={false} closable={false}>
                            <div>
                                <h4>{this.state.cabName}</h4>
                                <h4>{'Hook # ' + this.state.hookNum}</h4>
                                <div><h4 style={{marginBottom:'0px'}}>Description: </h4><InputText id='hookDesc' type="text" value={this.state.hookDesc} onChange={this.handleFieldChange} /></div>
                            </div>
                        </Dialog>
                        <Dialog visible={this.state.addHookDialog} onHide={this.closeAddHook} header={'Add Hooks'} footer={addHookFooter} baseZIndex={1300} blockScroll focusOnShow={false} closable={false}>
                            <div>
                                <h4>{this.state.cabName}</h4>
                                <div>
                                    <h4 style={{marginBottom:'0px'}}># Hooks: </h4>
                                    <InputText style={{textAlign:'center'}} keyfilter='pint' disabled={this.state.disabled}
                                        className='p-col-6'value={this.state.numAddHooks} onChange={this.handleFieldChange}
                                        type='text' id={'numAddHooks'} 
                                    />
                                    {this.state.numAddHooks===1000 ? (<div style={{color: 'red'}}>Maximum Number of Hooks is 1000</div>) : (null)}
                                </div>
                            </div>
                        </Dialog>
                        <Dialog visible={this.state.confirmDeleteCabinet} onHide={this.closeConfirmDeleteCabinet} header={'Delete: ' + this.state.cabName + '?'} footer={confirmDeleteFooter} baseZIndex={1300} blockScroll focusOnShow={false} closable={false}>
                            <div>Are you sure you want to delete: {this.state.cabName}?</div>
                        </Dialog>
                    </div>
                </div>
            );
        }
    }
}

export default KeyStorage;