import React from "react";
import { Redirect } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import InputAdornment from '@material-ui/core/InputAdornment';
import LockIcon from '@material-ui/icons/Lock';
import WorkIcon from '@material-ui/icons/Work';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import Grid from'@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import { userService } from "../services/Auth";
import MWlogo from '../images/MWlogo.png';

function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://www.mwspi.com/" target='_blank' rel='noopener'>
          Midwest Security Products Inc.
        </Link>{' '}
        {new Date().getFullYear()}
      </Typography>
    );
  }

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      },
      root: {
        width: '100%',
        '& > * + *': {
          marginTop: theme.spacing(2),
        },
      },
      form: {
        width: '100%', 
        marginTop: theme.spacing(1),
      },
      submitButton: {
        margin: theme.spacing(3, 0, 2, 0),
      },
      box: {
        marginTop: theme.spacing(0),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justify: 'left',
        textAlign: 'left',
      },
      loading: {
        display: 'flex',
        '& > * + *': {
          marginLeft: theme.spacing(2),
        },
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        margin: theme.spacing(3, 0, 2, 0),
      }
  });

class ResetPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            toLogin: false,
            popUpIsOpen: false,
            urlToken: new URLSearchParams(props.location.search).get('token'),
            errorMsg: false,
            invalidErrorMsg: false,
            isLoading: false,
            orgId: '',
            password: '',
            password2:'',
            passwordContainsNumber: false,
            passwordContainsSymbol: false,
            passwordContainsUpper: false,
            passwordContainsLower: false,
            passwordsMatch: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleFormSubmit = async e => {
        e.preventDefault();

        this.setState({ isLoading:true });

        await userService.resetPassword(this.state.urlToken, this.state.password, this.state.orgId)
        let isResetPasswordValid = sessionStorage.getItem('isResetPasswordValid');
        let parsedIsResetPasswordValid = JSON.parse(isResetPasswordValid);
        try {
            if (parsedIsResetPasswordValid.uiU_OTP_VAL === "VALID") {
                this.setState({ 
                    popUpIsOpen: true,
                    isLoading: false
                });
            } else if(parsedIsResetPasswordValid.uiU_OTP_VAL === "WEAK") {
                this.setState({ 
                    invalidErrorMsg: true,
                    isLoading: false 
                });
            } else {
                this.setState({ 
                    errorMsg: true,
                    isLoading: false
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    validateUpper(password) {
        if((/[A-Z]/).test(password)) {
            return true;
        } else {
            return false;
        }
    }
    
    validateLower(password) {
        if((/[a-z]/).test(password)) {
            return true;
        } else {
            return false;
        }
    }

    validateSymbol(password) {
        if((/[!*@#$%^&+=]/).test(password)) {
            return true;
        } else {
            return false;
        }
    }

    validateNumber(password) {
        if((/[0-9]/).test(password)) {
            return true;
        } else {
            return false;
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.password.length !== prevState.password.length) {
            if (this.validateSymbol(this.state.password)) {
                this.setState({ passwordContainsSymbol: true });
            } else {
                this.setState({ passwordContainsSymbol: false });
            }
            if (this.validateLower(this.state.password)) {
                this.setState({ passwordContainsLower: true });
            } else {
                this.setState({ passwordContainsLower: false });
            }
            if (this.validateUpper(this.state.password)) {
                this.setState({ passwordContainsUpper: true });
            } else {
                this.setState({ passwordContainsUpper: false });
            }
            if (this.validateNumber(this.state.password)) {
                this.setState({ passwordContainsNumber: true });
            } else {
                this.setState({ passwordContainsNumber: false });
            }
            if ((this.state.password === this.state.password2)&&this.state.password.length > 0) {
                this.setState({ passwordsMatch: true });
            } else {
                this.setState({ passwordsMatch: false });
            }
        }
        if (this.state.password2.length !== prevState.password2.length) {
            if((this.state.password2 === this.state.password)&&this.state.password2.length > 0) {
                this.setState({ passwordsMatch: true });
            } else {
                this.setState({ passwordsMatch: false });
            }
        }
    }

    handleInputChange(e) {
        const target = e.target;
        const value = target.value;
        const name = target.name;
        this.setState({ [name]: value });
    }

    handleClickOpen = () => {
        this.setState({ popUpIsOpen: true })
    }
    
    handleClickClose = () => {
        this.setState({ popUpIsOpen: false })
        this.setState({ toLogin: true })
    }

    render() {
        const { classes } = this.props;
        const { toLogin, popUpIsOpen, invalidPasswordPopup, errorMsg, invalidErrorMsg, isLoading, orgId, password, password2, passwordsMatch, passwordContainsLower, passwordContainsUpper, passwordContainsNumber, passwordContainsSymbol } = this.state;

        if(toLogin) {
            return(
                <Redirect to='/login' />
            );
        } else if (popUpIsOpen){
            return(
                <div>
                    <Dialog open={popUpIsOpen}>
                        <DialogTitle id="alert-dialog-title">
                            Password Reset
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Password has been reset.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClickClose} color="primary" autoFocus>
                                Return to Login
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            );
        } else if (invalidPasswordPopup) {
            return(
                <div>
                    <Dialog
                        open={invalidPasswordPopup}
                        disableBackdropClick
                        disableEscapeKeyDown
                    >
                        <DialogTitle id="alert-dialog-title">
                            Password Does Not Meet Password Requirements
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Password has not been reset.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClickClose} color="primary" autoFocus>
                                Return to Login
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            );            
        } else {
        return(
        <Container component="main" maxWidth="xs">
        <CssBaseline />
        {invalidErrorMsg ? (
              <div className={classes.root}>
                <Snackbar open={invalidErrorMsg} anchorOrigin={{vertical: 'top', horizontal: 'center' }}>	
                    <SnackbarContent 
                        severity="error" 
                        elevation={6} 
                        variant='outlined' 
                        message='Password does not meet password requirements'
                        style={{backgroundColor:'red'}}
                    />	
	              </Snackbar>
            </div>
            ) : (
              null
            )  
        }
        {errorMsg ? (
              <div className={classes.root}>
                <Snackbar open={errorMsg} anchorOrigin={{vertical: 'top', horizontal: 'center' }}>	
                <SnackbarContent 
                    severity="error" 
                    elevation={6} 
                    variant='outlined' 
                    message='Password link is expired or incorrect'
                    style={{backgroundColor:'red'}}
                  />	
	              </Snackbar>
            </div>
            ) : (
              null
            )  
        }
          <div className={classes.paper}>
            <a href='https://www.mwspi.com/' target='_blank' rel='noopener noreferrer'><img src={MWlogo} alt='logo' width='355' height='128' /></a>
            <Typography variant='h5' color='error' gutterBottom>
                Solutions. Designed. Delivered.
            </Typography>
            <form onSubmit={this.handleFormSubmit} className={classes.form}>
                <h1>LockViewer Enterprise</h1>
                <h2>New Password Requirements</h2>
                <Box className={classes.box}>
                    <ul>
                        {(password.length>7) ? 
                            (
                            <li>
                                <Grid container direction="row" alignItems="center">
                                    <Grid item>
                                        <CheckBoxOutlinedIcon  />
                                    </Grid>
                                    <Grid item>
                                        At least 8 characters length
                                    </Grid>
                                </Grid>
                            </li>
                            ) : 
                            (
                            <li>
                                <Grid container direction="row" alignItems="center">
                                    <Grid item>
                                        <CheckBoxOutlineBlankOutlinedIcon  />
                                    </Grid>
                                    <Grid item>
                                        At least 8 characters length
                                    </Grid>
                                </Grid>
                            </li>
                            )
                        }
                        {passwordContainsUpper ? 
                            (
                            <li>
                                <Grid container direction="row" alignItems="center">
                                    <Grid item>
                                        <CheckBoxOutlinedIcon  />
                                    </Grid>
                                    <Grid item>
                                        At least one uppercase letter
                                    </Grid>
                                </Grid>
                            </li>
                            ) : 
                            (
                            <li>
                                <Grid container direction="row" alignItems="center">
                                    <Grid item>
                                        <CheckBoxOutlineBlankOutlinedIcon  />
                                    </Grid>
                                    <Grid item>
                                        At least one uppercase letter
                                    </Grid>
                                </Grid>
                            </li>
                            )
                        }
                        {passwordContainsLower ? 
                            (
                            <li>
                                <Grid container direction="row" alignItems="center">
                                    <Grid item>
                                        <CheckBoxOutlinedIcon  />
                                    </Grid>
                                    <Grid item>
                                        At least one lowercase letter
                                    </Grid>
                                </Grid>
                            </li>
                            ) : 
                            (
                            <li>
                                <Grid container direction="row" alignItems="center">
                                    <Grid item>
                                        <CheckBoxOutlineBlankOutlinedIcon  />
                                    </Grid>
                                    <Grid item>
                                        At least one lowercase letter
                                    </Grid>
                                </Grid>
                            </li>
                            )
                        }
                        {passwordContainsSymbol ? 
                            (
                            <li>
                                <Grid container direction="row" alignItems="center">
                                    <Grid item>
                                        <CheckBoxOutlinedIcon  />
                                    </Grid>
                                    <Grid item>
                                        At least one symbol (! * @ # $ % ^ & + =)
                                    </Grid>
                                </Grid>
                            </li>
                            ) : 
                            (
                            <li>
                                <Grid container direction="row" alignItems="center">
                                    <Grid item>
                                        <CheckBoxOutlineBlankOutlinedIcon  />
                                    </Grid>
                                    <Grid item>
                                        At least one symbol (! * @ # $ % ^ & + =)
                                    </Grid>
                                </Grid>
                            </li>
                            )
                        }
                        {passwordContainsNumber ? 
                            (
                            <li>
                                <Grid container direction="row" alignItems="center">
                                    <Grid item>
                                        <CheckBoxOutlinedIcon  />
                                    </Grid>
                                    <Grid item>
                                        At least one number
                                    </Grid>
                                </Grid>
                            </li>
                            ) : 
                            (
                            <li>
                                <Grid container direction="row" alignItems="center">
                                    <Grid item>
                                        <CheckBoxOutlineBlankOutlinedIcon  />
                                    </Grid>
                                    <Grid item>
                                        At least one number
                                    </Grid>
                                </Grid>
                            </li>
                            )
                        }
                        {passwordsMatch ? 
                            (
                            <li>
                                <Grid container direction="row" alignItems="center">
                                    <Grid item>
                                        <CheckBoxOutlinedIcon  />
                                    </Grid>
                                    <Grid item>
                                        Passwords must match
                                    </Grid>
                                </Grid>
                            </li>
                            ) : 
                            (
                            <li>
                                <Grid container direction="row" alignItems="center">
                                    <Grid item>
                                        <CheckBoxOutlineBlankOutlinedIcon  />
                                    </Grid>
                                    <Grid item>
                                        Passwords must match
                                    </Grid>
                                </Grid>
                            </li>
                            )
                        }
                    </ul>
                </Box>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="orgId"
                    label="Organization"
                    name="orgId"
                    placeholder="Organization"
                    value={orgId}
                    onChange={this.handleInputChange}
                    autoFocus
                    helperText='Organization'
                    InputProps={{
                        startAdornment: (
                            <InputAdornment posistion="start">
                                <WorkIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="password"
                    label="New Password"
                    name="password"
                    type="password"
                    placeholder="New Password"
                    value={password}
                    onChange={this.handleInputChange}
                    autoFocus
                    helperText='New Password'
                    InputProps={{
                        startAdornment: (
                            <InputAdornment posistion="start">
                                <LockIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="password2"
                    label="Confirm Password"
                    name="password2"
                    type="password"
                    placeholder=" Confirm Password"
                    value={password2}
                    onChange={this.handleInputChange}
                    autoFocus
                    helperText='Confirm Password'
                    InputProps={{
                        startAdornment: (
                            <InputAdornment posistion="start">
                                <LockIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                {
                    isLoading ? (
                        <div className={classes.loading}>
                            <CircularProgress />
                        </div>
                    ) : (((password.length > 7)&&passwordContainsUpper&&passwordContainsLower&&passwordContainsNumber&&passwordContainsSymbol&&passwordsMatch&&(orgId.length!==0)) ? (
                        <div className={classes.submitButton}>
                            <Button
                                type="submit"
                                size="large"
                                fullWidth
                                variant="contained"
                                color="primary"
                            >
                                Reset Password
                            </Button>
                        </div>
                    ) : (
                        <div className={classes.submitButton}>
                        <Button
                            type="submit"
                            size="large"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled
                        >
                            Create Password
                        </Button>
                    </div>
                    ))
                }
            </form>
            </div>
            <Copyright />
        </Container>
        );
    }
}
}

export default withStyles(styles, { withTheme: true })(ResetPassword);