import React from 'react';

import { DeptService, PeopleService } from '../services/FetchServices';
import Layout from '../components/Layout';
import GeneralDatatable from '../components/GeneralDatatable';
import TextBox from '../components/inputComponents/textbox';
import PeopleDatatable from '../components/PeopleDatatable';

import {Messages} from 'primereact/messages';
import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import { isMobile } from 'react-device-detect';

class Departments extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            data: [],
            isLoading: true,
            selectedColumns: [
                {field:'uiD_NAME', header:'Name'},
                {field:'uiD_NOTES', header:'Notes'},
                {field:'uiD_DESC', header:'Description'}
            ],
            columnWidthTracker: "487.6,487.6,487.6",
            defaultOrder:["uiD_NAME", "uiD_NOTES", "uiD_DESC"],
            secRole: '0',
            disabled: true,
            hasButton: false,
            editDepartmentDialog: false,
            name: "",
            notes: "",
            desc: "",
            deptID: 0,
            dialogHeader: "",
            confirmDeleteDialog: false,
            error: false,
            peopleData: [],
            peopleIsLoading: true
        }
        this.handleWidth = this.handleWidth.bind(this);
        this.handleSelectedColumns = this.handleSelectedColumns.bind(this);
        this.editDepartment = this.editDepartment.bind(this);
        this.closeEdit = this.closeEdit.bind(this);
        this.handleNewDepartment = this.handleNewDepartment.bind(this);
        this.saveDepartment = this.saveDepartment.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.deleteDepartment = this.deleteDepartment.bind(this);
        this.showResults = this.showResults.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.cancelDelete = this.cancelDelete.bind(this);
        this.sortUsers = this.sortUsers.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    async componentDidMount() {
        let x = JSON.parse(sessionStorage.getItem("departmentsTableData"));
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));

        if(x !== null) {
            if(x.selectedColumns !== null) {
                this.setState({ selectedColumns: x.selectedColumns })
            }
        }

        if(parsedUserData.secString[4]==='3') {
            this.setState({hasButton: true, disabled: false})
        }

        await DeptService.FetchDept( parsedUserData.uiU_M1ORGID, 0, "" )
            .then(data => 
                this.setState({ 
                    data: data.depts, 
                    isLoading: false, 
                    orgID: parsedUserData.uiU_M1ORGID,
                    secRole: parsedUserData.secString[4]
                })
            );
    }

    handleWidth(width) {
        this.setState({ columnWidthTracker: width })
    }

    handleSelectedColumns(e) {
        this.setState({ selectedColumns: e })
    }

    showResults(data) {
        if(data.success) {
            this.messages.show({severity: 'success', summary: data.message, life:1500});
        } else {
            this.messages.show({severity: 'error', summary: data.message, life:1500});
        } 
    }

    handleSelect() {

    }

    async editDepartment(e) {
        if(this.state.secRole!=='1') {
            await PeopleService.fetchUsers( this.state.orgID, "", 0 ).then(data => this.sortUsers(data, e.data.uiD_ID));
            this.setState({
                editDepartmentDialog: true,
                name: e.data.uiD_NAME,
                notes: e.data.uiD_NOTES,
                desc: e.data.uiD_DESC,
                deptID: e.data.uiD_ID,
                dialogHeader: "Edit " + e.data.uiD_NAME,
                peopleIsLoading: false
            })
        }
    }

    closeEdit() { 
        this.setState({
            editDepartmentDialog: false,
            name: "",
            notes: "",
            desc: "",
            deptID: 0,
            dialogHeader: "",
            error: false,
            peopleData: [],
            peopleIsLoading: true
        })
    }

    handleNewDepartment() {
        this.setState({
            editDepartmentDialog: true,
            dialogHeader: 'New Department',
            deptID: -1
        })
    }

    async saveDepartment() {
        if(this.state.name==="") {
            this.setState({error: true})
        } else {
            await DeptService.StoreDept(this.state.orgID, this.state.deptID, this.state.name, this.state.notes, this.state.desc, "", "").then(data => this.showResults(data));
            await DeptService.FetchDept( this.state.orgID, 0, "" ).then(data => this.setState({data: data.depts}));
            this.closeEdit();
        }
    }

    confirmDelete() {
        this.setState({
            confirmDeleteDialog: true,
            dialogHeader: "Delete " + this.state.name
        })
    }

    cancelDelete() {
        this.setState({
            confirmDeleteDialog: false,
            dialogHeader: "Edit " + this.state.name
        })
    }

    async deleteDepartment() {
        await DeptService.DeleteDept(this.state.orgID, this.state.deptID).then(data => this.showResults(data));
        await DeptService.FetchDept( this.state.orgID, 0, "" ).then(data => this.setState({data: data.depts}));
        this.cancelDelete();
        this.closeEdit();
    }

    sortUsers(data, deptID) {
        let tempArray = [];
        for(let i = 0; i<data.user.length; i++) {
            if(data.user[i].uiU_DEPTID===deptID) {
                tempArray.push(
                    {
                        "uiU_M1ORGID":  data.user[i].uiU_M1ORGID,
                        "uiU_FN": data.user[i].uiU_FN,
                        "uiU_LN": data.user[i].uiU_LN,
                        "uiU_EMAIL": data.user[i].uiU_EMAIL,
                        "uiU_SALT": data.user[i].uiU_SALT,
                        "uiU_HASH": data.user[i].uiU_HASH,
                        "uiU_USERID": data.user[i].uiU_USERID,
                        "uiU_PW": data.user[i].uiU_PW,
                        "reseT_PW_TOKEN": data.user[i].reseT_PW_TOKEN,
                        "uiU_OTP_TIME": data.user[i].uiU_OTP_TIME,
                        "uiU_OTP_VAL": data.user[i].uiU_OTP_VAL,
                        "uiU_TOS": data.user[i].uiU_TOS,
                        "uiU_SUPERADMIN": data.user[i].uiU_SUPERADMIN,
                        "uiU_ID": data.user[i].uiU_ID,
                        "uiU_SECROLEID": data.user[i].uiU_SECROLEID,
                        "landingPage": data.user[i].landingPage,
                        "button1": data.user[i].button1,
                        "button2": data.user[i].button2,
                        "button3": data.user[i].button3,
                        "button4": data.user[i].button4,
                        "button5": data.user[i].button5,
                        "status": data.user[i].status,
                        "auditUserEvents": data.user[i].auditUserEvents,
                        "userToRoles": data.user[i].userToRoles,
                        "deptName": data.user[i].deptName,
                        "uiU_FULLNAME": data.user[i].uiU_FULLNAME,
                        "uiU_TITLE": data.user[i].uiU_TITLE,
                        "uiU_PHONE": data.user[i].uiU_PHONE,
                        "uiU_EMPLOYEEID": data.user[i].uiU_EMPLOYEEID,
                        "secString": data.user[i].secString,
                        "secRoleName": data.user[i].secRoleName,
                        "uiU_NOTES": data.user[i].uiU_NOTES,
                        "uiU_MID": data.user[i].uiU_MID,
                        "uiU_DEPTID": data.user[i].uiU_DEPTID,
                        "uiU_DOMAINID": data.user[i].uiU_DOMAINID,
                        "uiU_DELETED": data.user[i].uiU_DELETED,
                        "uiU_UID": data.user[i].uiU_UID,
                        "uiU_DEPT": data.user[i].uiU_DEPT,
                        "uiU_NUMKEYS": data.user[i].uiU_NUMKEYS,
                        "uiU_GUID": data.user[i].uiU_GUID,
                        "factorY_LOGIN": data.user[i].factorY_LOGIN,
                        "factorY_PW": data.user[i].factorY_PW,
                        "factorY_SECSTRING": data.user[i].factorY_SECSTRING,
                        "factorY_IP": data.user[i].factorY_IP,
                        "theme": data.user[i].theme,
                    }
                )
            }
        }
        this.setState({peopleData: tempArray})
    }

    handleFieldChange(e) {
        if(e.target.id==='name') {
            this.setState({ name: e.target.value })
            if(this.state.error && e.target.value!=="") {
                this.setState({error: false})
            } else if(e.target.value==="") {
                this.setState({error: true})
            }
        } else if (e.target.id==='desc') {
            this.setState({ desc: e.target.value })
        } else if (e.target.id==='notes') {
            this.setState({ notes: e.target.value })
        }
    }

    renderFooter() {
        return(
            <div>
                {this.state.error ? (<span style={{color: 'red', marginRight:'15px'}}>Name Cannot Be Blank</span>) : (null)}
                {(this.state.deptID!==-1 && this.state.secRole==='3' && this.state.name!=="" && this.state.peopleData.length<1) ? (<Button type='button' label='Delete' icon='pi pi-trash' onClick={this.confirmDelete} />) : (null)}
                {this.state.secRole==='3' ? (<Button type='button' label='Save and Exit' icon='pi pi-check' onClick={this.saveDepartment} />) : (null)}
                <Button type='button' label='Exit' icon='pi pi-times' onClick={this.closeEdit} />
            </div>
        );
    }

    renderDeleteFooter() {
        return(
            <div>
                <Button type='button' label='Delete' icon='pi pi-trash' onClick={this.deleteDepartment} />
                <Button type='button' label='Cancel' icon='pi pi-times' onClick={this.cancelDelete} />
            </div>
        )
    }
    
    render(){
        const footer=this.renderFooter();
        const deleteFooter=this.renderDeleteFooter();
        if(this.state.secRole==='0' && !this.state.isLoading) {
            return(<Layout><div>Your security role prevents you from seeing departments</div></Layout>)
        } else {
            return(
                <div> {(this.state.data.length===0 && !this.state.isLoading) ? (<Layout><Button type='button' label='New Department' icon='pi pi-plus' onClick={this.handleNewDepartment} /></Layout>) : (
                    <Layout>
                        <div className='container-div'>
                            <Messages ref={(el) => this.messages = el} />
                            <GeneralDatatable 
                                columnArray = {[
                                    {field:'uiD_NAME', header:'Name'},
                                    {field:'uiD_NOTES', header:'Notes'},
                                    {field:'uiD_DESC', header:'Description'}
                                ]}

                                csvHeader = {[
                                    {key:'uiD_NAME', label:'Name'},
                                    {key:'uiD_NOTES', label:'Notes'},
                                    {key:'uiD_DESC', label:'Description'}
                                ]}

                                defaultOrder={["uiD_NAME", "uiD_NOTES", "uiD_DESC"]}
                                tableRecords = {this.state.data}
                                pageNumber={10}
                                tableName={'Departments'}
                                exportFileName={'Departments.csv'}
                                tableData={'departmentsTableData'}
                                filterData={'departmentsFilterData'}
                                columnWidthTracker={this.state.columnWidthTracker}
                                isLoading={this.state.isLoading}
                                selectedColumns={this.state.selectedColumns}
                                handleRowClick={this.editDepartment}
                                handleWidth={this.handleWidth}
                                handleSelectedColumns={this.handleSelectedColumns}
                                hasButton={this.state.hasButton}
                                buttonLabel={'New Department'}
                                onButtonClick={this.handleNewDepartment}
                            />
                        </div>
                    </Layout>)}
                    <Dialog header={this.state.dialogHeader} visible={this.state.editDepartmentDialog} footer={footer} onHide={this.closeEdit} maximized baseZIndex={1300} blockScroll focusOnShow={false} closable={false}>
                        <div>
                            <TextBox mobile={isMobile} disabled={this.state.disabled} value={this.state.name} id="name" label={"Name: "} handleFieldChange={this.handleFieldChange} />
                            <TextBox mobile={isMobile} disabled={this.state.disabled} value={this.state.desc} id="desc" label={"Description: "} handleFieldChange={this.handleFieldChange} />
                            <TextBox area mobile={isMobile} disabled={this.state.disabled} value={this.state.notes} id="notes" label={"Notes: "} handleFieldChange={this.handleFieldChange} />
                        </div>
                        {this.state.peopleData.length > 0 ? 
                            (
                                <div style={{marginTop: '20px'}}>
                                    <PeopleDatatable
                                        peopleData={this.state.peopleData}
                                        pageNumber={36}
                                        tableName={`People in ${this.state.name}`}
                                        exportFileName={`People-in-${this.state.name}.csv`}
                                        tableData={'peopleDeptTableData'}
                                        filterData={'peopleDeptFilterData'}
                                        isLoading={this.state.peopleIsLoading}
                                        editRecord={this.handleSelect}
                                        hasButton={false}
                                    />
                                </div>
                            ) : (null)
                        }
                    </Dialog>
                    <Dialog header={this.state.dialogHeader} visible={this.state.confirmDeleteDialog} footer={deleteFooter} onHide={this.cancelDelete} baseZIndex={1300} blockScroll focusOnShow={false} closable={false}>
                        <div>Are you sure you would like to delete {this.state.name} ?</div>
                    </Dialog>
                </div>
            );
        }
    }
}

export default Departments;