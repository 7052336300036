import React from 'react';

import GeneralDatatable from './GeneralDatatable';
import {Messages} from 'primereact/messages';

class DoorHistDatatable extends React.Component {

    constructor(props) {
        super(props);

        this.state={
            data: [],
            selectedColumns: [
                {field:'loC_NAME', header:'Location'},
                {field:'dooR_NUMBER', header:'Door Number'},
                {field:'description', header:'Description'},
                {field:'corE_MARK', header:'Core Marking'},
                {field:'adD_REM_TEXT', header:'Added/Removed'},
                {field:'evenT_DATE', header:'Date'}
            ],
            columnWidthTracker: "243.9,243.9,243.9,243.9,243.9,243.9",
            defaultOrder: ["loC_NAME", "dooR_NUMBER", "description", "corE_MARK", "adD_REM_TEXT", "evenT_DATE"],
            isLoading: true,
        };
        this.handleWidth = this.handleWidth.bind(this);
        this.handleSelectedColumns = this.handleSelectedColumns.bind(this);
        this.editRecord = this.editRecord.bind(this);
        this.getDoorHist = this.getDoorHist.bind(this);
    }


    async componentDidMount() {
        let parsedTableData = JSON.parse(sessionStorage.getItem('DoorHistDatatableTableData'));

        if(parsedTableData!==null) {
            if(parsedTableData.selectedColumns !== null) {
                this.setState({ selectedColumns: parsedTableData.selectedColumns })
            }
        }

        this.getDoorHist();

        this.setState({ isLoading: false })
    }

    getDoorHist() {
        let tempArray = [];
        for(let i=0; i<this.props.doorHistData.length; i++) {
                        
            let dateObj = this.props.doorHistData[i].evenT_DATE.split('T');
            let dateVar = dateObj[0];
            let timeVar = dateObj[1];
            let dateVarArr = dateVar.split('-')
            let year = dateVarArr[0];
            let month = dateVarArr[1];
            let day = dateVarArr[2];
            let time = timeVar.split(':');
            let eventDateStr = month + '/'  + day + '/'  + year + ' ' + time[0] + ':' + time[1];

            let obj = {
                adD_REM: this.props.doorHistData[i].adD_REM,
                adD_REM_TEXT: this.props.doorHistData[i].adD_REM_TEXT,
                corE_ID: this.props.doorHistData[i].corE_ID,
                corE_MARK: this.props.doorHistData[i].corE_MARK,
                corE_MARK_ID: this.props.doorHistData[i].corE_MARK_ID,
                description: this.props.doorHistData[i].description,
                dooR_ID: this.props.doorHistData[i].dooR_ID,
                dooR_NUMBER: this.props.doorHistData[i].dooR_NUMBER,
                evenT_DATE: eventDateStr,
                loC_NAME: this.props.doorHistData[i].loC_NAME,
                orG_ID: this.props.doorHistData[i].orG_ID,
                reQ_ID: this.props.doorHistData[i].reQ_ID,
                reQ_NAME: this.props.doorHistData[i].reQ_NAME,
                udH_ID: this.props.doorHistData[i].udH_ID,
                useR_ID: this.props.doorHistData[i].useR_ID,
                useR_NAME: this.props.doorHistData[i].useR_NAME
            }
            tempArray.push(obj);
        }
        this.setState({data: tempArray})
    }

    handleWidth(width) {
        this.setState({ columnWidthTracker: width })
    }

    handleSelectedColumns(e) {
        this.setState({ selectedColumns: e })
    }

    editRecord() {
        
    }

    render() {
        return(
            <div>
                <Messages ref={(el) => this.messages = el} />
                {this.props.doorHistData.length===0 ? (<h3>This door currently has no core history.</h3>) : (
                    <div>
                        <GeneralDatatable 
                            columnArray = {[
                                {field:'loC_NAME', header:'Location'},
                                {field:'dooR_NUMBER', header:'Door Number'},
                                {field:'description', header:'Description'},
                                {field:'corE_MARK', header:'Core Marking'},
                                {field:'adD_REM_TEXT', header:'Added/Removed'},
                                {field:'evenT_DATE', header:'Date'}
                            ]}

                            csvHeaders = {[
                                {key:'loC_NAME', label:'Location'},
                                {key:'dooR_NUMBER', label:'Door Number'},
                                {key:'description', label:'Description'},
                                {key:'corE_MARK', label:'Core Marking'},
                                {key:'adD_REM_TEXT', label:'Added/Removed'},
                                {key:'evenT_DATE', label:'Date'} 
                            ]}
                            
                            defaultOrder={["loC_NAME", "dooR_NUMBER", "description", "corE_MARK", "adD_REM_TEXT", "evenT_DATE"]}

                            tableRecords = {this.state.data}
                            pageNumber={26}
                            tableName={'Door History'}
                            exportFileName={'DoorCoreHistory.csv'}
                            tableData={'DoorHistDatatableTableData'}
                            filterData={'DoorHistDatatableFilterData'}
                            columnWidthTracker={this.state.columnWidthTracker}
                            isLoading={this.state.isLoading}
                            selectedColumns={this.state.selectedColumns}
                            handleRowClick={this.editRecord}
                            handleWidth={this.handleWidth}
                            handleSelectedColumns={this.handleSelectedColumns}   
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default DoorHistDatatable;