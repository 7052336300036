import React from 'react';

import GeneralDatatable from './GeneralDatatable';
import {Messages} from 'primereact/messages';

class DoorCoreDatatable extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            coreData: [],
            selectedColumns: [
                {field:'loC_NAME', header:'Location'},
                {field:'dooR_NUMBER', header:'Door Number'},
                {field:'system', header:'System'},
                {field:'corE_MARK', header:'Core Marking'},
                {field:'status', header:'Status'},
                {field:'product', header:'Product'},  
                {field:'notes', header:'Notes'},
                {field:'fulL_CODE', header:'Full Code'},
                {field:'keyway', header:'Keyway'},  
                {field:'cross', header:'Cross'},
                {field:'fixeD_CORE', header:'Fixed Core'}
            ],
            columnWidthTracker: "133,133,133,133,133,133,133,133,133,133,133",
            defaultOrder: ["loC_NAME", "dooR_NUMBER", "system", "corE_MARK", "status", "product", "notes", "fulL_CODE", "keyway", "cross", "fixeD_CORE"],
            isLoading: true
        };
        this.handleWidth = this.handleWidth.bind(this);
        this.handleSelectedColumns = this.handleSelectedColumns.bind(this);
    }

    async componentDidMount() {
        let parsedTableData = JSON.parse(sessionStorage.getItem('DoorCoreDatatableTableData'));

        if(parsedTableData!==null) {
            if(parsedTableData.selectedColumns !== null) {
                this.setState({ selectedColumns: parsedTableData.selectedColumns })
            }
        }
        this.setState({ isLoading: false })
    }

    handleWidth(width) {
        this.setState({ columnWidthTracker: width })
    }

    handleSelectedColumns(e) {
        this.setState({ selectedColumns: e })
    }

    render() {
        return(
            <div>
                <Messages ref={(el) => this.messages = el} />
                <GeneralDatatable 
                    columnArray = {[
                        {field:'loC_NAME', header:'Location'},
                        {field:'dooR_NUMBER', header:'Door Number'},
                        {field:'system', header:'System'},
                        {field:'corE_MARK', header:'Core Marking'},
                        {field:'status', header:'Status'},
                        {field:'product', header:'Product'},  
                        {field:'notes', header:'Notes'},
                        {field:'fulL_CODE', header:'Full Code'},
                        {field:'keyway', header:'Keyway'},  
                        {field:'cross', header:'Cross'},
                        {field:'fixeD_CORE', header:'Fixed Core'}
                    ]}

                    csvHeaders = {[
                        {key:'loC_NAME', label:'Location'},
                        {key:'dooR_NUMBER', label:'Door Number'},
                        {key:'system', label:'System'},
                        {key:'corE_MARK', label:'Core Marking'},
                        {key:'status', label:'Status'},
                        {key:'product', label:'Product'},  
                        {key:'notes', label:'Notes'},
                        {key:'fulL_CODE', label:'Full Code'},
                        {key:'keyway', label:'Keyway'},  
                        {key:'cross', label:'Cross'},
                        {key:'fixeD_CORE', label:'Fixed Core'}
                    ]}
                    
                    defaultOrder={["loC_NAME", "dooR_NUMBER", "system", "corE_MARK", "status", "product", "notes", "fulL_CODE", "keyway", "cross", "fixeD_CORE"]}

                    tableRecords = {this.props.unassignedCores}
                    pageNumber={28}
                    tableName={'Unassigned Cores'}
                    exportFileName={'unassigned-cores.csv'}
                    tableData={'DoorCoreDatatableTableData'}
                    filterData={'DoorCoreDatatableFilterData'}
                    columnWidthTracker={this.state.columnWidthTracker}
                    isLoading={this.state.isLoading}
                    selectedColumns={this.state.selectedColumns}
                    handleRowClick={this.props.assignCore}
                    handleWidth={this.handleWidth}
                    handleSelectedColumns={this.handleSelectedColumns}   
                />
            </div>
        );
    }
}

export default DoorCoreDatatable;