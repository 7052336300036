const hostname = window && window.location && window.location.hostname;

let config2use = "";
let blob2use = "";
let theBlobUrl = "";

if (hostname === "localhost"){  
    config2use = process.env.REACT_APP_DEVENV;
    blob2use = process.env.REACT_APP_PROD;
    theBlobUrl = "/LockViewerEnterprise_BlobStorage/";

} else if (hostname === "uatenterprise.lockviewer.com"){
    config2use = process.env.REACT_APP_UATENV;
    blob2use = process.env.REACT_APP_UAT;
    theBlobUrl = hostname + "/LockViewerEnterprise_BlobStorage/";
} else if (hostname === "migtest.lockviewer.com"){
    config2use = process.env.REACT_APP_UATENV;
    blob2use = process.env.REACT_APP_UAT;
    theBlobUrl = hostname + "/LockViewerEnterprise_BlobStorage/";
} else {
    config2use = process.env.REACT_APP_PRODENV;
    blob2use = process.env.REACT_APP_PROD;
    theBlobUrl = hostname + "/LockViewerEnterprise_BlobStorage/";
}

export const serverAddr = {    
    apiUrl: config2use,
    blobContainer: blob2use,
    theBlobUrl: theBlobUrl
};
