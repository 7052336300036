import React from 'react';

import Layout from '../components/Layout';
import { DeviceService } from '../services/FetchServices';
import GeneralDatatable from '../components/GeneralDatatable';
import Label from '../components/inputComponents/Label';
import TextBox from '../components/inputComponents/textbox';

import {Button} from 'primereact/button';
import {Messages} from 'primereact/messages';
import {Dialog} from 'primereact/dialog';
import { BrowserView, MobileView } from 'react-device-detect';

//Page 22 for API, page 6 for internal routing
class Devices extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            devices: [],
            isLoading: true,
            selectedColumns: [
                {field:'devicE_NAME', header:'Device Name'},
                {field:'devicE_TYPE_NAME', header:'Device Type'},
                {field:'midwesT_DEVICE_ID', header:'LockViewer Device ID'},
                {field:'model', header:'Model'},
                {field:'description', header:'Description'},
                {field:'devicE_NOTES', header:'Notes'},
                {field:'firmware', header:'Firmware'},
                {field:'mfG_DEVICE_ID', header:'Mfg Device ID'}
            ],
            columnWidthTracker: "182.875,182.875,182.875,182.875,182.875,182.875,182.875,182.875",
            secRole:''
        }
      
        this.handleWidth = this.handleWidth.bind(this);
        this.handleSelectedColumns = this.handleSelectedColumns.bind(this);
        this.renderEditFooter  = this.renderEditFooter.bind(this);
        this.editRecord = this.editRecord.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleFieldSave = this.handleFieldSave.bind(this);
        this.showEditResults = this.showEditResults.bind(this);
    }

    async componentDidMount() {
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
                
        let parsedTableData = JSON.parse(sessionStorage.getItem("devicesTableData"));
        if(parsedTableData!==null) {
            if(parsedTableData.selectedColumns !== null) {
                this.setState({ selectedColumns: parsedTableData.selectedColumns })
            }
        }

        await DeviceService.fetchDevices( parsedUserData.uiU_M1ORGID, 0 )
            .then(data => 
                this.setState({ 
                    devices: data.devicelist,
                    isLoading: false,
                    secRole: parsedUserData.secString[27]
                })
            );
    }

    handleWidth(width) {
        this.setState({ columnWidthTracker: width })
    }

    handleSelectedColumns(e) {
        this.setState({ selectedColumns: e })
    }

    renderEditFooter() {
        if(this.state.secRole==='2') {
            return(
                <div>
                    <Button type='button' label='Exit' onClick={this.handleClose} />
                </div>
            );
        } else {
            return (
                <div>
                    <Button type='button' label='Save and Exit' onClick={this.handleFieldSave} />
                    <Button type='button' label='Exit' onClick={this.handleClose} />
                </div>
            );
        }
    }

    editRecord(e) {
        if (this.state.secRole==='1') {
            return;
        } else if(this.state.secRole==='2') {
            this.setState({ 
                displayRow: true,
                vaultID: e.data.devicE_ID,
                editName: e.data.devicE_NAME,
                editType: e.data.devicE_TYPE_NAME,
                editLockviewerID: e.data.midwesT_DEVICE_ID,
                editModel: e.data.model,
                editDesc: e.data.description,
                editNotes: e.data.devicE_NOTES,
                editFirmware: e.data.firmware,
                editMfgID: e.data.mfG_DEVICE_ID
            })
        } else {
            this.setState({ 
                editRow: true,
                vaultID: e.data.devicE_ID,
                editName: e.data.devicE_NAME,
                editType: e.data.devicE_TYPE_NAME,
                editLockviewerID: e.data.midwesT_DEVICE_ID,
                editModel: e.data.model,
                editDesc: e.data.description,
                editNotes: e.data.devicE_NOTES,
                editFirmware: e.data.firmware,
                editMfgID: e.data.mfG_DEVICE_ID
            })
        }
    }

    handleFieldChange(e) {
        if(e.target.id==='notes') {
            this.setState({ editNotes: e.target.value })
        } else if(e.target.id==='desc') {
            this.setState({ editDesc: e.target.value })
        }
    }

    handleClose() {
        this.setState({ 
            displayRow: false,
            editRow: false,
            vaultID: '',
            editName: '',
            editType: '',
            editLockviewerID: '',
            editModel: '',
            editDesc: '',
            editNotes: '',
            editFirmware: '',
            editMfgID: ''
        })
    }

    handleFieldSave = async e => {
        let userData = JSON.parse(sessionStorage.getItem('userData'));
        await DeviceService.updateDevices(userData.uiU_M1ORGID, this.state.vaultID, this.state.editName, this.state.editNotes, 0, this.state.editDesc, "").then(data => this.showEditResults(data));
        await DeviceService.fetchDevices( userData.uiU_M1ORGID, 0 ).then(data => this.setState({ devices: data.devicelist, editRow: false }));
    }

    showEditResults(data) {
        if(data.success===false) {
            this.messages.show({severity: 'error', summary: data.message, life:3000});
        } else {
            this.messages.show({ severity:'success', summary: "Saved Successfully", life:1000 })
        }
    }

    render() {
        const editFooter=this.renderEditFooter();
        if((this.state.secRole==='' && !this.state.isLoading) || (this.state.secRole==='0' && !this.state.isLoading)) {
            return (<Layout><div>Security Role prevents you from seeing data</div></Layout>);
        } else {
            return (
                <div>
                    {(this.state.devices.length===0 && !this.state.isLoading) ? (<Layout>Coming Soon</Layout>) : (
                        <div>
                        <Layout>
                            <div className='container-div'>
                                <Messages ref={(el) => this.messages = el} />
                                <GeneralDatatable 
                                    columnArray = {[
                                        {field:'devicE_NAME', header:'Device Name'},
                                        {field:'devicE_TYPE_NAME', header:'Device Type'},
                                        {field:'midwesT_DEVICE_ID', header:'LockViewer Device ID'},
                                        {field:'model', header:'Model'},
                                        {field:'description', header:'Description'},
                                        {field:'devicE_NOTES', header:'Notes'},
                                        {field:'firmware', header:'Firmware'},
                                        {field:'mfG_DEVICE_ID', header:'Mfg Device ID'}
                                    ]}

                                    csvHeader = {[
                                        {key:'devicE_NAME', label:'Device Name'},
                                        {key:'devicE_TYPE_NAME', label:'Device Type'},
                                        {key:'midwesT_DEVICE_ID', label:'LockViewer Device ID'},
                                        {key:'model', label:'Model'},
                                        {key:'description', label:'Description'},
                                        {key:'devicE_NOTES', label:'Notes'},
                                        {key:'firmware', label:'Firmware'},
                                        {key:'mfG_DEVICE_ID', label:'Mfg Device ID'}
                                    ]}
                                    
                                    defaultOrder={["devicE_NAME", "devicE_TYPE_NAME", "midwesT_DEVICE_ID", "model", "description", "devicE_NOTES", "firmware", "mfG_DEVICE_ID"]}

                                    tableRecords = {this.state.devices}
                                    pageNumber={22}
                                    tableName={'Devices'}
                                    exportFileName={'devices.csv'}
                                    tableData={'devicesTableData'}
                                    filterData={'devicesFilterData'}
                                    columnWidthTracker={this.state.columnWidthTracker}
                                    isLoading={this.state.isLoading}
                                    selectedColumns={this.state.selectedColumns}
                                    handleRowClick={this.editRecord}
                                    handleWidth={this.handleWidth}
                                    handleSelectedColumns={this.handleSelectedColumns}   
                                />
                            </div>
                        </Layout>
                        {/*SECURITY ROLE 2*/}
                        <Dialog header='View Record' visible={this.state.displayRow} onHide={this.handleClose} baseZIndex={1300} maximized focusOnShow={false} footer={editFooter} closable={false}>
                            <div className='p-grid p-justify-even'>
                                <BrowserView>
                                    <Label label={'Device Name: '} value={this.state.editName} />
                                    <Label label={'Device Type: '} value={this.state.editType} />
                                    <Label label={'LockViewer Device ID: '} value={this.state.editLockviewerID} />
                                    <Label label={'Model: '} value={this.state.editModel} />
                                    <Label label={'Firmware: '} value={this.state.editFirmware} />
                                    <Label label={'Mfg Device ID: '} value={this.state.editMfgID} />
                                    <Label label={'Description: '} value={this.state.editDesc} />
                                    <Label label={'Notes: '} value={this.state.editNotes} />
                                </BrowserView>
                                <MobileView>
                                    <Label mobile label={'Device Name: '} value={this.state.editName} />
                                    <Label mobile label={'Device Type: '} value={this.state.editType} />
                                    <Label mobile label={'LockViewer Device ID: '} value={this.state.editLockviewerID} />
                                    <Label mobile label={'Model: '} value={this.state.editModel} />
                                    <Label mobile label={'Firmware: '} value={this.state.editFirmware} />
                                    <Label mobile label={'Mfg Device ID: '} value={this.state.editMfgID} />
                                    <Label mobile label={'Description: '} value={this.state.editDesc} />
                                    <Label mobile label={'Notes: '} value={this.state.editNotes} />
                                </MobileView>
                            </div>
                        </Dialog>
                        {/*SECURITY ROLE 3*/}
                        <Dialog header='Edit Record' visible={this.state.editRow} onHide={this.handleClose} baseZIndex={1300} maximized focusOnShow={false} footer={editFooter} closable={false}>
                            <div className='p-grid p-justify-even'>
                                <BrowserView>
                                    <Label label={'Device Name: '} value={this.state.editName} />
                                    <Label label={'Device Type: '} value={this.state.editType} />
                                    <Label label={'LockViewer Device ID: '} value={this.state.editLockviewerID} />
                                    <Label label={'Model: '} value={this.state.editModel} />
                                    <Label label={'Firmware: '} value={this.state.editFirmware} />
                                    <Label label={'Mfg Device ID: '} value={this.state.editMfgID} />
                                    <Label label={'Description: '} value={this.state.editDesc} />
                                    <Label label={'Notes: '} value={this.state.editNotes} />
                                </BrowserView>
                                <MobileView>
                                    <Label mobile label={'Device Name: '} value={this.state.editName} />
                                    <Label mobile label={'Device Type: '} value={this.state.editType} />
                                    <Label mobile label={'LockViewer Device ID: '} value={this.state.editLockviewerID} />
                                    <Label mobile label={'Model: '} value={this.state.editModel} />
                                    <Label mobile label={'Firmware: '} value={this.state.editFirmware} />
                                    <Label mobile label={'Mfg Device ID: '} value={this.state.editMfgID} />
                                    <TextBox mobile area  value={this.state.editDesc} id="desc" label={"Description:"} handleFieldChange={(e) => this.handleFieldChange(e)} disabled={this.state.disabled} />
                                    <TextBox mobile area rows={5} value={this.state.editNotes} id="notes" label={"Notes:"} handleFieldChange={(e) => this.handleFieldChange(e)} disabled={this.state.disabled} />
                                </MobileView>
                            </div>
                        </Dialog>
                        </div>
                    )}
                </div>
            );
        }
    }
}

export default Devices;