import React from 'react';

import KeyHandInComponent from '../components/KeyHandInComponent';
import Layout from '../components/Layout';

class KeyHandIn extends React.Component {
    constructor(props) {
        super(props);
        this.state={
        };
    }

    render(){
        return(
            <div>
                <Layout>
                    <KeyHandInComponent />
                </Layout>
            </div>
        );
    }
}

export default KeyHandIn;