import React from 'react';

import { SupportService, PeopleService } from '../services/FetchServices';

import { Redirect } from 'react-router-dom';
import {Dialog} from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import {Button} from 'primereact/button';

import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';

const priorityItems = [
    {label: 'Low', value: 'Low'},
    {label: 'Medium', value: 'Medium'},
    {label: 'High', value: 'High'},
    {label: 'Emergency', value: 'Emergency'}
];

const categoryItems = [
    {label: 'Issue', value: 'Issue'},
    {label: 'Request', value: 'Request'},
    {label: 'Parts Request', value: 'Parts Request'},
    {label: 'Tech Service Request', value: 'Tech Service Request'},
    {label: 'Other', value: 'Other'}
];

const assigneeItems = [
    {label: 'Midwest Security Products Support', value: 'Midwest Security Products Support'},
    {label: 'Internal Support', value: 'Internal Support'}
];

class NewSupportTicket extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            selectedColumns:[
                {field:'tickeT_NO', header:'Case Number'},
                {field:'tickeT_STATUS', header:'Status'},
                {field:'creatE_DATE', header:'Date Created'},
                {field:'closE_DATE', header:'Date Closed'},
                {field:'tickeT_SUBJECT', header:'Case Subject'},
                {field:'tickeT_DESC', header:'Case Description'},
                {field:'priority', header:'Priority'},
                {field:'category', header:'Category'}
            ],
            columnWidthTracker:"182.875,182.875,182.875,182.875,182.875,182.875,182.875,182.875",
            isLoading: true,
            data: [],
            detailsData: [],
            groupID: 0,
            desc: '',
            notes:'',
            disabled: false,
            hasButton: true,
            detailDialog: true,
            sid: "",
            guid: "",
            tickeT_NO: "NEW",
            tickeT_STATUS: "Open",
            creatE_DATE: "",
            closE_DATE: "",
            lasT_UPDATED_BY: "",
            tickeT_SUBJECT: "",
            tickeT_DESC: "",
            assignedTo: "Internal Support",
            priority: "Low",
            contacT_METHOD: "Email",
            tickeT_CONTACT: "",
            contacT_EMAIL: "",
            contacT_PHONE: "",
            category: "Issue",
            filE1_PATH: "",
            filE2_PATH: "",
            filE3_PATH: "",
            filE4_PATH: "",
            filE5_PATH: "",
            updatedBy:"",
            newCase: false,
            dialogHeader: "Create Case",
            assignedToContactOptions: [],
            assignedToContact: ""
        }

        this.handleCancel = this.handleCancel.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleTicketSave = this.handleTicketSave.bind(this);
        this.updateTicket = this.updateTicket.bind(this);
        this.createObject = this.createObject.bind(this);
    }

    async componentDidMount(props) {
        let parsedUserData = JSON.parse(sessionStorage.getItem('userData'));
        await PeopleService.fetchUsers( parsedUserData.uiU_M1ORGID, "", parsedUserData.uiU_ID )
        .then(data => 
            this.setState({ 
                detailDialog: true, 
                orgID: parsedUserData.uiU_M1ORGID,
                tickeT_CONTACT: data.user[0].uiU_FN + " " + data.user[0].uiU_LN,
                contacT_PHONE: data.user[0].uiU_PHONE,
                contacT_EMAIL: data.user[0].uiU_EMAIL
            })      
        ); 

        let usersData = await PeopleService.fetchUsers( parsedUserData.uiU_M1ORGID, "", 0 );
        usersData = usersData.user;
        let tempArray = [];
        for(let i=0; i<usersData.length; i++) {
            if(usersData[i].uiU_EMAIL !== "") {
                let splitArr = usersData[i].uiU_EMAIL.split('@');
                if(splitArr[1] !== "mwspi.com") {
                    tempArray.push(this.createObject(usersData[i].uiU_EMAIL, usersData[i].uiU_EMAIL));
                }
            }
        }
        this.setState({assignedToContactOptions: tempArray})
    }

    handleCancel() {
        this.setState({toSupportCases: true})
    }

    showResults(data) {
        this.messages.clear();
        if(data.success) {
            this.messages.show({severity: 'success', summary: data.message, life:1000});
        } else {
            this.messages.show({severity: 'error', summary: data.message, sticky: true});
        }
    }

    async handleTicketSave() {
        let returnData = await SupportService.createTicket( this.state.orgID, this.state.tickeT_STATUS, this.state.closE_DATE, this.state.lasT_UPDATED_BY, this.state.tickeT_SUBJECT, this.state.tickeT_DESC, this.state.priority, this.state.contacT_METHOD, this.state.tickeT_CONTACT, this.state.contacT_EMAIL, this.state.contacT_PHONE, this.state.category, this.state.filE1_PATH, this.state.filE2_PATH, this.state.filE3_PATH, this.state.filE4_PATH, this.state.filE5_PATH, this.state.assignedToContact)
        if(returnData!==null) {
            this.setState({
                sid: returnData.supporthdr.sid,
                guid: returnData.supporthdr.guid,
                tickeT_NO: returnData.supporthdr.tickeT_NO,
                tickeT_STATUS: returnData.supporthdr.tickeT_STATUS,
                creatE_DATE: returnData.supporthdr.creatE_DATE,
                closE_DATE: returnData.supporthdr.closE_DATE,
                lasT_UPDATED_BY: returnData.supporthdr.lasT_UPDATED_BY,
                tickeT_SUBJECT: returnData.supporthdr.tickeT_SUBJECT,
                tickeT_DESC: returnData.supporthdr.tickeT_DESC,
                priority: returnData.supporthdr.priority,
                contacT_METHOD: returnData.supporthdr.contacT_METHOD,
                tickeT_CONTACT: returnData.supporthdr.tickeT_CONTACT,
                contacT_EMAIL: returnData.supporthdr.contacT_EMAIL,
                contacT_PHONE: returnData.supporthdr.contacT_PHONE,
                category: returnData.supporthdr.category,
                filE1_PATH: returnData.supporthdr.filE1_PATH,
                filE2_PATH: returnData.supporthdr.filE2_PATH,
                filE3_PATH: returnData.supporthdr.filE3_PATH,
                filE4_PATH: returnData.supporthdr.filE4_PATH,
                filE5_PATH: returnData.supporthdr.filE5_PATH,
                toSupportCases: true
            });
        }

        // await SupportService.getTickets( this.state.orgID, "").then(data => this.setState({ data: data.ticketlist}));
    }

    async updateTicket(file1, file2, file3, file4, file5) {
        let file1path = this.state.filE1_PATH;
        let file2path = this.state.filE2_PATH;
        let file3path = this.state.filE3_PATH;
        let file4path = this.state.filE4_PATH;
        let file5path = this.state.filE5_PATH;

        if(file1!=="") {
            file1path = file1;
        } else if(file2!=="") {
            file2path = file2;
        } else if(file3!=="") {
            file3path = file3;
        } else if(file4!=="") {
            file4path = file4;
        } else if(file5!=="") {
            file5path = file5;
        }

        await SupportService.updateTicket( this.state.orgID, this.state.tickeT_NO, this.state.tickeT_STATUS, this.state.closE_DATE, this.state.lasT_UPDATED_BY, this.state.tickeT_SUBJECT, this.state.tickeT_DESC, this.state.priority, this.state.contacT_METHOD, this.state.tickeT_CONTACT, this.state.contacT_EMAIL, this.state.contacT_PHONE, this.state.category, file1path, file2path, file3path, file4path, file5path)
    }

    async handleFieldChange(e) {
        if(e.target.id==='category') {
            this.setState({ category: e.target.value })
        } else if (e.target.id==='contacT_PHONE') {
            this.setState({ contacT_PHONE: e.target.value })
        } else if (e.target.id==='contacT_EMAIL') {
            this.setState({ contacT_EMAIL: e.target.value })
        } else if (e.target.id==='tickeT_CONTACT') {
            this.setState({ tickeT_CONTACT: e.target.value })
        } else if (e.target.id==='contacT_METHOD') {
            this.setState({ contacT_METHOD: e.target.value })
        } else if (e.target.id==='priority') {
            this.setState({ priority: e.target.value })
        } else if (e.target.id==='tickeT_DESC') {
            this.setState({ tickeT_DESC: e.target.value })
        } else if (e.target.id==='tickeT_SUBJECT') {
            // console.log(e.target.value.length)
            if(e.target.value.length < 50) {
                this.setState({ tickeT_SUBJECT: e.target.value })
            }
        } else if (e.target.id==='closE_DATE') {
            this.setState({ closE_DATE: e.target.value })
        } else if (e.target.id==='lasT_UPDATED_BY') {
            this.setState({ lasT_UPDATED_BY: e.target.value })
        } else if (e.target.id==='newUpdatedBy') {
            this.setState({ newUpdatedBy: e.target.value })
        } else if (e.target.id==='tickeT_NO') {
            this.setState({ tickeT_NO: e.target.value })
        } else if (e.target.id==='tickeT_STATUS') {
            this.setState({ tickeT_STATUS: e.target.value })
        } else if (e.target.id==='assignedTo') {
            this.setState({ assignedTo: e.target.value })
            if(e.target.value !== "Internal Support") {
                this.setState({ assignedToContact: "MIDWEST"})
            } else {
                this.setState({assignedToContact: ""})
            }
        } else if (e.target.id==='assignedToContact') {
            this.setState({ assignedToContact: e.target.value })
        }
    }

    createObject(label, value) {
        const obj = {};

        obj.label = label;
        obj.value = value;
        return obj;
    }

    renderButtons() {
        let disabled = false;
        if(this.state.tickeT_SUBJECT==="" || this.state.tickeT_DESC==="" || this.state.assignedToContact==="") {
            disabled = true;
        }
        return(
            <div>
                <Button type='button' disabled={disabled} label='Save and Exit' icon='pi pi-check' onClick={this.handleTicketSave} />
                <Button type='button' label='Exit' icon='pi pi-times' onClick={this.handleCancel} />
            </div>
        )
    }

    render(){    
        const buttonFooter=this.renderButtons();
        if(this.state.toSupportCases) {
            return(
                <Redirect to='/supportcases' />
            ); 
        } else {
            return(
                <div>
                    <Dialog header={this.state.dialogHeader}  visible={this.state.detailDialog} footer={buttonFooter} onHide={this.handleCancel} baseZIndex={1300} maximized blockScroll focusOnShow={false} closable={false}>
                        <div style={{width:'99vw', maxWidth:'100%'}}>
                            <div>
                                <div className="p-grid p-justify-center">
                                    <div className="p-col-12 p-lg-8"><strong>Please provide the following details to help us provide you with quick assistance</strong></div>
                                    <div className="p-col-12 p-lg-8">
                                        <div className='p-grid p-justify-start'>
                                            <div className="p-col-12 p-lg-2" style={{textAlign:'left'}}>
                                                    <strong>{"Category: "}</strong>
                                            </div>
                                            <div className='p-col-12 p-lg-12' >
                                                <Dropdown placeholder={"Select Category"} style={{textAlign:'left', width:'100%'}} disabled={this.state.disabled} options={categoryItems}
                                                value={this.state.category} onChange={(e) => this.handleFieldChange(e)} type='text' id={"category"} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-lg-8">
                                        <div className='p-grid p-justify-start'>
                                            <div className="p-col-12 p-lg-2" style={{textAlign:'left'}}>
                                                    <strong>{"Priority: "}</strong>
                                            </div>
                                            <div className='p-col-12 p-lg-12' >
                                                <Dropdown placeholder={"Select Priority"} disabled={this.state.disabled} style={{textAlign:'left', width:'100%'}} options={priorityItems}
                                                value={this.state.priority} onChange={(e) => this.handleFieldChange(e)} type='text' id={"priority"} />
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="p-col-12 p-lg-8">
                                        <div className='p-grid p-justify-start'>
                                            <div className="p-col-12 p-lg-2" style={{textAlign:'left'}}>
                                                    <strong>{"Support Type: "}</strong>
                                            </div>
                                            <div className='p-col-12 p-lg-12' >
                                                <Dropdown placeholder={"Select Support Type"} disabled={this.state.disabled} style={{textAlign:'left', width:'100%'}} options={assigneeItems}
                                                value={this.state.assignedTo} onChange={(e) => this.handleFieldChange(e)} type='text' id={"assignedTo"} />
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {this.state.assignedTo === "Internal Support" ? 
                                        (
                                            <div className="p-col-12 p-lg-8">
                                                <div className='p-grid p-justify-start'>
                                                    <div className="p-col-12 p-lg-4" style={{textAlign:'left'}}>
                                                            <strong>{"Internal Support Contact: "}</strong>
                                                    </div>
                                                    <div className='p-col-12 p-lg-12' >
                                                        <Dropdown placeholder={"Internal Support Contact"} disabled={this.state.disabled} style={{textAlign:'left', width:'100%'}} options={this.state.assignedToContactOptions}
                                                        value={this.state.assignedToContact} onChange={(e) => this.handleFieldChange(e)} type='text' id={"assignedToContact"} />
                                                    </div>
                                                </div>
                                            </div>
                                        ): 
                                    (null)}

                                    <div className="p-col-12 p-lg-8">
                                        <div className='p-grid p-justify-start'>
                                            <div className="p-col-12 p-lg-2" style={{textAlign:'left'}}>
                                                    <strong>{"Short Description: "}</strong>
                                            </div>
                                            <div className='p-col-12 p-lg-12' >
                                                <InputText placeholder={"[Enter Brief Description Here]"} disabled={this.state.disabled} style={{width:'100%'}} 
                                                value={this.state.tickeT_SUBJECT} onChange={(e) => this.handleFieldChange(e)} type='text' id={"tickeT_SUBJECT"} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-col-12 p-lg-8">
                                        <div className='p-grid p-justify-start'>
                                            <div className="p-col-12 p-lg-2" style={{textAlign:'left'}}>
                                                    <strong>{"Long Description: "}</strong>
                                            </div>
                                            <div className='p-col-12 p-lg-12' >
                                                <InputTextarea autoResize rows={5} placeholder={"[Enter Long Description Here]"} disabled={this.state.disabled} style={{width:'100%'}} 
                                                value={this.state.tickeT_DESC} onChange={(e) => this.handleFieldChange(e)} type='text' id={"tickeT_DESC"} />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {/* commented out until we are ready to do images
                                {this.state.tickeT_SUBJECT==="" ? 
                                    (null) : 
                                    (<TicketFilesComponent 
                                        blobFilePath1={`${this.state.tickeT_NO}-file1`}
                                        blobFilePath2={`${this.state.tickeT_NO}-file2`}
                                        blobFilePath3={`${this.state.tickeT_NO}-file3`}
                                        blobFilePath4={`${this.state.tickeT_NO}-file4`}
                                        blobFilePath5={`${this.state.tickeT_NO}-file5`}
                                        containerName={"-support-case"}
                                        newCase={true}
                                        newCaseDetail={false}
                                        saveNewTicket={this.handleTicketSave}
                                        updateTicket={this.updateTicket}
                                    />)
                                } */}
                            </div>
                        </div>
                    </Dialog>
                </div>
            );
        }
    }
}

export default NewSupportTicket;